import {HubConnection} from "@microsoft/signalr";
import {AppThunkAction} from "./index";
import {ChatThread} from "../models/chat/ChatThread";
import {ChatMessage} from "../models/chat/ChatMessage";
import {
    initialWebsocketRequestState,
    KnownWebsocketRequestAction,
    websocketRequestActionNames,
    WebsocketRequestActionType,
    WebsocketRequestActionTypes,
    websocketRequestReducer,
    WebsocketRequestState
} from "./WebsocketRequestState";
import {appSignalRClient, startSignalRConnection} from "../services/appSignalRClient";

const stateName = 'CHATS_STATE';

export type ChatsState = WebsocketRequestState<Required<ChatThread>>;

export const initialChatsState: ChatsState = initialWebsocketRequestState<Required<ChatThread>>();

export interface ChatsReadAction {
    name: string;
    type: 'CHATS_READ';
    element: ChatThread;
}

export type KnownChatAction =
    KnownWebsocketRequestAction<Required<ChatThread>>
    | ChatsReadAction;

export interface ChatsActionTypes extends WebsocketRequestActionTypes {
    readonly READ: 'CHATS_READ';
}

export const chatsActionNames: ChatsActionTypes = {
    ...websocketRequestActionNames,
    READ: 'CHATS_READ'
};

export type ChatActions = WebsocketRequestActionType<KnownChatAction> & {
    setSelected(thread: ChatThread | undefined): AppThunkAction<KnownChatAction>;
    createThread(thread: ChatThread): AppThunkAction<KnownChatAction>;
    createMessage(threadId: string, message: ChatMessage): AppThunkAction<KnownChatAction>;
    updateThreadName(threadId: string, name: string): AppThunkAction<KnownChatAction>;
    updateRead(threadId: string, userId: string): AppThunkAction<KnownChatAction>;
    updateAttendents(threadId: string, attendents: string[]): AppThunkAction<KnownChatAction>;
    deleteThread(thread: ChatThread): AppThunkAction<KnownChatAction>;
    deleteChatMessage(threadId: string, message: ChatMessage): AppThunkAction<KnownChatAction>;
}

let client: HubConnection;
export const chatsActions: ChatActions = {
    connect: (userId: string) => {
        return async (dispatch) => {
            await dispatch({name: stateName, type: chatsActionNames.LOADING});

            const hubPath = 'chatHub';
            client = appSignalRClient(hubPath, 'userId=' + userId, false);

            client.on('readQueuedChatThreads', (threads: ChatThread[]) => {
                dispatch({name: stateName, type: chatsActionNames.REFRESH, elements: threads});
            });

            client.on('readChatThread', (thread: ChatThread) => {
                dispatch({name: stateName, type: chatsActionNames.RECEIVE, element: thread, playSound: true});
            });

            await startSignalRConnection(hubPath, client);
            await dispatch({name: stateName, type: chatsActionNames.CONNECT});
        }
    },
    disconnect: () => {
        return async (dispatch) => {
            await dispatch({name: stateName, type: chatsActionNames.DISCONNECT});
            await client.stop();
        }
    },
    setSelected: (thread: ChatThread) => {
        return async (dispatch) => {
            await dispatch({name: stateName, type: chatsActionNames.EDIT, element: thread})
        }
    },
    createThread: (thread: ChatThread) => {
        return async (dispatch) => {
            const newThread = await client.invoke('createChatThread', thread);
            await dispatch({name: stateName, type: chatsActionNames.EDIT, element: newThread})
        }
    },
    createMessage: (threadId, message: ChatMessage) => {
        return async () => {
            await client.invoke('createChatMessage', threadId, message);
        }
    },
    updateThreadName: (threadId: string, name: string) => {
        return async () => {
            await client.invoke('updateChatThreadName', threadId, name);
        }
    },
    updateRead: (threadId: string, userId: string) => {
        return async () => {
            await client.invoke('updateChatThreadReadByIds', threadId, userId);
        }
    },
    updateAttendents: (threadId: string, attendents: string[]) => {
        return async () => {
            await client.invoke('UpdateChatThreadAttendents', threadId, attendents)
        }
    },
    deleteThread: (thread) => {
        return async (dispatch) => {
            await client.invoke("DeleteChatThread", thread.id);
            await dispatch({name: stateName, type: chatsActionNames.DELETE, element: thread});
        }
    },
    deleteChatMessage: (threadId, message) => {
        return async () => {
            await client.invoke("DeleteChatMessage", threadId, message);
        }
    }
};

export const chatsReducer = websocketRequestReducer<Required<ChatThread>, ChatsState>(stateName, initialChatsState,
    (state): ChatsState => {
        // const action = incomingAction as KnownChatAction;
        // TODO: ADD CUSTOM
        return state as ChatsState;
    }
);
