import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Routes from 'constants/Routes';

import HomePage from 'pages/home/HomePage';
import StatisticsPage from 'pages/statistics/StatisticsPage';
import ShiftPlannerPage from 'pages/shiftplanner/ShiftPlannerPage';
import EventUserTimeTable from 'pages/calendar/EventUserTimeTable';
import UsersPage from 'pages/admin/UsersPage';
import ArticlePage from 'pages/support/ArticlePage';
import SupportPage from 'pages/support/SupportPage';
import ProfilePage from 'pages/profile/ProfilePage';
import PermissionRoute from 'components/PermissionRoute';
import CentersPage from 'pages/admin/CentersPage';
import PermissionsPage from 'pages/admin/PermissionsPage';
import InquiryPage from "../inquiry/InquiryPage";
import ChatPage from "../chat/ChatPage";

const DashboardSwitch: React.FC = () => {
    return (
        <Switch>
            <Route exact path={Routes.dashboard} component={HomePage}/>
            <Route path={Routes.statistics} component={StatisticsPage}/>

            <Route path={Routes.chat + '/:threadId?'} component={ChatPage}/>
            <Route path={Routes.inquiry} component={InquiryPage}/>

            <Route path={Routes.shiftPlanner + '/:eventId?'} component={ShiftPlannerPage}/>
            <Route path={Routes.eventUserTimeTable} component={EventUserTimeTable}/>
            <Route path={Routes.users} component={UsersPage}/>

            <Route path={Routes.supportArticle} component={ArticlePage}/>
            <Route path={Routes.support} component={SupportPage}/>

            <Route path={Routes.profile} component={ProfilePage}/>

            {/* READY FOR PRODUCTION */}
            <PermissionRoute path={Routes.centers} component={CentersPage} permission={'Permissions.Admins.all'}/>
            <Route path={Routes.permissions} component={PermissionsPage}/>

            <Route>
                <Redirect to={Routes.dashboard}/>
            </Route>
        </Switch>
    )
}

export default DashboardSwitch;
