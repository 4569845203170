import React, {useState} from "react";
import {ChatThread} from "../../models/chat/ChatThread";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import StyledTooltip from "../../components/common/StyledTooltip";
import {IconButton} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ManageChatDialog from "./ManageChatDialog";

const AddOrRemovePeople: React.FC<{ thread: ChatThread | undefined }> = ({thread}) => {
    const users = useSelector((state: AppState) => state.users.elements);
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <StyledTooltip title="Tilføj/fjern personer">
                <IconButton onClick={handleClick}>
                    <GroupAddIcon/>
                </IconButton>
            </StyledTooltip>
            <ManageChatDialog
                open={open}
                onClose={handleClose}
                selectedThread={thread}
                users={users}
            />
        </React.Fragment>
    )
}

export default AddOrRemovePeople;
