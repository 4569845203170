import React, {CSSProperties} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box/Box";
import MessageTimestamp from "../../components/message/MessageTimestamp";
import ListItem from "@material-ui/core/ListItem/ListItem";
import PersonAvatar from "../PersonAvatar";
import {ListItemAvatar} from "@material-ui/core";

const useStyles = makeStyles<Theme, boolean>(({palette, spacing}) => ({
    root: {
        cursor: "pointer",
        boxShadow: 'inset 0 -1px 0px 0px rgba(224, 224, 224)',
        height: 80,
        padding: spacing(1, 2),
        position: 'relative',
        backgroundColor: isSelected => (isSelected ? palette.action.hover : palette.common.white),
    },
    primaryText: {
        width: 144,
        fontSize: 16,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingRight: spacing(1),
        fontWeight: isSelected => (isSelected ? 'bold' : 'normal'),
    },
    secondaryText: {
        display: 'block',
        fontSize: 13,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '50%',
        opacity: 0.7
    },
    selectedIndicator: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4px',
        height: '100%',
        backgroundColor: palette.secondary.main,
    },
    flexRow: {
        paddingBottom: spacing(0.5),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}));

interface ThreadCardProps {
    id: string;
    primary: string;
    secondary?: string;
    color?: string;
    avatar?: true;
    isSelected: boolean;
    updatedAt: Date;
    onSelect: (id: string) => void;
    indicators?: React.ReactNode;
    style: CSSProperties; // VIRTUALIZED LIST MUST PASS STYLE TO CHILD OTHERWISE IT DOESNT WORK!
}

const ThreadCard: React.FC<ThreadCardProps> = ({
    id,
    primary,
    secondary,
    color,
    avatar,
    isSelected,
    updatedAt,
    onSelect,
    indicators,
    style,
}) => {
    const classes = useStyles(isSelected);
    const handleClick = () => onSelect(id);

    return (
        <ListItem button className={classes.root} style={style} key={id} onClick={handleClick}>
            {isSelected && <div className={classes.selectedIndicator}/>}
            {avatar && (
                <ListItemAvatar>
                    <PersonAvatar name={primary} color={color}/>
                </ListItemAvatar>
            )}
            <Box display='flex' flexDirection='column' width='100%'>
                <div className={classes.flexRow}>
                    <span className={classes.primaryText}>{primary}</span>
                    {indicators}
                </div>
                <div className={classes.flexRow}>
                    <span className={classes.secondaryText}>{secondary}</span>
                    <MessageTimestamp updatedAt={updatedAt}/>
                </div>
            </Box>
        </ListItem>
    );
};

export default ThreadCard;
