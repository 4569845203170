import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

import {useCurrentUser} from '../../hooks/useCurrentUser';
import {userListActions} from "../../store/UsersState";
import eventsActions from "../../store/EventsState";
import {mobileMediaQuery} from "../../hooks/useMediaQueryMobile";
import {notificationsActions} from "../../store/NotificationsState";
import {chatsActions} from "../../store/ChatsState";

import Navigation from './Navigation';
import TopBar from './TopBar';
import DashboardSwitch from './DashboardSwitch';
import {inquiryActions} from "../../store/InquiriesState";

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        width: '100vw',
        minHeight: '100vh',
        position: 'relative',
        display: 'flex',
        overflow: 'auto',
    },
    content: {
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        ['@media ' + mobileMediaQuery]: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
}));

const DashboardPage: React.FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useCurrentUser();

    useEffect(() => {
        // dispatch(smsThreadsActions.connect());
        // dispatch(webThreadActions.connect());
        dispatch(inquiryActions.connect());
        dispatch(userListActions.refresh());
        dispatch(eventsActions.init());
        dispatch(chatsActions.connect(currentUser.id));
        dispatch(notificationsActions.connect(currentUser.id));
    }, [currentUser.id, dispatch]);

    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const toggleDrawer = (toggle?: boolean) => {
        if (toggle !== undefined) {
            setShowDrawer(toggle);
            return
        }
        setShowDrawer(!showDrawer);
    };

    return (
        <div className={classes.root}>
            <Navigation
                currentUser={currentUser}
                showDrawer={showDrawer}
                toggleDrawer={toggleDrawer}
            />
            <main className={classes.content}>
                <TopBar onToggle={toggleDrawer}/>
                <DashboardSwitch/>
            </main>
        </div>

    );
};

export default DashboardPage;
