import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import TextField from '@material-ui/core/TextField/TextField';
import daLocale from 'date-fns/locale/da';
import DateFnsUtils from '@date-io/date-fns';
import {FormControl, InputLabel, makeStyles, MenuItem, Select} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import User, {emptyPendingUser, emptyUser, PendingUser} from '../../models/User';
import Validators from '../../services/Validators';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';

import {headspaceAssociations} from '../../constants/businessLogic';
import {useCurrentUser} from '../../hooks/useCurrentUser';
import {NamedSelectFormField} from '../../components/form_fields/NamedSelectFormField';
import toIdList from '../../lib/toIdList';
import Center from '../../models/Center';
import Role from '../../models/Role';
import {SingleSelectFormField} from '../../components/form_fields/SingleSelectFormField';
import Tooltip from "@material-ui/core/Tooltip";


export const userColorOptions = [{
    name: 'Rådgiver',
    color: '#76d275',
}, {
    name: 'Afdækkende rådgiver',
    color: '#6ab7ff',
}];
/*
, {
    name: 'Andet',
    color: '#ff6f60',
}
 */


const useStyles = makeStyles(() => ({
    checkThis: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: -9,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    centerPickerField: {
        display: 'flex',
        alignItems: 'flex-end',
        '& :first-child': {
            marginBottom: 0,
        },
    },
    datePickerField: {
        display: 'flex',
        alignItems: 'flex-end',
        '& :first-child': {
            marginBottom: 0,
        },
    },
}));

interface PendingUserFormProps {
    user: User | undefined;
    onSubmit: (user: PendingUser) => void;
    submitButtonRef: React.RefObject<HTMLButtonElement>;
}


export const PendingUserForm: React.FC<PendingUserFormProps> = ({user = emptyUser, onSubmit, submitButtonRef}) => {
    const classes = useStyles();

    const currentUser = useCurrentUser();
    const roles = currentUser.roles;
    const centers = currentUser.centers;


    const [selectedCenterIdList, setSelectedCenterIdList] = useState<string[]>(toIdList(user?.centers));
    const [selectedRoleIdList, setSelectedRoleIdList] = useState<string[]>(toIdList(user?.roles));


    const handleSave = async (values: PendingUser) => {
        onSubmit({
            ...values,
            terminationDate: values.terminationDate ?? new Date("0001-01-01T00:00:00+00:00"),
            centerIds: selectedCenterIdList,
            roleIds: selectedRoleIdList,
        });
    };


    const initialValues = {
        ...emptyPendingUser,
        ...user,
        pending: true,
        employmentDate: new Date(String(user.employmentDate)),
    };

    return (
        <Formik<PendingUser>
            onSubmit={handleSave}
            initialValues={initialValues}
            validateOnBlur={true}
            validateOnChange={false}
        >
            {({values, touched, errors, setFieldValue}) => {
                return (
                    <Form>
                        <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        validate={Validators.notEmpty}
                                        error={errors.firstName && touched.firstName && values.firstName.length !== 0}
                                        helperText={values.firstName.length !== 0 && errors.firstName}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Fornavn"
                                        name="firstName"
                                        type="text"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        validate={Validators.notEmpty}
                                        error={errors.lastName && touched.lastName && values.lastName.length !== 0}
                                        helperText={values.lastName.length !== 0 && errors.lastName}
                                        margin="normal"
                                        fullWidth
                                        required
                                        name="lastName"
                                        label="Efternavn"
                                        type="text"
                                        id="lastName"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        validate={Validators.email}
                                        error={errors.email && touched.email && values.email.length !== 0}
                                        helperText={values.email.length !== 0 && errors.email}
                                        margin="normal"
                                        disabled={!!user.email}
                                        fullWidth
                                        required
                                        id="email"
                                        name="email"
                                        label="Email"
                                        type="email"
                                        autoComplete="email"
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.centerPickerField}>
                                    <NamedSelectFormField<Required<Center>>
                                        id={'centerIds'}
                                        label={'Center'}
                                        options={centers}
                                        selectedIdList={selectedCenterIdList}
                                        setSelectedIdList={setSelectedCenterIdList}
                                        error={errors.centerIds && touched.centerIds}
                                        fullWidth
                                        required
                                        multiple
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SingleSelectFormField
                                        id={'employment'}
                                        label={'Tilknytning til headspace'}
                                        options={headspaceAssociations}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.datePickerField}>
                                    <Field
                                        as={KeyboardDatePicker}
                                        format="dd / MM / yyyy"
                                        mask="__ / __ / ____"
                                        variant="inline"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="employmentDate"
                                        label="Ansættelsesdato"
                                        name="employmentDate"
                                        validate={(date: Date) => {
                                            const invalidDate = date === undefined || date.toString().toLowerCase().includes('invalid');
                                            return invalidDate ? 'Ugyldig dato' : undefined;
                                        }}
                                        error={!!errors.employmentDate}
                                        helperText={errors.employmentDate}
                                        onChange={(date) => setFieldValue('employmentDate', date, false)}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.datePickerField}>
                                    <Field
                                        as={KeyboardDatePicker}
                                        format="dd / MM / yyyy"
                                        mask="__ / __ / ____"
                                        variant="inline"
                                        margin="normal"
                                        fullWidth
                                        id="terminationDate"
                                        label="Fratrædelsesdato"
                                        name="terminationDate"
                                        error={!!errors.terminationDate}
                                        helperText={errors.terminationDate}
                                        onChange={(date) => setFieldValue('terminationDate', date, false)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NamedSelectFormField<Required<Role>>
                                        id={'roleIds'}
                                        label={'Systemrettigheder'}
                                        options={roles}
                                        selectedIdList={selectedRoleIdList}
                                        setSelectedIdList={setSelectedRoleIdList}
                                        error={errors.roleIds && touched.roleIds}
                                        fullWidth
                                        required
                                        multiple
                                        defaultItem={{name: 'Kan se brugere, opslag, vagter og beskeder'}}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <Tooltip
                                            title={"Bruger farven bliver brugt til at indikere erfarings niveau i forhold til rådgivning af unge"}
                                        >
                                            <InputLabel
                                                id={'color-input-label'}
                                                style={{display: 'flex', alignItems: 'start'}}
                                            >
                                                {'Farve'}
                                                <HelpOutlineRoundedIcon
                                                    style={{fontSize: 18, marginLeft: 8, marginTop: "-2"}}
                                                />
                                            </InputLabel>
                                        </Tooltip>
                                        <Field
                                            as={Select}
                                            fullWidth
                                            label={'Farve'}
                                            labelId={'color-input-label'}
                                            id={'color'}
                                            name={'color'}
                                            style={{backgroundColor: values.color, paddingLeft: 8}}
                                        >
                                            <MenuItem value={undefined} disabled>
                                                {'Farve'}
                                            </MenuItem>
                                            {userColorOptions.map((option) => (
                                                <MenuItem key={option.color} value={option.color}
                                                          style={{backgroundColor: option.color}}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>



                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        multiline={true}
                                        rows={4}
                                        margin="normal"
                                        fullWidth
                                        id="notes"
                                        label="Notefelt"
                                        name="notes"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        as={TextField}
                                        multiline={true}
                                        rows={4}
                                        margin="normal"
                                        fullWidth
                                        id="emergencyContact"
                                        label="Nødkontakt"
                                        name="emergencyContact"
                                        type="text"
                                        placeholder={'Navn, (relation)\n+45 12345678'}
                                    />
                                </Grid>

                                <Grid item xs={3} className={classes.checkThis}>
                                    <Field
                                        as={Checkbox}
                                        margin="normal"
                                        name="verifiedCriminalRecord"
                                        label="Godkendt straffeattest"
                                        id="verifiedCriminalRecord"
                                        checked={values.verifiedCriminalRecord}
                                        onChange={(event) => setFieldValue('verifiedCriminalRecord', event.target.checked, false)}
                                    />
                                    <Typography component="p" variant="body1">
                                        Straffeattest er godkendt
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} className={classes.checkThis}>
                                    <Field
                                        as={Checkbox}
                                        margin="normal"
                                        name="verifiedChildCertificate"
                                        label="Godkendt børneattest"
                                        id="verifiedChildCertificate"
                                        checked={values.verifiedChildCertificate}
                                        onChange={(event) => setFieldValue('verifiedChildCertificate', event.target.checked, false)}
                                    />
                                    <Typography component="p" variant="body1">
                                        Børneattest er godkendt
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} className={classes.checkThis}>
                                    <Field
                                        as={Checkbox}
                                        margin="normal"
                                        name="isOnLeave"
                                        label="Orlov"
                                        id="isOnLeave"
                                        checked={values.isOnLeave}
                                        onChange={(event) => setFieldValue('isOnLeave', event.target.checked, false)}
                                    />
                                    <Typography component="p" variant="body1">
                                        Personen er på orlov
                                    </Typography>
                                </Grid>
                            </Grid>

                        </MuiPickersUtilsProvider>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PendingUserForm;


