import React from "react";
import {Box, Button, IconButton} from "@material-ui/core";
import {Asset} from "../../models/file/Asset";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {HOST} from "../../constants/config";
import {lookup} from "mime-types";
import {getExtension} from "../../lib/files";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        '&:hover $actionWrapper': {
            opacity: 1
        }
    },
    messageSenderName: {
        fontSize: 14,
        color: theme.palette.grey['700'],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        textAlign: 'center'
    },
    messageBox: {
        maxWidth: '60%',
        padding: theme.spacing(2),
        margin: theme.spacing(0, 1),
        borderRadius: 7,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        '& a, a:hover, a:active, a:focus, a:visited': {
            color: 'white',
        }
    },
    imgMessageBox: {
        maxWidth: '60%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        borderRadius: 5,
        padding: 0,
        margin: theme.spacing(0, 1),
        border: "solid 1px #d2d2d2",
        cursor: 'pointer',
        textDecoration: 'none'
    },
    actionWrapper: {
        opacity: 0,
        [theme.breakpoints.down("sm")]: {
            opacity: 1
        }
    }
}));

interface MessageAssetDisplayProps {
    asset: Asset;
    me: boolean;
}

const MessageAssetDisplay: React.FC<MessageAssetDisplayProps> = ({asset, me}) => {

    const classes = useStyles();

    const {palette} = useTheme();
    const alignment = me ? 'flex-end' : 'flex-start';

    const boxStyle = {
        color: me ? palette.common.white : palette.common.black,
    };

    const messageBoxStyle = {
        ...boxStyle,
        backgroundColor: me ? palette.secondary.main : palette.grey['200']
    };

    const flexDirection = me ? 'row-reverse' : 'row';
    const assetUrl = HOST + "/api/v1/files/" + asset.filePathName + "?filename=" + asset.fileName;
    const mimeType = lookup(getExtension(asset.filePathName));
    const isImage = mimeType.includes('image');

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={alignment}
            className={classes.contentWrapper}
        >
            <Box display={'flex'}
                 alignItems='center'
                 flexDirection={flexDirection}
                 ml={me ? 2 : 0}
                 mr={me ? 0 : 2}
                 mt={2}
            >
                {isImage ? (
                    <Button className={classes.imgMessageBox} style={boxStyle}>
                        <img alt={asset.fileName} style={{maxWidth: '100%', maxHeight: 400, objectFit: 'cover'}} src={assetUrl}/>
                    </Button>
                ) : (
                    <a href={assetUrl} className={classes.messageBox} style={messageBoxStyle}>
                        {asset.fileName}
                    </a>
                )}
                <a href={assetUrl} style={{display: "block"}}>
                    <IconButton size='small' color='primary'>
                        <CloudDownloadIcon/>
                    </IconButton>
                </a>
            </Box>
        </Box>
    )
};

export default MessageAssetDisplay;