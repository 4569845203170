export interface CrudPermission {
    create: string;
    read: string;
    update: string;
    delete: string;
}

export interface AllPermissions {
    readonly users: CrudPermission;
    readonly centers: CrudPermission;
}

export const allPermissions: AllPermissions = {
    users: {
        create: 'Permissions.Users.Create',
        read: 'Permissions.Users.Read',
        update: 'Permissions.Users.Update',
        delete: 'Permissions.Users.Delete',
    },
    centers: {
        create: 'Permissions.Centers.Create',
        read: 'Permissions.Centers.Read',
        update: 'Permissions.Centers.Update',
        delete: 'Permissions.Centers.Delete',
    },
};

export default allPermissions;