import React from 'react';
import Validators from '../../services/Validators';
import RequestForm from '../../components/RequestForm';
import {Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {BackToLoginButton} from '../../components/buttons/BackToLoginButton';
import {useSelector} from 'react-redux';
import {AppState} from '../../store';
import appService from "../../services/appService";

export const ForgotPasswordForm: React.FC = () => {
    const location = useSelector((state: AppState) => state?.router?.location);
    const email = (location?.state as { email?: string })?.email;

    return (
        <React.Fragment>
            <Box width="100%" pt={2} pb={2} display={'flex'} justifyContent={'flex-start'}>
                <Typography>Indtast din email, så sender vi dig instruktioner om at nulstille din
                    adgangskode</Typography>
            </Box>
            <RequestForm<{ email: string }>
                request={async (values) => {
                    const response = await appService.auth.forgotPassword(values.email);
                    if (response.success) {
                        return {
                            severity: 'success',
                            message: 'Vi har sendt dig en mail med information om hvordan du nulstiller dit adgangskode',
                        };
                    }
                    return response.feedback;
                }} //AuthService.forgotPassword(values.email))}
                buttonText={'Nulstil adgangskode'}
                fields={[
                    {
                        id: 'email',
                        label: 'Email',
                        validator: Validators.email,
                        type: 'email',
                        initialValue: email,
                    },
                ]}
            />
            <BackToLoginButton/>
        </React.Fragment>
    );
};

export default ForgotPasswordForm;
