import manageCalendars from './images/outlook/manage_calendars.png';

export default `# Brug Outlook og den integrerede vagtplan

Har du succesfuldt integreret vagtplanen med din Outlook, men oplever problemer med at få det hele til at spille?

Læs med herunder.

## Kan du ikke se vagtplanen i Outlook?

Når du integrerer en vagtplan med din Outlook kalender, oprettes der en ny ”kalendergruppe”, fx ”HS Aalborg”. Under den kalendergruppe oprettes der en kalender for hhv. vagter og samtaler: ”Vagt: Aalborg! Og Samtale: Aalborg”.

Du skal slå begge kalendere til. Først derefter vil de være synlige i din Outlook-kalender.

![Outlook: Slå kalendrene til](${manageCalendars})

Du slår kalenderne til ved at trykke på hver af dem, så deres cirkler går fra at være tomme til farvede.
`
// ## Opret vagter eller samtaler fra Outlook
//
// Hvis du vil oprette vagter eller samtaler fra Outlook, så er det vigtigt, at du vælger at oprette aftalen i den rigtige kalender.
// Hvis du fx vil oprette en samtale, så skal du vælge ”Samtale: Aalborg” fra listen af kalendere.
//
// ![Outlook: Vælg kalender](${pickCalendar})
// `