import ThreadHeader, {ThreadHeaderProps} from "./ThreadHeader";
import {Box, IconButton, Menu, MenuItem, Paper} from "@material-ui/core";
import React, {useState} from "react";
import MessagesDisplay from "./MessagesDisplay";
import MessageInput from "./MessageInput";
import SendButton from "../buttons/SendButton";
import MessageInputContainer from "./MessageInputContainer";
import {AuthorMessage} from "../../models/Identifiable";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {mobileMediaQuery} from "../../hooks/useMediaQueryMobile";
import EmojiPicker from "./EmojiPicker";
import AssetPickerWrapper from "../assets/AssetPickerWrapper";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {Asset, LoadedAsset} from "../../models/file/Asset";
import appService from "../../services/appService";
import UploadedAssets from "./UploadedAssets";
import renderMessage from "./renderMessage";

const useStyles = makeStyles(() => ({
    hide: {
        ["@media " + mobileMediaQuery]: {
            display: "none"
        }
    }
}));

interface MessageInputProps {
    onSendMessage: (text: string, files?: Asset[]) => void;
    withEmoji?: true;
    withAssets?: true;
}

type HeaderProps = Omit<ThreadHeaderProps, "threadId" | "emptyAdornment" | "endAdornment"> & {
    emptyHeader?: React.ReactNode;
    endHeader?: React.ReactNode;
}

type ThreadDisplayProps<M extends AuthorMessage> = HeaderProps & MessageInputProps & {
    selectedId?: string;
    children?: React.ReactNode;
    identifyMe: (messageAuthorId: string) => boolean;
    onDeleteMessage: (threadId: string, message: M) => void;
    messages: M[];
    signature?: string | undefined;
};

const ThreadDisplay = <M extends AuthorMessage>({
    title,
    subtitle,
    color,
    messages,
    onBack,
    onTitleChange,
    onSendMessage,
    onThreadDelete,
    children,
    selectedId,
    emptyHeader,
    endHeader,
    identifyMe,
    onDeleteMessage,
    withEmoji,
    withAssets,
    signature,
}: ThreadDisplayProps<M>) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedMessage, setSelectedMessage] = useState<M>();
    const [textMap, setTextMap] = useState<{ [p: string]: string }>({});
    const [assetsMap, setAssetsMap] = useState<{ [p: string]: LoadedAsset[] }>({});

    const handleInputChange = (text = '') => {
        setTextMap({
            ...textMap,
            [String(selectedId)]: text
        });
    };
    const handleMessageActionClick = (event: React.MouseEvent<HTMLButtonElement>, message: M) => {
        setSelectedMessage(message);
        setAnchorEl(event.currentTarget);
    };
    const handleMessageActionClose = () => {
        setSelectedMessage(undefined);
        setAnchorEl(null);
    };
    const handleDeleteMessage = () => {
        if (selectedId && selectedMessage) onDeleteMessage(selectedId, selectedMessage);
        handleMessageActionClose();
    };
    const handleMessageRendering = renderMessage(identifyMe, handleMessageActionClick);
    const handleEmojiChange = (s: string) => handleInputChange(s);
    const handleChangeAssets = (assets: LoadedAsset[]) => {
        setAssetsMap({
            ...assetsMap,
            [String(selectedId)]: assets
        });
    };
    const handleSubmit = async (text: string) => {
        const files: Asset[] = [];
        if (withAssets) {
            for (const asset of assets) {
                const file = await appService.files.createFile(asset.file);
                if (file.success) {
                    files.push(file.value);
                }
            }
            handleChangeAssets([]);
        }

        await onSendMessage(text, files);
        handleInputChange("");
    }

    const text = textMap[String(selectedId)] ?? ""; //selectedId !== undefined ? textMap[selectedId] !== undefined ? textMap[selectedId] : "" : "";
    const assets = assetsMap[String(selectedId)] ?? []; //selectedId !== undefined ? assetsMap[selectedId] ?? [] : [];
    const inputProps = {
        endAdornment: (
            <>
                {withEmoji && <EmojiPicker value={text} onChange={handleEmojiChange}/>}
                {withAssets && (
                    <AssetPickerWrapper assets={assets} setAssets={handleChangeAssets}>
                        <IconButton size="small" component="span">
                            <AttachFileIcon/>
                        </IconButton>
                    </AssetPickerWrapper>
                )}
            </>
        )
    }
    const disabled = withAssets ? (text.length === 0 && assets.length === 0) : text.length === 0;
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            component={Paper}
            height="100%"
            className={!selectedId ? classes.hide : undefined}
        >
            <ThreadHeader
                threadId={selectedId}
                title={title}
                subtitle={subtitle}
                color={color}
                onBack={onBack}
                onTitleChange={onTitleChange}
                onThreadDelete={onThreadDelete}
                emptyAdornment={emptyHeader}
                endAdornment={endHeader}
            />
            <MessagesDisplay messages={messages} renderMessage={handleMessageRendering} />
            {children}
            {withAssets && <UploadedAssets assets={assets} onChange={handleChangeAssets} />}
            <MessageInputContainer signature={signature}>
                <MessageInput
                    value={text}
                    onChange={handleInputChange}
                    onSubmit={handleSubmit}
                    InputProps={inputProps}
                />
                <SendButton onClick={() => handleSubmit(text)} disabled={disabled}/>
            </MessageInputContainer>

            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMessageActionClose}
            >
                <MenuItem onClick={handleDeleteMessage}>
                    Slet besked
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default ThreadDisplay;
