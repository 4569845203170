import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../store';
import {CurrentUser, emptyCurrentUser} from '../models/User';
import {push} from 'connected-react-router';
import Routes from '../constants/Routes';

export const useCurrentUser = (): CurrentUser => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: AppState) => state.auth.currentUser);
    if (currentUser === undefined) {
        dispatch(push(Routes.landing));
    }
    return currentUser ?? emptyCurrentUser;
};

export default useCurrentUser;