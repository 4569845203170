import React from "react";
import Box from "@material-ui/core/Box";
import {Bar} from "react-chartjs-2";
import Call from "../../models/Call";
import {chartBackgroundColors, chartBorderColors} from "./UserBarChart";


const monthMillis = 2678400000;
const toFieldCountMap = (list: Call[]) => {
    if(!list || !list[0]) return [];
    const countMap = list.reduce<{ [p: string]: number }>((result, value) => {
            const label = new Date(value.createdAt).toISOString().split("T")[0];
            result[label] = !result[label] ? 1 : result[label] + 1;
            return result;
        },
        {}
    )
    return lastMonthCountMap(countMap);
}

export const lastMonthCountMap = (countMap) => {
    const now = new Date();
    const start = new Date(now.getTime() - monthMillis);
    const end = new Date();

    const result = {};
    const dt = new Date(start);
    while (dt <= end) {
        const key = new Date(dt).toISOString().split("T")[0];
        result[key] = countMap[key] || 0;
        dt.setDate(dt.getDate() + 1);
    }
    return result;

}



export const VideoBarChart: React.FC<{calls: Call[]}> = ({calls}) => {

    const fieldCountMap = toFieldCountMap(calls)
    const labels = Object.keys(fieldCountMap).sort((a, b) => a.localeCompare(b));
    const values = labels.map(label => fieldCountMap[label]).concat(0);


    return (
        <Box p={2}>
            <Box pl={2} pb={2} display={'flex'} justifyContent={'space-between'}>
                <span>{"Antal videoopkald"}</span>
            </Box>

            <Bar
                options={{legend: {display: false}}}
                data={{
                    labels: labels,
                    datasets: [{
                        label: '',
                        data: values,
                        backgroundColor: "#2CC66C", // chartBackgroundColors,
                        borderColor: "#2CC66C", // chartBorderColors,
                        borderWidth: 1
                    }]
                }}
            />
        </Box>
    )
}

export default VideoBarChart;