import {AuthorMessage} from "../../models/Identifiable";
import React from "react";
import {ChatMessage} from "../../models/chat/ChatMessage";
import {toLocalDateTimeString} from "../../lib/toLocalISO";
import Author from "../../models/Author";
import MessageBox from "./MessageBox";
import {IconButton, Tooltip} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MessageAssetDisplay from "../assets/MessageAssetDisplay";

type RenderMessageType = <M extends AuthorMessage>(
    isMe: (messageAuthorId: string) => boolean,
    onClick: (e: React.MouseEvent<HTMLButtonElement>, message: M) => void
) => (message: M) => JSX.Element;

const isChatMessage = (message: AuthorMessage): message is ChatMessage => {
    return (message as ChatMessage).author.firstName !== undefined;
}

const renderMessage: RenderMessageType = (isMe, onClick) => (message) => {
    const senderInfo = isChatMessage(message)
        ? `${message.author.firstName} ${message.author.lastName} - ${toLocalDateTimeString(message.createdAt)}`
        : `${(message.author as Author).name} - ${toLocalDateTimeString(message.createdAt)}`;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => onClick(event, message)
    const me = isMe(message.author.id)

    const key = message.id === "new" ? message.createdAt.getMilliseconds().toString() : message.id;
    return (
        <div key={key}>
            <MessageBox
                key={message.id}
                content={message.content}
                senderInfo={senderInfo}
                loading={message.id === "new"}
                me={me}
                action={(
                    <Tooltip title='Mere'>
                        <IconButton
                            aria-label='menu'
                            size='small'
                            onClick={handleClick}
                        >
                            <MoreVertIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                )}
            />
            {isChatMessage(message) && message.files?.map((asset) =>
                <MessageAssetDisplay key={asset.filePathName} asset={asset} me={me}/>
            )}
        </div>
    )
};

export default renderMessage;
