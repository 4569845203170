import appClient, {AppClient} from './appClient';
import RequestMethod from '../models/RequestMethod';
import AppResponse from "../models/httprequest/AppResponse";

const servicePath = "/api/v1/outlook/";

export interface UrlResponse {
    Url: string;
}

export interface AuthRequest {
    id: string;
    authorizationCode: string;
    antiForgeryToken: string;
}

export interface OutlookService {
    getAuthEndpoint: (id: string) => AppResponse<UrlResponse>;
    authorize: (authRequest: AuthRequest) => AppResponse<undefined>;
    isSynchronized: (id: string) => AppResponse<boolean>;
    deleteSynchronization: (id: string) => AppResponse<undefined>;
}

export const outlookServiceBuilder = (client: AppClient): OutlookService => ({
    async getAuthEndpoint(id: string): AppResponse<UrlResponse> {
        return await client.fetchJSON<UrlResponse>(RequestMethod.GET, servicePath + "center/" + id + "/auth-endpoint");
    },
    async authorize(authRequest: AuthRequest): AppResponse<undefined> {
        return await client.fetchJSON<undefined>(RequestMethod.POST, servicePath + "authorize", {
            ...authRequest,
        })
    },
    async isSynchronized(id: string): AppResponse<boolean> {
        return await client.fetchJSON<boolean>(RequestMethod.GET, servicePath + "center/" + id + "/synchronized");
    },
    async deleteSynchronization(id: string): AppResponse<undefined> {
        return await client.fetch<undefined>(RequestMethod.DELETE, servicePath + "center/" + id);
    }
});

export const outlookService = outlookServiceBuilder(appClient);

export default outlookService;
