import {Redirect, Route} from 'react-router';
import React from 'react';
import Routes from '../constants/Routes';
import {useSelector} from 'react-redux';
import {AppState} from '../store';

function PrivateRoute({component: Component, ...rest}) {
    const auth = useSelector((state: AppState) => state.auth);
    return <Route {...rest}>{auth.currentUser ? <Component/> : <Redirect to={Routes.landing}/>}</Route>;
}

export default PrivateRoute;
