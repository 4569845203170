import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import {push} from 'connected-react-router';
import Routes from '../../constants/Routes';
import {useDispatch} from 'react-redux';


export const BackToLoginButton: React.FC = () => {
    const dispatch = useDispatch();

    const onBackPress = () => {
        dispatch(push(Routes.landing));
    };

    return (
        <Box width="100%" mt={2} mb={1} display="flex" justifyContent="flex-start" alignItems="center">
            <Button color="primary" onClick={onBackPress}>
                <ArrowBackIcon/>
                <span style={{marginLeft: '4px'}}>Tilbage til log ind</span>
            </Button>
        </Box>
    );
};

export default BackToLoginButton;
