import React from 'react';
import config, {isProductionMode} from '../constants/config';


export const VersionTag: React.FC = () => {
    if (isProductionMode) {
        return <span/>
    }

    return (
        <span>headspace v{config.version} {config.API_ENV}</span>
    )
};

export default VersionTag;