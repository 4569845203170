import {ThemeOptions} from '@material-ui/core/styles';
import {green, lightBlue} from '@material-ui/core/colors';

// red
// #FE4A49
// #e24c26
// #e2264c

export const drawerWidth = 256;

export const defaultThemeConfig: ThemeOptions = {
    palette: {
        primary: {
            main: lightBlue[800],
            // main: lightBlue[800], //'#4CAF50', //44CF6C
            // light: '#7dff9c',
            // dark: '#009d3f', //5BBA6F
            // contrastText: '#ffffff'
        },
        secondary: {
            main: green[600], //HS
            // main: "#447D9F", //RD
            //     light: '#67daff',
            //     dark: '#007ac1',
        },
    },
    typography: {
        fontSize: 13,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                padding: '8px 16px',
                transition: 'opacity 0.5s cubic-bezier(0.12, 1.04, 0.77, 1.01)',
            },
        },
        MuiTableCell: {
            head: {
                fontWeight: 'bold',
            },
        },
    },
}

export default defaultThemeConfig;
