import {NODE_ENV} from '../constants/config';

export const debugPrint = (message?, ...optionalParams) => {
    // if (!isProductionMode && NODE_ENV !== 'test') {
    if (NODE_ENV !== 'test') {
        console.log(message, ...optionalParams);
    }
};

export default debugPrint;
