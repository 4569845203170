import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import {Field} from 'formik';
import {educationLevelOptions} from 'constants/businessLogic';

interface EducationLevelFormFieldProps {
    id: string;
    required?: boolean;
    style?: React.CSSProperties;
    error?: string | undefined;
    variant?: SelectProps['variant'];
}

export const EducationLevelFormField: React.FC<EducationLevelFormFieldProps> = ({id, required, style, error, variant}) => {
    const labelId = `${id}-label`;
    return (
        <FormControl fullWidth style={style}>
            <InputLabel id={labelId}>{`Højest fuldførte uddannelse${required ? ' *' : ''}`}</InputLabel>
            <Field
                as={Select}
                error={error}
                fullWidth
                required={required}
                name={id}
                label="Højest fuldførte uddannelse"
                labelId={labelId}
                id={id}
                variant={variant}
            >
                <MenuItem value={undefined} disabled>
                    Højest fuldførte uddannelse
                </MenuItem>
                {educationLevelOptions.map((edu) => (
                    <MenuItem key={edu} value={edu}>{edu}</MenuItem>
                ))}
            </Field>
        </FormControl>
    );
}

export default EducationLevelFormField;
