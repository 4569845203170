import React from 'react';
import BasePage from "../BasePage";
import useRequestValueList from "../../hooks/useRequestValueList";
import appService from "../../services/appService";
import ExportUserCSVButton from "./ExportUserCSVButton";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import UserBarChart from "./UserBarChart";
import InquiryBarChart from "./InquiryBarChart";
import VideoBarChart from "./VideoBarChart";
import ExportCallCSVButton from "./ExportCallCSVButton";

const StatisticsPage: React.FC = () => {
    const allUserData = useRequestValueList(appService.users.getAllData);
    const calls = useRequestValueList(appService.video.getAll);

    return (
        <BasePage>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box  display={'flex'}>
                        <ExportUserCSVButton userData={allUserData}/>
                        <Box width={16}/>
                        <ExportCallCSVButton calls={calls}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <UserBarChart users={allUserData}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} >
                    <Paper>
                        <InquiryBarChart/>
                    </Paper>
                </Grid>
                <Grid item xs={12} >
                    <Paper>
                        <VideoBarChart calls={calls}/>
                    </Paper>
                </Grid>
            </Grid>

        </BasePage>
    );
};


export default StatisticsPage;
