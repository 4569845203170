import React from "react";
import Popover from "@material-ui/core/Popover";
import {DatePicker, DatePickerProps, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import daLocale from "date-fns/locale/da";

type PopoverDatePickerProps = DatePickerProps & {
 children: JSX.Element;
}

const PopoverDatePicker: React.FC<PopoverDatePickerProps> = ({children, onChange, ...datePickerProps}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange: DatePickerProps["onChange"] = date => {
        onChange(date);
        handleClose();
    }

    return (
        <React.Fragment>
            {React.cloneElement(children, { onClick: handleClick })}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                    <DatePicker
                        disableToolbar
                        variant="static"
                        format="dd/MM/yyyy"
                        margin="normal"
                        onChange={handleChange}
                        {...datePickerProps}
                    />
                </MuiPickersUtilsProvider>
            </Popover>
        </React.Fragment>
    )
}

export default PopoverDatePicker
