import {makeStyles} from "@material-ui/core/styles";
import React, {ReactNode} from "react";
import Box from "@material-ui/core/Box/Box";
import {Paper} from "@material-ui/core";
import {FixedSizeList} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import LoadingOrError from "../../components/LoadingOrError";
import {mobileMediaQuery} from "../../hooks/useMediaQueryMobile";
import clsx from "clsx";

const ITEM_HEIGHT = 80;
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            maxWidth: 285,
        },
        [theme.breakpoints.up('lg')]: {
            width: '360px',
        },
        ['@media ' + mobileMediaQuery]: {
            maxWidth: '100%',
            margin: 0,
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    hide: {
        ["@media " + mobileMediaQuery]: {
            display: "none"
        }
    }
}));

interface ThreadListProps {
    selectedId?: string;
    elementsLength: number;
    loading: boolean;
    listItem: ({index, style}: { index: number; style: React.CSSProperties }) => ReactNode;
    children: React.ReactNode;
}

const ThreadList: React.FC<ThreadListProps> = (
    {
        selectedId,
        elementsLength,
        loading = false,
        listItem,
        children,
    }) => {
    const classes = useStyles();
    return (
        <Box component={Paper} className={clsx(classes.root, selectedId && classes.hide)}>
            <div className={classes.header}>
                {children}
            </div>
            <LoadingOrError loading={loading} type='list' skeletonHeight={64} skeletonCount={6}>
                <Box height="100%">
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                height={height}
                                width={width}
                                itemCount={elementsLength}
                                itemSize={ITEM_HEIGHT}>
                                {listItem}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            </LoadingOrError>
        </Box>
    )
};

export default ThreadList;