import {useSelector} from "react-redux";
import {AppState} from "../store";
import Center, {emptyCenter} from "../models/Center";

export const useCurrentCenter = (): Required<Center> => {
    const center = useSelector(((state: AppState) => state.auth.center));
    return {
        id: '',
        ...emptyCenter,
        ...center,
    };
};

export default useCurrentCenter;