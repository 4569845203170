import React from "react";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import {LoadedAsset} from "../../models/file/Asset";

const useStyles = makeStyles<Theme, { size: number }>((theme) => ({
    imgContainer: {
        width: props => props.size,
        height: props => props.size,
        borderRadius: 20,
        position: 'relative',
        overflow: 'hidden',
        marginRight: theme.spacing(1),
        border: "solid 1px #d2d2d2"
    }
}));

interface AssetImageThumbnailProps {
    asset: LoadedAsset;
    size: number;
}

export const AssetImageThumbnail: React.FC<AssetImageThumbnailProps> = ({children, asset, size}) => {
    const classes = useStyles({size});
    return (
        <Box display='flex'
             alignItems='center'
             justifyContent='center'
             className={classes.imgContainer}>
            <img alt={asset.file.name}
                 src={asset.dataUrl}
                 style={{
                     maxHeight: '110%'
                 }}/>
            {children}
        </Box>
    )
}

export default AssetImageThumbnail;