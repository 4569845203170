import React, {useState} from "react";
import Post from "../../models/Post";
import Box from "@material-ui/core/Box/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {Card, CardContent, CardHeader, Divider, IconButton} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import {toLocalDateTimeString} from "../../lib/toLocalISO";
import makeStyles from "@material-ui/core/styles/makeStyles";
import parse from 'html-react-parser';
import AddCommentIcon from '@material-ui/icons/AddComment';
import postsActions from "store/PostsState";
import {useDispatch} from "react-redux";
import useCurrentUser from "hooks/useCurrentUser";
import EmojiPicker from "components/message/EmojiPicker";
import {PostComment} from "./PostComment";
import MessageInput from "../../components/message/MessageInput";
import PostAssetsDisplay from "../../components/assets/PostAssetsDisplay";

const useStyles = makeStyles(({spacing}) => ({
    root: {
        marginTop: spacing(2)
    },
    comment: {
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        padding: spacing(1, 2, 1, 2),
        margin: spacing(0, 0, 1, 0),
        borderRadius: 7,
        backgroundColor: "rgb(240, 242, 245)",
        flex: 1,
    },
    divider: {
        margin: spacing(0, 0, 2, 0),
    },
    more: {
        fontSize: '0.8125rem',
    },
    showMore: {
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    content: {
        overflowWrap: "break-word",
        "& img": {
            maxWidth: "100%",
            height: 'auto',
        }
    },
}));

// TODO: clean this component
export const PostDisplay: React.FC<{ post: Post; onEditClick: (post: Post) => void }> = ({post, onEditClick}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [content, setContent] = useState<string>("");
    const [showCount, setShowCount] = useState(2);
    const currentUser = useCurrentUser();

    const sendCommentClick = () => {
        if (content !== "") {
            dispatch(postsActions.createComment(post.id, {
                content: content,
            }));
            setContent("");
            setShowCount(showCount + 1);
        }
    };

    // TODO: NOT GOOD
    const moreCommentsClick = () => {
        if (showCount + 2 > copyPostComments.length) {
            setShowCount(showCount + 1);
            return;
        }
        setShowCount(showCount + 2);
    };

    const handleEditPost = () => onEditClick(post);

    const handleDeleteComment = async (postId: string, commentId: string) => {
        await dispatch(postsActions.deleteComment(postId, commentId));
        if (showCount === copyPostComments.length) {
            setShowCount(showCount - 1);
        }
    };

    const endAdornment = (<EmojiPicker value={content} onChange={(e) => setContent(e)}/>);

    // TODO: FIX THIS SHIT ON THE SERVER //
    const copyPostComments = [...post?.comments ?? []];
    const slicePostComments = copyPostComments
        .slice((showCount >= copyPostComments.length ? 0 : copyPostComments.length - showCount), copyPostComments.length);
    const reversePostComments = slicePostComments.reverse();
    //////////////////////////////////////

    return (
        <Card className={classes.root}>
            <CardHeader style={{paddingBottom: 0}}
                        title={<p style={{margin: 0, fontSize: 16, fontWeight: "bold"}}>{post.author.name}</p>}
                        subheader={<p style={{margin: 0, fontSize: 14}}>{toLocalDateTimeString(post.updatedAt)}</p>}
                        action={post.author.id === currentUser.id && (
                            <Tooltip title="Rediger opslag">
                                <IconButton onClick={handleEditPost}>
                                    <Edit fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        )}
            />

            <CardContent style={{paddingBottom: 0, paddingTop: 0}}>
                <span className={classes.content}>{parse(post.content)}</span>
            </CardContent>

            <PostAssetsDisplay assets={post.files}/>

            <CardContent>
                <Divider className={classes.divider}/>
                <Box display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     className={classes.comment}>
                    <MessageInput value={content} onSubmit={sendCommentClick} onChange={setContent} InputProps={{
                        disableUnderline: true,
                        autoFocus: false,
                        placeholder: "Tilføj kommentar ...",
                        endAdornment
                    }}/>
                    <IconButton disabled={content === ""}
                                color="secondary"
                                onClick={sendCommentClick}>
                        <AddCommentIcon fontSize="small"/>
                    </IconButton>
                </Box>

                {reversePostComments.map((comment) =>
                    <PostComment
                        key={comment.id}
                        postId={post.id}
                        comment={comment}
                        onDeleteClick={handleDeleteComment}
                    />
                )}

                {copyPostComments.length > 2 && (
                    <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Box className={classes.more}>
                            {(copyPostComments.length ?? 0) > showCount && (
                                <span className={classes.showMore}
                                      onClick={moreCommentsClick}
                                >Vis flere kommentarer</span>
                            )}
                            {!((copyPostComments.length ?? 0) > showCount) && (
                                <span><b>Alle kommentarer vises</b></span>
                            )}
                        </Box>
                        <span className={classes.more}>{showCount} af {copyPostComments.length}</span>
                    </Box>
                )}

            </CardContent>

        </Card>
    )
};

export default PostDisplay;
