class Routes {
    // public pages
    static readonly landing = '/';
    static readonly register = '/register';
    static readonly resetPassword = '/resetPassword';
    static readonly contact = '/contact';

    static readonly action = '/action';
    static readonly test = '/test';
    static readonly error = '/error';
    static readonly outlookSync = '/outlook';
    static readonly connect = '/connect';

    // dashboard pages
    static readonly dashboard = '/dashboard';
    static readonly profile = '/dashboard/profile';
    static readonly statistics = '/dashboard/statistics';
    static readonly calendar = '/dashboard/calendar';
    static readonly shiftPlanner = '/dashboard/shift-planner';
    static readonly eventUserTimeTable = '/dashboard/time-overview';
    static readonly sms = '/dashboard/sms';
    static readonly web = '/dashboard/web';
    static readonly inquiry = '/dashboard/inquiry';
    static readonly chat = '/dashboard/message';
    static readonly webMessage = '/dashboard/web/:id';
    static readonly app = '/dashboard/app';
    static readonly users = '/dashboard/users';
    static readonly centers = '/dashboard/centers';
    static readonly permissions = '/dashboard/permissions';
    static readonly support = '/dashboard/support';
    static readonly supportArticle = '/dashboard/support/:id';

    static readonly extraction = '/dashboard/extraction';
}

// const routes: { readonly [key: string]: string; } = {
//
// };

export default Routes;
