import React, {useState} from "react";
import Post, {emptyPost} from "../../models/Post";
import {useCurrentCenter} from "../../hooks/useCurrentCenter";
import {Box, Button} from "@material-ui/core";

import {Editor, EditorState as EditorStateReact} from 'react-draft-wysiwyg';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {checkPermission} from "../../models/User";
import useCurrentUser from "../../hooks/useCurrentUser";
import AssetPickerWrapper from "../../components/assets/AssetPickerWrapper";
import {Asset, LoadedAsset} from "../../models/file/Asset";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import appService from "../../services/appService";
import AssetPickedDisplay from "../../components/assets/AssetPickedDisplay";
import {da, editorConfig} from "../../constants/editorConfig";
import SelectNamed from "../../components/SelectNamed";
import {headspaceAssociations} from "../../constants/businessLogic";
import {useCenterList} from "../../hooks/useRequestValueList";

export interface PostFormProps {
    post?: Post;
    onSubmit: (post: Post) => void;
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

export const PostForm: React.FC<PostFormProps> = ({post, onSubmit, submitButtonRef}) => {
    const currentUser = useCurrentUser();
    const center = useCurrentCenter();
    const allCenters = useCenterList();

    const createInitialEditorState = () => {
        if (post?.content) {
            const blocksFromHtml = htmlToDraft(post?.content);
            const {contentBlocks, entityMap} = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    };

    const [assets, setAssets] = useState<LoadedAsset[]>([]);
    const [editorState, setEditorState] = React.useState(createInitialEditorState);
    const isPostFromHQ = checkPermission(currentUser, "Permissions.Statistics.Read")
    const handleEditorStateChange = (editorState: EditorState) => setEditorState(editorState);
    const [selectedCenters, setSelectedCenters] = useState<string[]>([]);
    const [selectedFunctions, setSelectedFunctions] = useState<string[]>([]);


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const markup = convertToRaw(editorState.getCurrentContent());

        const files: Asset[] = [];
        for (const asset of assets) {
            const file = await appService.files.createFile(asset.file);
            if (file.success) {
                files.push(file.value);
            }
        }

        onSubmit({
            ...emptyPost,
            ...post,
            files: [...post?.files ?? [], ...files],
            centerId: center.id,
            centerIdAll: isPostFromHQ,
            centerIdList: selectedCenters,
            functionIdList: selectedFunctions,
            content: draftToHtml(markup),
        })
    };

    const handleRemoveAsset = (index: number) => {
        const assetCopy = [...assets];
        assetCopy.splice(index, 1);
        setAssets(assetCopy);
    };

    return (
        <form onSubmit={handleSubmit}>
            <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
            {isPostFromHQ && <Box display={'flex'} flexDirection={'column'} mb={4}>
                <Box display={'flex'} alignItems={'center'}>
                    <span style={{width: 240}}>Synligt for centre:</span>
                    <SelectNamed
                        value={selectedCenters}
                        onChange={(e) => setSelectedCenters(e.target.value as string[])}
                        variant="standard"
                        multiple
                        options={allCenters}
                    />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <span style={{width: 240}}>Synligt for funktioner:</span>
                    <SelectNamed
                        value={selectedFunctions}
                        onChange={(e) => setSelectedFunctions(e.target.value as string[])}
                        variant="standard"
                        multiple
                        options={headspaceAssociations.map(a => ({
                            id: a,
                            name: a,
                        }))}
                    />
                </Box>
            </Box>}

            <Editor
                editorState={editorState as EditorStateReact}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{...editorConfig}}
                editorStyle={{height: '100%', minHeight: 200}}
                placeholder="Skriv til dit center ..."
                onEditorStateChange={handleEditorStateChange as (state: EditorStateReact) => void}
                localization={{locale: 'da', translations: da}}
            />

            <AssetPickerWrapper assets={assets} setAssets={(assets) => setAssets(assets)}>
                <Button component={"span"} variant={"contained"}>
                    Vedhæft filer<AttachFileIcon/>
                </Button>
            </AssetPickerWrapper>

            {assets.length > 0 && <Box
                display='flex'
                style={{
                    paddingTop: 8,
                    overflowX: 'auto',
                    position: 'relative'
                }}
            >
                <Box
                    display='flex'
                    alignItems='flex-end'
                >
                    {assets.map((asset, index) => <AssetPickedDisplay key={index} asset={asset} index={index}
                                                                      handleRemove={() => handleRemoveAsset(index)}/>)}
                </Box>
            </Box>}


            {post?.files?.map((file) => file.fileName)}

        </form>
    )
};


export default PostForm;