import React, {useState} from 'react';
import Validators from '../../services/Validators';
import {Field, Form, Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import TextField from '@material-ui/core/TextField/TextField';
import {makeStyles} from '@material-ui/core';
import {FormButton} from '../../components/buttons/FormButton';
import {push} from 'connected-react-router';
import Routes from '../../constants/Routes';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RequestFeedbackDisplay from '../../components/RequestFeedbackDisplay';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {authActions} from '../../store/AuthState';
import useLocationQuery from '../../hooks/useLocationQuery';
import {AppState} from '../../store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    forgotPasswordIcon: {
        position: 'absolute',
        right: '0px',
        top: '0',
        margin: '20px 4px 4px 4px',
        color: theme.palette.common.black,
    },

    error: {
        margin: theme.spacing(0, 0, 2),
    },
}));

interface LoginFormProps {
    onSubmit: (email: string, password: string) => void;
    text?: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({onSubmit, text, children}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {loading, feedback, remember} = useSelector((state: AppState) => state.auth);
    const query = useLocationQuery();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onForgotPasswordPress = () => {
        dispatch(push(Routes.landing, {showForgotPassword: true, email: query.email}));
    };

    if (query.mode === 'resetPassword') {
        onForgotPasswordPress();
    }

    const onShowPasswordPress = () => {
        setShowPassword(!showPassword);
    };

    const onRememberChange = async (event) => {
        dispatch(authActions.updateRemember(event.target.checked));
    };

    const handleSubmit = (values) => onSubmit(values.email, values.password);

    const initialValues = {
        email: '',
        password: '',
    };

    return (
        <React.Fragment>
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({errors, values, touched, isValid}) => (
                    <Form className={classes.form}>
                        <Field
                            as={TextField}
                            validate={Validators.email}
                            error={errors.email && touched.email && values.email.length !== 0}
                            helperText={values.email.length !== 0 && errors.email}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Box position="relative">
                            <Field
                                as={TextField}
                                validate={Validators.password}
                                error={errors.password && touched.password && values.password.length !== 0}
                                helperText={values.password.length !== 0 && errors.password}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                id="password"
                                name="password"
                                label="Adgangskode"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                            />
                            <Tooltip title={showPassword ? 'Skjul adgangskoden' : 'Vis adganskoden'}>
                                <IconButton
                                    size="medium"
                                    className={classes.forgotPasswordIcon}
                                    onClick={onShowPasswordPress}
                                >
                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <FormControlLabel
                                label="Husk mig"
                                control={<Checkbox checked={remember} onChange={onRememberChange} color="primary"/>}
                            />
                            <Button color="primary" onClick={onForgotPasswordPress}>
                                Glemt din adgangskode?
                            </Button>
                        </Box>

                        <FormButton text={text ?? 'Log ind'} disabled={!isValid} isSubmitting={loading}/>
                        {children}
                        <RequestFeedbackDisplay feedback={feedback}/>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default LoginForm;
