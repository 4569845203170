import forsideAdministration from "./images/brugere/Forside_administration.png"
import forsideBrugere from "./images/brugere/Forside_brugere.png"
import brugereSideOpret from "./images/brugere/Brugere_side_opret.png"
import brugereSideOpretViewNavn from "./images/brugere/Brugere_side_opret_view_navn.png"
import brugereSideOpretViewEmail from "./images/brugere/Brugere_side_opret_view_email.png"
import brugereSideOpretViewCenter from "./images/brugere/Brugere_side_opret_view_center.png"
import brugereSideOpretViewDato from "./images/brugere/Brugere_side_opret_view_dato.png"
import brugereSideOpretViewStilling from "./images/brugere/Brugere_side_opret_view_stilling.png"
import brugereSideOpretViewRettigheder from "./images/brugere/Brugere_side_opret_view_rettigheder.png"
import brugereSideOpretViewFarve from "./images/brugere/Brugere_side_opret_view_farve.png"
import brugereSideOpretViewAttest from "./images/brugere/Brugere_side_opret_view_attest.png"
import brugereSideOpretViewGem from "./images/brugere/Brugere_side_opret_view_gem.png"
import brugereSideAndre from "./images/brugere/Brugere_side_andre.png"

export default `# Sådan administrer/opretter du en bruger

## Sådan opretter du en bruger

1. Tilgå siden "Brugere" i menuen på venstre side af skærmen.

![Klik på "Administration"](${forsideAdministration})

![Klik på "Brugere"](${forsideBrugere})

2. På denne side kan du alle oprettede bruger i dit center. For at oprette en ny bruger, klikker du blot på knappen "OPRET".

![Klik på "OPRET"](${brugereSideOpret})

3. Du skal her angive brugerens navn.

![Angiv brugerens navn](${brugereSideOpretViewNavn})

4. Du skal her angive brugerens emailadresse.

![Angiv brugerens emailadresse](${brugereSideOpretViewEmail})

5. Du skal her vælge hvilke headspace-centre, brugeren skal være tilknyttet.

![Vælg hvilke centre brugeren skal være tilknyttet](${brugereSideOpretViewCenter})

6. Du skal her angive ansættelsesdatoen for brugeren.

![Angiv brugerens ansættelsesdato](${brugereSideOpretViewDato})

7. Du skal her angive hvilken stilling brugeren har.

![Angiv brugerens stilling](${brugereSideOpretViewStilling})

8. Du skal her angive hvilke rettigheder brugeren skal have i systemmet.

![Angiv brugerens rettigheder](${brugereSideOpretViewRettigheder})

9. Du skal her vælge brugerens farve.

![Vælg brugerens farve](${brugereSideOpretViewFarve})

10. Du har her mulighed for at godkende brugerens "straffeattest" og "børneattest", ved at klikke på tjekboksene.

![Godkend evt. straffeattest og børneattest](${brugereSideOpretViewAttest})

11. Når du er færdig med at oprette brugeren, klikker du på knappen ”GEM”. 
Den nye bruger vil få tilsendt en email med et link til færdiggørelsen af oprettelsen.

![Klik på "GEM", for at oprette brugeren](${brugereSideOpretViewGem})

12. Når den nye bruger har udfyldt de sidste informationerne og færdiggjort oprettelsen,
vil den nye bruger modtage en velkomst email til headspace læringsplatformen. 

13. Den nye bruger vil nu kunne logge ind på headspace intranettet og tilgå læringplatformen på forsiden samt alle andre funkioner på intranettet.

## Andre funktionaliteter

![Andre funktionaliteter](${brugereSideAndre})

* Klikker du på feltet "Søg ..." får du mullighed for at søge på brugere.

* Under "Tilknyttning" kan du se brugerens tilknyttning til centeret.

* Under ”Læringsforløb” kan du se, hvilke læringsforløb på hs academy brugeren er i gang med.

* Under ”Status” kan du se, hvor langt en bruger er i oprettelsesprocessen.

* Klikker du på blyant-ikonet, får du mulighed for at redigere en bruger.

`;
