import defaultThemeConfig from '../constants/themeConfig';
import {createMuiTheme} from '@material-ui/core';
import {CurrentUser} from 'models/User';


export const isDSNUser = (user: CurrentUser): boolean =>
    Boolean(user.centers.find(c => c.name === "Sekretariat"))
    && !user.permissions['Permissions.Admins.all'];

const generateCustomTheme = (user?: CurrentUser) => {
    const isDSN = user ? isDSNUser(user) : false;
    return createMuiTheme({
        ...defaultThemeConfig,
        palette: {
            ...defaultThemeConfig.palette,
            secondary: {
                main: isDSN ? "rgb(210,35,42)" : "#26A95E", // rgb(125,183,66)
                light: isDSN ? undefined : "#2CC66C", // rgb(125,183,66)
                contrastText: '#ffffff',
            },
        },
    });
}

export default generateCustomTheme;
