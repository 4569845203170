// -----------------
// STATE - This defines the variant of data maintained in the Redux store.

import {CurrentUser} from '../models/User';
import {Action, Reducer} from 'redux';
import {CallHistoryMethodAction, push} from 'connected-react-router';
import {AppThunkAction} from './index';
import Routes from '../constants/Routes';
import {requestActionNames} from './RequestState';
import Authorization from '../models/Authorization';
import {clearAuthStorage} from '../services/localStorage';
import Center from "../models/Center";
import appService from "../services/appService";
import RequestFeedback from "../models/httprequest/RequestFeedback";

export interface AuthState {
    authorization: Authorization | undefined;
    currentUser: CurrentUser | undefined;
    center: Center | undefined;
    authenticated: boolean;
    loading: boolean;
    feedback: RequestFeedback | undefined;
    remember: boolean;
    ssoAccountId: string | undefined;
}

export const initialAuthState: AuthState = {
    authorization: undefined,
    currentUser: undefined,
    center: undefined,
    authenticated: false,
    loading: false,
    feedback: undefined,
    remember: false,
    ssoAccountId: undefined,
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for variant detection that works even after serialization/deserialization.

interface AuthActionTypes {
    readonly LOADING: 'AUTH_LOADING';
    readonly ERROR: 'AUTH_ERROR';
    readonly SIGN_IN: 'AUTH_SIGN_IN';
    readonly SIGN_OUT: 'AUTH_SIGN_OUT';
    readonly UPDATE_REMEMBER: 'AUTH_UPDATE_REMEMBER';
    readonly UPDATE_CURRENT_USER: 'AUTH_UPDATE_CURRENT_USER';
    readonly UPDATE_CENTER: 'AUTH_UPDATE_CENTER';

    readonly SSO_CONNECT: 'AUTH_SSO_CONNECT';

    // static readonly LOGIN = 'AUTH_LOGIN';
    // static readonly LOGOUT = 'AUTH_LOGOUT';
}

export const authActionsTypes: AuthActionTypes = {
    LOADING: 'AUTH_LOADING',
    ERROR: 'AUTH_ERROR',
    SIGN_IN: 'AUTH_SIGN_IN',
    SIGN_OUT: 'AUTH_SIGN_OUT',
    UPDATE_REMEMBER: 'AUTH_UPDATE_REMEMBER',
    UPDATE_CURRENT_USER: 'AUTH_UPDATE_CURRENT_USER',
    UPDATE_CENTER: 'AUTH_UPDATE_CENTER',
    SSO_CONNECT: 'AUTH_SSO_CONNECT',
};

export interface AuthLoadingAction {
    type: 'AUTH_LOADING';
}

export interface AuthErrorAction {
    type: 'AUTH_ERROR';
    feedback: RequestFeedback;
}

export interface AuthSignInAction {
    type: 'AUTH_SIGN_IN';
    authorization: Authorization;
    currentUser: CurrentUser;
}

export interface AuthSignOutAction {
    type: 'AUTH_SIGN_OUT';
}

export interface AuthUpdateRememberAction {
    type: 'AUTH_UPDATE_REMEMBER';
    remember: boolean;
}

export interface AuthUpdateCurrentUserAction {
    type: 'AUTH_UPDATE_CURRENT_USER';
    currentUser: CurrentUser;
}

export interface AuthUpdateCenterAction {
    type: 'AUTH_UPDATE_CENTER';
    center: Center;
}

export interface AuthSSOConnectAction {
    type: 'AUTH_SSO_CONNECT';
    ssoAccountId: string;
}


// Declare a 'discriminated union' variant. This guarantees that all references to 'variant' properties contain one of the
// declared variant strings (and not any other arbitrary string).
export type KnownAuthAction =
    AuthLoadingAction
    | AuthErrorAction
    | AuthSignInAction
    | AuthSignOutAction
    | AuthUpdateRememberAction
    | AuthUpdateCurrentUserAction
    | AuthUpdateCenterAction
    | AuthSSOConnectAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const authActions = {
    signInWithSSO: (accountId: string): AppThunkAction<KnownAuthAction | CallHistoryMethodAction> => {
        return async (dispatch) => {
            dispatch({type: authActionsTypes.LOADING});
            const response = await appService.auth.signInWithSSO(accountId);

            if (response.success) {
                await dispatch({
                    type: authActionsTypes.SIGN_IN,
                    authorization: response.value.authorization,
                    currentUser: response.value.currentUser,
                });
                await dispatch(push(Routes.dashboard));
                return;
            }

            if (response.feedback.errorCode === "sso/not-found") {
                await dispatch({
                    type: authActionsTypes.SSO_CONNECT,
                    ssoAccountId: accountId,
                })
                await dispatch(push(Routes.connect))
                return;
            }

            dispatch({type: authActionsTypes.ERROR, feedback: response.feedback});
        }
    },

    connectWithSSO: (ssoAccountId: string, email: string, password: string): AppThunkAction<KnownAuthAction | CallHistoryMethodAction> => {
        return async (dispatch) => {
            dispatch({type: authActionsTypes.LOADING});
            const response = await appService.auth.connectWithSSO(ssoAccountId, email, password);

            if (response.success) {
                await dispatch({
                    type: authActionsTypes.SIGN_IN,
                    authorization: response.value.authorization,
                    currentUser: response.value.currentUser,
                });
                await dispatch(push(Routes.dashboard));
                return;
            }

            dispatch({type: authActionsTypes.ERROR, feedback: response.feedback});
            dispatch(push(Routes.landing));
        }
    },

    signInWithEmailAndPassword: (email: string, password: string): AppThunkAction<KnownAuthAction | CallHistoryMethodAction> => {
        return async (dispatch) => {
            await dispatch({type: authActionsTypes.LOADING});
            const response = await appService.auth.signInWithEmailAndPassword(email, password);
            if (response.success) {
                await dispatch({
                    type: authActionsTypes.SIGN_IN,
                    authorization: response.value.authorization,
                    currentUser: response.value.currentUser,
                });
                await dispatch(push(Routes.dashboard));
                return;
            }
            dispatch({type: authActionsTypes.ERROR, feedback: response.feedback});
        };
    },

    signOut: (): AppThunkAction<KnownAuthAction | Action | CallHistoryMethodAction> => {
        return async (dispatch) => {
            await dispatch({type: requestActionNames.CLEAR});
            await dispatch({type: authActionsTypes.SIGN_OUT});
            await dispatch(push(Routes.landing));
        };
    },

    updateRemember: (remember: boolean): AppThunkAction<KnownAuthAction> => {
        return async (dispatch) => {
            await dispatch({type: authActionsTypes.UPDATE_REMEMBER, remember: remember});
        };
    },


    updateCurrentUser: (currentUser: CurrentUser): AppThunkAction<AuthUpdateCurrentUserAction> => {
        return async (dispatch) => {
            await dispatch({type: authActionsTypes.UPDATE_CURRENT_USER, currentUser: currentUser})
        }
    },

    updateCenter: (center: Center): AppThunkAction<KnownAuthAction> => {
        return async (dispatch) => {
            await dispatch({type: authActionsTypes.UPDATE_CENTER, center: center});
        };
    },


};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const authReducer: Reducer<AuthState> = (state: AuthState | undefined, incomingAction: Action): AuthState => {
    if (state === undefined) {
        return initialAuthState;
    }

    const action = incomingAction as KnownAuthAction;
    switch (action.type) {
        case authActionsTypes.LOADING:
            return {
                ...state,
                loading: true,
            };
        case authActionsTypes.ERROR:
            return {
                ...state,
                loading: false,
                feedback: action.feedback,
            };
        case authActionsTypes.SIGN_IN:
            return {
                ...state,
                loading: false,
                feedback: undefined,
                currentUser: action.currentUser,
                center: action.currentUser.centers[0],
                authorization: action.authorization,
            };
        case authActionsTypes.UPDATE_CENTER:
            return {
                ...state,
                center: action.center,
            }
        case authActionsTypes.SIGN_OUT: {
            clearAuthStorage();
            return {
                ...initialAuthState
            };
        }
        case authActionsTypes.UPDATE_REMEMBER:
            return {
                ...state,
                remember: action.remember,
            };
        case authActionsTypes.UPDATE_CURRENT_USER:
            return {
                ...state,
                currentUser: action.currentUser,
            };
        case authActionsTypes.SSO_CONNECT:
            return {
                ...state,
                ssoAccountId: action.ssoAccountId,
                loading: false,
                feedback: undefined,
            }
        default:
            return state;
    }
};
