import restService, {RestServiceType} from "./restService";
import Call from "../models/Call";
import {AppClient} from "./appClient";
import RequestMethod from "../models/RequestMethod";
import AppResponse from "../models/httprequest/AppResponse";

const servicePath = '/api/v1/video';

export type VideoService = RestServiceType<Call> & {
    createCall: (arg: { [p in string]: string }) => AppResponse<Call>;
}

export function videoService(client: AppClient): VideoService {
    return {
        ...restService<Call>(client, servicePath),
        async createCall(redirects): AppResponse<Call> {
            return await client.fetchJSON(RequestMethod.POST, servicePath, redirects);
        }
    }
}


export default videoService;