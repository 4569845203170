import React from "react";
import {IconButton} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import {LoadedAsset} from "../../models/file/Asset";
import AssetImageThumbnail from "./AssetImageThumbnail";
import AssetFileThumbnail from "./AssetFileThumbnail";

const useStyles = makeStyles<Theme>((theme) => ({
    deleteIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: "#F44336",
        margin: theme.spacing(0.5),
        opacity: 1,
    }
}));

interface AssetPickedDisplayProps {
    asset: LoadedAsset;
    index: number;
    handleRemove: (index: number) => void;
    size?: number;
}

const AssetPickedDisplay: React.FC<AssetPickedDisplayProps> = ({asset, index, handleRemove, size = 90}) => {

    const classes = useStyles();

    const DeleteButton = () =>
        <IconButton size='small'
                    className={classes.deleteIcon}
                    onClick={() => handleRemove(index)}>
            <HighlightOffIcon/>
        </IconButton>;

    if (asset.file.type.includes('image')) {
        return (
            <AssetImageThumbnail asset={asset} size={size}>
                <DeleteButton/>
            </AssetImageThumbnail>
        )
    }

    return (
        <AssetFileThumbnail asset={asset}>
            <DeleteButton/>
        </AssetFileThumbnail>
    )

};

export default AssetPickedDisplay;