export const updatedDateText = (updatedAt: Date | string) => {
    const currentDate = new Date();
    const date = new Date(updatedAt);
    const millisDiff = Math.abs(currentDate.getTime() - date.getTime());
    const dayDiff = millisDiff / (1000 * 60 * 60 * 24);
    const hourDiff = Math.floor(millisDiff / (1000 * 60 * 60));
    const minuteDiff = Math.floor(millisDiff / (1000 * 60));
    if (dayDiff > 7) {
        return date.toLocaleDateString('en-GB');
    } else if (dayDiff > 1) {
        return date.toLocaleDateString('da-DK', {weekday: 'long'});
    } else if (dayDiff === 1) {
        return 'I går';
    } else if (hourDiff === 1) {
        return '' + hourDiff + ' time'
    } else if (hourDiff > 1) {
        return '' + hourDiff + ' timer'
    } else if (minuteDiff > 0) {
        return '' + minuteDiff + ' min'
    } else if (minuteDiff <= 0) {
        return 'nu';
    }
    return date.toLocaleDateString('en-GB');
};

export default updatedDateText;
