import React, {useState} from 'react';

import RegisterForm from './RegisterForm';
import ActionView from '../../components/ActionView';
import useLocationQuery from '../../hooks/useLocationQuery';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {ConfirmedUser} from 'models/User';
import RequestFeedbackDisplay from 'components/RequestFeedbackDisplay';
import {Button} from '@material-ui/core';
import debugPrint from 'lib/debugPrint';
import appService from '../../services/appService';
import RequestFeedback from "../../models/httprequest/RequestFeedback";

const RegisterPage: React.FC = () => {
    const dispatch = useDispatch();
    const {token, firstName, lastName, userId} = useLocationQuery();
    const handleOkClick = () => dispatch(push('/'));
    const [feedback, setFeedback] = useState<RequestFeedback & { action?: JSX.Element }>();

    const onSavePress = async (values: ConfirmedUser) => {
        const response = await appService.users.confirm({
            ...values,
            id: userId,
        }, token);
        debugPrint('response:', response);
        if (response.feedback?.severity === 'success') {
            const successFeedback = {
                ...response.feedback,
                message: 'Du vil modtage en velkomst email til headspace læringsplatformen og kan nu logge ind på intranettet',
            };
            setFeedback(successFeedback);
        } else {
            setFeedback(response.feedback);
        }
    };

    const action = feedback?.severity === 'success' ? <Button onClick={handleOkClick}>OK</Button> : undefined;

    return (
        <ActionView
            title="Registrer profiloplysninger"
            subtitle="For at få adgang til headspace platformen skal du angive en adgangskode og udfylde nogle informationer om dig selv. Disse informationer vil blive brugt til intern statistik"
        >
            <RegisterForm
                onSavePress={onSavePress}
                firstName={firstName}
                lastName={lastName}
            />
            <RequestFeedbackDisplay feedback={feedback} action={action}/>
        </ActionView>
    )
};

export default RegisterPage;
