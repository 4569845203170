import React, {createRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar/Avatar';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItem from '@material-ui/core/ListItem/ListItem';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import {Chip} from '@material-ui/core';
import User, {AgyliaActivity, checkPermission, emptyUser, PendingUser, userSearchFilter} from '../../models/User';
import useCrudRequestState from '../../hooks/useCrudRequestState';
import {userListActions} from '../../store/UsersState';
import {CrudDialog} from '../../components/CrudDialog';
import PendingUserForm from './PendingUserForm';
import {useCurrentUser} from '../../hooks/useCurrentUser';
import TableActionButton from '../../components/buttons/TableActionButton';
import EnhancedTable from '../../components/EnhancedTable';
import BasePage from '../BasePage';
import allPermissions from '../../models/Permissions';
import appService from '../../services/appService';
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import AgyliaActivityPopover from "./AgyliaActivityPopover";
import useMediaQueryMobile from "../../hooks/useMediaQueryMobile";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    unVerified: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    avatar: {
        backgroundColor: theme.palette.secondary.light,
    },
    chip: {
        margin: 2,
    },
    centers: {
        display: 'block',
        '& > span': {
            marginLeft: theme.spacing(1),
        }
    }
}));

const usersTableLabels = [{
    name: 'Navn',
    field: 'firstName',
    permission: true,
}, {
    name: 'Telefonnummer',
    field: 'phoneNumber',
    permission: true,
}, {
    name: 'Tilknytning',
    field: 'employment',
    permission: true,
}, {
    name: 'Læringsforløb',
    tooltip: "Påbegyndte kurser/materialer i HS Academy",
    field: 'agyliaActivities',
    permission: false,
}, {
    name: 'Status',
    field: 'status',
    permission: true,
}];


const UsersPage: React.FC = () => {
    const classes = useStyles();
    const isMobile = useMediaQueryMobile();
    const currentUser = useCurrentUser();
    const currentCenter = useSelector((state: AppState) => state.auth.center);
    const createPermission = checkPermission(currentUser, allPermissions.users.create);
    const updatePermission = checkPermission(currentUser, allPermissions.users.update);

    const {state: {elements: allUsers, editElement, loading, feedback}, updateElement, deleteElement, handleEditClick} = useCrudRequestState<User>(state => state.users, userListActions);
    const elements = allUsers.filter(user => user.centers?.find(c => c.id === currentCenter?.id))

    const submitButtonRef: React.RefObject<HTMLButtonElement> = createRef<HTMLButtonElement>();

    const handleResend = ({id}: PendingUser) => () => {
        id && appService.users.resendInvite(id);
    };

    const handleSubmit = (user: PendingUser) => {
        updateElement(user as User);
    };


    const [activityPopover, setActivityPopover] = React.useState<{ anchorEl: Element; activityList?: AgyliaActivity[] } | undefined>(undefined);
    const handleActivityClick = (activityList?: AgyliaActivity[]) => (event) => {
        setActivityPopover({
            anchorEl: event.currentTarget,
            activityList: activityList,
        });
    };

    const tableLabels = isMobile ? [usersTableLabels[0]] : usersTableLabels.filter(l => l.permission || createPermission || updatePermission);

    return (
        <BasePage>
            <React.Fragment>

                <EnhancedTable<User>
                    tableHeadLabels={tableLabels}
                    elements={elements}
                    elementSearchFilter={userSearchFilter}
                    createPermission={createPermission}
                    onAddClick={handleEditClick(emptyUser)}
                    loading={loading}
                >
                    {(sortedUsers: User[]) => (
                        sortedUsers.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell padding="none">
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar style={{backgroundColor: user.color}} alt={user.firstName} src="#"
                                                    className={classes.avatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={user.firstName + ' ' + user.lastName}
                                            secondary={user.email}
                                        />
                                    </ListItem>
                                </TableCell>
                                {!isMobile && <TableCell padding="none">
                                    <ListItem>{user.phoneNumber}</ListItem>
                                </TableCell>}
                                {!isMobile && <TableCell padding="none">
                                    <ListItem>{user.employment}</ListItem>
                                </TableCell>}
                                {!isMobile && (createPermission || updatePermission) &&
                                <TableCell padding="none" hidden={isMobile}>
                                    <ListItem button onClick={handleActivityClick(user.agyliaActivities)}>
                                        {user?.agyliaActivities?.length ?? 'ingen'}
                                    </ListItem>
                                </TableCell>}
                                {!isMobile && <TableCell padding="none">
                                    {updatePermission && (
                                        <ListItem>
                                            <Tooltip
                                                title={'Brugeren har endnu ikke har godkendt invitationen på email og registreret en adgangskode'}>
                                                <Chip
                                                    style={{display: !user.pending ? 'none' : 'inline-flex',}}
                                                    className={classes.unVerified}
                                                    label={'Afventer'}
                                                    variant={'outlined'}
                                                />
                                            </Tooltip>
                                            <Tooltip title={'Børneattest og straffeattest er ikke godkendt'}>
                                                <Chip
                                                    style={{display: user.verifiedChildCertificate && user.verifiedCriminalRecord ? 'none' : 'inline-flex',}}
                                                    className={classes.unVerified}
                                                    label={'Ikke godkendt'}
                                                    variant={'outlined'}
                                                />
                                            </Tooltip>
                                            <Tooltip title={'Personen er på orlov'}>
                                                <Chip
                                                    style={{display: user.isOnLeave ? 'inline-flex' : 'none',}}
                                                    className={classes.unVerified}
                                                    label={'På orlov'}
                                                    variant={'outlined'}
                                                />
                                            </Tooltip>
                                        </ListItem>
                                    )}
                                </TableCell>}
                                <TableCell padding="none" align="right">
                                    <ListItem style={{justifyContent: 'flex-end'}}>
                                        {user.pending &&
                                        <TableActionButton
                                            type={'resend'}
                                            permission={updatePermission}
                                            onClick={handleResend(user)}
                                            title="Gensend invitation"
                                        />
                                        }
                                        <TableActionButton
                                            type={'edit'}
                                            permission={updatePermission}
                                            onClick={handleEditClick(user)}
                                        />
                                    </ListItem>
                                </TableCell>
                            </TableRow>
                        )))}
                </EnhancedTable>

                <CrudDialog<User>
                    title={editElement?.id ? 'Rediger brugeren' : 'Tilføj en bruger'}
                    loading={loading}
                    element={editElement}
                    onSave={() => submitButtonRef?.current?.click && submitButtonRef.current.click()}
                    onDelete={deleteElement}
                    onCancel={handleEditClick(undefined)}
                    feedback={feedback}
                    maxWidth={'lg'}
                >
                    <PendingUserForm
                        user={editElement}
                        onSubmit={handleSubmit}
                        submitButtonRef={submitButtonRef}
                    />
                </CrudDialog>

                <AgyliaActivityPopover
                    anchorEl={activityPopover?.anchorEl}
                    activityList={activityPopover?.activityList}
                    onClose={() => setActivityPopover(undefined)}
                />

            </React.Fragment>
        </BasePage>
    );
};


export default UsersPage;
