import {makeStyles} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import MovieIcon from "@material-ui/icons/Movie";
import React from "react";
import MUILink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box/Box";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";


const useStyles = makeStyles(({spacing}) => ({
    link: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textDecoration: 'none',
        paddingBottom: spacing(2),
        marginBottom: spacing(2),
        borderBottom: 'solid 1px #aaa',
        fontWeight: 600,
    },
    noDeco: {
        textDecoration: 'none',
    }
}));

interface SupportRowProps {
    href?: string;
    to?: string;
    text: string;
    type?: 'article' | 'video';
}

const supportTypeIcons = {
    'article': <DescriptionIcon/>,
    'video': <MovieIcon/>,
};


export const SupportRow: React.FC<SupportRowProps> = ({href, to, text, type = 'article'}) => {
    const classes = useStyles();

    const row = (
        <>
            <Box pr={2}>
                {supportTypeIcons[type]}
            </Box>
            {text}
            <Box flex={1}/>
            <NavigateNextIcon/>
        </>
    )

    if (href) {
        return (
            <MUILink
                className={classes.link}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
            >
                {row}
            </MUILink>
        )
    }

    if (to) {
        return (
            <Link className={classes.noDeco} to={to}>
                <MUILink className={classes.link}>
                    {row}
                </MUILink>
            </Link>
        )
    }

    return null;
};

export default SupportRow;