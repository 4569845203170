
export type Identifiable = {
    id: string;
};

export type OptionalIdentifiable = {
    id?: string;
};

export interface IdentifiableThread<T extends IdentifiableMessage> extends Identifiable {
    id: string;
    messages: T[];
    updatedAt: Date;
}

export const isThread = <T extends IdentifiableMessage>(i: unknown): i is IdentifiableThread<T> => (i as IdentifiableThread<T>).updatedAt !== undefined;
export const isThreads = <T extends IdentifiableMessage>(i: unknown[]): i is IdentifiableThread<T>[] => isThread(i[0]);

export interface IdentifiableMessage extends Identifiable {
    id: string;
    content: string;
}

export interface AuthorMessage extends IdentifiableMessage {
    author: Identifiable;
    createdAt: Date;
}

export default Identifiable;
