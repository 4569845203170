import {makeStyles} from "@material-ui/core/styles";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import format from "date-fns/format";
import daLocale from "date-fns/locale/da";
import React, {Dispatch, SetStateAction} from "react";
import isValid from "date-fns/isValid";
import isWithinInterval from "date-fns/isWithinInterval";
import isSameDay from "date-fns/isSameDay";
import clsx from "clsx";
import {IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import grey from "@material-ui/core/colors/grey";
import Popover from "@material-ui/core/Popover";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DateIntervalState} from "./DateIntervalPicker";

const useStyles = makeStyles(theme => ({
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
}));

export const startOfWeekMonday = (date: Date) => {
    return startOfWeek(date, {weekStartsOn: 1});
};

export const endOfWeekSunday = (date: Date) => {
    return endOfWeek(date, {weekStartsOn: 1});
};

const formatDanish = (date: Date, dateFormat: string) => {
    return format(date, dateFormat, {locale: daLocale});
};

export interface WeekDateIntervalState {
    start: Date;
    end?: Date;
}

export const WeekIntervalPicker: React.FC<{ state: WeekDateIntervalState; setState: Dispatch<SetStateAction<DateIntervalState>> }> = ({state, setState}) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [interval, setInterval] = React.useState<{ start: Date; end: Date | undefined }>(
        {
            start: state.start,
            end: undefined
        }
    );
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setState({start: interval.start, end: interval.end ?? endOfWeekSunday(interval.start)});
    };

    const handleWeekChange = date => {
        const newDate = new Date(date);

        const oldStart = startOfWeekMonday(new Date(interval.start));
        const oldEnd = interval.end ? endOfWeekSunday(new Date(interval.end)) : undefined;

        const newStart = (newDate < oldStart) || oldEnd ? newDate : oldStart;
        const newEnd = (newDate > oldStart) && !oldEnd ? newDate : undefined;

        const newStartOfWeek = startOfWeekMonday(newStart);
        const newEndOfWeek = newEnd ? endOfWeekSunday(newEnd) : undefined;

        setInterval({start: newStartOfWeek, end: newEndOfWeek});
    };

    const formatWeekSelectLabel = (date, invalidLabel) => {
        const dateClone = new Date(date);
        return dateClone && isValid(dateClone)
            ? `Fra ${formatDanish(interval.start, "MMM do")} til  ${formatDanish(interval.end ?? endOfWeekSunday(interval.start), "MMM do")}`
            : invalidLabel;
    };

    const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {

        const dateClone = new Date(date);
        const start = startOfWeekMonday(interval.start);
        const end = interval.end ? endOfWeekSunday(interval.end) : endOfWeekSunday(interval.start);

        const dayIsBetween = isWithinInterval(dateClone, {start, end});
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d")} </span>
                </IconButton>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Button onClick={handleClick}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <span style={{color: grey['600']}}>Periode</span>
                    <span style={{color: grey['700'], fontWeight: 600}}>{formatWeekSelectLabel(state.start, "")}</span>
                </Box>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                    <DatePicker
                        disableToolbar
                        label="Week picker"
                        variant="static"
                        margin="normal"
                        value={state.start}
                        onChange={handleWeekChange}
                        renderDay={renderWrappedWeekDay}
                        labelFunc={formatWeekSelectLabel}
                    />
                </MuiPickersUtilsProvider>
                <Box display='flex' justifyContent='center' width='100%'>
                    <Button onClick={handleClose}>Færdig</Button>
                </Box>
            </Popover>
        </React.Fragment>
    )
};