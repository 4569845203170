import React, {useState} from 'react';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Center, {centerSearchFilter, emptyCenter} from '../../models/Center';
import useCrudRequestState from '../../hooks/useCrudRequestState';
import {checkPermission} from '../../models/User';
import {useCurrentUser} from '../../hooks/useCurrentUser';
import TableActionButton from '../../components/buttons/TableActionButton';
import EnhancedTable from '../../components/EnhancedTable';
import centerListActions from '../../store/CentersState';
import BasePage from '../BasePage';
import CenterEditRow from './CenterEditRow';

const centerTableHeadLabels = [{
    name: 'Navn',
    field: 'name',
}, {
    name: 'SMS-Keywords',
    field: 'keywords',
    tooltip: 'Dette er de aktive sms-keywords for det pågældende center, læs mere på http://headspace.dk/1403'
}];

const CentersPage: React.FC = () => {

    const currentUser = useCurrentUser();
    const createPermission = checkPermission(currentUser, 'Permissions.Centers.Create');
    const updatePermission = checkPermission(currentUser, 'Permissions.Centers.Update');

    const [createdCenters, setCreatedCenters] = useState<string[]>([]);

    const {state: {elements: centers, editElement, loading, error}, updateElement, deleteElement, handleEditClick} = useCrudRequestState<Center>(state => state.centers, centerListActions);
    // const isAddElement = editElement !== undefined && editElement.id === undefined;

    const handleUpdateCenter = async (center: Center) => {
        updateElement(center);
        setCreatedCenters([ center.name, ...createdCenters])
    }

    return (
        <BasePage>
            <EnhancedTable<Center>
                tableHeadLabels={centerTableHeadLabels}
                elements={centers}
                elementSearchFilter={centerSearchFilter}
                createPermission={createPermission}
                onAddClick={handleEditClick(emptyCenter)}
                loading={loading}
                error={error}
            >
                {(sortedCenters: Center[]) => (
                    (editElement !== undefined && editElement.id === undefined ? [editElement] : []).concat(sortedCenters).map((element) => {
                        const updatePermissionAndConnectedCenter = currentUser.permissions['Permissions.Admins.all'] || updatePermission && currentUser.centers.reduce<boolean>((prev, center) => prev || center.id === element.id, false);
                        if (editElement && (editElement.id === element.id || element.id === undefined)) {
                            return (
                                <CenterEditRow
                                    key={editElement.id ?? 'new'}
                                    editElement={editElement}
                                    updatePermission={editElement.id !== undefined ? (createdCenters.includes(element.name) ? true : updatePermissionAndConnectedCenter) : createPermission}
                                    onCancel={handleEditClick(undefined)}
                                    onDelete={deleteElement}
                                    onUpdate={handleUpdateCenter}
                                />
                            )
                        }
                        return (
                            <TableRow key={element.id}>
                                <TableCell>{element.name}</TableCell>
                                <TableCell>{element.keywords?.join(', ')}</TableCell>
                                <TableCell style={{display: 'flex', padding: 8}} align={'right'}>
                                    <TableActionButton
                                        type={'edit'}
                                        permission={createdCenters.includes(element.name) ? true : updatePermissionAndConnectedCenter}
                                        onClick={handleEditClick(element)}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    }))}
            </EnhancedTable>
        </BasePage>
    );
};


export default CentersPage;
