import {Dispatch, SetStateAction, useState} from 'react';
import Identifiable from '../models/Identifiable';

interface CompareFunction<S> {
    (a: S, b: S): boolean;
}

interface CompareIdFunction {
    (a: Identifiable, b: Identifiable): boolean;
}

interface ElementFunction<S> {
    (element: S | undefined): void;
}

const compareWithId = (a: Identifiable, b: Identifiable) => a.id === b.id;

export default function useCrudDummyState<S extends Identifiable>(
    initialState: S[] | (() => S[]),
    compare: CompareFunction<S> | CompareIdFunction = compareWithId
): [S[], S | undefined, Dispatch<SetStateAction<S | undefined>>, ElementFunction<S>, ElementFunction<S>] {

    const [elements, setElements] = useState(initialState);
    const [editElement, setEditElement] = useState<S | undefined>(undefined);

    function updateElement(element: S | undefined) {
        if (element === undefined) return;
        const newElements = [...elements];
        const elementIndex = elements.findIndex((a) => compare(a, element));
        elementIndex === -1 ? newElements.push(element) : (newElements[elementIndex] = element);
        setElements(newElements);
        setEditElement(undefined);
    }

    function deleteElement(element: S | undefined) {
        if (element === undefined) return;
        const newElements = [...elements];
        const elementIndex = elements.findIndex((a) => compare(a, element));
        newElements.splice(elementIndex, 1);
        setElements(newElements);
        setEditElement(undefined);
    }

    return [elements, editElement, setEditElement, updateElement, deleteElement];
}
