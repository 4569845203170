import searchFilter from '../lib/searchFilter';
import Center from './Center';
import Role from './Role';

export interface PendingUser {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    centerIds: string[];
    roleIds: string[];
    employmentDate?: Date;
    terminationDate?: Date;
    employment?: string;
    verifiedCriminalRecord: boolean;
    verifiedChildCertificate: boolean;
    color: string;
    notes?: string;
    emergencyContact?: string;
    isOnLeave?: boolean;
}

export const emptyPendingUser: PendingUser = {
    email: '',
    firstName: '',
    lastName: '',
    employment: undefined,
    employmentDate: new Date(),
    terminationDate: new Date("0001-01-01T00:00:00+00:00"),
    verifiedChildCertificate: false,
    verifiedCriminalRecord: false,
    centerIds: [],
    roleIds: [],
    color: '',
};

export interface ConfirmedUser {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    roles: Role[];
    pending: boolean;
    sex: string;
    birthdate?: Date;
    educationLevel?: string;
    educationSubject?: string;
    workStatus?: string;
    centers?: Required<Center>[];
    acceptedPrivacyPolicy: boolean;
    agyliaActivities?: AgyliaActivity[];
    receiveEmailNotifications?: boolean;
    receiveSMSNotifications?: boolean;
}

export interface AgyliaActivity {
    date: string;
    ref: string;
    score?: string | null;
    status: "incomplete" | "completed";
    title: string;
}

export const emptyConfirmedUser: ConfirmedUser = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    pending: false,
    centers: [],
    roles: [],
    sex: '',
    acceptedPrivacyPolicy: false,
    agyliaActivities: []
};


export interface CurrentUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    color: string;
    centers: Required<Center>[];
    roles: Required<Role>[];
    employment?: string;
    permissions: {
        [a: string]: boolean;
    };
}

export const checkPermission = (user: CurrentUser, permission: string): boolean => {
    return user.permissions && (user.permissions['Permissions.Admins.all'] || user.permissions[permission]);
};

export const emptyCurrentUser: CurrentUser = {
    id: '',
    firstName: '',
    lastName: '',
    color: '',
    email: '',
    centers: [],
    roles: [],
    permissions: {},
};

export type User = PendingUser & ConfirmedUser;

export const emptyUser: User = {
    ...emptyPendingUser,
    ...emptyConfirmedUser,
};

export type TestUser = User & { password: string };

export type UserDataUpdate = {
    // firstName: string;
    // lastName: string;
    id?: string;
    sex?: string;
    birthdate?: Date;
    educationLevel?: string;
    educationSubject?: string;
    workStatus?: string;
};

export const userSearchFilter = (search: string) => ({firstName, lastName, email}) => searchFilter({
    name: firstName + ' ' + lastName,
    email: email
}, search);

export default User;
