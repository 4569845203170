import React, {useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import {Container, Grid} from '@material-ui/core';
import useLocationQuery from 'hooks/useLocationQuery';
import outlookService from 'services/outlookService';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import Routes from 'constants/Routes';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        '& svg': {
            height: theme.spacing(16),
            width: 'auto',
            padding: theme.spacing(16, 0),
        }
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        '&:active,:visited,:link': {
            color: theme.palette.secondary.main,
        }
    },
    h2: {
        color: theme.palette.secondary.main,
        textAlign: "center",
    }
}))

export const OutlookSyncPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useLocationQuery();
    const {code, state} = location;

    useEffect(() => {
        const authorize = async () => {
            try {
                const {Id, AntiForgeryToken} = JSON.parse(state);
                await outlookService.authorize({
                    id: Id,
                    authorizationCode: code,
                    antiForgeryToken: AntiForgeryToken,
                });
                dispatch(push(Routes.shiftPlanner));
            } catch (e) {
                console.log("Something went wrong:", e);
            }
        }
        authorize();
    })

    return (
        <Container maxWidth="md" className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <h2 className={classes.h2}>
                        Din Outlook kalender er succesfuldt blevet synkroniseret med vagtplanen
                    </h2>
                </Grid>
                <Grid item xs={12} className={classes.icon}>
                    <CheckIcon/>
                </Grid>
                <Grid item xs={12} className={classes.link}>
                    Du vil blive omdirigeret tilbage til intranettet
                </Grid>
            </Grid>
        </Container>
    );
};

export default OutlookSyncPage;
