import React from "react";
import {Participant} from "../models/calendar";
import {userColorOptions} from "../pages/admin/PendingUserForm";


export const ParticipantDisplay: React.FC<Participant> = (p) => {
    return (
        <div key={p.id} style={{display: 'flex', alignItems: 'center'}}>
            <div style={{
                width: 10,
                height: 10,
                background: p.color || userColorOptions[0].color,
                borderRadius: '100%'
            }}/>
            <span style={{paddingLeft: 6}}>
                {p.name}
            </span>
        </div>
    )
}


export default ParticipantDisplay;