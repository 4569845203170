import forsideHenvendelser from "./images/sms/Forside_henvendelser.png"
import forsideSms from "./images/sms/Forside_SMS.png"
import smsSideBeskedtraad from "./images/sms/SMS_side_beskedtaad.png"
import smsSideBeskedsamtale from "./images/sms/SMS_side_beskedsamtale.png"
import smsSideAndre from "./images/sms/SMS_side_andre.png"
export default `# Sådan besvarer du SMS-henvendelser

Vi får en SMS, når børn og unge skriver på nummeret 1403 i formatet:

> hs keyword besked

Nummeret 1403 bliver delt af flere virksomheder. ”hs” sikrer, at beskeden sendes til headspace. ”keyword” sikrer, at beskeden havner hos det rigtige center. Derfor skal ”keyword” matche det/de keywords, der er sat op for centeret.

Det er gratis for den unge at benytte denne SMS-service.

1. Du tilgår siden ”SMS” ved at klikke på ”Henvendelser” og derefter vælge ”SMS” i menuen på venstre side af skærmen.

![Klik på "Henvendelser"](${forsideHenvendelser})

![Klik på "SMS"](${forsideSms})

2. Til venstre kan du se en oversigt over alle SMS-henvendelser til dit center. Klikker du på søg, kan du med den unges navn eller kaldenavn finde en bestemt beskedtråd

![Dette er SMS-henvendelser siden](${smsSideBeskedtraad})

3. Til højre kan du se samtalen med den unge. For at besvare en henvendelse skal du skrive i feltet ”Skriv en besked…” og derefter klikke på knappen ”SEND”.

![Dette er SMS-henvendelser siden](${smsSideBeskedsamtale})

## Andre funktionaliteter

![Dette er SMS-henvendelser siden](${smsSideAndre})

* Klikker du på blyant-ikonet, får du mulighed for at ændre den unges kaldenavn.

* Klikker du på kamera-ikonet, får du mulighed for at lave et videolink, og dette link bliver kopieret til din udklipsholder.

* Klikker du på kuvert-ikonet, får du mulighed for at stemple samtaletråden som læst eller ulæst. (Dette kan ikke ses af den unge).
`;
