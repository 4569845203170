import EventApi from '@fullcalendar/core/api/EventApi';
import Identifiable from '../Identifiable';
import {Participant} from "./Participant";

export interface AppEventType {
    id: string;
    name: string;
    color: string;
}

export const emptyAppEventType: AppEventType = {
    id: '',
    name: '',
    color: '',
};

export interface AppEvent {
    id?: string;
    batchId?: string;
    centerId?: string;
    title: string;
    description?: string;
    type?: AppEventType;
    typeId?: string;
    location?: string;
    createdBy?: Participant;
    createdAt?: Date;
    updatedAt?: Date;
    start: Date;
    end: Date;
    participants?: Participant[];
    participantIds?: string[];
    maxParticipants?: number;
    isCancelled: boolean;
    isMoved: boolean;
    isNoShow: boolean;
    allDay: boolean;
    reminderMillis?: number;


    // visual attributes
    backgroundColor?: string;
    borderColor?: string;
}

export interface AppEventFormValues {
    title: string;
    description: string;
    startDate: string;
    start: Date;
    endDate: string;
    end: Date;
    allDay: boolean;
    isCancelled: boolean;
    isMoved: boolean;
    isNoShow: boolean;
    maxParticipants?: number;
    typeId: string;
    reminderMillis?: string;
}

const dateFromCurrentHour = () => {
    const date = new Date();
    date.setMinutes(0, 0, 0);
    return date;
}
const dateFromNextHour = () => {
    const date = dateFromCurrentHour();
    date.setHours(date.getHours() + 1);
    return date;
}


export const emptyAppEvent: AppEvent = {
    id: '',
    title: '',
    description: '',
    location: '',
    start: dateFromCurrentHour(),
    end: dateFromNextHour(),
    isCancelled: false,
    isMoved: false,
    isNoShow: false,
    allDay: false,
};

export function eventFromDate(date: Date | string, allDay: boolean) {
    const start = new Date(date);
    if (allDay) start.setHours(8, 0)
    const end = new Date(start);
    end.setHours(end.getHours() + 1);
    return {
        ...emptyAppEvent,
        id: '',
        start: start,
        end: end,
        allDay: allDay,
    };
}


export function eventFromEventAPI(event: EventApi): AppEvent & Identifiable {
    // console.log("eventFromEventAPI.event:", event)

    const start = event.start ?? new Date();
    const oneHourFromStart = new Date(start);
    oneHourFromStart.setHours(oneHourFromStart.getHours() + 1);


    return {
        id: event.id,
        title: event.title,
        start: start,
        end: event.end ?? oneHourFromStart,
        allDay: event.allDay,
        backgroundColor: event.backgroundColor,
        borderColor: event.borderColor,
        ...event.extendedProps,
    }
}


export default AppEvent;
