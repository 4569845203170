import {Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {UserData} from "../../services/userService";
import {downloadAsCSVFile} from "../../lib/downloadAsFile";
import useCrudRequestState from "../../hooks/useCrudRequestState";
import User from "../../models/User";
import {userListActions} from "../../store/UsersState";
import {toLocalISODate} from "../../lib/toLocalISO";

export const ExportUserCSVButton: React.FC<{ userData: UserData[] }> = ({userData}) => {
    const {state: {elements: existingUsers, }} = useCrudRequestState<User>(state => state.users, userListActions);

    const handleExportClick = () => {

        const content = userData.map(user => {
            const data: string[] = []

            const existingUser = existingUsers.find(eu => eu.id === user.id);
            data.push(existingUser?.firstName ?? "");
            data.push(existingUser?.lastName ?? "");
            data.push(existingUser?.email ?? "");
            data.push(existingUser?.phoneNumber ?? "");

            data.push((user.centers ?? []).map(c => c.name).join(","))
            data.push(user.sex ?? "");
            data.push(user.birthdate ? toLocalISODate(user.birthdate) : "")
            data.push(user.educationLevel ?? "");
            data.push(user.educationSubject ?? "");
            data.push(user.workStatus ?? "");
            data.push(user.employment ?? "");

            data.push(user.createdAt ? toLocalISODate(user.createdAt) : "");
            data.push(user.updatedAt ? toLocalISODate(user.updatedAt) : "");
            data.push(user.deletedAt ? toLocalISODate(user.deletedAt) : "");
            data.push(user.employmentDate ? toLocalISODate(user.employmentDate) : "");
            data.push(user.terminationDate ? toLocalISODate(user.terminationDate) : "");

            return data.join(";");
        })

        const contentHeader = [
            "Fornavn",
            "Efternavn",
            "Email",
            "Mobilnummer",
            "Centre",
            "Køn",
            "Fødselsdato",
            "Uddannelsesnivau",
            "Uddannelsesemne",
            "Arbejdsstatus",
            "Ansættelse",
            "Oprettet",
            "Opdateret",
            "Slettet",
            "Ansættelsesdato",
            "Afsluttet",
        ]

        const csv = contentHeader.join(";") + "\n" + content.join("\n")

        const currentDate = new Date().toISOString().split("T")[0];
        const fileName = "" + currentDate + "-headspace-brugere.csv";
        downloadAsCSVFile(fileName, csv)
    }

    return (
        <Tooltip title={"Eksporter brugerdata til csv"}>
            <Button
                variant={"contained"}
                color='primary'
                component={"a"}
                onClick={handleExportClick}
            >
                Eksporter brugerdata
            </Button>
        </Tooltip>

    )
}


export default ExportUserCSVButton;