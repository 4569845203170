import React from 'react';
import {sexOptions} from 'constants/businessLogic';
import {SingleSelectFormField} from './SingleSelectFormField';

interface SexFormFieldProps {
    id: string;
    error?: string;
}

export const SexFormField: React.FC<SexFormFieldProps> = ({id, error}) => {
    return (
        <SingleSelectFormField
            fullWidth={true}
            id={id}
            options={sexOptions}
            error={error}
            label={'Køn'}
        />
    );
};


export default SexFormField;
