import React, {useState} from 'react';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Center from '../../models/Center';
import TableActionButton from '../../components/buttons/TableActionButton';
import {TextField} from '@material-ui/core';
import Box from '@material-ui/core/Box';

interface CenterEditRowProps {
    editElement: Center;
    updatePermission: boolean;
    onCancel: () => void;
    onUpdate: (center: Center) => void;
    onDelete: (center: Center) => void;
}

const CenterEditRow: React.FC<CenterEditRowProps> = ({editElement, updatePermission, onCancel, onUpdate, onDelete}) => {

    const [keywords, setKeywords] = useState<string[]>(editElement?.keywords ?? []);
    const [centerName, setCenterName] = useState<string>(editElement?.name ?? '');
    const [newKeyword, setNewKeyword] = useState<string>('');

    const handleUpdateCenter = async () => {
        if (editElement !== undefined) {
            const updatedElement: Center = {
                ...editElement,
                name: centerName,
                keywords: keywords.toString().split(','),
                id: editElement.id,
            };
            await onUpdate(updatedElement)

        }
    };

    const handleAddKeyword = () => {
        if (newKeyword) {
            setKeywords(keywords.concat(newKeyword));
            setNewKeyword('');
        }
    };

    const handleRemoveKeyword = (keyword: string) => () => setKeywords(keywords.filter(word => word !== keyword));

    return (
        <React.Fragment key={editElement.id ?? 'new'}>
            <TableRow selected={true}>
                <TableCell>
                    <TextField
                        label="Centernavn"
                        value={centerName}
                        onChange={(event) => setCenterName(event.target.value)}
                        autoFocus
                    />
                </TableCell>
                <TableCell>
                    {keywords?.join(', ')}
                </TableCell>
                <TableCell style={{padding: 8}}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        {editElement.id !== undefined && (
                            <TableActionButton
                                type={'delete'}
                                title={'Slet'}
                                permission={updatePermission}
                                onClick={() => onDelete(editElement)}
                            />
                        )}
                        <TableActionButton
                            type={'cancel'}
                            title={'Annuller'}
                            permission={true}
                            onClick={onCancel}
                        />
                        <TableActionButton
                            type={'save'}
                            title={'Gem ændringer'}
                            permission={updatePermission}
                            onClick={handleUpdateCenter}
                        />
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow selected={true}>
                <TableCell/>
                <TableCell>
                    <TextField
                        label="SMS keyword"
                        value={newKeyword}
                        onChange={(event) => setNewKeyword(event.target.value)}
                    />
                </TableCell>
                <TableCell style={{padding: 8}}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <TableActionButton
                            type={'add'}
                            title={'Tilføj keyword'}
                            permission={updatePermission}
                            onClick={handleAddKeyword}
                        />
                    </Box>
                </TableCell>
            </TableRow>
            {keywords.map(keyword => (
                <TableRow key={keyword} selected={true}>
                    <TableCell/>
                    <TableCell>
                        {keyword}
                    </TableCell>
                    <TableCell style={{padding: 8}}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <TableActionButton
                                type={'remove'}
                                title={'Fjern keyword'}
                                permission={updatePermission}
                                onClick={handleRemoveKeyword(keyword)}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
            ))}
        </React.Fragment>
    );
};


export default CenterEditRow;
