import Author from "./Author";


export interface PostAuthor extends Author {
    id: string;
    name: string;
    email: string;
    color?: string;
    url?: string;
}

export const emptyPostAuthor: PostAuthor = {
    id: '',
    name: 'Michael Guldborg',
    email: 'michael@danmark.ai',
    color: '#f00',
    url: '',
}

export default PostAuthor;