import {Box, CircularProgress} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {renderDangerousHtml} from "lib/renderDangerousHtml";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
    messageSenderName: {
        fontSize: 14,
        color: theme.palette.grey['700'],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        textAlign: 'center'
    },
    contentWrapper: {
        '&:hover $actionWrapper': {
            opacity: 1
        }
    },
    messageBox: {
        maxWidth: '60%',
        padding: theme.spacing(2),
        borderRadius: 7,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        '& a, a:hover, a:active, a:focus, a:visited': {
            color: 'white',
        }
    },
    actionWrapper: {
        opacity: 0,
        [theme.breakpoints.down("sm")]: {
            opacity: 1
        }
    }
}));

interface MessageBox {
    content: string;
    me: boolean;
    senderInfo: string | JSX.Element;
    action?: React.ReactNode;
    loading?: boolean;
}

const MessageBox: React.FC<MessageBox> = ({
                                              content,
                                              me,
                                              senderInfo,
                                              action,
    loading,
                                          }) => {
    const classes = useStyles();
    const {palette} = useTheme();
    const contentWithHyperLink = content.replace(/\bhttps:\/\/[a-zA-Z-_./0-9]*/g, '<a href="$&?type=raadgiver" target="_blank" rel="noopener noreferrer">$&</a>');

    const alignment = me ? 'flex-end' : 'flex-start';
    const messageBoxStyle = {
        backgroundColor: me ? palette.secondary.main : palette.grey['200'],
        color: me ? palette.common.white : palette.common.black,
    };

    const flexDirection = me ? 'row-reverse' : 'row';

    return (
        <Box>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={alignment}
                className={classes.contentWrapper}
            >
                <Box display={'flex'}
                     alignItems='center'
                     flexDirection={flexDirection}
                     ml={me ? 2 : 0}
                     mr={me ? 0 : 2}
                     mt={2}>
                    <Box mr={1} ml={1} className={classes.messageBox} style={messageBoxStyle}>
                        {renderDangerousHtml(contentWithHyperLink)}
                    </Box>
                    <Box className={classes.actionWrapper}>{action}</Box>
                </Box>
                <span className={classes.messageSenderName}>
                    {loading ? <CircularProgress size={16}/> : senderInfo}
                </span>
            </Box>
        </Box>
    )
};

export default MessageBox;