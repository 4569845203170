import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";

export type ColorDotType = 'error' | 'warning';

const useStyles = makeStyles<Theme, { type: ColorDotType }>(({palette}) => ({
    root: {
        width: 8,
        height: 8,
        marginRight: 4,
        borderRadius: '100%',
        backgroundColor: ({type}) => type === 'error' ? palette.error.main : palette.warning.main,
        display: 'block',
    },
}));

export interface ColorDotProps {
    type: ColorDotType;
    visible?: boolean;
    tooltip: string;
}

export const ColorDot: React.FC<ColorDotProps> = ({type, visible, tooltip}) => {
    const classes = useStyles({type});

    if (!visible) {
        return <React.Fragment/>
    }

    return (
        <Tooltip title={tooltip}>
            <span className={classes.root}/>
        </Tooltip>
    )
}

export default ColorDot;