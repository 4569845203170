import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {SingleSelectField} from "../../components/form_fields/SingleSelectFormField";
import {Bar} from "react-chartjs-2";
import {UserData} from "../../services/userService";
import {DateButton} from "../../components/pickers/DateIntervalPicker";


const userChartOptions = [
    {id: 'sex', name: 'Køn'},
    {id: 'centers', name: 'Center'},
    {id: 'employment', name: 'Tilknytning til headspace'},
    {id: 'workStatus', name: 'Arbejdsstatus'},
    {id: 'educationLevel', name: 'Højest fuldførte uddannelse'},
    {id: 'educationSubject', name: 'Uddannelses fagområde'},
];

const toFieldCountMap = (list: {}[], fieldName: string) => {
    return list.reduce<{ [p: string]: number }>((result, value) => {
            let label = Array.isArray(value[fieldName]) ? value[fieldName][0]?.name : value[fieldName];
            label = label && label !== "string" ? label : "NaN";
            result[label] = !result[label] ? 1 : result[label] + 1;
            return result;
        },
        {}
    );
}

export const UserBarChart: React.FC<{ users: UserData[] }> = ({users}) => {
    const [date, setDate] = useState<Date>(new Date())
    const dateTime = date.getTime();

    const [fieldName, setFieldName] = useState<string>(userChartOptions[1].id);
    const filteredUsers = users.filter(user => {
        const createdAt = new Date(user.createdAt);
        const createdAtTime = createdAt.getTime();
        if (createdAtTime < 0) { // if before 1970
            return false;
        }
        if (createdAtTime > dateTime) {
            return false;
        }

        const deletedAt = new Date(user.deletedAt ?? "");
        const deletedAtTime = deletedAt.getTime();
        if (deletedAtTime < 0) { // if before 1970
            return true;
        }
        return deletedAtTime >= dateTime;
    })


    const fieldCountMap = toFieldCountMap(filteredUsers, fieldName)
    const labels = Object.keys(fieldCountMap).sort((a, b) => a.localeCompare(b));
    const values = labels.map(label => fieldCountMap[label]).concat(0);


    return (
        <Box p={2}>
            <Box pl={2} pb={2} display={'flex'} justifyContent={'space-between'}>
                <SingleSelectField
                    id={'field'}
                    value={fieldName}
                    setValue={setFieldName}
                    options={userChartOptions.map(option => option.id)}
                    render={(id) => userChartOptions.find(option => option.id === id)?.name ?? id}
                />

                <DateButton title={"Dato"} date={date} onDateChange={d => setDate(d ?? new Date())}/>
            </Box>
            <Bar
                options={{legend: {display: false}}}
                data={{
                    labels: labels,
                    datasets: [{
                        label: '',
                        data: values,
                        backgroundColor: "#2CC66C", //chartBackgroundColors,
                        borderColor: "#2CC66C", //chartBorderColors,
                        borderWidth: 1
                    }]
                }}
            />
        </Box>
    )
}

export const chartBackgroundColors = [0,1,2,3,4,5,6,7,8,9].map(() => [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
]).flat()

export const chartBorderColors = [0,1,2,3,4,5,6,7,8,9].map(() => [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
]).flat()


export default UserBarChart;