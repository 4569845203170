import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectProps, Tooltip} from '@material-ui/core';
import {Field} from 'formik';
import {educationSubjectOptions} from 'constants/businessLogic';


interface EducationLevelFormFieldProps {
    id: string;
    required?: boolean;
    style?: React.CSSProperties;
    error?: string | undefined;
    educationLevel?: string;
    variant?: SelectProps['variant'];
}

export const EducationLevelFormField: React.FC<EducationLevelFormFieldProps> = ({id, educationLevel, required, style, error, variant}) => {
    const labelId = `${id}-label`;
    return (
        <Tooltip
            title="Vælg først din højeste fuldførte uddannelse"
            disableFocusListener={!!educationLevel}
            disableHoverListener={!!educationLevel}
            disableTouchListener={!!educationLevel}
        >
            <FormControl fullWidth style={style}>
                <InputLabel id={labelId}>{`Fagområde${required ? ' *' : ''}`}</InputLabel>
                <Field
                    as={Select}
                    error={error}
                    fullWidth
                    required={required}
                    name={id}
                    label="Fagområde"
                    id={id}
                    labelId={labelId}
                    disabled={!educationLevel}
                    variant={variant}
                >
                    <MenuItem value={undefined} disabled>
                        Fagområde
                    </MenuItem>
                    {!!educationLevel && educationSubjectOptions[educationLevel]?.map((subject) =>
                        <MenuItem
                            key={subject}
                            value={subject}>{subject}</MenuItem>)}
                </Field>
            </FormControl>
        </Tooltip>
    );
}

export default EducationLevelFormField;
