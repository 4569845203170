import {initialRequestState, requestActions, RequestActionType, requestReducer, RequestState} from './RequestState';
import Center from '../models/Center';
import appService from '../services/appService';

const stateName = 'CENTERS_STATE';

export type CentersState = RequestState<Required<Center>>;

export const initialCentersState = initialRequestState<Required<Center>>();

export const centerListActions: RequestActionType<Center> = {
    ...requestActions<Center>(stateName, appService.centers),
};

export const centersReducer = requestReducer<Required<Center>>(stateName, initialCentersState);

export default centerListActions;

