export const loremIpsum = "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.";
export const shortLoremIpsum = loremIpsum.substring(0, 62);

export const headspaceAssociations = [
    'Frivillig ungerådgiver',
    'Afdækkende frivillig ungerådgiver',
    'Lønnet ungerådgiver',
    'Daglig ansvarlig ungerådgiver',
    'Fremskudt kommunal medarbejder',
    'Fremskudt regional medarbejder',
    'Kommunikationsfrivillig',
    'Sekretariatsmedarbejder',
    'I løntilskud',
    'Praktikant',
    'Vikar',
    'Center',
    'Centerchef',
    'Direktør',
];

export const sexOptions = ['Kvinde', 'Mand', 'Ikke-binær', 'Transkønnet'];

export const educationLevelOptions = [
    'Grundskole',
    'Gymnasiale uddannelser',
    'Erhvervsfaglige uddannelser',
    'Adgangsgivende uddannelsesforløb',
    'Korte videregående uddannelser (KVU)',
    'Mellemlange videregående uddannelser (MVU)',
    'Bacheloruddannelser (BACH)',
    'Lange videregående uddannelser (LVU)',
    'Ph.d. og forskeruddannelser',
];

export const educationSubjectOptions = {
    'Grundskole': ['Ingen uddannelse', 'Grundskole til og med 6. klasse', 'Grundskole 7.-9. klasse', 'Grundskole 10. klasse'],
    'Gymnasiale uddannelser': ['Alment gymnasiale uddannelser', 'Erhvervsrettede gymnasiale uddannelser', 'Internationale gymnasiale uddannelser'],
    'Erhvervsfaglige uddannelser': [
        'Den pædagogiske assistent uddannelse',
        'Hospitalsteknisk assistent',
        'Social- og sundhedsassistent/hjælper',
        'Tandklinisk assistent',
        'Kontor, handel og forretningsservice ',
        'Teknologi, byggeri og transport',
        'Fødevarer, jordbrug og oplevelser',
        'Andre erhvervsfaglige uddannelser',
    ],
    'Adgangsgivende uddannelsesforløb': ['Adgangskurser - videregående udd.', 'Adgangseksamen - ingeniøruddannelse', 'Adgangsgivende værkstedsskoleforløb'],
    'Korte videregående uddannelser (KVU)': [
        'Bio- og laboratorietekniske område',
        'Designfaglige område',
        'Økonomisk-merkantile område',
        'It-faglige område',
        'Samfundsfaglige område',
        'Sundhedsfaglige område',
        'Tekniske område',
    ],
    'Mellemlange videregående uddannelser (MVU)': [
        'Lærer',
        'Pædagog',
        'Andre pædagogiske MVU',
        'Ergoterapeut',
        'Fysioterapeut',
        'Sygeplejerske',
        'Andre sundhedsfaglige MVU',
        'Socialrådgiver',
        'Andre samfundsfaglige MVU',
        'Journalist',
        'Andre medie- og kommunikationsfaglige MVU',
        'Designfaglige MVU',
        'Økonomisk-merkantile MVU',
        'Tekniske MVU',
        'Andre MVU',
    ],
    'Bacheloruddannelser (BACH)': [
        'Medicin (læge)',
        'Folkesundhedsvidenskab',
        'Andre sundhedsfaglige BACH',
        'Antropologi',
        'Sociologi',
        'Psykologi',
        'Andre samfundsvidenskabelige BACH',
        'Pædagogik',
        'Kommunikation',
        'Medievidenskab',
        'Religion',
        'Andre hummanistiske BACH',
        'Tekniske-videnskabelige BACH',
        'Teologisk BACH',
        'Idræt',
        'Interaktionsdesign',
        'Andre naturvidenskabelige BACH',
        'Andre BACH',
    ],
    'Lange videregående uddannelser (LVU)': [
        'Folkesundhedsvidenskab',
        'Andre sundhedsfaglige LVU',
        'Antropologi',
        'Sociologi',
        'Psykologi',
        'Andre samfundsvidenskabelige LVU',
        'Pædagogik',
        'Kommunikation',
        'Medievidenskab',
        'Religion',
        'Andre hummanistiske LVU',
        'Tekniske-videnskabelige LVU',
        'Teologisk LVU',
        'Idræt',
        'Interaktionsdesign',
        'Andre naturvidenskabelige LVU',
        'Andre LVU',
    ],
    'Ph.d. og forskeruddannelser': [
        'Humanistisk og teologisk, Ph.d.',
        'Kunstnerisk, Ph.d.',
        'Naturvidenskab, Ph.d.',
        'Samfundsvidenskab, Ph.d.',
        'Teknisk videnskab, Ph.d.',
        'Ph.d. og forskeruddannelser',
        'Sundhedsvidenskab, Ph.d.',
        'Videregående uddannelser uden nærmere angivelse, Ph.d.',
    ],
};

export const workStatusOptions = [
    'Studerende',
    'Lønmodtager',
    'Selvstændig',
    'Arbejdsløs',
    'Midlertidigt ude af arbejdsstyrken',
    'Førtidspensionist',
    'Folkepensionist',
    'Efterlønsmodtager',
    'Kontanthjælpsmodtager',
    'Øvrige ude af erhverv',
];

export default {
    headspaceAssociations,
    educations: educationLevelOptions,
    sexes: sexOptions,
    academicSubjects: educationSubjectOptions,
    jobSituation: workStatusOptions
};
