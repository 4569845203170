import React, {useState} from 'react';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import TextField from '@material-ui/core/TextField/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import {sexOptions} from '../../constants/businessLogic';
import {useCenterList} from '../../hooks/useRequestValueList';
import {WebMessageForm} from 'models/web';

const useStyles = makeStyles((theme) => ({
    form: {
        fontFamily: 'vagBold',
        width: '100%',
        '& > div > div > div': {
            padding: theme.spacing(2),
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& p a': {
            color: 'blue',
        }
    },
    dropDown: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttonContainer: {
        position: 'relative',
    },
    sendButton: {
        fontWeight: 'bold',
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
    checkbox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'end',
        marginTop: theme.spacing(1),
    }
}));

type Fields = Partial<WebMessageForm>;

interface EmailFormProps {
    onSubmit: (values: WebMessageForm) => Promise<void>;
}

export const EmailForm: React.FC<EmailFormProps> = ({onSubmit}) => {
    const classes = useStyles();
    const lightGreen = '#60a73f';
    const centers = useCenterList().filter(c => c.name !== "Development");
    const [acceptedPrivacy, setAcceptedPrivacy] = useState<boolean>(false);

    const handleSubmit = async (values: Fields, {setSubmitting, resetForm}: FormikHelpers<Fields>) => {
        if (!acceptedPrivacy) {
            return;
        }
        await onSubmit({
            ...(values as WebMessageForm),
            centerName: centers.find(center => center.id === values.centerId)?.name ?? '',
        });
        setSubmitting(false);
        resetForm();
    };

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        const offset = inputLabel?.current?.offsetWidth;
        if (offset !== undefined) {
            setLabelWidth(offset);
        }
    }, []);

    const initialValues: Fields = {
        name: undefined,
        age: undefined,
        sex: undefined,
        email: undefined,
        centerId: undefined,
        content: undefined,
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({isSubmitting}) => (
                <Form className={classes.form}>
                    <Container maxWidth="md">

                        <Grid container>
                            <Grid item xs={12}>
                                <h2 style={{color: lightGreen}}>Skriv en besked til os i headspace</h2>
                                <p>Her kan du skrive en besked til dit lokale headspace.</p>
                                <p>Udfyld felterne herunder, og skriv din besked til os. Husk, at du kan være anonym, hvis du ønsker det. Vi ser ikke din mailadresse, selvom du skal skrive den her. Den bliver nemlig krypteret, men den er vores vej tilbage til dig.</p>
                                <p>Når du har sendt din besked, vil du hurtigst muligt få en besked fra en headspace-medarbejder, hvor du har mulighed for at booke en gratis og anonym samtale i dit lokale headspace-center.</p>
                                <p><b>Vi sender besked retur på email, så hold øje med din indbakke, spam, og der hvor du modtager uønsket mail.</b></p>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    color={'secondary'}
                                    id="name"
                                    name="name"
                                    label="Navn (behøves ikke)"
                                    type="text"
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    margin="normal"
                                    color={'secondary'}
                                    fullWidth
                                    id="age"
                                    name="age"
                                    label="Alder (behøves ikke)"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth className={classes.dropDown}>
                                    <InputLabel ref={inputLabel} id="sex-label">Køn (behøves ikke)</InputLabel>
                                    <Field
                                        as={Select}
                                        id="sex"
                                        name="sex"
                                        labelId="sex-label"
                                        color={'secondary'}
                                        labelWidth={labelWidth}
                                    >
                                        <MenuItem value={undefined} disabled={true}>Køn</MenuItem>
                                        {sexOptions.map(sex => (
                                            <MenuItem key={sex} value={sex}>{sex}</MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box pb={6}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    margin="normal"
                                    color={'secondary'}
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="E-mail"
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" required fullWidth className={classes.dropDown}>
                                    <InputLabel ref={inputLabel} id="center-label">Center</InputLabel>
                                    <Field
                                        as={Select}
                                        id="centerId"
                                        name="centerId"
                                        labelId="center-label"
                                        color={'secondary'}
                                        labelWidth={labelWidth}
                                    >
                                        <MenuItem value={undefined} disabled={true}>Center</MenuItem>
                                        {centers.map(center => (
                                            <MenuItem key={center.id} value={center.id}>{center.name}</MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    margin="normal"
                                    color={'secondary'}
                                    required
                                    fullWidth
                                    multiline
                                    placeholder={'Skriv din besked her...'}
                                    rows={8}
                                    id="content"
                                    name="content"
                                    type="text"
                                />
                            </Grid>
                            <Grid container item xs={10}>
                                <Grid item xs={11}>
                                    <p style={{
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}>{'Jeg accepterer headspaces privatlivspolitik'}</p>
                                </Grid>
                                <Grid item xs={1} className={classes.checkbox}>
                                    <Checkbox
                                        checked={acceptedPrivacy}
                                        onChange={(event) => setAcceptedPrivacy(event.target.checked)}
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p>Du giver os dermed lov til at behandle dine oplysninger – dit navn, din mailadresse og din besked. Så kan og må vi nemlig gerne svare dig, når vi har læst din besked.</p>
                                    <p>{'Bare rolig, vi passer rigtig godt på dine oplysninger og kunne aldrig finde på at give dem videre til andre. Du kan læse om vores privatlivspolitik '}
                                        <a href="https://www.headspace.dk/om-headspace/privatlivspolitik"
                                           target="_blank"
                                           style={{color: lightGreen}}
                                           rel="noopener noreferrer">
                                            {'her'}
                                        </a>.
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid container item xs={2} justify="flex-end">
                                <div className={classes.buttonContainer}>
                                    <Button
                                        className={classes.sendButton}
                                        color="secondary"
                                        variant="contained"
                                        aria-label="submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Send
                                    </Button>
                                    {isSubmitting &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};

export default EmailForm;
