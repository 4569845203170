import {Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import Call from "../../models/Call";
import {downloadAsCSVFile} from "../../lib/downloadAsFile";

export const ExportCallCSVButton: React.FC<{ calls: Call[] }> = ({calls}) => {

    const handleExportClick = () => downloadVideoCallAsCSVFile(calls);

    return (
        <Tooltip title={"Eksporter videodata til csv"}>
            <Button
                variant={"contained"}
                color='primary'
                component={"a"}
                onClick={handleExportClick}
            >
                Eksporter videodata
            </Button>
        </Tooltip>

    )
}

export function downloadVideoCallAsCSVFile(calls: Call[]) {
    const currentDate = new Date().toISOString().split("T")[0];
    const fileName = "" + currentDate + "-headspace-videokald.csv";

    const separator = ";"
    const header = Object.keys(calls[0]).filter(e => e !== 'redirects');
    const csv = calls.map(row => header.map(fieldName => {
        if (row[fieldName] === null) return '';
        return row[fieldName];
    }).join(separator));
    csv.unshift(header.join(separator));
    csv.unshift("SEP=" + separator);
    const content = csv.join('\r\n')

    return downloadAsCSVFile(fileName, content)

}


export default ExportCallCSVButton;