import {AppClient} from './appClient';
import RequestMethod from '../models/RequestMethod';
import AppResponse from "../models/httprequest/AppResponse";

const servicePath = '/api/v1/support';

export interface SupportEmailRequest {
    title: string;
    text: string;
}

export interface SupportService {
    sendEmail: (request: SupportEmailRequest) => AppResponse<unknown>;
}

export function supportService(client: AppClient): SupportService {
    return {
        sendEmail: (request: SupportEmailRequest) => {
            return client.fetchJSON(RequestMethod.POST, servicePath, {
                ...request
            });
        }
    }
}
