import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import IconButton, {IconButtonProps} from "@material-ui/core/IconButton";
import {Badge, Tooltip} from "@material-ui/core";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import Popover from "@material-ui/core/Popover";
import NotificationList from "./NotificationList";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import ProfileIcon from "@material-ui/icons/AccountCircle";
import {notificationsActions} from "../../store/NotificationsState";

const NotificationDisplay: React.FC = () => {
    const {elements} = useSelector((state: AppState) => state.notifications);
    const iconRef = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
        dispatch(notificationsActions.updateRead());
    };

    const unreadNotificationsCount = (): number => {
        return elements.filter(notification => !notification.read).length
    };

    return (
        <React.Fragment>
            <AppBarIconButton onClick={handleOpen}>
                <Badge badgeContent={unreadNotificationsCount()} color="error">
                    <NotificationsRoundedIcon ref={iconRef}/>
                </Badge>
            </AppBarIconButton>
            <Popover
                anchorEl={iconRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
            >
                <NotificationList notifications={elements} onClose={handleClose}/>

                {elements.length === 0 &&
                <div style={{padding: "30px 0", textAlign: "center", minWidth: 300}}>
                    Ingen notifikationer
                </div>
                }

            </Popover>
        </React.Fragment>
    )
};


export const ProfileButton: React.FC = () => {
    const dispatch = useDispatch();
    return (
        <AppBarIconButton onClick={() => dispatch(push(Routes.profile))}>
            <Tooltip title="Tilgå din profilside">
                <ProfileIcon />
            </Tooltip>
        </AppBarIconButton>
    )
};


const useStyles = makeStyles(theme => ({
    sendButton: {
        marginLeft: theme.spacing(2),

        '& svg': {
            height: theme.spacing(4),
            width: 'auto',
            // height: theme.spacing(3),
            // width: 'auto',
        }
    }
}));

export const AppBarIconButton: React.FC<IconButtonProps> = ({children, onClick}) => {
    const classes = useStyles()
    return (
        <IconButton className={classes.sendButton} onClick={onClick} size={"small"}>
            {children}
        </IconButton>
    )
}


export default NotificationDisplay;