import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Paper from '@material-ui/core/Paper/Paper';
import Box from '@material-ui/core/Box/Box';
import {makeStyles} from '@material-ui/core';
import LoginForm from './LoginForm';
import {useDispatch, useSelector} from 'react-redux';
import ForgotPasswordForm from './ForgotPasswordForm';
import {AppState} from '../../store';
import VersionTag from '../../components/VersionTag';
import background from '../../assets/headspace-bg.jpg'

import logo from '../../assets/dsn-headspace-logo.png';
import {authActions} from "../../store/AuthState";
import SingleSignOnButton from "./SingleSignOnButton";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: "url(" + background + ")",
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(8, 6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const LoginPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useSelector((state: AppState) => state?.router?.location);

    const showForgotPassword = (location?.state as { showForgotPassword?: boolean })?.showForgotPassword ?? false;

    const onLoginPress = (email: string, password: string) => {
        dispatch(authActions.signInWithEmailAndPassword(email, password));
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5}>
                <Paper elevation={6} square className={classes.content}>
                    <Box display="flex" flexDirection="column" alignItems="center" pb={1}>
                        <img src={logo} alt="Det Sociale Netværk/headspace" width="320px"/>
                    </Box>
                    {showForgotPassword && <ForgotPasswordForm/>}
                    {!showForgotPassword && (
                        <LoginForm onSubmit={onLoginPress}>
                            <SingleSignOnButton />
                        </LoginForm>
                    )}
                    <VersionTag/>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
