import React, {useState} from "react";
import {toValueMap} from "../../lib/toIdValueMap";
import {Box, Checkbox, useTheme} from "@material-ui/core";
import {FilledTextField, FilledTimeField, useFilledTextFieldStyle} from "../../components/FilledTextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import {DateIntervalState} from "../../components/pickers/DateIntervalPicker";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ShiftInterval, ShiftPlan} from "../../services/eventService";
import useCurrentCenter from "../../hooks/useCurrentCenter";
import {endOfWeekSunday, startOfWeekMonday, WeekIntervalPicker} from "../../components/pickers/WeekIntervalPicker";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import daLocale from "date-fns/locale/da";
import {toLocalISOTime} from "../../lib/toLocalISO";
import TextField from "@material-ui/core/TextField/TextField";
import SelectNamed from "../../components/SelectNamed";

const useStyles = makeStyles(({spacing}) => ({
    sectionTitle: {
        marginBottom: spacing(2),
        fontSize: 18,
        fontWeight: 600,
    },
}));


const initialStartTime = new Date();
initialStartTime.setHours(10, 0, 0, 0);
const initialEndTime = new Date();
initialEndTime.setHours(14, 0, 0, 0);

const initialIntervalInput = {
    title: '',
    start: initialStartTime,
    end: initialEndTime,
};

interface ShiftPlanFormProps {
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
    onSubmit?: (shiftPlan: ShiftPlan) => void;
}


const ShiftPlanForm: React.FC<ShiftPlanFormProps> = ({submitButtonRef, onSubmit}) => {
    const classes = useStyles();
    const classesTemp = useFilledTextFieldStyle();

    const theme = useTheme();
    const currentCenter = useCurrentCenter();
    const dayList = ['MAN', 'TIR', 'ONS', 'TOR', 'FRE', 'LØR', 'SØN'];
    const initialSelectedDays = {
        ...toValueMap(dayList, true),
        'LØR': false,
        'SØN': false
    };


    const [selectedDays, setSelectedDays] = useState(initialSelectedDays);
    const [maxParticipants, setMaxParticipants] = useState(4);
    const [dateInterval, setDateInterval] = useState<DateIntervalState>(
        {
            start: new Date(),
            end: new Date()
        }
    );

    const [shiftIntervals, setShiftIntervals] = useState<ShiftInterval[]>([]);
    const [shiftIntervalInput, setShiftIntervalInput] = useState<{ title: string; start: Date; end: Date }>(initialIntervalInput);
    const [allDay, setAllDay] = useState<boolean>(false);


    const handleAddInterval = () => () => {
        setShiftIntervals([...shiftIntervals, {
            title: shiftIntervalInput.title,
            startTime: toLocalISOTime(`${shiftIntervalInput.start}`),
            endTime: toLocalISOTime(`${shiftIntervalInput.end}`),
        }]);
        const start = new Date(`${shiftIntervalInput.start}`);
        const end = new Date(`${shiftIntervalInput.end}`);
        const hourDiff = end.getHours() - start.getHours();
        const endHour = Math.min(23, end.getHours() + hourDiff);
        const endMinute = Math.min(59, shiftIntervalInput?.end?.getMinutes() ?? 0);
        const nextEnd = new Date(shiftIntervalInput.start);
        nextEnd?.setHours(endHour, endMinute, 0, 0);
        setShiftIntervalInput({
            title: '',
            start: shiftIntervalInput.end,
            end: nextEnd,
        });
    };

    const handleDeleteInterval = (index: number) => () => {
        setShiftIntervals([...shiftIntervals].filter((_, i) => i !== index));
    };


    const [reminderMillis, setReminderMillis] = useState('0');

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault(); // prevent refresh
                console.log(shiftIntervalInput)
                onSubmit && onSubmit({
                    centerId: currentCenter.id,
                    maxParticipants: maxParticipants,
                    weekDays: Object.keys(selectedDays).map(key => selectedDays[key]),
                    startDate: startOfWeekMonday(dateInterval.start),
                    endDate: endOfWeekSunday(dateInterval.end),
                    shiftIntervals: shiftIntervals,
                    allDay: allDay,
                    reminderMillis: parseInt(reminderMillis ?? '0')
                });
            }}>
            <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
            <div className={classes.sectionTitle}>Vælg uge dage:</div>
            <Box mb={2} display={'flex'} justifyContent={'space-between'}>
                {dayList.map((text) => {
                    return (
                        <DayButton
                            key={text}
                            text={text}
                            selected={selectedDays[text]}
                            onClick={() => {
                                setSelectedDays({
                                    ...selectedDays,
                                    [text]: !selectedDays[text]
                                })
                            }}
                        />
                    )
                })}
            </Box>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                color={'secondary'}
                id="maxParticipants"
                name="maxParticipants"
                label={"Maks antal medarbejdere per vagt"}
                type="number"
                value={maxParticipants}
                onChange={(e) => setMaxParticipants(Math.max(0, parseInt(e.target.value)))}
            />

            <div className={classes.sectionTitle}>Vælg periode:</div>
            <Box mb={2} display={'flex'}>
                <WeekIntervalPicker state={dateInterval} setState={setDateInterval}/>
            </Box>

            <div className={classes.sectionTitle}>
                Hele dagen
                <Tooltip
                    title={"Hvis valgt vil vagterne blive til heldags begivenheder uanset det indtastede start og slut tidspunkt"}>
                    <Checkbox
                        color={'primary'}
                        name="allDay"
                        id="allDay"
                        checked={allDay}
                        onChange={(event) => setAllDay(event.target.checked)}
                    />
                </Tooltip>
            </div>

            <div className={classes.sectionTitle} style={{display: allDay ? 'none' : 'flex'}}>
                <span style={{minWidth: 94, marginRight: 16}}>
                    Notifikation
                </span>
                <SelectNamed
                    className={classesTemp.textField}
                    variant="filled"
                    value={reminderMillis}
                    onChange={e => setReminderMillis(e.target.value as string)}
                    options={[{
                        id: '0',
                        name: 'Ingen',
                    }, {
                        id: '1800000',
                        name: '30 minutter før',
                    }, {
                        id: '3600000',
                        name: '1 time før',
                    }, {
                        id: '86400000',
                        name: '1 dag før',
                    }]}
                />
            </div>


            <div className={classes.sectionTitle}>Tilføj vagter:</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <FilledTextField
                        placeholder={'Tilføj titel'}
                        value={shiftIntervalInput.title}
                        onChange={(e) => {
                            setShiftIntervalInput({
                                ...shiftIntervalInput,
                                title: e.target.value,
                            })
                        }}/>
                    <FilledTimeField
                        id="start"
                        name="start"
                        value={shiftIntervalInput.start}
                        onChange={(date) => setShiftIntervalInput({
                            ...shiftIntervalInput,
                            start: date || shiftIntervalInput.start,
                        })}
                    />
                    <FilledTimeField
                        id="end"
                        name="end"
                        value={shiftIntervalInput.end}
                        onChange={(date) => setShiftIntervalInput({
                            ...shiftIntervalInput,
                            end: date || shiftIntervalInput.end,
                        })}
                    />
                    <Tooltip title={'Tilføj ny'}>
                        <IconButton onClick={handleAddInterval()}>
                            <AddIcon style={{color: theme.palette.success.main}}/>
                        </IconButton>
                    </Tooltip>

                </Box>
            </MuiPickersUtilsProvider>

            {shiftIntervals.map((shiftInterval, index) => (
                <Box key={shiftInterval.title + index} display={'flex'} alignItems={'center'}>
                    <Box flex={1} pl={2}
                         style={{color: shiftInterval.title ? 'black' : 'grey'}}>{shiftInterval.title || '(ingen titel)'}</Box>
                    <Box flex={1} pl={2}>{shiftInterval.startTime}</Box>
                    <Box flex={1} pl={2}>{shiftInterval.endTime}</Box>
                    <Tooltip title={'Fjern'}>
                        <IconButton onClick={handleDeleteInterval(index)}>
                            <DeleteIcon color={'error'}/>
                        </IconButton>
                    </Tooltip>
                </Box>
            ))}
        </form>
    );
};

const DayButton: React.FC<{ text: string; selected?: boolean; onClick: VoidFunction }> = ({text, selected, onClick}) => {

    return (
        <Button
            onClick={() => onClick()}
            style={{
                width: 64,
                background: selected ? 'black' : 'white',
                color: selected ? 'white' : 'black',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: 20,
                borderRadius: 7,
                paddingTop: 4,
                paddingBottom: 4,
            }}>
            {text}
        </Button>
    )
};

export default ShiftPlanForm;