import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import Routes from 'constants/Routes';
import { Route, Redirect } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const currentUser = useCurrentUser();

    if (currentUser) {
        return (
            <Route>
                <Redirect to={Routes.dashboard}/>
            </Route>
        )
    }

    return (
        <div>
            <h3>404: Siden findes ikke</h3>
            <p>Vi kunne desværre ikke finde den side, du ledte efter...</p>
            <Route>
                <Redirect to={Routes.landing}/>
            </Route>
        </div>
    );
};

export default NotFoundPage;
