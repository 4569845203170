import {useDispatch, useSelector} from 'react-redux';
import {RequestActionType, RequestState} from '../store/RequestState';
import {AppState} from '../store';
import {useEffect} from 'react';
import {Dispatch} from "redux";

export interface ElementHandler<T> {
    (element: T | undefined): () => void;
}

export interface ElementFunction<T> {
    (element: T | undefined): void;
}

export interface SelectorFunction<T, S extends RequestState<T> = RequestState<T>> {
    (state: AppState): S;
}

export interface CrudRequestState<T, S extends RequestState<T> = RequestState<T>> {
    state: S;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>;
    setEditElement: ElementFunction<T | undefined>;
    updateElement: ElementFunction<T>;
    deleteElement: ElementFunction<T>;
    handleEditClick: ElementHandler<T>;
}

// export function useCenterCrudRequestState<T>(
//     selector: SelectorFunction<T>,
//     actions: RequestActionType<T>
// ): CrudRequestState<T> {
//
// }


export function useCrudRequestState<T, S extends RequestState<T> = RequestState<T>>(
    selector: SelectorFunction<T, S>,
    actions: RequestActionType<T>,
    refresh = true,
): CrudRequestState<T, S> {
    const dispatch = useDispatch();
    const state: S = useSelector(selector);
    const setEditElement = (element: T | undefined) => {
        dispatch(actions.setEditElement(element));
    };

    const updateElement = (element: T | undefined) => {
        dispatch(actions.updateElement(element));
    };

    const deleteElement = (element: T | undefined) => {
        dispatch(actions.deleteElement(element));
    };

    const handleEditClick = (element: T | undefined) => () => {
        setEditElement(element);
    };

    // REFRESH CURRENT STATE
    useEffect(() => {
        if(refresh) {
            dispatch(actions.refresh());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    


    return {state, dispatch, setEditElement, updateElement, deleteElement, handleEditClick};
}

export default useCrudRequestState;
