import opretOpslag from './opret_opslag';
import besvarSMSHenvendelser from './besvar_sms_henvendelser';
import besvarWebHenvendelser from './besvar_web_henvendelser';
import opretVideosamtale from './opret_videosamtale';
import vagtplan from './vagtplan';
import administrereBrugere from './administrere_brugere';
import outlookIntegration from "./outlook_integration";
import outlookUsage from "./outlook_usage";

const articleFilePathnames: { [filepath: string]: { title: string; source: string } } = {
    "administrere_brugere": {title: "Sådan administrer/opretter du en bruger", source: administrereBrugere},
    "besvar_sms_henvendelser": {title: "Sådan besvarer du SMS-henvendelser", source: besvarSMSHenvendelser},
    "besvar_web_henvendelser": {title: "Sådan besvarer du Web-henvendelser", source: besvarWebHenvendelser},
    "opret_videosamtale": {title: "Sådan opretter du en videosamtale", source: opretVideosamtale},
    "vagtplan": {title: "Sådan bruger du vagtplanen", source: vagtplan},
    "opret_opslag": {title: "Sådan opretter du et opslag", source: opretOpslag},
    "outlook_integration": { title: "Sådan integrerer du vagtplanen med Outlook", source: outlookIntegration },
    "outlook_brug": { title: "Brug af Outlook sammen med vagtplanen", source: outlookUsage }
}

export default articleFilePathnames;
