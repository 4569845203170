import React, {useCallback, useState} from 'react';
import {DialogContentText, IconButton, Tooltip} from '@material-ui/core';
import appService from '../../services/appService';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import LinkIcon from '@material-ui/icons/Link';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";


const VideoLinkButton: React.FC<{ onSendClick: (message: string) => void }> = ({onSendClick}) => {
    const [link, setLink] = useState<string | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => setOpen(false);
    const handleClick = async () => {
        if (link) {
            setOpen(true);
            return;
        }

        const response = await appService.video.createCall(
            {
                default: 'https://www.survey-xact.dk/LinkCollector?key=GZWSCDSELN9K',
                raadgiver: 'https://www.survey-xact.dk/LinkCollector?key=TNTFCYJAS636'
            }
        );

        if (!response.success) {
            return;
        }

        setLink('https://video.headspace.dk/calls/' + response.value.id);
        setOpen(true);
    };

    const copyToClipboard = useCallback(async () => {
        await navigator.clipboard.writeText(link ?? '');
    }, [link]);

    return (
        <React.Fragment>
            <Tooltip title='Opret videolink'>
                <IconButton color='primary' onClick={handleClick}>
                    <VideoCallIcon style={{fontSize: 30}}/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={Boolean(link && open)}
                onClose={handleClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Her er dit videolink
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <div style={{
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 4,
                            backgroundColor: "#ddd",
                            padding: 16,
                            color: 'black',
                        }}>
                            {link}

                            <Tooltip
                                title={'Kopier videolink'}
                                aria-label='videolink'
                            >
                                <IconButton
                                    aria-label='videolink'
                                    color='primary'
                                    onClick={copyToClipboard}
                                    style={{padding: 0, marginLeft: 16}}
                                >
                                    <LinkIcon/>
                                </IconButton>
                            </Tooltip>

                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() => {
                            const message = "Her er linket til videosamtalen:\n" + link + "\nNår samtalen er færdig, lægger du på og kommer videre til vores spørgeskema.";
                            onSendClick && link && onSendClick(message);
                            handleClose();
                        }}
                    >
                        SEND VIDEOLINK
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};


export default VideoLinkButton;