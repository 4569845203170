import {
    FormControl,
    InputLabel,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    SelectProps
} from '@material-ui/core';
import React, {Dispatch, SetStateAction} from 'react';
import {Field} from 'formik';
import Checkbox from '@material-ui/core/Checkbox';


export interface NamedValue {
    id: string;
    name: string;
    description?: string;
}

export interface NamedSelectFormFieldProps<T extends NamedValue> {
    className?: string;
    id: string;
    label?: string;
    required?: boolean;
    error?: string | boolean;
    variant?: SelectProps['variant'];
    options: T[];
    selectedIdList: string[];
    setSelectedIdList: Dispatch<SetStateAction<string[]>>;
    fullWidth?: boolean;
    multiple?: boolean;
    defaultItem?: { name: string };
}

export function NamedSelectFormField<T extends NamedValue>(
    {
        id,
        label,
        required = false,
        selectedIdList,
        defaultItem,
        multiple = false,
        setSelectedIdList,
        fullWidth = false,
        error,
        options,
    }: NamedSelectFormFieldProps<T>) {

    const labelId = `${id}-label`;
    const inputLabel = label + (required ? ' *' : '');


    const renderValue = (selectedIdList: string[]) => {
        return options.filter((option) => selectedIdList.includes(option.id)).map(value => value.description ?? value.name).join(', ');
    };

    const isSelected = (id: string): boolean => {
        return selectedIdList.findIndex((selectedId) => selectedId === id) !== -1;
    };

    const updateSelectedIdList = (id: string) => {
        isSelected(id) ? setSelectedIdList(selectedIdList.filter((selectedId: string) => selectedId !== id))
            : setSelectedIdList(selectedIdList.concat([id]))
    };


    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel id={labelId}>
                {inputLabel}
            </InputLabel>
            <Field
                as={Select}
                // validate={() => selectedCenterIds.length <= 0 ? 'Brugeren skal have mindst 1 center tilknytning' : undefined}
                error={error}
                fullWidth={fullWidth}
                required={required}
                name={id}
                label={label}
                labelId={labelId}
                id={id}
                multiple={multiple}
                renderValue={renderValue}
                value={selectedIdList}
                onChange={(event) => setSelectedIdList([event.target.value].flat())}
            >
                <MenuItem value={undefined} disabled>
                    {label}
                </MenuItem>
                {defaultItem && (
                    <MenuItem value={undefined} disabled>
                        <ListItemText primary={defaultItem.name}/>
                        <ListItemSecondaryAction>
                            <Checkbox checked={true}/>
                        </ListItemSecondaryAction>
                    </MenuItem>
                )}
                {options?.map((option: T) => {
                    return (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                        >
                            <ListItemText primary={option.description ?? option.name}/>
                            <ListItemSecondaryAction>
                                <Checkbox
                                    onChange={() => updateSelectedIdList(option.id)}
                                    checked={isSelected(option.id)}
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    );
                })}
            </Field>
        </FormControl>
    );
}
