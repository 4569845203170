import React from "react";
import Button from "@material-ui/core/Button";
import {PublicClientApplication, AuthenticationResult} from "@azure/msal-browser";
import {authActions} from "../../store/AuthState";
import {useDispatch} from "react-redux";
import MSIcon from "../../assets/ms-icon.webp"

// Config object to be passed to Msal on creation
// Doc: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md

export const msalConfig = {
    auth: {
        clientId: "dbc68b80-ff7e-4158-af67-71a866682170", // Headspace
        authority: "https://login.microsoftonline.com/c5d589cc-652d-431d-a668-d4b66c04271f", // Headspace
        // clientId: "4316ffe2-b88b-4751-966b-1c997fc2cbbf", // DTU
        // authority: "https://login.microsoftonline.com/f251f123-c9ce-448e-9277-34bb285911d9", // DTU
        redirectUri: "https://www.admin.headspace.dk/connect", //http://localhost:3000/connect
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};

const MSClient = new PublicClientApplication(msalConfig)

const SingleSignOnButton: React.FC = () => {
    const dispatch = useDispatch();

    const handleClick = async () => {
        const res: AuthenticationResult | void = await MSClient.loginPopup(loginRequest).catch(err => {
            console.error(err);
        });
        if (res === undefined) return;

        if (res.accessToken && res.account?.localAccountId) {
            dispatch(authActions.signInWithSSO(res.account?.localAccountId));
        }
    }

    return (
        <Button
            fullWidth={true}
            color="primary"
            variant="contained"
            aria-label="submit"
            type="submit"
            style={{
                fontWeight: 'bold',
                marginBottom: '16px'
            }}
            onClick={handleClick}
            startIcon={<img src={MSIcon} alt="MS Logo"/>}
        >
            Log ind med Microsoft
        </Button>
    )
}

export default SingleSignOnButton;
