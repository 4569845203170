import {AgyliaActivity} from "../../models/User";
import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {toLocalDayDateString} from "../../lib/toLocalISO";


export interface AgyliaActivityPopoverProps {
    anchorEl?: Element;
    activityList?: AgyliaActivity[];
    onClose: () => void;
}

export const AgyliaActivityPopover: React.FC<AgyliaActivityPopoverProps> = ({anchorEl, activityList, onClose}) => {
    const theme = useTheme();
    return (
        <Popover
            id={anchorEl ? 'agylia-popover' : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Box p={2}>
                <div style={{fontWeight: 600, fontSize: 18, marginBottom: 16}}>
                    Oversigt over brugerens aktivitet i HS Academy
                </div>
                {activityList?.map(activity => (
                    <ListItem key={activity.title + "-" + activity.date}>
                        <ListItemAvatar>
                            {activity.status === 'completed' ?
                                <Tooltip title={"Gennemført"}>
                                    <DoneIcon style={{color: theme.palette.success.main}}/>
                                </Tooltip> :
                                <Tooltip title={"Ikke gennemført"}>
                                    <NotInterestedIcon style={{color: theme.palette.warning.main}}/>
                                </Tooltip>}
                        </ListItemAvatar>
                        <ListItemText
                            primary={activity.title}
                            secondary={toLocalDayDateString(activity.date)}
                        />
                    </ListItem>
                )) || 'Brugeren har ikke påbegyndt noget materiale på HS Academy'}
            </Box>
        </Popover>
    )
}
export default AgyliaActivityPopover;