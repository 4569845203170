import React from "react";
import Box from "@material-ui/core/Box";
import {Bar} from "react-chartjs-2";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {chartBackgroundColors, chartBorderColors} from "./UserBarChart";
import {lastMonthCountMap} from "./VideoBarChart";
import {InquiryThread} from "../../models/Inquiry";


const toFieldCountMap = (list: InquiryThread[]) => {
    if (!list || !list[0]) return [];
    const countMap = list.reduce<{ [p: string]: number }>((result, value) => {

            value.messages.forEach((message) => {
                const label = new Date(message.createdAt).toISOString().split("T")[0];
                if (message.author.id !== value.author.id) return;
                result[label] = !result[label] ? 1 : result[label] + 1;
            })
            return result;
        },
        {}
    )

    return lastMonthCountMap(countMap);

}


export const InquiryBarChart: React.FC = () => {

    const inquiryThreads = useSelector((state: AppState) => state.inquiries.elements);
    const fieldCountMap = toFieldCountMap(inquiryThreads)
    const labels = Object.keys(fieldCountMap).sort((a, b) => a.localeCompare(b));
    const values = labels.map(label => fieldCountMap[label]).concat(0);

    return (
        <Box p={2}>
            <Box pl={2} pb={2} display={'flex'} justifyContent={'space-between'}>
                <span>{"Antal ingående beskeder"}</span>
            </Box>

            <Bar
                options={{legend: {display: false}}}
                data={{
                    labels: labels,
                    datasets: [{
                        label: '',
                        data: values,
                        backgroundColor: "#2CC66C", // chartBackgroundColors,
                        borderColor: "#2CC66C", // chartBorderColors,
                        borderWidth: 1
                    }]
                }}
            />
        </Box>
    )
}

export default InquiryBarChart;