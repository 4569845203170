import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory, Link } from 'react-router-dom';
import { Paper, Box, makeStyles, Divider, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import articles from './articles';
import Routes from 'constants/Routes';
import BasePage from 'pages/BasePage';
import IssueForm from './IssueForm';


const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: 960,
    },
    back: {
        display: 'flex',
        '& > a, a:hover, a:active, a:visited': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            height: 24,
            lineHeight: "24px",
            display: "inherit",
        }
    },
    markdown: {
        maxWidth: 800,
        marginBottom: theme.spacing(3),
        '& img': {
            maxWidth: 800,
            textAlign: 'center',
        }
    },
    footer: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(3),
        '& > div': {
            marginLeft: theme.spacing(-1),
        }
    }
}))

const ArticlePage: React.FC<{ match: { params: { id: string } } }> = ({ match: { params: { id }}}) => {
    const history = useHistory();
    const classes = useStyles();

    const article = articles[id];

    if (!article) {
        history.replace(Routes.support)
    }

    return (
        <BasePage>
            <Paper className={classes.paper}>
                <Box p={2} display="flex" flexDirection="column">
                    <Box className={classes.back}>
                        <Link to={Routes.support}>
                            <ArrowBack color="primary"/> Tilbage til support
                        </Link>
                    </Box>
                    <Box className={classes.markdown}>
                        <ReactMarkdown source={article.source} />
                    </Box>
                    <Divider variant="fullWidth" orientation="horizontal" />
                    <Box className={classes.footer}>
                        <Typography variant="body1">
                            Fandt du ikke svar på dine spørgsmål, så skriv til os. Du er altid velkommen.
                        </Typography>
                        <IssueForm />
                    </Box>
                </Box>
            </Paper>
        </BasePage>
    )
}

export default ArticlePage;
