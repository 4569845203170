import forsideUdenOplag from "./images/opslag/Forside_uden_opslag.png"
import opretOpslag from "./images/opslag/Opret_opslag.png"
import gemOpslag from "./images/opslag/Gem_opslag.png"
import forsideMedOpslag from "./images/opslag/Forside_med_opslag.png"
import opretOpslagFunktionsbjaelke from "./images/opslag/Opret_opslag_funktionsbjaelke.png"
export default `# Sådan opretter du et opslag.

1. Tilgå forsiden ved at vælge "Forside" i menuen på venstre side af skærmen.

2. Herefter klikker du på den blå knap "OPRET NYT OPSLAG"
![Klik på "OPRET NYT OPSLAG"](${forsideUdenOplag})

3. Skriv dit opslag i feltet ”Tilføj besked…” og benyt bjælken ovenover til at redigere teksten. En oversigt over de forskellige knapper findes forneden i ”Funktionsoversigt”

![Skriv dit opslag i feltet "*Tilføj besked...*"](${opretOpslag})

4. Når du er færdig med at skrive opslaget til dit center, mangler det bare at blive lagt op. . Det gør du ved at klikke på knappen "GEM".
![Klik på "GEM" for at lægge dit opslag op](${gemOpslag})

5. Herefter kan alle brugere i dit headspace se dit opslag på deres forside. Du kan altid redigere opslaget efterfølgende ved at klikke på blyant-ikonet.
![Alle medlemerne af dit center kan nu se opslaget på deres forside](${forsideMedOpslag})

## Funktionsoversigt

![Her er et billed af alle knapper på bjælken](${opretOpslagFunktionsbjaelke})

* Ved at klikke på "Normal", få du mulighed for at ændre størrelsen af din skrift. Du bliver givet valgmulighederne "Heading 1", "Heading 2" og "Normal".
  
* Ved at klikke på "**B**", får du mulighed for at gøre din skrift fed.

* Ved at klikke på "*I*", får du mulighed for at skrive i kursiv.

* Ved at klikke på "U", får du mulighed for at skrive text med en understregning.

* Ved at klikke på knappen med punkterne "1, 2 og 3", får du mulighed for at lave en punktoversigt med tal.

* Ved at klikke på knappen med punkterne, får du mulighed for at lave en punktoversigt.

* Ved at klikke på de to knapper efter "punktoversigten", får du mulighed at lave indryk i teksten, enten til højre eller venstre.

* Ved at klikke på knappen med ikonet, der ligner et link af en kæde, får du mulighed at at indsætte et link, som folk kan tilgå ved at klikke på det.

* Ved at klikke på knappen med ikonet, der ligner et billed, får du mulighed for at indsætte et billed i dit opslag.

* Ved at klikke på den sidste knap i bjælken får du mulighed for at fravælge de knapper, som der står aktive.
`;
