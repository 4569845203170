import {LoadedAsset} from "../../models/file/Asset";
import React from "react";
import {Box} from "@material-ui/core";
import AssetPickedDisplay from "../assets/AssetPickedDisplay";

interface UploadedAssetsProps {
    assets: LoadedAsset[];
    onChange: (assets: LoadedAsset[]) => void;
}

const UploadedAssets: React.FC<UploadedAssetsProps> = ({assets, onChange}) => {

    const handleRemoveAsset = (index: number) => {
        const assetsCopy = [...assets];
        assetsCopy.splice(index, 1);
        onChange(assetsCopy);
    };
    const style: React.CSSProperties = {
        background: "#F5F5F5",
        overflowX: 'auto',
    }

    if (assets.length > 0) return (
        <Box display="flex" pt={1} pl={2} pr={2} position="relative" style={style}>
            <Box display='flex' alignItems='flex-end'>
                {assets.map((asset, index) =>
                    <AssetPickedDisplay
                        key={index}
                        asset={asset}
                        handleRemove={handleRemoveAsset}
                        index={index}
                    />
                )}
            </Box>
        </Box>
    )

    return <div/>
}

export default UploadedAssets;
