import {AppClient} from './appClient';
import User, {CurrentUser} from '../models/User';
import RequestMethod from '../models/RequestMethod';
import AppResponse from "../models/httprequest/AppResponse";

export interface IsSynchronizedResponse {
    IsSynchronized: boolean;
}

const servicePath = '/api/v1/me';

export type MeService = {
    get: () => AppResponse<User>;
    updateEmail: (request: {firstName: string; lastName: string; email: string}) => AppResponse<User>;
    updatePassword: (password: string) => AppResponse<User>;
    updateData: (userData: CurrentUser) => AppResponse<User>;
    isSynchronized: () => AppResponse<IsSynchronizedResponse>;
};

export function meService(client: AppClient): MeService {
    return {
        async get() {
            return client.fetchJSON<User>(RequestMethod.GET, servicePath);
        },
        async updateEmail(request) {
            return client.fetchJSON<User>(RequestMethod.PUT, servicePath + '/email', request);
        },
        async updatePassword(password) {
            return client.fetchJSON<User>(RequestMethod.PUT, servicePath + '/password', {
                password: password
            });
        },
        async updateData(userData: CurrentUser) {
            return client.fetchJSON<User>(RequestMethod.PUT, servicePath + '/update-data', {
                ...userData
            });
        },
        async isSynchronized(): AppResponse<IsSynchronizedResponse> {
            return await client.fetchJSON<IsSynchronizedResponse>(RequestMethod.GET, servicePath + '/is-outlook-synchronized');
        }
    }
}


