import React from 'react';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        position: 'relative',
    },
    sendButton: {
        fontWeight: 'bold',
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
}));

interface FormButtonProps {
    text?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    isSubmitting?: boolean;
}

export const FormButton: React.FC<FormButtonProps> = ({children, text, disabled = false, isSubmitting = false, fullWidth = true}) => {
    const classes = useStyles();
    return (
        <div className={classes.buttonContainer}>
            <Button
                className={classes.sendButton}
                fullWidth={fullWidth}
                color="primary"
                variant="contained"
                aria-label="submit"
                type="submit"
                disabled={disabled || isSubmitting}
            >
                {text ?? children}
            </Button>
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
    );
};

export default FormButton;
