import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';

import AddButton from './buttons/AddButton';
import {TableSortLabel} from '@material-ui/core';
import {PropertyCallback} from '../models/Callbacks';
import SearchInput from './SearchInput';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    utilityHead: {
        backgroundColor: grey[200],
        '& tr:first-child': {
            '& th': {
                borderBottom: 'none',
                paddingBottom: 0,
            },
        },
    },
}));

interface EnhancedTableHeadProps {
    labels: TableHeadLabel[];
    search: string | undefined;
    onSearchChange: ((search: string) => void) | undefined;
    onAddClick?: () => void;
    orderBy: string;
    orderAscending: boolean;
    onRequestSort: PropertyCallback;
    createPermission?: boolean;
    actions?: React.ReactNode;
}

export interface TableHeadLabel {
    name: string;
    field: string;
    tooltip?: string;
}


const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({labels, search, onSearchChange, orderBy, orderAscending, onRequestSort, onAddClick, createPermission = false, actions}) => {
    const classes = useStyles();
    const orderDirection = orderAscending ? 'asc' : 'desc';
    const onRequestSortHandler = property => () => {
        onRequestSort(property);
    };

    const hasSearch = Boolean(search) && Boolean(onSearchChange);
    const hasActions = Boolean(actions) || Boolean(onAddClick);
    const showHeadActions = hasSearch || hasActions;

    return (
        <TableHead className={classes.utilityHead}>
            {showHeadActions && (
                <TableRow style={{maxHeight: 60}}>
                    {search !== undefined && onSearchChange !== undefined && (
                        <TableCell>
                            <SearchInput search={search} onSearchChange={onSearchChange}/>
                        </TableCell>
                    )}
                    {Array.from(Array(labels?.length - 1)).map((_, index) => (
                        <TableCell key={'' + index}/>
                    ))}
                    <TableCell align="right">
                        {actions}
                        {onAddClick && (
                            <AddButton permission={createPermission} onClick={onAddClick}/>
                        )}
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                {labels.map((label) => (
                    <TableCell
                        key={label.field}
                    >
                        <Box display={'flex'} alignItems={'center'}>
                            <TableSortLabel
                                active={orderBy === label.field}
                                direction={orderBy === label.field ? orderDirection : 'asc'}
                                onClick={onRequestSortHandler(label.field)}
                            >
                                {label.name}
                            </TableSortLabel>
                            {label.tooltip && <Tooltip title={label.tooltip}>
                                <HelpOutlineIcon fontSize={'small'}/>
                            </Tooltip>}
                        </Box>
                    </TableCell>
                ))}
                <TableCell/>
            </TableRow>
        </TableHead>
    );
};

export default EnhancedTableHead;
