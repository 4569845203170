import React, {createRef} from "react";

import {CrudDialog} from "../../components/CrudDialog";
import Post, {emptyPost} from "../../models/Post";
import PostForm from "./PostForm";
import useCrudRequestState from "../../hooks/useCrudRequestState";
import postActions from "../../store/PostsState";
import PostDisplay from "./PostDisplay";
import {useCurrentCenter} from "../../hooks/useCurrentCenter";
import useMediaQueryMobile from "hooks/useMediaQueryMobile";

import Box from "@material-ui/core/Box/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LoadingOrError from "../../components/LoadingOrError";
import useCurrentUser from "../../hooks/useCurrentUser";


export const PostView = () => {

    const isMobile = useMediaQueryMobile();
    const submitButtonRef: React.RefObject<HTMLButtonElement> = createRef<HTMLButtonElement>();
    const center = useCurrentCenter();
    const currentUser = useCurrentUser();
    const {state: {elements, editElement, loading, feedback}, setEditElement, updateElement, deleteElement, handleEditClick} = useCrudRequestState<Post>(state => state.posts, postActions);
    const filteredPosts = elements.filter(post => {
        if(!post.centerIdAll && post.centerId !== center?.id) return false;
        if(!post.centerIdAll && post.centerId === center?.id) return true;
        if(!post.centerIdAll) return false;
        if (post.centerIdList?.includes(center.id)) {
            return true;
        }
        if (currentUser.employment && post.functionIdList?.includes(currentUser.employment)) {
            return true;
        }
        return false;
    });

    return (
        <>
            {isMobile ? (
                <Button fullWidth variant="contained" color="primary" onClick={handleEditClick(emptyPost)}>
                    Opret nyt opslag
                </Button>
            ) : (
                <Box mt={2} p={2} pt={2} pb={2} display="flex" alignItems="center" component={Paper}>
                    <Box flex={1}>
                        Skriv et opslag til dit center
                    </Box>
                    <Button variant="contained" color="primary" onClick={handleEditClick(emptyPost)}>
                        Opret nyt opslag
                    </Button>
                </Box>
            )}

            <LoadingOrError loading={loading} type={"card"} skeletonCount={2}>
                {filteredPosts.map((post) => <PostDisplay key={post.id} post={post} onEditClick={setEditElement}/>)}
            </LoadingOrError>

            <CrudDialog<Post>
                title="Tilføj et opslag"
                element={editElement}
                onCancel={handleEditClick(undefined)}
                onDelete={deleteElement}
                onSave={() => submitButtonRef?.current?.click && submitButtonRef.current.click()}
                loading={loading}
                feedback={feedback}
            >
                <PostForm
                    post={editElement}
                    onSubmit={updateElement}
                    submitButtonRef={submitButtonRef}
                />
            </CrudDialog>
        </>
    )
};

export default PostView;