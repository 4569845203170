import {
    initialRequestState,
    KnownRequestAction,
    NamedAction,
    requestActionNames,
    RequestActionTypes,
    requestReducer,
    RequestState
} from "./RequestState";
import Identifiable, {isThread, isThreads} from "../models/Identifiable";
import {Reducer} from "redux";
import {AppThunkAction} from "./index";
import {sms} from "../assets";
import {InquiryThread, isSMSThread} from "../models/Inquiry";

export interface WebsocketRequestState<S> extends RequestState<S> {
    connected: boolean;
}

export const initialWebsocketRequestState = <S>(initialState: Required<S>[] = []): WebsocketRequestState<S> => {
    return {
        ...initialRequestState<Required<S>>(initialState),
        connected: false,
    }
};

export interface WebsocketRequestActionTypes extends RequestActionTypes {
    readonly CONNECT: "SOCKET_CONNECT";
    readonly DISCONNECT: 'SOCKET_DISCONNECT';
    readonly RECEIVE: 'SOCKET_RECEIVE';
}

export const websocketRequestActionNames: WebsocketRequestActionTypes = {
    ...requestActionNames,
    CONNECT: 'SOCKET_CONNECT',
    DISCONNECT: 'SOCKET_DISCONNECT',
    RECEIVE: 'SOCKET_RECEIVE',
};

export interface SocketConnectAction {
    name: string;
    type: 'SOCKET_CONNECT';
}

export interface SocketDisconnectAction {
    name: string;
    type: 'SOCKET_DISCONNECT';
}

export interface SocketReceiveAction<S> {
    name: string;
    type: 'SOCKET_RECEIVE';
    element: S;
    playSound?: boolean;
}

export type KnownWebsocketRequestAction<S> =
    KnownRequestAction<S>
    | SocketConnectAction
    | SocketDisconnectAction
    | SocketReceiveAction<S>;


export interface WebsocketRequestActionType<T> {
    connect(param?): AppThunkAction<T>;

    disconnect(): AppThunkAction<T>;
}

export function websocketRequestReducer<T extends Identifiable, S extends WebsocketRequestState<T> = WebsocketRequestState<T>>(stateName: string, initialState: S, reducer?: (state: S, incomingAction: NamedAction) => S): Reducer<S, NamedAction> {
    return requestReducer<T, S>(stateName, initialState, (state, incomingAction): S => {
        const action = incomingAction as KnownWebsocketRequestAction<T>;
        switch (action.type) {
            case websocketRequestActionNames.CONNECT:
                return {
                    ...state,
                    connected: true
                };
            case websocketRequestActionNames.DISCONNECT:
                return {
                    ...state,
                    connected: false
                };
            case websocketRequestActionNames.RECEIVE: {
                const elementIndex = state.elements.findIndex((e) => e.id === action.element.id);

                // play message sound
                // if force play sound or thread not in list
                if (action.playSound || elementIndex === -1) {
                    sms.play();
                } else {
                    // if thread is sms and last message is youth
                    const smsThread = action.element as unknown as InquiryThread;
                    if (isSMSThread(smsThread)) {
                        const lastMessageIsAnonymous = smsThread.author.id === smsThread.messages[smsThread.messages.length - 1].author.id;
                        const newLengthIsGreaterThanLastLength = smsThread.messages.length > (state.elements[elementIndex] as unknown as InquiryThread).messages.length;
                        if (lastMessageIsAnonymous && newLengthIsGreaterThanLastLength) {
                            sms.play();
                        }
                    }
                }

                // if new thread add to top of list
                if (elementIndex === -1) {
                    return {
                        ...state,
                        elements: [action.element, ...state.elements],
                        loading: false
                    }
                }

                // if updatedAt i different from local state move thread to top of list
                if (isThread(action.element) && isThreads(state.elements) && (action.element.updatedAt !== state.elements[elementIndex].updatedAt)) {
                    state.elements.splice(elementIndex, 1);
                    state.elements = [action.element, ...state.elements] as T[];
                } else {
                    state.elements[elementIndex] = action.element;
                }

                return {
                    ...state,
                    elements: state.elements,
                    editElement: action.element.id === state.editElement?.id ? action.element : state.editElement,
                    error: undefined,
                    feedback: undefined
                };
            }
            default: {
                if (reducer && typeof reducer === "function") {
                    return reducer(state, action);
                }
                return state;
            }
        }
    });
}

