import React from "react";
import AssetLightBoxWrapper from "./AssetLightBoxWrapper";
import {Box, GridList, GridListTile} from "@material-ui/core";
import {HOST} from "../../constants/config";
import {Asset} from "../../models/file/Asset";

const grid = {
    1: {
        gridListCols: 1,
        gridListTileCols: [1]
    },
    2: {
        gridListCols: 2,
        gridListTileCols: [1, 1]
    },
    3: {
        gridListCols: 2,
        gridListTileCols: [1, 1, 2]
    },
    4: {
        gridListCols: 2,
        gridListTileCols: [1, 1, 1, 1]
    },
    5: {
        gridListCols: 2,
        gridListTileCols: [1, 1, 1, 1, 1]
    }
}

const maxImages = 3;

export const AssetImageGrid: React.FC<{ assets: Asset[] }> = ({assets}) => {
    if (assets.length === 0) return null;    
    return (
        <Box style={{
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
        >
            <AssetLightBoxWrapper>
                <GridList cellHeight={300}
                          spacing={2}                          
                          cols={5 > assets.length ? grid[assets.length].gridListCols : 2}
                >
                    {assets.map((asset, index) => {
                        const assetUrl = HOST + "/api/v1/files/" + asset.filePathName + "?filename=" + asset.fileName;

                        if (index > maxImages) {
                            return (
                                <img key={asset.id}
                                     alt={asset.fileName}
                                     style={{opacity: 0, position: "absolute"}}
                                     src={assetUrl}/>
                            )
                        }

                        return (
                            <GridListTile key={asset.id}
                                          style={{zIndex: 2}}
                                          cols={
                                              5 > assets.length ?
                                                  grid[assets.length].gridListTileCols[index] :
                                                  grid[5].gridListTileCols[index]
                                          }
                            >
                                {(assets.length > 4 && index === maxImages) &&
                                <div style={{
                                    
                                    width: '100%',
                                    height: '100%',
                                    position: "absolute",
                                    background: 'rgba(0,0,0,0.3)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 2,
                                    color: 'white',
                                    pointerEvents: 'none'
                                }}>
                                    <span style={{fontSize: 22, fontWeight: "bold"}}>+{assets.length - 4}</span>
                                </div>
                                }

                                <img alt={asset.fileName} style={{cursor: 'pointer', objectFit: 'contain', width: '100%', height: '100%'}} 
                                     src={assetUrl}/>

                            </GridListTile>
                        )
                    })}
                </GridList>
            </AssetLightBoxWrapper>
        </Box>
    )
}

export default AssetImageGrid;