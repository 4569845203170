import packageJson from '../../package.json';

export const NODE_ENV = process.env.NODE_ENV;
// const isNodeProduction = NODE_ENV === 'production';
// const isNodeDevelopment = NODE_ENV === 'development';
// const isNodeTest = NODE_ENV === 'test';

export const API_ENV: string | undefined = process.env.REACT_APP_API_ENV || 'development';


export const isProductionMode = API_ENV === 'production';
export const isDevelopmentMode = API_ENV === 'development';
export const isLocalhostMode = API_ENV === 'localhost';

// console.log('process.env:', process.env);
// console.log('API_ENV:', API_ENV);
const PROD_CLIENT = 'https://admin.headspace.dk';
const DEV_CLIENT = 'https://dev.headspace.danmark.ai';
const LOCAL_CLIENT = 'http://localhost:3000';
export const CLIENT_URL = isProductionMode ? PROD_CLIENT : isLocalhostMode ? LOCAL_CLIENT : DEV_CLIENT;

const PROD_HOST = 'https://api.danmark.ai';
const DEV_HOST = 'https://dev.api.danmark.ai';
const LOCAL_HOST = 'http://localhost:8080';
export const HOST = isProductionMode ? PROD_HOST : isLocalhostMode ? LOCAL_HOST : DEV_HOST;

export const config = {
    name: packageJson.name,
    version: packageJson.version,
    API_ENV: API_ENV,
    NODE_ENV: NODE_ENV,
    isProductionMode,
    isDevelopmentMode,
    isLocalhostMode,
};

export default config;
