import React from 'react';
import Routes from '../../constants/Routes';

import BusinessIcon from '@material-ui/icons/Business'
import SmsIcon from '@material-ui/icons/Sms';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HourglassIcon from '@material-ui/icons/HourglassEmpty';

import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import SubjectIcon from '@material-ui/icons/Subject';

export interface NavItem {
    name: string;
    icon: JSX.Element;
    path: string;
    permission?: string;
    notOnMobile?: boolean;
}

export const navigationMap: {name: string; open: boolean; routes: NavItem[]}[] = [
    {
        name: "Forside",
        open: false,
        routes: [
            {name: 'Forside', icon: <HomeRoundedIcon/>, path: Routes.dashboard},
        ],
    },
    {
        name: "Beskeder",
        open: false,
        routes: [
            {name: 'Beskeder', icon: <SmsIcon/>, path: Routes.chat},
        ],
    },
    {
        name: "Henvendelser",
        open: false,
        routes: [
            {
                name: "Henvendelser",
                icon: <QuestionAnswerRoundedIcon/>,
                path: Routes.inquiry,
                permission: 'Permissions.Sms.Read'
            }
        ]
    },
    // {
    //     name: "Henvendelser",
    //     open: false,
    //     routes: [
    //         {
    //             name: 'SMS',
    //             icon: <QuestionAnswerRoundedIcon/>,
    //             path: Routes.sms,
    //             permission: 'Permissions.Sms.Read'
    //         },
    //         {name: 'Web', icon: <EmailIcon/>, path: Routes.web, permission: 'Permissions.Sms.Read'},
    //     ],
    // },
    {
        name: "Planlægning",
        open: true,
        routes: [
            // {name: 'Kalender', icon: <EventIcon/>, path: Routes.calendar, notOnMobile: true},
            {name: 'Vagtplan', icon: <ScheduleIcon/>, path: Routes.shiftPlanner},
            {name: 'Timeoversigt', icon: <HourglassIcon/>, path: Routes.eventUserTimeTable, permission: 'Permissions.Calendar.Create'},
        ]
    }, {
        name: "Administration",
        open: false,
        routes: [
            {name: 'Brugere', icon: <PeopleAltIcon/>, path: Routes.users},
            {name: 'Center-indstillinger', icon: <BusinessIcon/>, path: Routes.centers, permission: 'Permissions.Admins.All'},
            {
                name: 'Statistik',
                icon: <BarChartRoundedIcon/>,
                path: Routes.statistics,
                permission: "Permissions.Statistics.Read",
                notOnMobile: true,
            },
        ]
    },
    {
        name: "Support",
        open: false,
        routes: [
            {name: 'Support', icon: <InfoIcon/>, path: Routes.support},
        ],
    },
    {
        name: "Log ud",
        open: false,
        routes: [
            {name: 'Log ud', icon: <ExitToAppIcon/>, path: Routes.landing},
        ],
    },
];

export default navigationMap;
