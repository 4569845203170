import React from 'react';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ResendIcon from '@material-ui/icons/Send';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {IconButton, Theme, Tooltip} from '@material-ui/core';

type buttonTypes = 'edit' | 'delete' | 'cancel' | 'save' | 'add' | 'remove' | 'resend';

interface ButtonTypes {
    type: buttonTypes;
}

const useStyles = makeStyles<Theme, ButtonTypes>(theme => {
        const backgroundColorMap = {
            'delete': theme.palette.error.main,
            'cancel': theme.palette.warning.main,
            'save': theme.palette.success.main,
            'add': theme.palette.success.light,
            'remove': theme.palette.error.light,
            'resend': theme.palette.background.paper,
        };
        return ({
            sendButton: {
                color: ({type}) => type === 'resend' ? theme.palette.primary.light : theme.palette.common.white,
                backgroundColor: ({type}) => backgroundColorMap[type] ?? theme.palette.primary.light,
            }
        })
    }
);

export interface TableActionButtonProps {
    type: buttonTypes;
    onClick?: VoidFunction;
    permission?: boolean;
    title?: string;
}

const icons = {
    'edit': <EditIcon/>,
    'delete': <DeleteIcon/>,
    'cancel': <CloseIcon/>,
    'save': <DoneIcon/>,
    'add': <AddIcon/>,
    'remove': <RemoveIcon/>,
    'resend': <ResendIcon/>,
};

export const TableActionButton: React.FC<TableActionButtonProps> = ({onClick, type, permission, title}) => {
    const classes = useStyles({type});
    if (!permission) {
        return (<div/>);
    }

    return (
        <Box mr={2}>
            {title ? (
                <Tooltip title={title} aria-label={title.toLowerCase().replace(' ', '-')} placement="top">
                    <IconButton onClick={onClick} className={classes.button}>
                        {icons[type]}
                    </IconButton>
                </Tooltip>
            ) : (
                <IconButton onClick={onClick} className={classes.button}>
                    {icons[type]}
                </IconButton>
            )}
        </Box>
    )
};


export default TableActionButton;
