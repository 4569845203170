import React, {useState} from 'react';
import {Button, Grid, TextField} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import appService from "../../services/appService";
import RequestFeedbackDisplay from "../../components/RequestFeedbackDisplay";
import RequestFeedback from "../../models/httprequest/RequestFeedback";
import Validators from "../../services/Validators";


interface Passwords {
    password: string;
    passwordRepeat: string;
}


export const ProfilePasswordForm: React.FC = () => {

    const [feedback, setFeedback] = useState<RequestFeedback>()

    const handleSubmit = async (values: Passwords) => {
        if (values.password !== values.passwordRepeat) {
            return setFeedback({severity: "warning", message: "De indtasted passwords matcher ikke"})
        }

        const response = await appService.me.updatePassword(values.password);
        setFeedback(!response.success ? response.feedback : {
            severity: "success",
            message: "Din adgangskode er blevet opdateret"
        })
    }

    return (
        <Formik<Passwords>
            onSubmit={handleSubmit}
            initialValues={{
                password: '',
                passwordRepeat: '',
            }}
        >
            {({values, touched, errors}) => (
                <Form style={{width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Field
                                as={TextField}
                                required
                                fullWidth
                                validate={Validators.password}
                                error={errors.password && touched.password && values.password.length !== 0}
                                helperText={values.password.length !== 0 && errors.password}
                                variant="outlined"
                                id="password"
                                label="Ny adgangskode"
                                type="password"
                                name="password"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                as={TextField}
                                required
                                fullWidth
                                error={errors.passwordRepeat && touched.passwordRepeat && values.passwordRepeat.length !== 0}
                                helperText={values.passwordRepeat.length !== 0 && errors.passwordRepeat}
                                variant="outlined"
                                id="passwordRepeat"
                                label={"Gentag ny adgangskode"}
                                type="password"
                                name="passwordRepeat"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <RequestFeedbackDisplay feedback={feedback}/>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end" alignItems="center">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                aria-label="submit"
                            >
                                Skift adgangskode
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ProfilePasswordForm;


