import React from 'react';
import Box from '@material-ui/core/Box/Box';
import AppLogo from './AppLogo';
import {makeStyles, Theme} from '@material-ui/core';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import Routes from "../constants/Routes";


const useStyles = makeStyles<Theme>((theme) => ({
    paper: {
        minWidth: 360,
        maxWidth: theme.breakpoints.width('md'),
        padding: theme.spacing(8, 4),
        paddingTop: theme.spacing(4),
    },
    logoBox: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        cursor: 'selector',
    }
}));

interface ActionViewProps {
    title: string;
    subtitle?: string;
}


export const ActionView: React.FC<ActionViewProps> = ({title, subtitle, children}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const handleLogoClick = () => dispatch(push(Routes.landing));
    return (
        <Box pt={4} pb={4} display={'flex'} flexDirection={'column'}>
            <div className={classes.logoBox} onClick={handleLogoClick}>
                <AppLogo variant={'h3'}/>
            </div>
            <Box display={'flex'} justifyContent={'center'}>
                <Paper className={classes.paper} elevation={isMobile ? 0 : 6}>
                    <Typography component="h2" variant="h5">
                        {title}
                    </Typography>
                    {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}

                    <Box mt={3} mb={3}>
                        <Divider orientation="horizontal" variant="fullWidth"/>
                    </Box>
                    {children}
                </Paper>
            </Box>
        </Box>
    );
};

export default ActionView;
