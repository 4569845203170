import {Notification} from "../models/Notification";
import {
    initialWebsocketRequestState,
    KnownWebsocketRequestAction,
    websocketRequestActionNames,
    WebsocketRequestActionType,
    WebsocketRequestActionTypes,
    websocketRequestReducer,
    WebsocketRequestState
} from "./WebsocketRequestState";
import {HubConnection} from "@microsoft/signalr";
import {appSignalRClient, startSignalRConnection} from "../services/appSignalRClient";
import {AppThunkAction} from "./index";

const stateName = 'NOTIFICATIONS_STATE';

export type NotificationsState = WebsocketRequestState<Required<Notification>>;

export const initialNotificationsState: NotificationsState = initialWebsocketRequestState<Required<Notification>>();

export interface NotificationsReadQueuedAction {
    name: string;
    type: 'NOTIFICATIONS_READ_QUEUED';
    elements: Notification[];
}

export interface NotificationsReadAction {
    name: string;
    type: 'NOTIFICATION_READ';
    element: Notification;
}

export interface NotificationsSetReadAction {
    name: string;
    type: 'NOTIFICATIONS_SET_READ';
}

export type KnownNotificationAction =
    KnownWebsocketRequestAction<Required<Notification>>
    | NotificationsSetReadAction
    | NotificationsReadAction
    | NotificationsReadQueuedAction;

export interface NotificationsActionTypes extends WebsocketRequestActionTypes {
    readonly READ_QUEUED: 'NOTIFICATIONS_READ_QUEUED';
    readonly READ: 'NOTIFICATION_READ';
    readonly SET_READ: 'NOTIFICATIONS_SET_READ';
}

export const notificationsActionNames: NotificationsActionTypes = {
    ...websocketRequestActionNames,
    READ_QUEUED: 'NOTIFICATIONS_READ_QUEUED',
    READ: 'NOTIFICATION_READ',
    SET_READ: 'NOTIFICATIONS_SET_READ'
};

export type NotificationActions = WebsocketRequestActionType<KnownNotificationAction> & {
    readQueued(): AppThunkAction<KnownNotificationAction>;
    updateRead(): AppThunkAction<KnownNotificationAction>;
};

let client: HubConnection;
export const notificationsActions: NotificationActions = {
    connect: (userId: string) => {
        return async (dispatch) => {
            await dispatch({name: stateName, type: notificationsActionNames.LOADING});

            const hubPath = 'notificationHub';
            client = appSignalRClient(hubPath, 'userId=' + userId, false);

            client.on('readQueuedNotifications', (notifications: Notification[]) => {
                dispatch({name: stateName, type: notificationsActionNames.READ_QUEUED, elements: notifications});
            });

            client.on('readNotification', (notification: Notification) => {
                dispatch({name: stateName, type: notificationsActionNames.READ, element: notification});
            });

            await startSignalRConnection(hubPath, client);
            await dispatch({name: stateName, type: notificationsActionNames.CONNECT});
        }
    },
    disconnect: () => {
        return async (dispatch) => {
            await dispatch({name: stateName, type: notificationsActionNames.DISCONNECT});
            await client.stop();
        }
    },
    readQueued: () => {
        return async (dispatch, getState) => {
            await client.invoke('readPageQueuedNotifications', getState().notifications.elements.length);
        }
    },
    updateRead: () => {
        return async (dispatch) => {
            await client.invoke('updateNotificationsRead');
            await dispatch({name: stateName, type: notificationsActionNames.SET_READ});
            
        }
    }
};

export const notificationsReducer = websocketRequestReducer<Required<Notification>, NotificationsState>(stateName, initialNotificationsState,
    (state, incomingAction): NotificationsState => {
        const action = incomingAction as KnownNotificationAction;
        switch (action.type) {
            case notificationsActionNames.READ_QUEUED:
                return {
                    ...state,
                    elements: [...state.elements, ...action.elements],
                    loading: false
                };
            case notificationsActionNames.READ:
                return {
                    ...state,
                    elements: [action.element, ...state.elements]
                };
            case notificationsActionNames.SET_READ:
                return {
                    ...state,
                    elements: state.elements.map(n => {
                        n.read = true;
                        return n;
                    }),
                    loading: false
                };
            default:
                return state as NotificationsState;
        }
    }
);