import {FormControl, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import React, {CSSProperties, Dispatch, SetStateAction} from 'react';
import {Field} from 'formik';
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface SingleSelectFormFieldProps {
    className?: string;
    id: string;
    label?: string;
    required?: boolean;
    error?: string | undefined;
    variant?: SelectProps['variant'];
    options: string[];
    fullWidth?: boolean;
    render?: (id: string) => string;
    style?: CSSProperties;
    disabled?: boolean;
}

export const SingleSelectFormField: React.FC<SingleSelectFormFieldProps> = ({className, style, id, label, render, required = false, fullWidth = false, error, variant, options, disabled}) => {
    const labelId = `${id}-label`;
    const inputLabel = label + (required ? ' *' : '');
    return (
        <FormControl fullWidth={fullWidth} className={className} style={style}>
            {label && <InputLabel id={labelId}>{inputLabel}</InputLabel>}
            <Field
                as={Select}
                variant={variant}
                fullWidth
                required={required}
                error={error}
                label={label}
                labelId={labelId}
                id={id}
                name={id}
                disabled={disabled}
            >
                <MenuItem value={undefined} disabled>
                    {label}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {(render && render(option)) || option}
                    </MenuItem>
                ))}
            </Field>
        </FormControl>
    );
};


export interface SingleSelectFieldProps {
    value: string | undefined;
    variant?: 'standard' | 'outlined' | 'filled';
    setValue: Dispatch<SetStateAction<string | undefined>> | Dispatch<SetStateAction<string>>;
    id: string;
    label?: string;
    required?: boolean;
    options: (string)[];
    render?: (id: string) => string;
}


const useStyles = makeStyles(() => ({
    formControl: {
        "& .MuiSelect-select": {
            padding: "10px 32px 10px 16px"
        }
    },
}))

export const SingleSelectField: React.FC<SingleSelectFieldProps> = ({id, label, options, value, setValue, render, required = false, variant = "standard"}) => {
    const labelId = `${id}-label`;
    const inputLabel = label + (required ? ' *' : '');
    const classes = useStyles();
    return (
        <FormControl variant={variant} className={classes.formControl}>
            {label && <InputLabel id={labelId}>{inputLabel}</InputLabel>}
            <Select
                label={label}
                labelId={labelId}
                id={id}
                name={id}
                value={value}
                onChange={(event) => setValue(event.target.value as string)}
            >
                <MenuItem value={undefined} disabled>
                    {label}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {(render && render(option)) || option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
