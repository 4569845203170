import restService, {RestServiceType} from "./restService";
import RequestMethod from "../models/RequestMethod";
import AppResponse from "../models/httprequest/AppResponse";
import {AppClient} from "./appClient";
import Post from "models/Post";


export type PostService = RestServiceType<Post> & {
    createComment: (postId: string, comment: { content: string }) => AppResponse<Required<Post>>;
    deleteComment: (postId: string, commentId: string) => AppResponse<Required<Post>>;
};

const path = '/api/v1/posts';

export function postService(client: AppClient): PostService {
    return {
        ...restService<Post>(client, path),
        getAllByCenterId: async (centerId) => {
            return await client.fetchJSON<Required<Post>[]>(RequestMethod.GET, path + "?centerId=" + centerId);
        },
        createComment: (postId, comment) => {
            return client.fetchJSON(RequestMethod.POST, path + '/' + postId + '/comments', {
                ...comment,
            })
        },
        deleteComment: (postId, commentId) => {
            return client.fetchJSON(RequestMethod.DELETE, path + '/' + postId + '/comments/' + commentId)
        }
    }
}


export default postService;