import {
    initialRequestState,
    KnownRequestAction,
    requestActionNames,
    requestActions,
    RequestActionType,
    requestReducer,
    RequestState
} from './RequestState';
import appService from '../services/appService';
import Post from "../models/Post";
import {AppThunkAction} from 'store';

const stateName = 'POSTS_STATE';

export type PostsState = RequestState<Required<Post>>;

export const initialPostsState = initialRequestState<Required<Post>>();

export type PostActions = RequestActionType<Post> & {
    getAllByCenterId(centerId: string): AppThunkAction<KnownRequestAction<Post>>;
    createComment(postId: string, comment: { content: string }): AppThunkAction<KnownRequestAction<Post>>;
    deleteComment(postId: string, commentId: string): AppThunkAction<KnownRequestAction<Post>>;
}

export const postsActions: PostActions = {
    ...requestActions<Post>(stateName, appService.posts),
    getAllByCenterId: (centerId) => {
        return async (dispatch) => {
            const response = await appService.posts.getAllByCenterId(centerId);
            if (!response.success) {
                return dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
            }
            dispatch({name: stateName, type: requestActionNames.REFRESH, elements: response.value});
        }
    },
    createComment: (postId, comment) => {
        return async (dispatch) => {
            const response = await appService.posts.createComment(postId, comment);
            if (response.success) {
                dispatch({name: stateName, type: requestActionNames.UPDATE, element: response.value});
            } else {
                dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
            }
        };
    },
    deleteComment: (postId, commentId) => {
        return async (dispatch) => {
            const response = await appService.posts.deleteComment(postId, commentId);
            if (response.success) {
                dispatch({name: stateName, type: requestActionNames.UPDATE, element: response.value});
            } else {
                dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
            }
        }
    }
};

export const postsReducer = requestReducer<Required<Post>>(stateName, initialPostsState);

export default postsActions;
