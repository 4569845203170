import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';

import LoginPage from './pages/auth/LoginPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import ErrorPage from './pages/error/ErrorPage';
import NotFoundPage from './pages/error/NotFoundPage';
import Routes from './constants/Routes';
import PrivateRoute from './components/PrivateRoute';
import configureStore from './store/configureStore';
import RegisterPage from './pages/auth/RegisterPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ContactPage from './pages/action/ContactPage';
import OutlookSyncPage from 'pages/action/OutlookSyncPage';
import CustomThemeProvider from 'components/CustomThemeProvider';
import ConnectPage from "./pages/auth/ConnectPage";

const history = createBrowserHistory();
const store = configureStore(history);

const App: React.FC = () => {

    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path={Routes.landing} component={LoginPage}/>
                        <Route path={Routes.resetPassword} component={ResetPasswordPage}/>
                        <Route path={Routes.register} component={RegisterPage}/>
                        <Route path={Routes.contact} component={ContactPage}/>
                        <PrivateRoute path={Routes.dashboard} component={DashboardPage}/>
                        <Route path={Routes.outlookSync} component={OutlookSyncPage}/>
                        <Route path={Routes.error} component={ErrorPage}/>
                        <Route path={Routes.connect} component={ConnectPage} />
                        <Route path="*" component={NotFoundPage}/>
                    </Switch>
                </ConnectedRouter>
            </CustomThemeProvider>
        </Provider>
    );
};

export default App;
