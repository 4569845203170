import React, {ReactElement, ReactNode} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {mobileMediaQuery} from "../hooks/useMediaQueryMobile";
import {Theme} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: theme.palette.background.default, //'#F8FAFB', //
    },
    content: {
        display: 'flex',
        flex: 1,
        padding: theme.spacing(1.5),
        paddingTop: 0,
        ['@media ' + mobileMediaQuery]: {
            padding: 0,
        },
        maxHeight: 'calc(100vh - 76px)',
    },
    column: { flexDirection: "column" },
    row: { flexDirection: "row" }
}));

interface BaseDashboardPageProps {
    children?: ReactNode[] | ReactElement;
    direction?: "row" | "column";
}

const BasePage: React.FC<BaseDashboardPageProps & React.HTMLProps<HTMLDivElement>> = ({direction = "row", children, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={classes.root} {...rest}>
            <div className={clsx(classes.content, classes[direction])}>
                {children}
            </div>
        </div>
    );
};

export default BasePage;
