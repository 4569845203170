import React from 'react';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import TextField from '@material-ui/core/TextField/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Button, CircularProgress, Container, Grid} from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    form: {
        fontFamily: 'vagBold',
        width: '100%',
        '& > div > div > div': {
            padding: theme.spacing(2),
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& p a': {
            color: 'blue',
        }
    },
    dropDown: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttonContainer: {
        position: 'relative',
    },
    sendButton: {
        fontWeight: 'bold',
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
}));

interface Fields {
    content: string;
}

interface ReplyFormProps {
    onSubmit: (content: string) => Promise<void>;
}

export const ReplyForm: React.FC<ReplyFormProps> = ({onSubmit}) => {
    const classes = useStyles();
    const lightGreen = '#60a73f';


    const handleSubmit = async (values: Fields, {setSubmitting, resetForm}: FormikHelpers<Fields>) => {
        await onSubmit(values.content);
        setSubmitting(false);
        resetForm();
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={{content: ''}}>
            {({isSubmitting}) => (
                <Form className={classes.form}>
                    <Container maxWidth="md">
                        <Grid container>
                            <Grid item xs={12}>
                                <h2 style={{color: lightGreen}}>{'Her kan du besvare beskeden fra dit lokale headspace-center'}</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    margin="normal"
                                    color={'secondary'}
                                    required
                                    fullWidth
                                    multiline
                                    placeholder={'Skriv din besked her...'}
                                    rows={8}
                                    id="content"
                                    name="content"
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Box pt={4}>
                                    <b>Og så skal vi huske at gøre dig opmærksom på...</b>
                                    <p>...at når du sender din besked, giver du os samtidig lov til at behandle dine oplysninger – dit navn, din mailadresse og din besked. Så kan og må vi nemlig gerne svare dig, når vi har læst din besked.</p>
                                    <p>Bare rolig, vi passer rigtig godt på dine oplysninger og kunne aldrig finde på at give dem videre til andre. Du kan læse om vores privatlivspolitik
                                        <a href="https://www.headspace.dk/om-headspace/privatlivspolitik"
                                           target="_blank"
                                           rel="noopener noreferrer">
                                            her
                                        </a>.
                                    </p>
                                </Box>
                            </Grid>
                            <Grid container item xs={2} justify="flex-end">
                                <div className={classes.buttonContainer}>
                                    <Button
                                        className={classes.sendButton}
                                        color="secondary"
                                        variant="contained"
                                        aria-label="submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Send
                                    </Button>
                                    {isSubmitting &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};

export default ReplyForm;
