import React, {useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import User, {CurrentUser} from 'models/User';
import {Field, Form, Formik} from 'formik';
import {
    BirthDateFormField,
    EducationLevelFormField,
    EducationSubjectFormField,
    SexFormField,
    WorkStatusFormField
} from 'components/form_fields';
import appService from "../../services/appService";
import RequestFeedback from "../../models/httprequest/RequestFeedback";
import RequestFeedbackDisplay from "../../components/RequestFeedbackDisplay";
import {useDispatch} from "react-redux";
import {authActions} from "../../store/AuthState";
import TextField from "@material-ui/core/TextField/TextField";
import Validators from "../../services/Validators";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

interface ProfileFormProps {
    currentUser: CurrentUser;
}

type FormValues = Partial<Pick<User, 'firstName' | 'lastName' | 'sex' | 'birthdate' | 'workStatus' | 'educationLevel' | 'educationSubject' | 'phoneNumber' | 'receiveEmailNotifications' | 'receiveSMSNotifications'>>

const ProfileForm: React.FC<ProfileFormProps> = ({currentUser}) => {

    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState<RequestFeedback>();

    const handleSubmit = async (values: FormValues) => {
        const user: CurrentUser = {...currentUser, ...values};
        const response = await appService.me.updateData(user);
        if (!response.success) {
            return setFeedback(response.feedback);
        }
        dispatch(authActions.updateCurrentUser(Object.assign(currentUser, response.value)));
        setFeedback({
            severity: "success",
            message: "Dine oplysninger er blevet opdateret"
        })
    }

    const {phoneNumber, sex, birthdate, educationLevel, educationSubject, workStatus, receiveEmailNotifications, receiveSMSNotifications}: FormValues = currentUser;

    return (
        <Formik<FormValues>
            onSubmit={handleSubmit}
            initialValues={{
                sex,
                phoneNumber,
                birthdate: new Date(String(birthdate)) ?? new Date(),
                workStatus,
                educationLevel,
                educationSubject,
                receiveEmailNotifications: receiveEmailNotifications || false,
                receiveSMSNotifications: receiveSMSNotifications || false,
            }}
        >
            {({values, errors, setFieldValue}) => (
                <Form style={{width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SexFormField id="sex"/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                as={TextField}
                                validate={() => Validators.phoneNumber(values.phoneNumber)}
                                error={errors.phoneNumber}
                                helperText={errors.phoneNumber}
                                fullWidth
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Telefonnummer"
                                type="phone"
                                autoComplete='phone'
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BirthDateFormField id="birthdate" setFieldValue={setFieldValue}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <WorkStatusFormField id="workStatus"/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EducationLevelFormField id="educationLevel"/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EducationSubjectFormField
                                id="educationSubject"
                                educationLevel={values.educationLevel}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                label="Modtag notifikationer på email"
                                control={<Checkbox
                                    checked={values.receiveEmailNotifications}
                                    onChange={(event) => setFieldValue('receiveEmailNotifications', event.target.checked)}
                                    color="primary"
                                />}
                            />
                            <FormControlLabel
                                label="Modtag notifikationer på SMS"
                                control={<Checkbox
                                    checked={values.receiveSMSNotifications}
                                    onChange={(event) => setFieldValue('receiveSMSNotifications', event.target.checked)}
                                    color="primary"
                                />}
                            />
                        </Grid>
                        <Grid item xs={6}/>
                        <Grid item xs={6}>
                            <RequestFeedbackDisplay feedback={feedback}/>
                        </Grid>
                        <Grid item xs={12} md={6}
                              style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                aria-label="submit"
                            >
                                Opdater oplysninger
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileForm;
