import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Center from '../models/Center';
import appService from '../services/appService';
import Identifiable from "../models/Identifiable";
import {IdValueMap, toIdValueMap} from "../lib/toIdValueMap";
import AppResponse from "../models/httprequest/AppResponse";


export const useCenterList = () => {
    return useRequestValueList<Required<Center>>(appService.centers.getAll);
};

export const useRequestValueList = <T>(fetch: () => AppResponse<T[]>): T[] => {
    const [valueList] = useRequestValueState(fetch);
    return valueList
}

export const useRequestValueListFilter = <T extends Identifiable>(fetch: () => AppResponse<T[]>): [T[], IdValueMap<boolean>, Dispatch<SetStateAction<IdValueMap<boolean>>>] => {
    const valueList = useRequestValueList<T>(fetch);
    const [selectedIdValueMap, setSelectedIdValueMap] = useState<IdValueMap<boolean>>({});
    useEffect(() => {
        setSelectedIdValueMap(toIdValueMap(valueList, true));
    }, [valueList])
    return [valueList, selectedIdValueMap, setSelectedIdValueMap];
}


export const useRequestValueState = <T>(fetch: () => AppResponse<T[]>): [T[], Dispatch<SetStateAction<T[]>>] => {
    const [valueList, setValueList] = useState<T[]>([]);

    useEffect(() => {
        const fetchValueList = async () => {
            const valueListResponse = await fetch();
            if (valueListResponse.success) {
                setValueList(valueListResponse.value);
            }
        };
        fetchValueList().catch((error) => {
            console.log(error)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [valueList, setValueList];
};


export default useRequestValueList;