import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Theme} from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
    text: {
        color: ({color}) => color === "white" ? color : theme.palette.secondary.main,
        fontWeight: 600,
        width: "min-content",
    },
}));

export const AppLogo: React.FC<{ variant?: 'body1' | 'h3' | 'h4' | 'h5'; color?: "white" | "#c40109" }> = ({variant = 'h4', color = "green", children}) => {
    const classes = useStyles({color});
    return (
        <Typography className={classes.text} component="h1" variant={variant}>
            {children ?? "headspace"}
        </Typography>
    );
};

export default AppLogo;
