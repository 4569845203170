import {ChatMessage} from "./ChatMessage";
import { IdentifiableThread } from "../Identifiable";

export interface ChatThread extends IdentifiableThread<ChatMessage> {
    id: string;
    name: string;
    attendantIds: string[];
    readByIds: string[];
    messages: ChatMessage[];
    createdAt: Date;
    updatedAt: Date;
}

export const emptyChat: ChatThread = {
    id: '',
    name: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    attendantIds: [],
    readByIds: [],
    messages: []
};
