import {
    initialRequestState,
    KnownRequestAction,
    requestActionNames,
    requestActions,
    RequestActionType,
    RequestActionTypes,
    requestReducer,
    RequestState
} from './RequestState';
import AppEvent from '../models/calendar/AppEvent';
import appService from '../services/appService';
import EventType from "../models/calendar/EventType";
import {AppThunkAction} from "./index";
import {ShiftPlan} from "../services/eventService";

const stateName = 'EVENTS_STATE';

export interface EventsState extends RequestState<Required<AppEvent>> {
    eventTypes: EventType[];
    editShiftPlan: boolean;
}

export const initialEventsState: EventsState = {
    ...initialRequestState<Required<AppEvent>>(),
    eventTypes: [],
    editShiftPlan: false,
};

export interface EventInitAction {
    name: string;
    type: 'EVENT_TYPES_INIT';
    types: Required<EventType>[];
}

export interface EventSetEditShiftPlan {
    name: string;
    type: 'SET_EDIT_SHIFT_PLAN';
    value: boolean;
}

export interface EventCreateShiftPlanAction {
    name: string;
    type: 'EVENTS_CREATE_SHIFT_PLAN';
    elements: Required<AppEvent>[];
}

export interface EventDeleteMultipleAction {
    name: string;
    type: 'EVENTS_DELETE_MULTIPLE';
    ids: string[];
}

export interface EventGetAllByCenterId {
    name: string;
    type: 'GET_ALL_BY_CENTER_ID';
    elements: Required<AppEvent>[];
}

export type KnownEventAction =
    KnownRequestAction<Required<AppEvent>>
    | EventInitAction
    | EventCreateShiftPlanAction
    | EventSetEditShiftPlan
    | EventDeleteMultipleAction
    | EventGetAllByCenterId

export interface EventActionTypes extends RequestActionTypes {
    readonly INIT_EVENT_TYPES: 'EVENT_TYPES_INIT';
    readonly SET_EDIT_SHIFT_PLAN: "SET_EDIT_SHIFT_PLAN";
    readonly CREATE_SHIFT_PLAN: 'EVENTS_CREATE_SHIFT_PLAN';
    readonly DELETE_MULTIPLE: 'EVENTS_DELETE_MULTIPLE';
}

export const eventActionNames: EventActionTypes = {
    ...requestActionNames,
    INIT_EVENT_TYPES: 'EVENT_TYPES_INIT',
    SET_EDIT_SHIFT_PLAN: "SET_EDIT_SHIFT_PLAN",
    CREATE_SHIFT_PLAN: "EVENTS_CREATE_SHIFT_PLAN",
    DELETE_MULTIPLE: "EVENTS_DELETE_MULTIPLE",
};

export type EventDeleteOption = 'single' | 'all' | 'future' | '';

export type EventActions = RequestActionType<AppEvent> & {
    init(): AppThunkAction<KnownEventAction>;
    setEditShiftPlan(value: boolean): AppThunkAction<KnownEventAction>;
    createShiftPlan(shiftPlan: ShiftPlan): AppThunkAction<KnownEventAction>;
    deleteEvent(event: Required<AppEvent> | undefined, option?: EventDeleteOption): AppThunkAction<KnownEventAction>;
    toggleMe(eventId?: string): AppThunkAction<KnownEventAction>;
    getAllByCenterIdForRange(centerId: string, start: Date, end: Date): AppThunkAction<KnownEventAction>;
}

export const eventsActions: EventActions = {
    ...requestActions<AppEvent>(stateName, appService.events),
    getAllByCenterIdForRange: (centerId: string, start: Date, end: Date) => {
        return async (dispatch) => {
            dispatch({name: stateName, type: eventActionNames.LOADING});

            const response = await appService.events.getAllByCenterIdForRange(centerId, start, end);
            if (!response.success) {
                dispatch({name: stateName, type: eventActionNames.ERROR, feedback: response.feedback})
                return
            }

            dispatch({
                name: stateName,
                type: eventActionNames.REFRESH,
                elements: response.value,
            });
        }
    },
    init: () => {
        return async (dispatch) => {
            dispatch({name: stateName, type: eventActionNames.LOADING});

            const typeResponse = await appService.eventTypes.getAll();
            if (!typeResponse.success) {
                dispatch({name: stateName, type: eventActionNames.ERROR, feedback: typeResponse.feedback})
                return
            }

            dispatch({
                name: stateName,
                type: eventActionNames.INIT_EVENT_TYPES,
                types: typeResponse.value
            });
        };
    },
    setEditShiftPlan: (value) => {
        return async (dispatch) => {
            dispatch({name: stateName, type: eventActionNames.SET_EDIT_SHIFT_PLAN, value});
        }
    },

    createShiftPlan: (shiftPlan) => {
        return async (dispatch) => {
            dispatch({name: stateName, type: eventActionNames.LOADING});

            const response = await appService.events.createShiftPlan(shiftPlan);
            if (!response.success) {
                return dispatch({name: stateName, type: eventActionNames.ERROR, feedback: response.feedback});
            }

            dispatch({name: stateName, type: eventActionNames.CREATE_SHIFT_PLAN, elements: response.value});

        }
    },
    deleteEvent: (event, option) => {
        return async (dispatch) => {
            if(!event?.id) return;

            dispatch({name: stateName, type: eventActionNames.LOADING});

            if (option === "single") {
                const response = await appService.events.delete(event);
                if (response.success) {
                    return dispatch({name: stateName, type: eventActionNames.DELETE, element: event});
                }
                return dispatch({name: stateName, type: eventActionNames.ERROR, feedback: response.feedback})
            }

            if (option === "all") {
                const response = await appService.events.deleteShiftPlan(event.batchId);
                if (response.success) {
                    const ids = response.value.map(event => event.id);
                    return dispatch({name: stateName, type: eventActionNames.DELETE_MULTIPLE, ids: ids});
                }
                return dispatch({name: stateName, type: eventActionNames.ERROR, feedback: response.feedback})
            }

            // TODO HANDLE "future" OPTION

        }
    },
    toggleMe: (eventId) => {
        return async (dispatch) => {
            if (!eventId) return;

            dispatch({name: stateName, type: eventActionNames.LOADING});

            const response = await appService.events.toggleMe(eventId);

            if (!response.success) {
                return dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
            }
            return dispatch({
                name: stateName,
                type: requestActionNames.UPDATE,
                element: response.value as unknown as Required<AppEvent>
            });
        }
    }
};

export const eventsReducer = requestReducer<Required<AppEvent>, EventsState>(stateName, initialEventsState, (state, incomingAction): EventsState => {
    const action = incomingAction as KnownEventAction;
    switch (action.type) {
        case eventActionNames.INIT_EVENT_TYPES: {
            return {
                ...state,
                eventTypes: action.types,
                editElement: undefined,
                loading: false,
                error: undefined,
                feedback: undefined,
            }
        }
        case eventActionNames.SET_EDIT_SHIFT_PLAN: {
            return {
                ...state,
                editShiftPlan: action.value,
                error: undefined,
                feedback: undefined,
            }
        }
        case eventActionNames.CREATE_SHIFT_PLAN: {
            const events = state.elements.concat(action.elements);
            return {
                ...state,
                elements: events,
                editShiftPlan: false,
                editElement: undefined,
                loading: false,
                error: undefined,
                feedback: undefined,
            }
        }
        case eventActionNames.DELETE_MULTIPLE: {
            const events = state.elements.filter(value => !action.ids.includes(value.id));
            return {
                ...state,
                elements: events,
                editShiftPlan: false,
                editElement: undefined,
                loading: false,
                error: undefined,
                feedback: undefined,
            }
        }
        default:
            return state as EventsState;
    }
});

export default eventsActions;
