import React from "react";
import {Notification} from "../../models/Notification";
import {
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    useTheme
} from "@material-ui/core";
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import Routes from "../../constants/Routes";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import updatedDateText from "../../lib/updatedAtText";
import {renderDangerousHtml} from "../../lib/renderDangerousHtml";
import NoteIcon from '@material-ui/icons/Note';
import ChatIcon from '@material-ui/icons/Sms';
import {notificationsActions} from "../../store/NotificationsState";

interface NotificationListProps {
    notifications: Notification[];
    onClose: VoidFunction;
}

interface NotificationType {
    icon: JSX.Element;
    link?: string;
}

const defaultType: NotificationType = {icon: <EventRoundedIcon fontSize="small"/>};
const types: { [p: string]: NotificationType } = {
    "calendar": {icon: <EventRoundedIcon fontSize="small"/>, link: Routes.calendar},
    "Post": {icon: <NoteIcon fontSize="small"/>, link: Routes.dashboard},
    "Chat": {icon: <ChatIcon fontSize="small"/>, link: Routes.chat},
    "Event": {icon: <EventRoundedIcon fontSize="small"/>, link: Routes.shiftPlanner},
};

const NotificationList: React.FC<NotificationListProps> = ({notifications, onClose}) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const colors = {
        "Create": theme.palette.success.main,
        "Update": theme.palette.info.main,
        "Delete": theme.palette.error.main
    };

    const handleGetOlder = () => {
        dispatch(notificationsActions.readQueued());
    };

    if (notifications.length === 0) {
        return <div/>
    }

    return (
        <List style={{position: "relative"}}>
            <div style={{padding: theme.spacing(0, 2, 0, 2)}}>
                <h3 style={{marginTop: theme.spacing(1, 0, 1, 2)}}>Notifikationer</h3>
            </div>
            {
                notifications.map((notification: Notification) => {
                        const type = types[notification.type] ?? defaultType;
                        return (
                            <ListItem key={notification.id} button
                                      style={{minWidth: 350}}
                                      onClick={() => {
                                          if (type.link) {
                                              dispatch(push(type.link + '/' + notification.itemId));
                                              onClose();
                                          }
                                      }}>
                                <ListItemAvatar>
                                    <Avatar style={{background: colors[notification.action]}}>
                                        {
                                            type.icon
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={renderDangerousHtml(notification.text)}
                                    secondary={updatedDateText(notification.createdAt)}
                                />

                                {!notification.read &&
                                <ListItemSecondaryAction>
                                    <div style={{
                                        padding: 4,
                                        background: theme.palette.error.main,
                                        borderRadius: "50%"
                                    }}/>
                                </ListItemSecondaryAction>
                                }

                            </ListItem>
                        )
                    }
                )
            }
            {notifications.length >= 10 &&
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Button style={{padding: theme.spacing(2, 2, 2, 2)}}
                        onClick={() => handleGetOlder()}>
                    Indlæs flere
                </Button>
            </div>
            }
        </List>
    );
};

export default NotificationList;