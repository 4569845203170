import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {useSelector} from "react-redux";

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import {Box, Checkbox} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {Alert, Autocomplete} from "@material-ui/lab";
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import useCenterUserList from "../../hooks/useCenterUserList";

import {SingleSelectFormField} from '../../components/form_fields/SingleSelectFormField';
import {toLocalISODate, toLocalISOTime} from "../../lib/toLocalISO";
import {Participant} from "../../models/calendar";
import {toIdValueMap} from "../../lib/toIdValueMap";
import {AppState} from "../../store";
import AppEvent, {AppEventFormValues, emptyAppEvent} from '../../models/calendar/AppEvent';
import ParticipantDisplay from "../../components/ParticipantDisplay";
import {
    FilledDateField,
    FilledTextField,
    FilledTimeField,
    useFilledTextFieldStyle
} from "../../components/FilledTextField";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import daLocale from "date-fns/locale/da";
import InputAdornment from "@material-ui/core/InputAdornment";
import StatusSelecter from "./StatusSelecter";
import dateIsValid from "../../lib/dateIsValid";
import SelectNamed from "../../components/SelectNamed";

const useStyles = makeStyles(({spacing, palette}) => ({
    title: {
        padding: spacing(2, 0),
        '& label': {
            fontSize: '28px',
        },
        '& input': {
            height: 44,
            fontSize: '28px',
            color: '#3c4043',
        }
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(2),
        position: 'relative',
    },
    label: {
        minWidth: spacing(6),
        fontSize: 18,
    },
    checkboxLabel: {
        minWidth: spacing(6),
        fontSize: 18,
        '& .MuiCheckbox-root': {
            marginLeft: '-9px',
        }
    },
    header: {
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    typeField: {
        margin: spacing(2),
        '& .MuiSelect-root, svg': {
            color: palette.common.white,
            fontSize: 24,
            fontWeight: 600,
        },
        '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& .MuiSelect-icon.Mui-disabled': { // Hide v on type selector if disabled
            color: 'rgba(0,0,0,0)'
        },
    },
    numberField: {
        '& input': {
            paddingLeft: 0,
            textAlign: 'right',
        }
    },
}));

export interface EventFormProps {
    event?: AppEvent;
    onSubmit: (event: AppEvent) => void;
    submitButtonRef: React.RefObject<HTMLButtonElement>;
    eventType?: string;
}


export const EventForm: React.FC<EventFormProps> = ({event = emptyAppEvent, onSubmit, submitButtonRef, eventType}) => {
    const classes = useStyles();
    const classesTemp = useFilledTextFieldStyle();

    const {center, eventTypeList} = useSelector((state: AppState) => {
        return {
            center: state.auth.center,
            eventTypeList: state.events.eventTypes
        }
    });

    const eventTypeMap = toIdValueMap(eventTypeList);
    const initialEventTypeId = eventType ?? Object.keys(eventTypeMap)[0] ?? '';


    const filterOptions = createFilterOptions<Participant>({
        stringify: option => option.name,
    });

    const [participantInput, setParticipantInput] = useState<string>('');
    const [participantList, setParticipantList] = useState<Participant[]>(event?.participants ?? []);

    const userList = useCenterUserList();
    const userOptionList = userList.filter(user => participantList.findIndex(p => p.id === user.id) === -1).map(user => ({
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
        color: user.color,
    }));

    const initialValues = {
        title: event.title,
        description: event.description ?? '',
        location: event.location ?? '',
        start: new Date(event.start),
        end: new Date(event.end),
        startDate: toLocalISODate(event.start),
        endDate: toLocalISODate(event.end),
        allDay: event?.allDay ?? false,
        isCancelled: event?.isCancelled, //event.allDay,
        isMoved: event?.isMoved,
        isNoShow: event?.isNoShow,
        typeId: event?.type?.id ?? initialEventTypeId,
        maxParticipants: event?.maxParticipants,
        reminderMillis: '' + (event?.reminderMillis ?? 0),
    };


    const handleSubmit = (values) => {
        const eventType = eventTypeMap[values.typeId];
        const color = eventType.color;

        const start = new Date(toLocalISODate(values.startDate) + "T" + toLocalISOTime(values.start));
        const end = new Date(toLocalISODate(values.endDate) + "T" + toLocalISOTime(values.end));

        onSubmit({
            id: event.id,
            centerId: center?.id,
            title: values.title,
            description: values.description,
            location: values.location,
            type: eventType,
            typeId: values.typeId,
            start: start,
            end: end,
            allDay: values.allDay,
            isCancelled: values.isCancelled,
            isMoved: values.isMoved,
            isNoShow: values.isNoShow,
            backgroundColor: color,
            borderColor: color,
            participantIds: participantList.map(p => p.id),
            maxParticipants: values.maxParticipants,
            reminderMillis: parseInt(values.reminderMillis ?? '0'),
        });
    };

    const isBlueUser = participantList.filter(user => (
        user.color === "#6ab7ff"
    )).length === 0;

    return (
        <Formik<AppEventFormValues> onSubmit={handleSubmit} initialValues={initialValues}>
            {({values, setFieldValue}) => {
                const isTalkType = values.typeId === "talk";
                const isShiftType = values.typeId === "shift";
                const titlePlaceholder = isTalkType ? 'Hvilken ung er samtalen med?' : 'Tilføj titel';
                const participantText = isTalkType ? 'Rådgivere' : 'Deltagere';
                const participantPlaceholder = isTalkType ? 'Tilføj rådgivere' : 'Tilføj deltagere';

                const removeParticipant = (participant: Participant) => {
                    setParticipantList(participantList.filter(p => participant.id !== p.id));
                };

                return (
                    <Form>
                        <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                        <div className={classes.header} style={{backgroundColor: eventTypeMap[values.typeId]?.color}}>
                            <SingleSelectFormField
                                className={classes.typeField}
                                id={'typeId'}
                                disabled={Boolean(eventType)}
                                render={(id) => eventTypeMap[id].name}
                                options={Object.keys(eventTypeMap)}
                                style={{
                                    border: '2px solid white',
                                    borderRadius: 4,
                                    paddingLeft: 12,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                }}
                            />
                        </div>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>

                            <Box display={'flex'} className={'MuiDialogContent-root'}>
                                <Box flex={1}>
                                    <Field
                                        as={TextField}
                                        className={classes.title}
                                        variant="standard"
                                        fullWidth
                                        id="title"
                                        name="title"
                                        placeholder={titlePlaceholder}
                                        type="text"
                                        autoFocus={true}
                                    />

                                    <div className={classes.row}>
                                <span className={classes.label}>
                                    Fra
                                </span>
                                        <Field
                                            as={FilledDateField}
                                            required
                                            id="startDate"
                                            name="startDate"
                                            onChange={(date: Date) => {
                                                setFieldValue("startDate", date)
                                                if (dateIsValid(date)) {
                                                    const diffTime = new Date(date).getTime() - new Date(values.startDate).getTime();
                                                    const endDate = new Date(new Date(values.endDate).getTime() + diffTime);
                                                    if (dateIsValid(endDate)) setFieldValue("endDate", toLocalISODate(endDate))
                                                }
                                            }}
                                        />

                                        <Box paddingRight={2}/>

                                        <Field
                                            as={FilledTimeField}
                                            required
                                            id="start"
                                            name="start"
                                            onChange={(date) => {
                                                setFieldValue("start", date)
                                                if (dateIsValid(date)) {
                                                    const diffTime = new Date(date).getTime() - new Date(values.start).getTime();
                                                    const end = new Date(new Date(values.end).getTime() + diffTime);
                                                    if (dateIsValid(end)) setFieldValue("end", end)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={classes.row}>
                                        <span className={classes.label}>
                                            Til
                                        </span>
                                        <Field
                                            as={FilledDateField}
                                            required
                                            id="endDate"
                                            name="endDate"
                                            onChange={(date: Date) => setFieldValue("endDate", date)}
                                        />
                                        <Box paddingRight={2}/>
                                        <Field
                                            as={FilledTimeField}
                                            required
                                            id="end"
                                            name="end"
                                            onChange={(date) => {
                                                setFieldValue('end', date);
                                                // setFieldValue('endTime', timeInputAdd(values.endTime, timeInputDiff(values.startTime, e.target.value)));
                                            }}
                                        />
                                    </div>

                                    <div className={classes.row}>
                                        <Checkbox
                                            color={'primary'}
                                            name="allDay"
                                            id="allDay"
                                            checked={values.allDay}
                                            onChange={(event) => setFieldValue('allDay', event.target.checked, false)}
                                        />
                                        <span className={classes.label} style={{minWidth: 96, marginRight: "8px"}}>
                                            Hele dagen
                                        </span>
                                        <StatusSelecter values={values} setFieldValue={setFieldValue}/>
                                    </div>

                                    <div className={classes.row} style={{display: values.allDay ? 'none' : undefined}}>
                                        <span className={classes.label} style={{minWidth: 136, marginRight: "8px"}}>
                                            Notifikation
                                        </span>
                                        <Field
                                            as={SelectNamed}
                                            className={classesTemp.textField}
                                            name={'reminderMillis'}
                                            variant="filled"
                                            options={[{
                                                id: '0',
                                                name: 'Ingen',
                                                millis: 0,
                                            }, {
                                                id: '1800000',
                                                name: '30 minutter før',
                                                millis: 1800000,
                                            }, {
                                                id: '3600000',
                                                name: '1 time før',
                                                millis: 3600000,
                                            }, {
                                                id: '86400000',
                                                name: '1 dag før',
                                                millis: 86400000,
                                            }]}
                                        />
                                    </div>

                                    <div className={classes.row}>
                                        <Field
                                            as={FilledTextField}
                                            id="location"
                                            name="location"
                                            type="location"
                                            placeholder='Tilføj placering/lokale'
                                        />
                                    </div>

                                    <div className={classes.row}>
                                        <Field
                                            as={FilledTextField}
                                            multiline={true}
                                            rows={8}
                                            id="description"
                                            name="description"
                                            type="description"
                                            placeholder='Tilføj beskrivelse'
                                        />
                                    </div>
                                </Box>

                                <Box pl={2}>
                                    <Box height={56} pt={2} pb={2} display={'flex'} alignItems={'center'}>
                                        <Typography variant={'h5'} component={'h6'}>
                                            {participantText}
                                        </Typography>
                                    </Box>
                                    {isShiftType && <div className={classes.row}>
                                        <Field
                                            as={FilledTextField}
                                            color={'secondary'}
                                            id="maxParticipants"
                                            name="maxParticipants"
                                            type="number"
                                            InputProps={{
                                                startAdornment: <InputAdornment style={{margin: 0}} position="start">Max
                                                    antal</InputAdornment>,
                                            }}
                                            onChange={(e) => setFieldValue("maxParticipants", Math.max(0, parseInt(e.target.value)))}
                                        />
                                    </div>}
                                    <div className={classes.row}>
                                        <Autocomplete<Participant>
                                            id="participant-search-input"
                                            multiple
                                            value={participantList}
                                            onChange={(event, newValue) => setParticipantList(newValue)}
                                            inputValue={participantInput}
                                            onInputChange={(event, newInputValue) => setParticipantInput(newInputValue)}
                                            options={userOptionList}
                                            style={{width: 300}}
                                            filterOptions={filterOptions}
                                            renderInput={(params) =>
                                                <FilledTextField
                                                    {...params}
                                                    placeholder={participantPlaceholder}
                                                    variant="filled"
                                                />
                                            }
                                            renderOption={ParticipantDisplay}
                                            renderTags={() => null}
                                        />
                                    </div>
                                    {participantList.map(participant => (
                                        <div key={participant.id} className={classes.row}>
                                            <ParticipantDisplay {...participant}/>
                                            <Box flex={1}/>
                                            <Box pr={3}>
                                                <IconButton
                                                    size={"small"}
                                                    onClick={() => removeParticipant(participant)}>
                                                    <Close fontSize={"small"}/>
                                                </IconButton>
                                            </Box>
                                        </div>
                                    ))}

                                </Box>
                            </Box>
                        </MuiPickersUtilsProvider>

                        {isBlueUser && values.typeId === "talk" &&
                        <Alert severity="warning">Der er ikke tilknyttet en afdækkende rådgiver for denne
                            samtale!</Alert>}
                    </Form>
                )
            }}
        </Formik>
    )
};






