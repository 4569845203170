import React from 'react';
import clsx from "clsx";
import {IconButton, Theme} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationBell, {ProfileButton} from "./NotificationDisplay";
import { mobileMediaQuery } from 'hooks/useMediaQueryMobile';
import {makeStyles} from "@material-ui/core/styles";
import useScrollingUp from "../../hooks/useScrollingUp";
import Routes from "../../constants/Routes";
import { useLocation, matchPath } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles<Theme>(theme => ({
    root: {
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: 'all .2s cubic-bezier(0.4, 0.0, 0.2, 1)',
        padding: theme.spacing(1, 2),
        position: 'inherit',
        zIndex: 10,
        ['@media ' + mobileMediaQuery]: {
            padding: theme.spacing(1, 2, 1, 1),
        }
    },
    buffer: {
        ['@media ' + mobileMediaQuery]: {
            height: 48,
            position: 'relative',
            width: '100%',
            padding: theme.spacing(1, 2, 1, 1),
        }
    },
    follow: {
        position: 'fixed',
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.default,
        boxShadow: '0 0 20px rgba(100, 100, 100, 0.2)',
    },
    onMobile: {
        display: 'none',
        ['@media ' + mobileMediaQuery]: {
            display: 'block',
        }
    },
    title: {fontWeight: 600, color: "black"},
}))

const titleMap: { [route: string]: string } = {
    [Routes.centers]: "Center-indstillinger",
    [Routes.permissions]: "Systemrettigheder",
    [Routes.users]: "Administration",
    [Routes.calendar]: "Kalender",
    [Routes.chat]: "Beskeder",
    [Routes.eventUserTimeTable]: "Timeoversigt",
    [Routes.profile]: "Min side",
    [Routes.shiftPlanner]: "Vagtplan",
    [Routes.sms]: "SMS-henvendelser",
    [Routes.statistics]: "Statistik",
    [Routes.support]: "Support",
    [Routes.web]: "Web-henvendelser",
    [Routes.inquiry]: "Henvendelser"
}

const dynamicTitlesMap = {
    [Routes.supportArticle]: "Support",
    [Routes.chat + '/:threadId?']: "Beskeder",
    [Routes.shiftPlanner + '/:eventId?']: "Vagtplan",
    [Routes.webMessage]: "Web-henvendelser",
}

const useTitle = (): string => {
    const location = useLocation();
    const title = titleMap[location.pathname];
    if (title) return title;

    const dynamicRoute = Object.keys(dynamicTitlesMap)
        .find(route => matchPath(location.pathname, { path: route }));
    if (dynamicRoute) return dynamicTitlesMap[dynamicRoute];

    return "";
}

// const subtitleMap: { [route: string]: string } = {
//     [Routes.centers]: 'Her kan du se en oversigt over centre og hvilke SMS-keywords, der er tilknyttet hvert center',
//     [Routes.users]: 'Her kan du se brugere tilknyttet samme center som dig',
//     [Routes.chat]: 'Send direkte beskeder eller opret gruppechats internt, til kollegaer og frivillige',
//     [Routes.sms]: 'Her kan du læse og besvare SMS-henvendelser fra børn og unge',
//     [Routes.web]: 'Her kan du læse og besvare henvendelser fra børn og unge, som har skrevet til os via kontaktformularen på hjemmesiden',
// }

const TopBar: React.FC<{ onToggle: (toggle?: boolean) => void }> = ({onToggle}) => {
    const scrollingUp = useScrollingUp();
    const classes = useStyles();
    const handleToggle = () => onToggle();
    const title = useTitle();

    return (
        <>
            {scrollingUp && <div className={classes.buffer} />}
            <div className={scrollingUp ? clsx(classes.root, classes.follow) : classes.root}>
                <Box display="flex" flexDirection="row" alignItems="baseline">
                    <div className={classes.onMobile}>
                        <IconButton onClick={handleToggle}>
                            <MenuIcon color="inherit" aria-controls="nav-menu" aria-haspopup="true"/>
                        </IconButton>
                    </div>
                    <Typography variant={'h5'} className={classes.title}>
                        {title}
                    </Typography>
                </Box>
                <div>
                    <NotificationBell/>
                    <ProfileButton/>
                </div>
            </div>
        </>
    )
}

export default TopBar;
