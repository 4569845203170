import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {green} from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

import outlookService from '../../services/outlookService';
import OutlookSvg from '../../assets/outlook-icon.svg';
import ConfirmDialog from 'components/ConfirmDialog';

const useStyles = makeStyles(() => ({
    buttonBox: {
        position: "relative",
        color: green[400],
        fontSize: "32px",
        width: "32px",
        height: "32px",
        cursor: "pointer",
        borderRadius: "50%",
    },
    absolute: {
        position: "absolute",
    },
    pointer: {
        cursor: "pointer",
    },
    space: { marginRight: "16px" },
}))

const OutlookSync: React.FC<{centerId: string; permission: boolean}> = ({ centerId, permission }) => {
    const [open, setOpen] = React.useState(false);
    const [synched, setSynched] = React.useState(false);
    const classes = useStyles();

    const handleConfirm = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const isSynched = async () => {
            const res = await outlookService.isSynchronized(centerId);
            const returnValue = () => {
                if (res.success) {
                    return res.value
                }
                return false;
            }
            setSynched(returnValue());
        };
        isSynched();
    }, [centerId]);

    const handleSynchronize = async () => {
        const res = await outlookService.getAuthEndpoint(centerId);
        if (res.success) {
            window.open(res.value.Url);
        }
    }

    const handleDesynchronize = async () => {
        const res = await outlookService.deleteSynchronization(centerId);
        if (res.success) {
            setSynched(false);
            setOpen(false);
        }
    }
    
    if (!permission) return null;

    const synchedTitle = `Denne vagtplan er synkroniseret med din Outlook kalender.\nKlik for at desynkronisere.`

    const outlookSynchronizationButton = synched ? (
            <Tooltip title={synchedTitle} className={classes.space}>
                <Box className={classes.buttonBox} onClick={handleConfirm}>
                    <img src={OutlookSvg} className={classes.absolute} alt="Outlook" width="32px" height="32px" />
                    <CheckIcon className={classes.absolute} color="inherit" fontSize="inherit" />
                </Box>
            </Tooltip>
        ) : (
            <Tooltip title="Synkroniser vagtplanen med Outlook" className={classes.space}>
                <img src={OutlookSvg} onClick={handleSynchronize} className={classes.pointer} alt="Outlook" width="32px" height="32px" />
            </Tooltip>
        );

    return (
        <>
            {outlookSynchronizationButton}
            <ConfirmDialog
                title="Er du sikker på, at du vil desynkronisere vagtplanen og din Outlook kalender?"
                open={open}
                onClose={handleClose}
                onConfirm={handleDesynchronize}
            />
        </>
    )
}

export default OutlookSync;
