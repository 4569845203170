import forsidePlanaegning from "./images/vagtplan/Forside_planlaegning.png"
import forsideVagtplan from "./images/vagtplan/Forside_vagtplan.png"
import vagtplanSideNyVagtplan from "./images/vagtplan/Vagtplan_side_ny_vagtplan.png"
import vagtplanSideNyVagtplanViewVaelgDage from "./images/vagtplan/Vagtplan_side_ny_vagtplan_view_vaelg_dage.png"
import vagtplanSideNyVagtplanViewVaelgPeriode from "./images/vagtplan/Vagtplan_side_ny_vagtplan_view_vaelg_periode.png"
import vagtplanSideNyVagtplanViewVaelgTid from "./images/vagtplan/Vagtplan_side_ny_vagtplan_view_vaelg_tid.png"
import vagtplanSideNyVagtplanViewTilfoejVagtGem from "./images/vagtplan/Vagtplan_side_ny_vagtplan_view_tilfoej_vagt_gem.png"
import vagtplanSideMedVagter from "./images/vagtplan/Vagtplan_side_med_vagter.png"
import vagtplanSideNySamtale from "./images/vagtplan/Vagtplan_side_ny_samtale.png"
import vagtplanSideNySamtaleViewTitle from "./images/vagtplan/Vagtplan_side_ny_samtale_view_title.png"
import vagtplanSideNySamtaleViewFraTil from "./images/vagtplan/Vagtplan_side_ny_samtale_view_fra_til.png"
import vagtplanSideNySamtaleViewAflyst from "./images/vagtplan/Vagtplan_side_ny_samtale_view_aflyst.png"
import vagtplanSideNySamtaleViewPlacering from "./images/vagtplan/Vagtplan_side_ny_samtale_view_placering.png"
import vagtplanSideNySamtaleViewDeltagere from "./images/vagtplan/Vagtplan_side_ny_samtale_view_deltagere.png"
import vagtplanSideNySamtaleViewGem from "./images/vagtplan/Vagtplan_side_ny_samtale_view_gem.png"
import vagtplanSideMedSamtale from "./images/vagtplan/Vagtplan_side_med_samtale.png"
import vagtplanSideMedSamtaleTilmeld from "./images/vagtplan/Vagtplan_side_med_samtale_tilmeld.png"
export default `# Sådan bruger du vagtplanen

## Sådan opretter du en vagtplan

1. Tilgå siden "Vagtplan" ved at klikke på "Planlægning" og derefter vælge "Vagtplan" i menuen på venstre side af skærmen.

![Klik på "Planlægning"](${forsidePlanaegning})

![Klik på "Vagtplan"](${forsideVagtplan})

2. På denne side kan du se kommende vagter i dit center. For at oprette en ny vagtplan klikker du på ”NY VAGTPLAN”

![Klik på "NY VAGTPLAN"](${vagtplanSideNyVagtplan})

3. Du kan vælge, hvilke dage vagten skal gælde for. Dage markeret med en sort boks, er valgte dage.

![Vælg dage](${vagtplanSideNyVagtplanViewVaelgDage})

4. Du kan vælge, hvor lang en periode vagtplanen skal strække sig over.

![Vælg periode](${vagtplanSideNyVagtplanViewVaelgPeriode})

5. Du kan give vagten en titel og definere, hvilket tidsrum vagten strækker sig over. Ønsker du at tilføje flere vagter over samme periode, klikker du på det grøne plus ikon.

![Vælg tid](${vagtplanSideNyVagtplanViewVaelgTid})

6. Når du er færdig med at lave en samlet vagtplan, klikker du på knappen "GEM" for at gøre vagtplanen synlig for dit center.

![Klik på knappen "GEM](${vagtplanSideNyVagtplanViewTilfoejVagtGem})

![Vagtplan side med vagter.](${vagtplanSideMedVagter})

## Sådan opretter du en samtale

1. For at oprette en samtale med en ung klikker du på knappen "NY SAMTALE".

![Klik på knappen "Ny samtale"](${vagtplanSideNySamtale})

2. Du kan give samtalen en titel i feltet ”Tilføj titel”. Du kan evt. bruge navnet/kaldenavnet på den unge.

![Tilføj en title til samtalen i feltet "Tilføj title"](${vagtplanSideNySamtaleViewTitle})

3. Angiv tidspunkt og dato for samtalen i felterne ”Fra” og ”Til”.

![Tilføj tid og dato i felterne "Fra" og "Til"](${vagtplanSideNySamtaleViewFraTil})

4. Du kan aflyse samtalen ved at klikke på tjekboksen til højre for ”Aflyst”.

![Aflys samtalen ved at klikke på tjekboksen til højre for "Aflyst"](${vagtplanSideNySamtaleViewAflyst})

5. Du kan tilføje en placering for samtalen og evt. ekstra kommentarer i felterne "Tilføj placering/lokale" og "Tilføj beskrivelse".

![Tilføj placering og beskrivelse i felterne "Tilføj placering/lokale" og "Tilføj beskrivelse"](${vagtplanSideNySamtaleViewPlacering})

6. Du kan tilføje kollegaer til samtalen ved at klikke på feltet "Tilføj deltagere" og søge på deres navne. Når du finder en kollega, som skal deltage i samtalen, klikker du blot på vedkommende.

![Tilføj deltagere ved at klikke på feltet "Tilføj deltagere"](${vagtplanSideNySamtaleViewDeltagere})

7. Når du har angivet de nødvendige informationer for samtalen, klikker du på knappen "GEM" for at gøre samtalen synlig for dit center.

![Klik på knappen "GEM"](${vagtplanSideNySamtaleViewGem})

![Vagtplan med samtale](${vagtplanSideMedSamtale})

## Andre funktioner

* Du kan melde dig selv til en vagt ved at klikke på person-ikonet.

![Tilmeld dig en vagt](${vagtplanSideMedSamtaleTilmeld})`;
