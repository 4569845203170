import React from "react";
import {Box, Dialog, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {FilledTextField} from "../../components/FilledTextField";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import ParticipantDisplay from "../../components/ParticipantDisplay";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppEvent from "../../models/calendar/AppEvent";
import {IdValueMap, toIdValueMap} from "../../lib/toIdValueMap";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import EventType from "../../models/calendar/EventType";
import {toLocalISODate, toLocalISOTime} from "../../lib/toLocalISO";

const useStyles = makeStyles(({spacing}) => ({
    title: {
        padding: spacing(2, 0),
        '& label': {
            fontSize: '28px',
        },
        '& input': {
            height: 44,
            fontSize: '28px',
            color: '#3c4043',
        }
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(2),
        position: 'relative',
    },
    label: {
        minWidth: spacing(6),
        fontSize: 18,
    },
    checkboxLabel: {
        minWidth: spacing(6),
        fontSize: 18,
        '& .MuiCheckbox-root': {
            marginLeft: '-9px',
        }
    },
    header: {
        height: 68,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: "white",
        '& span': {
            fontSize: 24,
        }
    },
}));

interface EventViewDialogProps {
    event: AppEvent | undefined;
    onClose: () => void;
    mobile?: boolean;
}

const EventViewDialog: React.FC<EventViewDialogProps> = ({ event, onClose, mobile }) => {
    const classes = useStyles();

    const {eventTypeList} = useSelector((state: AppState) => ({ eventTypeList: state.events.eventTypes }));
    const eventTypeMap: IdValueMap<Required<EventType>> = toIdValueMap(eventTypeList);

    function isOpen(event: EventViewDialogProps["event"]): event is Required<AppEvent> {
        return Boolean(event);
    }

    if (!isOpen(event)) return null;

    return (
        <Dialog maxWidth="sm" fullWidth={true} fullScreen={mobile} open={isOpen(event)} onClose={onClose} style={{overflow: "inherit"}}>
            <DialogTitle className={classes.header} disableTypography={true} style={{backgroundColor: eventTypeMap[event.typeId]?.color}}>
                <Typography noWrap={true} component="span">{event.title}</Typography>
                <IconButton onClick={onClose} color="inherit">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
                <Box display={'flex'} className={'MuiDialogContent-root'}>
                    <Box flex={1}>
                        <div className={classes.row}>
                            <span className={classes.label}>
                                Fra
                            </span>
                            <div>{toLocalISODate(event.start)} {toLocalISOTime(event.start)}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>
                                Til
                            </span>
                            <div>{toLocalISODate(event.end)} {toLocalISOTime(event.end)}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.checkboxLabel}>
                                <Checkbox
                                    color={'primary'}
                                    name="isCancelled"
                                    id="isCancelled"
                                    checked={event.isCancelled}
                                    disabled={true}
                                />
                            </span>
                            <span className={classes.label}>Aflyst</span>
                        </div>

                        <div className={classes.row}>
                            <FilledTextField
                                id="location"
                                name="location"
                                type="location"
                                value={event.location}
                                disabled={true}
                            />
                        </div>

                        <div className={classes.row}>
                            <FilledTextField
                                multiline={true}
                                rows={mobile ? 8 : 4}
                                id="description"
                                name="description"
                                type="description"
                                value={event.description}
                                disabled={true}
                            />
                        </div>
                    </Box>

                    <Box pl={2}>
                        <Box pb={2} display="flex">
                            <Typography variant={'h5'} component={'h6'}>
                                {event.typeId === "talk" ? 'Rådgivere' : 'Deltagere'}
                            </Typography>
                        </Box>
                        {event.participants?.map(participant => (
                            <div key={participant.id} className={classes.row}>
                                <ParticipantDisplay {...participant}/>
                            </div>
                        ))}

                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default EventViewDialog;
