import React, {useState} from 'react';

import {Calendar} from "@fullcalendar/core/Calendar";
import FullCalendar from "@fullcalendar/react";
import {Box, MenuItem, Theme} from "@material-ui/core";
import {DatePickerProps} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import {SingleSelectField, SingleSelectFieldProps} from "../../components/form_fields/SingleSelectFormField";
import AddButton from "../../components/buttons/AddButton";
import useCurrentUser from "../../hooks/useCurrentUser";
import {checkPermission} from "../../models/User";
import {toIdValueMap} from "../../lib/toIdValueMap";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PopoverDatePicker from "../../components/pickers/PopoverDatePicker";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Menu from "@material-ui/core/Menu";
import ColorButton from "../../components/buttons/ColorButton";
import AddIcon from "@material-ui/icons/Add";
import eventsActions from "../../store/EventsState";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import OutlookSync from "./OutlookSync";
import {addWeeks, subWeeks} from "date-fns";

const useStyles = makeStyles(theme => ({
    titleBox: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        minWidth: 160,
        textAlign: "center",
        padding: theme.spacing(0, 2),
        cursor: "pointer",
    }
}))

const calendarViewMap = toIdValueMap([{
    id: 'timeGridOneDay',
    name: 'Dag',
}, {
    id: 'timeGridWeek',
    name: 'Uge',
}, {
    id: 'dayGridWeek',
    name: 'Uge-liste',
}, {
    id: 'dayGridFourDay',
    name: 'Arbejdsuge',
}, {
    id: 'dayGridMonth',
    name: 'Måned',
}
]);

export const CALENDAR_VIEW_KEY = "CALENDAR_VIEW";

export interface View {
    title: string;
    type: string;
    currentStart: string;
    currentEnd: string;
}

interface ShiftPlannerToolbarProps {
    calendarRef: React.RefObject<FullCalendar>;
    view: View;
    onChangeView: (view: View) => void;
    onAddShiftEvent: () => void;
    onAddTalkEvent: () => void;
    onAddOtherEvent: () => void;
    onNewShiftPlan: () => void;
}

const ShiftPlannerToolbar: React.FC<ShiftPlannerToolbarProps> = ({calendarRef, view, onChangeView, onAddShiftEvent, onAddTalkEvent, onAddOtherEvent, onNewShiftPlan}) => {
    const dispatch = useDispatch();
    const centerId = useSelector((state: AppState) => state.auth.center?.id) as string;
    const [monthMid, setMonthMid] = useState(new Date().getMonth());

    const classes = useStyles();
    const wideEnoughScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const calendar = () => calendarRef?.current?.getApi() ?? {} as Calendar;
    const currentUser = useCurrentUser();
    const permittedToCRUD = checkPermission(currentUser, "Permissions.Calendar.Create");

    const checkToFetchNewEvents = (date: Date) => {
        if (centerId !== undefined && date.getMonth() !== monthMid) {
            setMonthMid(date.getMonth());
            // 1 week before first day of current month
            const start = subWeeks(new Date(date.getFullYear(), date.getMonth(), 0, 0, 0, 0), 1)
            const end = addWeeks(start, 7)
            dispatch(eventsActions.getAllByCenterIdForRange(centerId, start, end));
        }
    }

    const handleChangeView: SingleSelectFieldProps["setValue"] = (id) => {
        if (id && typeof id === "string") {
            calendar().changeView(id);
            const updatedView = calendar().view;
            onChangeView({
                title: updatedView.title,
                currentStart: updatedView.currentStart.toString(),
                currentEnd: updatedView.currentEnd.toString(),
                type: id
            })
            localStorage.setItem(CALENDAR_VIEW_KEY, id);
        }
    };
    const handleRenderViewText = (id: string) => calendarViewMap[id].name;
    const handlePrev = () => {
        calendar().prev();
        checkToFetchNewEvents(calendar().getDate());
    }
    const handleNext = () => {
        calendar().next();
        checkToFetchNewEvents(calendar().getDate());
    }
    const handleDateChange: DatePickerProps["onChange"] = date => {
        checkToFetchNewEvents(date as Date);
        calendar().gotoDate(date);
    }

    const handleNewShiftPlan = () => {
        onNewShiftPlan();
        handleClose();
    };
    const handleAddShiftEvent = () => {
        onAddShiftEvent();
        handleClose();
    };
    const handleAddTalkEvent = () => {
        onAddTalkEvent();
        handleClose();
    };
    const handleAddOtherEvent = () => {
        onAddOtherEvent();
        handleClose();
    };

    const calendarViewMapKeys = Object.keys(calendarViewMap);

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                <Box display={'flex'} alignItems={'center'} pl={2} pr={2}>
                    <Tooltip title={'Forrige uge'}>
                        <IconButton onClick={handlePrev}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Næste uge'}>
                        <IconButton onClick={handleNext}>
                            <ChevronRightIcon/>
                        </IconButton>
                    </Tooltip>
                    <PopoverDatePicker value={new Date()} onChange={handleDateChange}>
                        <Box className={classes.titleBox}>
                            <Typography variant={"button"} style={{fontWeight: 600}}>
                                {view.title}
                            </Typography>
                            <ExpandIcon/>
                        </Box>
                    </PopoverDatePicker>
                </Box>
                <Box display={'flex'} alignItems={'center'} pl={2} pr={2}>
                    <OutlookSync permission={permittedToCRUD} centerId={centerId}/>
                    <SingleSelectField
                        variant={"outlined"}
                        id={"view"}
                        value={view.type}
                        setValue={handleChangeView}
                        options={calendarViewMapKeys}
                        render={handleRenderViewText}
                    />
                    {wideEnoughScreen ? (
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" ml={2}>
                            <AddButton
                                permission={permittedToCRUD}
                                text="VAGTPLAN"
                                onClick={handleNewShiftPlan}
                            />
                            <Box mr={2}/>
                            <AddButton
                                permission={permittedToCRUD}
                                text="VAGT"
                                onClick={handleAddShiftEvent}
                            />
                            <Box mr={2}/>
                            <AddButton
                                permission={permittedToCRUD}
                                text="SAMTALE"
                                color="secondary"
                                onClick={handleAddTalkEvent}
                            />
                            <Box mr={2}/>
                            {permittedToCRUD && (
                                <ColorButton
                                    color="#e67b1e"
                                    hoverColor="#ae4d00"
                                    onClick={handleAddOtherEvent}
                                >
                                    ANDET <AddIcon fontSize={'small'}/>
                                </ColorButton>
                            )}
                        </Box>
                    ) : (
                        <>
                            <Box mr={2}/>
                            <AddButton
                                permission={permittedToCRUD}
                                color="primary"
                                onClick={handleCreate}
                                aria-controls="create-menu"
                                aria-haspopup="true"
                            />
                            <Menu
                                id="create-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem color="primary" onClick={handleNewShiftPlan}>OPRET VAGTPLAN</MenuItem>
                                <MenuItem color="primary" onClick={handleAddShiftEvent}>OPRET VAGT</MenuItem>
                                <MenuItem color="secondary" onClick={handleAddTalkEvent}>OPRET SAMTALE</MenuItem>
                                <MenuItem color="inherit" onClick={handleAddOtherEvent}>OPRET ANDET</MenuItem>
                            </Menu>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default ShiftPlannerToolbar;
