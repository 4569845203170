import ActionView from "../../components/ActionView";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {authActions} from "../../store/AuthState";
import LoginForm from "./LoginForm";
import Routes from "../../constants/Routes";
import {push} from "connected-react-router";

const ConnectPage = () => {
    const ssoAccountId = useSelector((state: AppState) => state.auth.ssoAccountId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!ssoAccountId) dispatch(push(Routes.landing))
    }, [dispatch, ssoAccountId])

    const handleSubmit = (email: string, password: string) => {
        if (ssoAccountId) {
            dispatch(authActions.connectWithSSO(ssoAccountId, email, password));
        }
    }

    return (
        <ActionView
            title="Forbind din HS konto med Single Sign-On"
            subtitle="Indtast din email og adgangskode, så vi kan forbinde din konto"
        >
            <LoginForm onSubmit={handleSubmit} text="Forbind og log ind" />
        </ActionView>
    )
}

export default ConnectPage;
