import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {History} from 'history';
import {AppState, initialApplicationState, reducers} from './';
import {loadAppState, loadAuthState, saveAppState, saveAuthState} from "../services/localStorage";

export const configureStore = (history: History) => {

    const initialState: AppState = {
        ...initialApplicationState,
        ...loadAppState(),
        auth: loadAuthState(),
    };

    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });


    const enhancers = [];
    // const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    // if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    //     enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    // }

    const store = createStore(rootReducer, initialState as any, compose(applyMiddleware(...middleware), ...enhancers));

    store.subscribe(() => {
        const state = store.getState();
        saveAuthState(state.auth);
        saveAppState(state as Partial<AppState>)
    });

    return store;
};

export default configureStore;
