export const searchFilter = (value: { [key: string]: string }, search: string): boolean => {
    if (!search) return true;
    const searchLower = search.toLowerCase();
    // console.log(value);
    // Object.keys(value).map((current) => console.log(value[current]?.toLowerCase(), value[current]?.toLowerCase()?.includes(searchLower)));
    return Object.keys(value).reduce<boolean>((prev, current) => prev || value[current]?.toLowerCase()?.includes(searchLower), false);
};

export const searchFilterHandler = (search: string) => (value: { [key: string]: string }) => searchFilter(value, search);

export default searchFilter;
