import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Box from "@material-ui/core/Box/Box";
import {Tooltip} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles(({palette, spacing}) => ({
    root: {
        backgroundColor: palette.action.hover,
        boxShadow: 'inset 0 -1px 0px 0px rgba(224, 224, 224)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    primaryText: {
        fontSize: 17,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingRight: spacing(1),
        fontWeight: 'normal',
    },
    footer: {
        color: 'grey',
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center'
    }
}));

interface MessageInputContainerProps {
    children: React.ReactNode;
    signature?: string;
}

const MessageInputContainer: React.FC<MessageInputContainerProps> = ({
                                                                         children,
                                                                         signature
                                                                     }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root} p={4} pt={2} pb={2}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
                {children}
            </Box>
            {signature && <Tooltip title="Automatisk signatur">
                <span className={classes.footer}>
                    {signature}
                    <HelpOutlineIcon/>
                </span>
            </Tooltip>}
        </Box>
    );
};

export default MessageInputContainer;