import React from 'react';
import Validators from '../services/Validators';
import {Field, FieldValidator, Form, Formik} from 'formik';
import TextField from '@material-ui/core/TextField/TextField';
import {FormButton} from './buttons/FormButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useRequestFeedbackState from '../hooks/useRequestFeedbackState';
import RequestFeedbackDisplay from './RequestFeedbackDisplay';
import RequestFeedback from "../models/httprequest/RequestFeedback";

const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
        '& div': {
            display: 'flex'
        }
    },
}));

interface RequestFormField {
    id: string;
    label: string;
    initialValue?: string;
    type?: 'email' | 'password' | 'text';
    validator?: FieldValidator;
}


interface RequestFormProps<TValues> {
    fields: RequestFormField[];
    validate?: (values: TValues) => string | undefined;
    buttonText: string;
    request: (values: TValues) => Promise<RequestFeedback>;
}

export const RequestForm = <TValues, >({fields, validate, request, buttonText}: RequestFormProps<TValues>) => {
    const classes = useStyles();
    const [{loading, feedback}, setRequestState, setErrorMessage, setLoading] = useRequestFeedbackState();

    const onSubmit = async (values, {resetForm}) => {
        const error: undefined | string = validate && validate(values);
        if (error !== undefined) {
            return setErrorMessage(error);
        }

        setLoading(true);
        const feedback = await request(values);
        resetForm();
        setRequestState({loading: false, feedback: feedback});
    };

    const initialValues: TValues = Object.assign({}, ...fields.map((v) => ({[v.id]: v?.initialValue ?? ''})));

    return (
        <Formik<TValues> onSubmit={onSubmit} initialValues={initialValues}>
            {({values, errors, touched, isValid}) => (
                <Form className={classes.form}>
                    {fields.map((field, index) => (
                        <Field
                            key={field.id}
                            as={TextField}
                            validate={field.validator ?? Validators.notEmpty}
                            error={errors[field.id] && touched[field.id] && values[field.id].length !== 0}
                            helperText={values[field.id].length !== 0 && errors[field.id]}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id={field.id}
                            label={field.label}
                            name={field.id}
                            type={field.type ?? 'text'}
                            autoComplete={field.id}
                            autoFocus={index === 0}
                        />
                    ))}
                    <FormButton text={buttonText} disabled={!isValid} isSubmitting={loading}/>
                    <RequestFeedbackDisplay feedback={feedback}/>
                </Form>
            )}
        </Formik>
    );
};

export default RequestForm;
