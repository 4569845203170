import Identifiable from "../models/Identifiable";

export interface IdValueMap<T> {
    [p: string]: T;
}

export const toIdValueMap = <TElement extends Identifiable, TValue = TElement>(list: TElement[], defaultValue?: TValue | ((element: TElement) => TValue)): IdValueMap<TValue> => list.reduce((result, value) => {
        result[value.id] = defaultValue ?? value;
        return result;
    },
    {}
);

export const toValueMap = <TElement, TValue>(list: TElement[], defaultValue?: TValue) => list.reduce((result, value) => {
        result['' + value] = defaultValue ?? value;
        return result;
    },
    {}
);