import {AuthState, initialAuthState} from '../store/AuthState';
import Authorization, {emptyAuthorization} from '../models/Authorization';
import {AppState} from "../store";

const APP_STATE_KEY = 'APP_STATE_KEY';
const AUTH_STATE_KEY = 'AUTH_STATE_KEY';
const AUTHORIZATION_KEY = 'AUTHORIZATION_KEY';


export const saveAppState = (state: Partial<AppState>): void => {
    const partialState: Partial<AppState> = {
        events: {
            elements: [],
            editShiftPlan: false,
            loading: false,
            eventTypes: state.events?.eventTypes ?? [],
        },
    }
    const serializedState = JSON.stringify(partialState);
    localStorage.setItem(APP_STATE_KEY, serializedState)
}

export const loadAppState = (): Partial<AppState> => {
    const serializedState = localStorage.getItem(APP_STATE_KEY);
    return serializedState === null ? {} : JSON.parse(serializedState);
};

export const loadAuthState = (): AuthState => {
    // console.log('loadAuthState');
    const serializedState = localStorage.getItem(AUTH_STATE_KEY) ?? sessionStorage.getItem(AUTH_STATE_KEY);
    const authState = serializedState === null ? initialAuthState : JSON.parse(serializedState);
    authState.loading = false;
    return authState;
};

export const saveAuthState = (state: AuthState): void => {
    // console.log('saveAuthState');
    const serializedState = JSON.stringify(state);
    state.remember ? localStorage.setItem(AUTH_STATE_KEY, serializedState)
        : sessionStorage.setItem(AUTH_STATE_KEY, serializedState);
};

export const clearAuthStorage = (): void => {
    // console.log('clearLocalStorage');
    sessionStorage.removeItem(APP_STATE_KEY);
    sessionStorage.removeItem(AUTH_STATE_KEY);
    sessionStorage.removeItem(AUTHORIZATION_KEY);
    localStorage.removeItem(APP_STATE_KEY);
    localStorage.removeItem(AUTH_STATE_KEY);
    localStorage.removeItem(AUTHORIZATION_KEY);
};

const scopedKey = (key: string, scope?: string) => scope ? key + '.' + scope : key;

export const loadAuthorization = (scope?: string): Authorization | undefined => {
    const key = scopedKey(AUTHORIZATION_KEY, scope);
    const serializedState = localStorage.getItem(key) ?? sessionStorage.getItem(key);
    return serializedState === null ? emptyAuthorization : JSON.parse(serializedState);
};

export const saveAuthorization = (authorization: Authorization, scope?: string): void => {
    const key = scopedKey(AUTHORIZATION_KEY, scope);
    const serializedState = JSON.stringify(authorization ?? emptyAuthorization);
    loadAuthState().remember ? localStorage.setItem(key, serializedState)
        : sessionStorage.setItem(key, serializedState);
};

export const clearAuthorization = (scope?: string): void => {
    const key = scopedKey(AUTHORIZATION_KEY, scope);
    loadAuthState().remember ? localStorage.removeItem(key)
        : sessionStorage.removeItem(key);
};
