import {Reducer} from 'redux';
import {AppThunkAction} from './index';
import {RestServiceType} from '../services/restService';
import Identifiable, {OptionalIdentifiable} from '../models/Identifiable';
import RequestFeedback from "../models/httprequest/RequestFeedback";
import {sms} from "../assets";

// -----------------
// STATE - This defines the variant of data maintained in the Redux store.

export interface RequestState<S> {
    elements: S[];
    editElement?: S;
    loading: boolean;
    feedback?: RequestFeedback;
    error?: string;
}

export const initialRequestState = <S>(initialState: S[] = []): RequestState<S> => {
    return {
        elements: initialState,
        editElement: undefined,
        loading: false,
        feedback: undefined,
        error: '',
    };
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for variant detection that works even after serialization/deserialization.

export interface RequestActionTypes {
    readonly LOADING: 'REQUEST_LOADING';
    readonly ERROR: 'REQUEST_ERROR';
    readonly REFRESH: 'REQUEST_REFRESH';
    readonly EDIT: 'REQUEST_EDIT';
    readonly UPDATE: 'REQUEST_UPDATE';
    readonly UPDATE_SELECTED: 'REQUEST_UPDATE_SELECTED';
    readonly SORT: 'REQUEST_SORT';
    readonly DELETE: 'REQUEST_DELETE';
    readonly CLEAR: 'REQUEST_CLEAR';
}

export const requestActionNames: RequestActionTypes = {
    DELETE: 'REQUEST_DELETE',
    EDIT: 'REQUEST_EDIT',
    ERROR: 'REQUEST_ERROR',
    LOADING: 'REQUEST_LOADING',
    REFRESH: 'REQUEST_REFRESH',
    SORT: 'REQUEST_SORT',
    UPDATE: 'REQUEST_UPDATE',
    UPDATE_SELECTED: 'REQUEST_UPDATE_SELECTED',
    CLEAR: 'REQUEST_CLEAR',
};

export interface RequestRefreshAction<S> {
    name: string;
    type: 'REQUEST_REFRESH';
    keepEditElement?: boolean;
    elements: S[];
}

export interface RequestLoadingAction {
    name: string;
    type: 'REQUEST_LOADING';
}

export interface RequestErrorAction {
    name: string;
    type: 'REQUEST_ERROR';
    feedback?: RequestFeedback;
}

export interface RequestEditAction<S> {
    name: string;
    type: 'REQUEST_EDIT';
    element: S | undefined;
}

export interface RequestUpdateAction<S> {
    name: string;
    type: 'REQUEST_UPDATE';
    element: S;
    compare?: (a: S, b: S) => number;
    playSound?: boolean;
}

export interface RequestUpdateSelectedAction<S> {
    name: string;
    type: 'REQUEST_UPDATE_SELECTED';
    element: S;
}

export interface RequestSortAction<S> {
    name: string;
    type: 'REQUEST_SORT';
    compare: (a: S, b: S) => number;
}

export interface RequestDeleteAction<S> {
    name: string;
    type: 'REQUEST_DELETE';
    element: S;
}

export interface RequestClearAction {
    name: string;
    type: 'REQUEST_CLEAR';
}

// Declare a 'discriminated union' variant. This guarantees that all references to 'variant' properties contain one of the
// declared variant strings (and not any other arbitrary string).

export type KnownRequestAction<S> =
    | RequestLoadingAction
    | RequestErrorAction
    | RequestRefreshAction<S>
    | RequestEditAction<S>
    | RequestUpdateAction<S>
    | RequestUpdateSelectedAction<S>
    | RequestSortAction<S>
    | RequestDeleteAction<S>
    | RequestClearAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface RequestActionType<T> {
    refresh(): AppThunkAction<KnownRequestAction<T>>;

    getAllByCenterId(centerId: string): AppThunkAction<KnownRequestAction<T>>;

    setEditElement(element: T | undefined): AppThunkAction<KnownRequestAction<T>>;

    updateElement(element: T | undefined): AppThunkAction<KnownRequestAction<T>>;

    deleteElement(element: T | undefined): AppThunkAction<KnownRequestAction<T>>;

    cancelError(): AppThunkAction<KnownRequestAction<T>>;
}

export const requestActions = <T extends OptionalIdentifiable>(stateName: string, service: RestServiceType<T>): RequestActionType<T> => {
    return {
        refresh: (): AppThunkAction<KnownRequestAction<T>> => {
            return async (dispatch) => {
                dispatch({name: stateName, type: requestActionNames.LOADING});
                const response = await service.getAll();
                if (response.success) {
                    dispatch({name: stateName, type: requestActionNames.REFRESH, elements: response.value});
                } else {
                    dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback})
                }
            };
        },

        getAllByCenterId: (centerId) => {
            return async (dispatch) => {
                const response = await service.getAllByCenterId(centerId);
                if (response.success) {
                    dispatch({name: stateName, type: requestActionNames.REFRESH, elements: response.value});
                } else {
                    dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
                }
            }
        },

        setEditElement: (element: T | undefined): AppThunkAction<KnownRequestAction<T>> => {
            return async (dispatch) => {
                dispatch({name: stateName, type: requestActionNames.EDIT, element: element});
            };
        },

        updateElement: (element: T): AppThunkAction<KnownRequestAction<T>> => {
            return async (dispatch) => {
                dispatch({name: stateName, type: requestActionNames.LOADING});

                const response = element?.id ? await service.update(element) : await service.create(element);
                if (response.success) {
                    dispatch({name: stateName, type: requestActionNames.UPDATE, element: response.value});
                }
                dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
            };
        },

        deleteElement: (element: T): AppThunkAction<KnownRequestAction<T>> => {
            return async (dispatch) => {
                dispatch({name: stateName, type: requestActionNames.LOADING});
                const response = await service.delete(element);
                if (response.success) {
                    dispatch({name: stateName, type: requestActionNames.DELETE, element: element});
                }
                dispatch({name: stateName, type: requestActionNames.ERROR, feedback: response.feedback});
            };
        },

        cancelError(): AppThunkAction<KnownRequestAction<T>> {
            return (dispatch) => {
                dispatch({name: stateName, type: requestActionNames.ERROR, feedback: undefined})
            }
        }
    };
};

export interface NamedAction {
    name: string;
    type: string;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// THIS IS THE ONE
//https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic/

export function requestReducer<T extends Identifiable, S extends RequestState<T> = RequestState<T>>(stateName, initialState: S, reducer?: (state: S, incomingAction: NamedAction) => S): Reducer<S, NamedAction> {
    return (state: S | undefined, incomingAction: NamedAction): S => {
        if (state === undefined) {
            return {...initialState};
        }
        if (incomingAction.type === requestActionNames.CLEAR) {
            return {...initialState};
        }
        if (incomingAction.name !== stateName) {
            return state;
        }

        const action = incomingAction as KnownRequestAction<T>;
        switch (action.type) {
            case requestActionNames.LOADING:
                return {
                    ...state,
                    loading: true,
                    error: undefined,
                };
            case requestActionNames.ERROR:
                return {
                    ...state,
                    loading: false,
                    feedback: action.feedback,
                    error: action.feedback?.severity === 'success' ? action.feedback.message : undefined,
                };
            case requestActionNames.REFRESH:
                return {
                    ...state,
                    elements: action.elements,
                    editElement: action.keepEditElement ? state.editElement : undefined,
                    loading: false,
                    error: undefined,
                    feedback: undefined,
                };
            case requestActionNames.EDIT:
                return {
                    ...state,
                    editElement: action.element,
                    loading: false,
                    error: undefined,
                    feedback: undefined,
                };
            case requestActionNames.UPDATE: {
                const elementIndex = state.elements.findIndex((e) => e.id === action.element.id);
                elementIndex === -1 ? state.elements.unshift(action.element) : (state.elements[elementIndex] = action.element);

                if (action.playSound) {
                    sms.play();
                }

                return {
                    ...state,
                    elements: state.elements,
                    editElement: undefined,
                    loading: false,
                    error: undefined,
                    feedback: undefined,
                };
            }
            case requestActionNames.UPDATE_SELECTED: {
                const elementIndex = state.elements.findIndex((e) => e.id === action.element.id);
                elementIndex === -1 ? state.elements.unshift(action.element) : (state.elements[elementIndex] = action.element);

                return {
                    ...state,
                    elements: state.elements,
                    editElement: action.element,
                    loading: false,
                    error: undefined,
                    feedback: undefined,
                };
            }
            case requestActionNames.SORT: {
                const elements = state.elements.sort(action.compare);
                return {
                    ...state,
                    elements: elements,
                    editElement: undefined,
                    loading: false,
                    error: undefined,
                    feedback: undefined,
                };
            }
            case requestActionNames.DELETE: {
                const elements = state.elements.filter((e) => e.id !== action.element.id);
                return {
                    ...state,
                    elements: elements,
                    // editElement: undefined, // Makes the inquiries jump
                    loading: false,
                    error: undefined,
                    feedback: undefined,
                };
            }
            default: {
                if (reducer && typeof reducer === "function") {
                    return reducer(state, action);
                }
                return state;
            }
        }
    };
}
