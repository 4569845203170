import RequestMethod from '../models/RequestMethod';
import {WebMessage, WebMessageForm, WebThread} from 'models/web';
import AppResponse from "../models/httprequest/AppResponse";
import appClient from "./appClient";

export interface WebFormService {

    createTread(values: WebMessageForm): AppResponse<WebThread>;

    respondWithMessage(threadId: string, message: string, token: string): AppResponse<WebMessage>;
}

export const webFormService = {
    async createTread(values: WebMessageForm) {
        return appClient.fetchJSON<WebThread>(RequestMethod.POST, '/api/v1/email', {
            ...values
        });
    },
    async respondWithMessage(threadId: string, message: string, token: string) {
        const authorization = 'Bearer ' + token;
        return appClient.fetchJSON<WebMessage>(RequestMethod.POST, '/api/v1/email/' + threadId + '/messages', {
            content: message,
            author: {}
        }, {
            Authorization: authorization,
        })
    },
};

export default webFormService;
