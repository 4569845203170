import React from 'react';

import {ThemeProvider} from '@material-ui/core';
import generateCustomTheme from "../lib/generateCustomTheme";
import {useSelector} from "react-redux";
import {AppState} from "../store";

const CustomThemeProvider: React.FC = ({children}) => {
    const currentUser = useSelector((state: AppState) => state.auth.currentUser);
    const theme = generateCustomTheme(currentUser);

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default CustomThemeProvider;
