import React from 'react';
import Button from '@material-ui/core/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import {PropTypes} from "@material-ui/core";

interface FloatingAddButtonProps {
    text?: string;
    color?: PropTypes.Color;
    permission?: boolean;
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    className?: string;
}

export const AddButton: React.FC<FloatingAddButtonProps> = ({onClick, permission = false, color = 'primary', text, className}) => {
    
    if (!permission) return null;
    
    return (
        <Button variant="contained" className={className} color={color} onClick={onClick}>
            {text || 'OPRET'} <AddIcon fontSize={'small'}/>
        </Button>
    );
};


export default AddButton;
