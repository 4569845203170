                                    import {CurrentUser} from "../../models/User";
import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {Button, Grid, TextField} from "@material-ui/core";
import appService from "../../services/appService";
import {useDispatch} from "react-redux";
import {authActions} from "../../store/AuthState";
import RequestFeedback from "../../models/httprequest/RequestFeedback";
import RequestFeedbackDisplay from "../../components/RequestFeedbackDisplay";


interface ProfileNameAndEmailFormProps {
    user: CurrentUser;
}

export interface NameAndEmail {
    firstName: string;
    lastName: string;
    email: string;
}

export const ProfileNameAndEmailForm: React.FC<ProfileNameAndEmailFormProps> = ({user}) => {
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState<RequestFeedback | undefined>()

    const handleSubmit = async (values: NameAndEmail) => {
        const response = await appService.me.updateEmail(values);
        setFeedback(!response.success ? response.feedback : {
            severity: "success",
            message: "Dit navn og email er blevet opdateret"
        })

        if (response.success) {
            dispatch(authActions.updateCurrentUser({
                ...user,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
            }))
        }
    }

    return (
        <Formik<NameAndEmail>
            onSubmit={handleSubmit}
            initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
            }}
        >
            {() => (
                <Form style={{width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                as={TextField}
                                required
                                fullWidth
                                variant="outlined"
                                id="firstName"
                                label="Fornavn"
                                type="firstName"
                                name="firstName"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                as={TextField}
                                required
                                fullWidth
                                variant="outlined"
                                id="lastName"
                                label={"Efternavn"}
                                type="lastName"
                                name="lastName"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                as={TextField}
                                required
                                fullWidth
                                variant="outlined"
                                id="email"
                                label="Email"
                                type="email"
                                name="email"
                            />
                        </Grid>
                        <Grid item md={6}/>
                        <Grid item xs={12} md={6}>
                            <RequestFeedbackDisplay feedback={feedback}/>
                        </Grid>
                        <Grid item xs={12} md={6}
                              style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                aria-label="submit"
                            >
                                Opdater navn og email
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileNameAndEmailForm;