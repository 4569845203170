import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import useCurrentCenter from "../../hooks/useCurrentCenter";
import searchFilter from "../../lib/searchFilter";
import BasePage from "../BasePage";
import ThreadList from "../../components/message/ThreadList";
import ThreadCard from "../../components/message/ThreadCard";
import SearchInput from "../../components/SearchInput";
import ThreadDisplay from "../../components/message/ThreadDisplay";
import {differenceInDays} from "date-fns";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import Box from "@material-ui/core/Box/Box";
import ColorDot from "../../components/common/ColorDot";
import {SingleSelectField} from "../../components/form_fields/SingleSelectFormField";
import VideoLinkButton from "../../components/buttons/VideoLinkButton";
import DraftsIcon from "@material-ui/icons/Drafts";
import MailIcon from "@material-ui/icons/Mail";
import {InquiryMessage, InquiryThread, isSMSThread} from "../../models/Inquiry";
import {inquiryActions} from "../../store/InquiriesState";
import RequestFeedbackDisplay from "../../components/RequestFeedbackDisplay";
import WebMessage from "../../models/web/WebMessage";
import LoadingOrError from "../../components/LoadingOrError";

const messageFilterIds = ["Alle", "Ulæst", "Ubesvaret"];
export const smsFooter = (keyword?: string) => `(Husk at starte alle dine beskeder til os med hs ${keyword})`;
const InquiryPage: React.FC = () => {
    const dispatch = useDispatch();
    const currentCenter = useCurrentCenter();
    const {elements = [], loading, editElement, feedback} = useSelector((state: AppState) => state.inquiries);
    const [search, setSearch] = useState<string>('');
    const [filterId, setFilterId] = useState(messageFilterIds[0]);

    const handleDeleteMessage = (_: string, message: InquiryMessage) => dispatch(inquiryActions.deleteMessage(message.id))
    const handleDeleteThread = () => editElement && dispatch(inquiryActions.deleteThread(editElement))
    const handleSendMessage = (text: string) => {
        dispatch(inquiryActions.sendMessage(text));
    }
    const handleSelectThread = (t: InquiryThread) => () => {
        dispatch(inquiryActions.updateRead(t.id, true));
        dispatch(inquiryActions.setEditElement(t));
    };
    const handleBack = () => dispatch(inquiryActions.setEditElement(undefined));
    const handleNameChange = (name: string) => editElement && dispatch(inquiryActions.updateAuthor({
        ...editElement?.author,
        name
    }));
    const handleFeedback = () => dispatch(inquiryActions.setFeedback(undefined));
    const handleReadToggle = () => {
        if (editElement) {
            const {id, read} = editElement;
            dispatch(inquiryActions.updateRead(id, !read));
        }
    }

    const filteredThreads = elements?.filter(threadSearchFilter(search, currentCenter.id, filterId)) ?? [];

    const generateThreadCard = ({index, style}) => {
        const thread = filteredThreads[index];
        const secondary = isSMSThread(thread) ? "sms" : "web";

        const [soonToBeDeleted, daysToDeletion] = calculateDeletion(thread);
        const isAnswered = thread.messages[thread.messages.length - 1]?.author?.id !== thread.author.id

        return (
            <ThreadCard
                style={style}
                id={thread.id}
                primary={thread.author.name}
                secondary={secondary}
                isSelected={thread.id === editElement?.id}
                updatedAt={thread.updatedAt}
                onSelect={handleSelectThread(thread)}
                color={thread.author.color}
                avatar
                indicators={soonToBeDeleted ? (
                    <Tooltip title={"Samtalen slettes om " + daysToDeletion + " dage"}>
                        <Warning color="error"/>
                    </Tooltip>
                ) : (
                    <Box display={'flex'}>
                        <ColorDot type={"warning"} visible={!isAnswered} tooltip={"Ubesvaret"}/>
                        <ColorDot type={"error"} visible={!thread.read} tooltip={"Ulæst"}/>
                    </Box>
                )}
            />
        )
    }
    const endHeader = (
        <>
            <VideoLinkButton onSendClick={handleSendMessage}/>
            <Tooltip title={"Markér som " + (editElement?.read ? "ulæst" : "læst")}
                     aria-label="read unread">
                <IconButton aria-label="set unread" onClick={handleReadToggle}>
                    {editElement?.read ? <DraftsIcon/> : <MailIcon/>}
                </IconButton>
            </Tooltip>
        </>
    )

    return (
        <BasePage>
            <ThreadList
                selectedId={editElement?.id}
                elementsLength={filteredThreads.length}
                loading={loading}
                listItem={generateThreadCard}
            >
                <SearchInput search={search} onSearchChange={setSearch}/>
                <SingleSelectField
                    id="filter"
                    value={filterId}
                    setValue={setFilterId}
                    options={messageFilterIds}
                />
            </ThreadList>
            <ThreadDisplay
                selectedId={editElement?.id}
                title={editElement?.author.name}
                messages={editElement?.messages ?? []}
                onBack={handleBack}
                color={editElement?.author.color}
                onTitleChange={handleNameChange}
                onSendMessage={handleSendMessage}
                onThreadDelete={handleDeleteThread}
                identifyMe={(messageAuthorId => messageAuthorId !== editElement?.author.id)}
                onDeleteMessage={handleDeleteMessage}
                signature={editElement && isSMSThread(editElement) ? smsFooter(editElement.keyword) : ""}
                endHeader={endHeader}
            >
                {editElement === undefined && <LoadingOrError loading={loading} error={"Vælg henvendelse"}/>}
                <RequestFeedbackDisplay
                    feedback={feedback}
                    action={<Button onClick={handleFeedback}>OK</Button>}
                />
            </ThreadDisplay>
        </BasePage>
    )
};

const threadSearchFilter = (search: string, currentCenterId?: string, filterId?: string) =>
    ({author, centerId, messages, read}: InquiryThread) =>
        (centerId === currentCenterId) &&
        (filterId === "Alle" || (filterId === "Ulæst" && !read) || (filterId === "Ubesvaret" && messages[messages.length - 1]?.author?.id === author?.id)) &&
        searchFilter({
            name: author.name,
            messages: (messages as InquiryMessage[]).map(m => m.content).join(),
        }, search);

const calculateDeletion = (thread: InquiryThread) => {

    let latestYouthSMS: WebMessage | undefined = undefined;
    let index = thread.messages.length - 1;
    for (; index >= 0; index--) {
        const msg = thread.messages[index]
        if (msg.author.id === thread.author.id) {
            latestYouthSMS = msg;
            break;
        }
    }
    if (latestYouthSMS === undefined) return [false, 0]

    const ingoingDiff = latestYouthSMS ? differenceInDays(new Date(), new Date(latestYouthSMS.createdAt)) : -1;

    const soonToBeDeleted = ingoingDiff > 76;
    const daysToDeletion = 90 - ingoingDiff;

    return [soonToBeDeleted, daysToDeletion];
};

export default InquiryPage;
