import signIn from './images/outlook/sign_in.png';
import success from './images/outlook/success.png';

export default `# Sådan integrerer du vagtplanen med Outlook
På vagtplanlæggeren kan du se et Outlook-ikon i højre side.
Hvis du holder musen over ikonet, så kan du se en status for, om du har integreret vagtplanen med en Outlook-kalender.

1. Tryk på Outlook-ikonet inde på ”Vagtplanen”. En ny side åbner i din browser. Hvis det ikke er tilfældet, så tjek, om du har givet siden lov til at åbne pop-up vinduer i browseren.
Du bliver bedt om at vælge en konto eller indtaste din email.

Den nye side skulle meget gerne bede dig om at vælge en konto eller bede dig om at indtaste din mail.

![Outlook: Vælg konto](${signIn})

2. Vælg konto eller indtast din email og adgangskode for den konto, som du ønsker integreret med vagtplanen. 

3. Godkend de ting, der spørges om tilladelse til. Herefter bliver du dirigeret tilbage til systemet, som nu vil fortælle, at integrationen har været en succes. 

![Outlook: Success!](${success})

Hvis du oplever problemer, har spørgsmål eller andet så kontakt os endelig.
`
