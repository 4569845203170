import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box/Box";
import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";


export const SupportPaper: React.FC<{ title?: string; subtitle?: string; flexDirection?: 'row' | 'column' }> = ({title, flexDirection = 'column', children}) => {

    return (
        <Container maxWidth={'lg'} style={{float: 'left', padding: 0 }}>
            <Box component={Paper} p={4} display={'flex'} flexDirection={flexDirection} mb={4}>
                {title &&
                    <Box flex={1}>
                        <Box pb={2}>
                             <Typography variant={'h5'}>
                                {title}
                            </Typography>
                        </Box>
                    </Box>
                }
                {children}
            </Box>
        </Container>
    )
}

export default SupportPaper;