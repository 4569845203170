import React, {useState} from 'react';
import {Box, Collapse, Divider, Drawer, ListItem, makeStyles} from '@material-ui/core';
import AppLogo from 'components/AppLogo';
import CenterSelector from 'components/CenterSelector';
import {useDispatch, useSelector} from 'react-redux';
import Routes from 'constants/Routes';
import {authActions} from 'store/AuthState';
import {push} from 'connected-react-router';
import NavButton from 'components/buttons/NavButton';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {checkPermission, CurrentUser} from 'models/User';
import useMediaQueryMobile, {mobileMediaQuery} from 'hooks/useMediaQueryMobile';
import navigationMap, {NavItem} from './navigationMap';
import {drawerWidth} from 'constants/themeConfig';
import useCurrentCenter from "../../hooks/useCurrentCenter";
import {AppState} from "../../store";
import {chatsActions} from "../../store/ChatsState";
import {isDSNUser} from "../../lib/generateCustomTheme";
import {inquiryActions} from "../../store/InquiriesState";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        ['@media ' + mobileMediaQuery]: {
            width: "auto",
        }
    },
    drawerPaper: {
        width: drawerWidth,
        border: 'none',
        background: `linear-gradient(130deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%)`,
        ['@media ' + mobileMediaQuery]: {
            width: "auto",
        },
    },
    text: {
        height: "32px",
        lineHeight: "32px",
        flex: 1,
        marginLeft: 4,
        marginRight: 12,
        fontSize: '18px',
        fontWeight: 600,
        color: theme.palette.common.white,
    },
    collapse: {
        color: 'white',
    },
}));

interface NavigationProps {
    currentUser: CurrentUser;
    showDrawer: boolean;
    toggleDrawer: (toggle?: boolean) => void;
}

const Navigation: React.FC<NavigationProps> = ({currentUser, showDrawer, toggleDrawer}) => {
    const isMobile = useMediaQueryMobile();
    const dispatch = useDispatch();
    const currentCenter = useCurrentCenter();
    const classes = useStyles();
    const [navigationMapState, setNavigationMapState] = useState(navigationMap);

    // Decorate navigation
    const anyUnreadChatMessages = useSelector((state: AppState) => !!state.chats?.elements?.find((thread) => !thread.readByIds.includes(currentUser.id)));
    // const anyUnreadSMSMessages = useSelector((state: AppState) => !!state.smsThreads?.elements?.find((thread) => !thread.read && thread.centerId === currentCenter.id));
    // const anyUnreadWebMessages = useSelector((state: AppState) => !!state.webThreads?.elements?.find((thread) => !thread.read && thread.centerId === currentCenter.id));
    const anyUnreadInquires = useSelector((state: AppState) => !!state.inquiries.elements.find(t => !t.read && t.centerId === currentCenter.id));
    const navDecoration: { [p: string]: { dot?: boolean; onClick?: VoidFunction } } = {
        [Routes.chat]: {dot: anyUnreadChatMessages},
        // [Routes.sms]: {dot: anyUnreadSMSMessages},
        // [Routes.web]: {dot: anyUnreadWebMessages},
        [Routes.inquiry]: {dot: anyUnreadInquires},
        [Routes.landing]: {
            onClick: () => {
                dispatch(authActions.signOut());
                // dispatch(smsThreadsActions.disconnect());
                // dispatch(webThreadActions.disconnect());
                dispatch(inquiryActions.disconnect());
                dispatch(chatsActions.disconnect());
                toggleDrawer();
            }
        },
    };

    const handleNavItemClick = (item: NavItem) => navDecoration[item.path]?.onClick ? navDecoration[item.path]?.onClick : () => {
        dispatch(push(item.path));
        if (isMobile) toggleDrawer();
    };

    const handleNavigationClick = (index: number) => () => {
        const newState = [...navigationMapState];
        newState[index].open = !newState[index].open;
        setNavigationMapState(newState);
    };

    const isItemPermitted = (item: NavItem) => !item.permission || checkPermission(currentUser, item.permission);

    const addFlexGrow = (item: NavItem) => {
        if (item.name === "Support" && !isMobile)
            return (
                <>
                    <Divider variant="fullWidth" orientation="horizontal"/>
                    <Box flex={1}/>
                </>
            );
    };

    const drawerContent = navigationMapState.map(({name, open, routes}, index) => {
        const permittedRoutes = routes.filter(isItemPermitted);
        if (permittedRoutes.length === 0) {
            return null;
        } else if (permittedRoutes.length === 1) {
            const routeItem = permittedRoutes[0];
            return (
                <React.Fragment key={routeItem.name}>
                    {addFlexGrow(routeItem)}
                    <Divider variant="fullWidth" orientation="horizontal"/>
                    <NavButton
                        navItem={routeItem}
                        showDot={navDecoration[routeItem.path]?.dot}
                        onClick={handleNavItemClick(routeItem)}
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment key={name}>
                <Divider variant="fullWidth" orientation="horizontal"/>
                <ListItem className={classes.collapse} button onClick={handleNavigationClick(index)}>
                    <span className={classes.text}>{name}</span>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse className={classes.collapse} in={open} timeout="auto" unmountOnExit>
                    {permittedRoutes.map((item) => {
                        if ((isMobile && !item.notOnMobile) || !isMobile) {
                            return (
                                <React.Fragment key={item.name}>
                                    <NavButton
                                        navItem={item}
                                        showDot={navDecoration[item.path]?.dot}
                                        onClick={handleNavItemClick(item)}
                                    />
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </Collapse>
            </React.Fragment>
        );
    });

    // DON'T use isMobile in render cycle
    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}
            anchor={isMobile ? "top" : "left"}
            open={isMobile ? showDrawer : true}
            onClose={() => toggleDrawer(false)}
            className={classes.drawer}
            classes={{paper: classes.drawerPaper}}
            PaperProps={{elevation: 2}}
        >
            <Box display="flex" justifyContent="center" alignItems="start" flexDirection="column" p={2}>
                <AppLogo variant="h5" color="white">
                    {isDSNUser(currentUser) ? "Det Sociale Netværk" : "headspace"}
                </AppLogo>
                <CenterSelector/>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                {drawerContent}
            </Box>
        </Drawer>
    )
};

export default Navigation;
