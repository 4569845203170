import forsideHenvendelser from "./images/web/Forside_henvendelser.png"
import forsideWeb from "./images/web/Forside_Web.png"
import webSideSamtaleTraad from "./images/web/Web_side_samtale_traad.png"
import webSideBeskedBesvar from "./images/web/Web_side_besked_besvar.png"
import webSideBeskedBesvarSend from "./images/web/Web_side_besked_besvar_send.png"
import webSideBeskedBesvarSendVideo from "./images/web/Web_side_besked_besvar_send_video.png"
export default `# Sådan besvarer du Web-henvendelser

1. Tilgå siden "Web" ved at klikke på "Henvendelser" og derefter vælge "Web" i menuen på venstre side af skærmen.

![Klik på "Henvendelser"](${forsideHenvendelser})

![Klik på "Web"](${forsideWeb})

2. På denne side kan du se alle beskeder, der er skrevet til dit center gennem kontaktformularen på vores hjemmeside. Klik på beskeden for at se hele samtalen.

![Klik på en besked](${webSideSamtaleTraad})

3. Du kan nu læse samtalen med den unge og besvare beskeder ved at klikke på knappen ”Besvar”.

![Klik på en besked](${webSideBeskedBesvar})

4. I feltet, hvor markøren står, kan du skrive din besked. Når du er færdig, skal du blot sende ved at klippe på knappen ”SEND”.

![Klik på en besked](${webSideBeskedBesvarSend})

## Sådan sender du et videolink

* Begynd med at klikke på kamera-ikonet. Dernæst indsætter du det kopierede link i feltet, hvor markøren står på billedet herunder. Når du har skrevet en besked til den unge, sender du meddelelsen ved at klikke på knappen ”SEND”.

![Klik på en besked](${webSideBeskedBesvarSendVideo})
`;
