export const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25mb

export interface LoadedAsset {
    file: File;
    fileName: string;
    dataUrl?: string;
}

export interface Asset {
    id: string;
    fileName: string;
    filePathName: string;
}

export const emptyAsset: Asset = {
    id: '',
    fileName: '',
    filePathName: ''
};

