import React, {useState} from 'react';
import stableSortByKey from '../lib/stableSortByKey';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import Paper from '@material-ui/core/Paper/Paper';
import Table from '@material-ui/core/Table/Table';
import EnhancedTableHead, {TableHeadLabel} from './EnhancedTableHead';
import TableBody from '@material-ui/core/TableBody/TableBody';
import LoadingOrError from './LoadingOrError';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    errorContainer: {
        overflow: 'hidden',
        height: '100%',
    }
})

export interface EnhancedTableProps<T> {
    tableHeadLabels: TableHeadLabel[];
    elements: T[];
    createPermission: boolean;
    initialOrderBy?: string;
    loading: boolean;
    error?: string;
    elementSearchFilter?: (search: string) => (element: T) => boolean;
    onAddClick?: VoidFunction;
    children: (sortedElements: T[]) => React.ReactNode;
    actions?: React.ReactNode;
}

export const EnhancedTable = <T, >({children, tableHeadLabels, elements, loading, error, elementSearchFilter, createPermission, onAddClick, initialOrderBy = 'name', actions}: EnhancedTableProps<T>) => {
    const classes = useStyles();
    const [orderAscending, setOrderAscending] = useState<boolean>(true);
    const [orderBy, setOrderBy] = useState<string>(initialOrderBy);
    const [search, setSearch] = useState<string>('');
    const onRequestSort = (property) => {
        setOrderAscending(!(property === orderBy && orderAscending));
        setOrderBy(property);
    };

    const filteredElements: T[] = elementSearchFilter !== undefined ? elements.filter(elementSearchFilter(search)) : elements;
    const sortedElements: T[] = stableSortByKey(filteredElements, orderBy, orderAscending);

    const emptyLoading = loading && sortedElements.length === 0;

    return (
        <TableContainer component={Paper} className={error ? classes.errorContainer : undefined}>
            <Table>
                <EnhancedTableHead
                    labels={tableHeadLabels}
                    search={elementSearchFilter !== undefined ? search : undefined}
                    onSearchChange={elementSearchFilter !== undefined ? setSearch : undefined}
                    orderBy={orderBy}
                    orderAscending={orderAscending}
                    onRequestSort={onRequestSort}
                    createPermission={createPermission}
                    onAddClick={onAddClick}
                    actions={actions}
                />
                {!emptyLoading && (
                    <TableBody>
                        {children(sortedElements)}
                    </TableBody>
                )}
            </Table>
            <LoadingOrError loading={emptyLoading} error={error}/>
        </TableContainer>
    );
};

export default EnhancedTable;
