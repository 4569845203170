export const editorConfig = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history']
}

export const da =  {
    // Generic
    "generic.add": "Tilføj",
    "generic.cancel": "Annuller",

    // BlockType
    "components.controls.blocktype.h1": "Overskrift 1",
    "components.controls.blocktype.h2": "Overskrift 2",
    "components.controls.blocktype.h3": "Overskrift 3",
    "components.controls.blocktype.h4": "Overskrift 4",
    "components.controls.blocktype.h5": "Overskrift 5",
    "components.controls.blocktype.h6": "Overskrift 6",
    "components.controls.blocktype.blockquote": "Blokcitat",
    "components.controls.blocktype.code": "Kode",
    "components.controls.blocktype.blocktype": "Blok Type",
    "components.controls.blocktype.normal": "Normal",

    // Color Picker
    "components.controls.colorpicker.colorpicker": "Farver",
    "components.controls.colorpicker.text": "Tekst",
    "components.controls.colorpicker.background": "Baggrund",

    // Embedded
    "components.controls.embedded.embedded": "Indlejre",
    "components.controls.embedded.embeddedlink": "Indlejre Link",
    "components.controls.embedded.enterlink": "Indtast link",

    // Emoji
    "components.controls.emoji.emoji": "Emoji",

    // FontFamily
    "components.controls.fontfamily.fontfamily": "Fonttype",

    // FontSize
    "components.controls.fontsize.fontsize": "Fontstørrelser",

    // History
    "components.controls.history.history": "Historie",
    "components.controls.history.undo": "Fortryd",
    "components.controls.history.redo": "Gendan",

    // Image
    "components.controls.image.image": "Billede",
    "components.controls.image.fileUpload": "Filoverførsel",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText":
        "Drop filen eller klik for at uploade",

    // Inline
    "components.controls.inline.bold": "Fed",
    "components.controls.inline.italic": "Kursiv",
    "components.controls.inline.underline": "Understrege",
    "components.controls.inline.strikethrough": "Gennemstreget",
    "components.controls.inline.monospace": "Monospace",
    "components.controls.inline.superscript": "Hævet",
    "components.controls.inline.subscript": "Sænket",

    // Link
    "components.controls.link.linkTitle": "Link Titel",
    "components.controls.link.linkTarget": "Link",
    "components.controls.link.linkTargetOption": "Åbn link i nyt vindue",
    "components.controls.link.link": "Link",
    "components.controls.link.unlink": "Fjern link",

    // List
    "components.controls.list.list": "Liste",
    "components.controls.list.unordered": "Uordnet",
    "components.controls.list.ordered": "Ordnet",
    "components.controls.list.indent": "Indrykning",
    "components.controls.list.outdent": "Udrykning",

    // Remove
    "components.controls.remove.remove": "Fjern",

    // TextAlign
    "components.controls.textalign.textalign": "Tekstjustering",
    "components.controls.textalign.left": "Venstre",
    "components.controls.textalign.center": "Center",
    "components.controls.textalign.right": "Højre",
    "components.controls.textalign.justify": "Margener"
}