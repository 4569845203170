import {SMSThread} from "./sms/SMSThread";
import {WebMessage, WebThread} from "./web";
import {SMSMessage} from "./sms/SMSMessage";
import {SMSAuthor} from "./sms/SMSAuthor";
import WebAuthor from "./web/WebAuthor";

export type InquiryThread = SMSThread | WebThread;
export type InquiryMessage = SMSMessage | WebMessage;
export type InquiryAuthor = SMSAuthor | WebAuthor;

export const isSMSThread = (thread: InquiryThread): thread is SMSThread => (thread as any)?.type === "sms";
export const isSMSAuthor = (author: InquiryAuthor): author is SMSAuthor => (author as WebAuthor).sex === undefined && (author as WebAuthor).age === undefined;
export const isSMSMessage = (msg: InquiryMessage): msg is SMSMessage => isSMSAuthor(msg.author as InquiryAuthor);
