import React from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import Table from '@material-ui/core/Table/Table';
import Paper from '@material-ui/core/Paper/Paper';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Permission from '../../models/Permission';
import Role, {roleHasPermission} from '../../models/Role';
import BasePage from '../BasePage';
import useCrudDummyState from '../../hooks/useCrudDummyState';

interface PermissionGroup {
    field?: string;
    name: string;
    permissions: Permission[];
}

const userPermissionGroup: PermissionGroup = {
    name: 'Bruger administration',
    permissions: [{
        id: 'Permissions.Users.Create',
        name: 'Oprette brugere',
        shortText: ''
    }, {
        id: 'Permissions.Users.Read',
        name: 'Se bruger oversigt',
        shortText: ''

    }, {
        id: 'Permissions.Users.Update',
        name: 'Redigere brugere',
        shortText: ''
    }, {
        id: 'Permissions.Users.Delete',
        name: 'Slette brugere',
        shortText: ''
    }]
};
const centerPermissionGroup: PermissionGroup = {
    name: 'Center administration',
    permissions: [{
        id: 'Permissions.Centers.Create',
        name: 'Oprette centre',
        shortText: ''
    }, {
        id: 'Permissions.Centers.Read',
        name: 'Se center oversigt',
        shortText: ''

    }, {
        id: 'Permissions.Centers.Update',
        name: 'Redigere centre',
        shortText: ''
    }, {
        id: 'Permissions.Centers.Delete',
        name: 'Slette centre',
        shortText: ''
    }]
};

const smsPermissionGroup: PermissionGroup = {
    name: 'SMS-henvendelser',
    permissions: [{
        id: 'Permissions.SMS.Create',
        name: 'Besvare SMS-henvendelser',
        shortText: ''
    }, {
        id: 'Permissions.SMS.Read',
        name: 'Læse SMS-henvendelser',
        shortText: ''

    }]
};
const emailPermissionGroup: PermissionGroup = {
    name: 'Web-henvendelser',
    permissions: [{
        id: 'Permissions.Email.Create',
        name: 'Besvare Web-henvendelser',
        shortText: ''
    }, {
        id: 'Permissions.Email.Read',
        name: 'Læse Web-henvendelser',
        shortText: ''

    }]
};


const dummyRoles: Required<Role>[] = [];
/*


    [

    {
        id: '0',
        name: 'Alle kan',
        permissions: [
            ...userPermissionGroup.permissions.filter((permission) => permission.id?.toLocaleLowerCase().includes('read')),
            ...centerPermissionGroup.permissions.filter((permission) => permission.id?.toLocaleLowerCase().includes('read')),
        ],
    },
    {
        id: '1',
        name: 'Håndtere ungehenvendelser',
        permissions: [
            ...smsPermissionGroup.permissions,
            ...emailPermissionGroup.permissions,
        ],
    },
    {
        id: '2',
        name: 'Håndtere brugeradministration',
        permissions: [
            ...userPermissionGroup.permissions,
            ...centerPermissionGroup.permissions.filter(permission =>
                !permission.id?.toLocaleLowerCase().includes('delete') && !permission.id?.toLocaleLowerCase().includes('create'),
            ),
        ],
    },


];
 */


export const PermissionsPage: React.FC = () => {
    const permissionGroups: PermissionGroup[] = [
        userPermissionGroup,
        centerPermissionGroup,
        smsPermissionGroup,
        emailPermissionGroup,
    ];
    const [roles, , setRoleEdit] = useCrudDummyState<Required<Role>>(dummyRoles);
    const updatePermission = false;


    return (
        <BasePage>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            {roles.map((role) => (
                                <TableCell key={role.id} align="center">
                                    {role.name}
                                    {updatePermission && <IconButton
                                        aria-label="flere muligheder"
                                        component="span"
                                        style={{padding: '8px'}}
                                        onClick={() => setRoleEdit(role)}
                                    >
                                        <EditIcon fontSize={'small'}/>
                                    </IconButton>}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {permissionGroups.map((group) => (
                            <React.Fragment key={group.name}>
                                <TableRow>
                                    <TableCell>
                                        <strong>{group.name}</strong>
                                    </TableCell>
                                    {Array.from(Array(roles.length)).map((_, index) => <TableCell key={index}/>)}
                                </TableRow>
                                {group.permissions.map(permission => (

                                    <TableRow key={permission.id}>
                                        <TableCell component="th" scope="row">
                                            {permission.name}
                                        </TableCell>
                                        {roles.map((role) => (
                                            <TableCell key={role.id} align="center" padding="none">
                                                <Checkbox
                                                    checked={roleHasPermission(role, permission.name)}
                                                    color="primary"
                                                    disabled={true}
                                                    inputProps={{'aria-label': 'disabled checked checkbox'}}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </BasePage>
    );
};

export default PermissionsPage;
