import React from 'react';
import ActionView from '../../components/ActionView';
import ResetPasswordForm from './ResetPasswordForm';


const ResetPasswordPage: React.FC = () => {
    // const query = useLocationQuery();
    // const {firstName, lastName} = query;

    return (
        <ActionView
            title="Nulstil adgangskode"
            subtitle="Indtast din nye adgangskode"
        >
            <ResetPasswordForm/>
        </ActionView>
    )
};

export default ResetPasswordPage;
