import React from 'react';
import {Picker, PickerProps} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import {Menu, useTheme} from '@material-ui/core';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";

const i18n: PickerProps['i18n'] = {
    search: 'Søg',
    notfound: 'Ingen emojies fundet',
    categories: {
        search: 'Søgeresultater',
        recent: 'Mest anvendt',
        people: 'Smileys & Mennesker',
        nature: 'Dyr & Natur',
        foods: 'Mad & Drikke',
        activity: 'Aktivitet',
        places: 'Rejser & Steder',
        objects: 'Objekter',
        symbols: 'Symboler',
        flags: 'Flag',
        custom: 'Custom',
    },
};

const useStyles = makeStyles<Theme>((theme) => ({
    icon: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
}));

interface EmojiPickerProps {
    value: string;
    onChange: (e: string) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({onChange, value}) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const classes = useStyles();

    const handleShow: IconButtonProps["onClick"] = e => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    disablePadding: true,
                }}>
                <Picker
                    title='Vælg din emoji…' emoji='point_up'
                    enableFrequentEmojiSort={false}
                    native={true}
                    theme="light"
                    color={theme.palette.secondary.light}
                    i18n={i18n}
                    emojiTooltip={false}
                    onSelect={(emoji) => {
                        onChange(value + (emoji as { native: string })?.native);
                    }}
                />
            </Menu>

            <IconButton onClick={handleShow} size="small" className={classes.icon}>
                <EmojiEmotionsOutlinedIcon/>
            </IconButton>
        </>
    )
};

export default EmojiPicker;
