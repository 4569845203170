import RequestFeedback from "./RequestFeedback";
import HttpFeedbackMap from "./HttpFeedbackMap";
import ErrorResponseJson from "./ErrorResponseJson";


export type AppResponse<T> = Promise<AppSuccessResponse<T> | AppErrorResponse>;

export interface AppSuccessResponse<T> {
    status: number;
    statusText?: string;
    success: true;
    feedback: RequestFeedback;
    value: T;
}

export interface AppErrorResponse {
    status: number;
    statusText?: string;
    success: false;
    feedback: RequestFeedback;
}


export const responseFromError = (status: number, errorResponseJson?: ErrorResponseJson): AppErrorResponse => ({
    status: status,
    success: false,
    feedback: {
        ...HttpFeedbackMap[status],
        ...errorResponseJson,
    }
});


export default AppResponse;