import React from "react";
import {SRLWrapper} from "simple-react-lightbox";
import {default as Lightbox} from "simple-react-lightbox";

const options = {
    settings: {
        autoplaySpeed: 0,
        overlayColor: 'rgba(0, 0, 0, 0.8)',
        disablePanzoom: true,
    },
    buttons: {
        backgroundColor: 'rgba(0,0,0,0)',
        showDownloadButton: false,
        showAutoplayButton: false,
        showThumbnailsButton: false
    },
    caption: {
        showCaption: false
    }
};

export const AssetLightBoxWrapper: React.FC = ({children}) => {
    return (
        <Lightbox>
            <SRLWrapper options={options}>
                {children}
            </SRLWrapper>
        </Lightbox>
    )
}

export default AssetLightBoxWrapper;