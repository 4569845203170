import React from "react";
import {Asset} from "../../models/file/Asset";
import {getExtension} from "../../lib/files";
import {lookup} from "mime-types";
import AssetImageGrid from "./AssetImageGrid";
import {Box, CardContent} from "@material-ui/core";
import AssetFileThumbnail from "./AssetFileThumbnail";

// const useStyles = makeStyles((theme) => ({}));

interface PostAssetsDisplayProps {
    assets?: Asset[];
}

interface ReducedAssets {
    images: Asset[];
    files: Asset[];
}

const PostAssetsDisplay: React.FC<PostAssetsDisplayProps> = ({assets}) => {

    if (!assets || assets.length === 0) return null;

    const reducedAssets = assets.reduce((accumulator: ReducedAssets, currentValue) => {
        const mimeType = lookup(getExtension(currentValue.filePathName));
        const isImage = mimeType.includes('image');
        if (isImage) {
            accumulator.images = [...accumulator.images, currentValue];
        } else {
            accumulator.files = [...accumulator.files, currentValue];
        }
        return accumulator;
    }, {images: [], files: []})

    return (
        <>
            {reducedAssets.files.length > 0 &&
            <CardContent>
                <Box display="flex"
                     flexWrap="wrap"
                >
                    {reducedAssets.files.map((asset) => {
                        return (
                            <AssetFileThumbnail key={asset.id} asset={asset}/>
                        )
                    })}
                </Box>
            </CardContent>
            }
            <AssetImageGrid assets={reducedAssets.images}/>
        </>
    )
};

export default PostAssetsDisplay;