import React, {useEffect, useState} from "react";
import updatedAtText from "../../lib/updatedAtText";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>(() => ({
    text: {
        display: 'block',
        fontSize: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
}));

interface MessageTimestampProps {
    updatedAt: Date;
}

const MessageTimestamp: React.FC<MessageTimestampProps> = ({updatedAt}) => {

    const classes = useStyles();
    const [timestamp, setTimestamp] = useState<string>(updatedAtText(updatedAt));

    useEffect(() => {
        const updateTimestamp = async () => {
            setTimestamp(updatedAtText(updatedAt));
        };
        const interval = setInterval(updateTimestamp, 10000);
        return () => clearInterval(interval);
    }, [updatedAt]);

    return <span className={classes.text}>{timestamp}</span>;
};

export default MessageTimestamp;