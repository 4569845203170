import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectProps} from '@material-ui/core';
import {Field} from 'formik';
import {workStatusOptions} from 'constants/businessLogic';

interface WorkStatusFormFieldProps {
    id: string;
    required?: boolean;
    style?: React.CSSProperties;
    error?: string | undefined;
    variant?: SelectProps['variant'];
}

export const WorkStatusFormField: React.FC<WorkStatusFormFieldProps> = ({id, required, style, error, variant}) => {
    const labelId = `${id}-label`;
    return (
        <FormControl fullWidth style={style}>
            <InputLabel id={labelId}>{`Tilknytning til arbejdsmarked${required ? ' *' : ''}`}</InputLabel>
            <Field
                as={Select}
                error={error}
                fullWidth
                required={required}
                name={id}
                label="Tilknytning til arbjedsmarked"
                id={id}
                labelId={labelId}
                variant={variant}
            >
                <MenuItem value={undefined} disabled>
                    Tilknytning til uddannelse/arbejdsmarked
                </MenuItem>
                {workStatusOptions.map((ws) => (
                    <MenuItem key={ws} value={ws}>{ws}</MenuItem>
                ))}
            </Field>
        </FormControl>
    );
}

export default WorkStatusFormField;
