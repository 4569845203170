import React from 'react';
import Box from '@material-ui/core/Box/Box';
import BasePage from '../BasePage';
import SupportPaper from "./SupportPaper";
import SupportRow from "./SupportRow";
import IssueForm from './IssueForm';
import {Typography} from '@material-ui/core';

import articles from './articles';
import Routes from 'constants/Routes';
import VersionTag from "../../components/VersionTag";

const SupportPage: React.FC = () => {
    return (
        <BasePage direction="column">
            <SupportPaper>
                <Box display="flex" flexDirection="column">
                    {Object.keys(articles).map(filepath => (
                        <SupportRow
                            key={filepath}
                            to={Routes.support + "/" + filepath}
                            text={articles[filepath].title}
                        />
                    ))}
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                    <Box p={1}>
                        <Typography variant="h6">
                            Send dine kommentarer til os
                        </Typography>
                        <Typography variant="body2">
                            Oplever du fejl, har du spørgsmål eller måske forslag til forbedringer, så skriv til os.
                        </Typography>
                    </Box>
                    <IssueForm/>
                </Box>
            </SupportPaper>
            <SupportPaper>
                <Box mb={2}>
                    <Typography variant="h6">
                        Video introduktion
                    </Typography>
                </Box>
                <iframe
                    title="Introduktion"
                    width="100%"
                    height="320"
                    src="https://www.youtube-nocookie.com/embed/b3Zb9UxHvww"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </SupportPaper>
            <Box position="relative" bottom={24} textAlign="right">
                <VersionTag/>
            </Box>
        </BasePage>
    );
};


export default SupportPage;
