import User, {ConfirmedUser, PendingUser} from '../models/User';
import restService, {RestServiceType} from '../services/restService';
import {AppClient} from './appClient';
import RequestMethod from '../models/RequestMethod';
import AppResponse from "../models/httprequest/AppResponse";

const servicePath = '/api/v1/users';

export interface UserData {
    id: string;
    createdAt: Date | string;
    updatedAt?: Date | string;
    color: string;
    centerIds: string[];
    centers: { id: string; name: string }[];
    verifiedCriminalRecord: boolean;
    verifiedChildCertificate: boolean;
    sex?: string;
    workStatus?: string;
    educationLevel?: string;
    educationSubject?: string;
    employment?: string;
    employmentDate?: Date | string;
    birthdate?: Date | string;
    deletedAt?: Date | string;
    terminationDate?: Date | string;
}

export type UserListService = RestServiceType<PendingUser, User> & {
    confirm: (user: ConfirmedUser, token: string) => AppResponse<ConfirmedUser>;
    resendInvite: (id: string) => AppResponse<undefined>;
    getAllData: () => AppResponse<UserData[]>;
};

export function userListService(client: AppClient): UserListService {
    return {
        ...restService<PendingUser, User>(client, servicePath),

        async resendInvite(id: string): AppResponse<undefined> {
            return await client.fetchJSON(RequestMethod.POST, servicePath + '/' + id + '/resend-invite');
        },

        async confirm(user, token): AppResponse<User> {
            const authorization = 'Bearer ' + token;
            return await client.fetchJSON<User>(RequestMethod.POST, '/api/v1/users/' + user.id + '/confirm', {
                ...user,
            }, {
                'Authorization': authorization,
            });
        },

        async getAllData() {
            return await client.fetchJSON(RequestMethod.GET, servicePath + '/data');
        }
    }
}


