import React from "react";
import {Button, ButtonProps, withStyles} from "@material-ui/core";

interface ColorButtonProps {
    color: string;
    hoverColor?: string;
}

const ColorButton: React.FC<Omit<ButtonProps, "color"> & ColorButtonProps> = ({ color, hoverColor, ...props }) => {
    const ColoredButton = withStyles(() => ({
        root: {
            color: "white", // theme.palette.getContrastText(color),
            backgroundColor: color,
            '&:hover': {
                backgroundColor: hoverColor ?? color,
            },
        },
    }))(Button)

    return <ColoredButton variant="contained" color="primary" {...props} />;
};

export default ColorButton;
