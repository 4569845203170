import React from "react";
import {Box, Link} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import {Asset, LoadedAsset} from "../../models/file/Asset";
import DescriptionIcon from "@material-ui/icons/Description";
import {getExtension} from "../../lib/files";
import {HOST} from "../../constants/config";

const useStyles = makeStyles<Theme>((theme) => ({
    fileContainer: {
        height: 60,
        maxWidth: 250,
        borderRadius: 16,
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        border: "solid 1px #d2d2d2",
        marginRight: theme.spacing(1),
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
            textDecoration: 'none'
        }
    }
}));

interface AssetFileThumbnailProps {
    asset: LoadedAsset | Asset;
}

export const AssetFileThumbnail: React.FC<AssetFileThumbnailProps> = ({children, asset}) => {
    const classes = useStyles();
    const assetUrl = "filePathName" in asset ? HOST + "/api/v1/files/" + asset.filePathName + "?filename=" + asset.fileName : undefined;
    return (
        <Link href={assetUrl} className={classes.fileContainer}>
            <Box display='flex'
                 alignItems='center'
                 justifyContent='center'
                 height='100%'
                 width={60}
                 style={{background: '#4caf50'}}
            >
                <DescriptionIcon style={{color: 'white'}}/>
            </Box>
            <Box display='flex'
                 justifyContent='center'
                 flexDirection='column'
                 pl={1}
                 pr={3}>
                <span
                    style={{fontSize: 14}}><b>{(getExtension(asset.fileName))?.toUpperCase() ?? "INGEN TYPE"}</b></span>
                <span style={{fontSize: 14}}>{asset.fileName}</span>
            </Box>
            {children}
        </Link>
    )
}

export default AssetFileThumbnail;