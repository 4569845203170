import {
    initialWebsocketRequestState,
    KnownWebsocketRequestAction,
    websocketRequestActionNames,
    WebsocketRequestActionType,
    websocketRequestReducer,
    WebsocketRequestState
} from "./WebsocketRequestState";
import {appSignalRClient, startSignalRConnection} from "../services/appSignalRClient";
import {HubConnection} from "@microsoft/signalr";
import {AppThunkAction} from "./index";
import {InquiryAuthor, InquiryThread, isSMSThread} from "../models/Inquiry";
import RequestFeedback from "../models/httprequest/RequestFeedback";

export const inquiryState = 'INQUIRY_STATE';
const stateName = inquiryState;

export type InquiryState = WebsocketRequestState<Required<InquiryThread>>;

export const initialInquiryState: InquiryState = initialWebsocketRequestState<Required<InquiryThread>>();

export type KnownInquiryActions = KnownWebsocketRequestAction<Required<InquiryThread>>;

export type InquiryActions = WebsocketRequestActionType<KnownInquiryActions> & {
    deleteThread(thread: InquiryThread): AppThunkAction<KnownInquiryActions>;
    setEditElement(thread: InquiryThread | undefined): AppThunkAction<KnownInquiryActions>;
    updateRead(id: string, read: boolean): AppThunkAction<KnownInquiryActions>;
    updateAuthor(author: InquiryAuthor): AppThunkAction<KnownInquiryActions>;
    sendMessage(text: string): AppThunkAction<KnownInquiryActions>;
    deleteMessage(messageId: string): AppThunkAction<KnownInquiryActions>;
    setFeedback(feedback?: RequestFeedback): AppThunkAction<KnownInquiryActions>;
}

let connection: HubConnection;
export const inquiryActions: InquiryActions = {
    connect: () => {
        return async (dispatch, getState) => {
            await dispatch({name: stateName, type: websocketRequestActionNames.LOADING});

            // stop connection if started
            if (connection) {
                await connection?.stop()
                await dispatch({name: stateName, type: websocketRequestActionNames.EDIT, element: undefined});
            }


            // const queryString = getState().auth.currentUser?.centers?.map(
            //     (center) => "centerIds=" + encodeURIComponent(center.id ?? '')
            // ).join("&") ?? [];
            const queryString = "centerId=" + encodeURIComponent(getState().auth.center?.id ?? '')

            const hubPath = 'inquiryHub';
            connection = appSignalRClient(hubPath, `${queryString}`, false);

            connection.on('Refresh', (threads: InquiryThread[]) => {
                console.log('inquiryHub.refresh:', threads);
                dispatch({
                    name: stateName,
                    type: websocketRequestActionNames.REFRESH,
                    elements: threads,
                    // fixes jump issue when websocket re-connects
                    keepEditElement: true,
                });
            });

            connection.on('Update', (thread: InquiryThread) => {
                // console.log('inquiryHub.update:', thread)
                dispatch({name: stateName, type: websocketRequestActionNames.RECEIVE, element: thread});
            });

            connection.on('Receive', (thread: InquiryThread) => {
                // console.log('inquiryHub.receive:', thread)
                dispatch({
                    name: stateName,
                    type: websocketRequestActionNames.RECEIVE,
                    element: thread,
                    playSound: true
                });
            });

            connection.on('Delete', (thread: InquiryThread) => {
                // console.log('inquiryHub.delete:', thread)
                // Delete sets the editElement to undefined
                dispatch({name: stateName, type: websocketRequestActionNames.DELETE, element: thread});
            });

            await startSignalRConnection(hubPath, connection);
            await dispatch({name: stateName, type: websocketRequestActionNames.CONNECT});
        };
    },
    disconnect: () => {
        return async (dispatch) => {
            await dispatch({name: stateName, type: websocketRequestActionNames.DISCONNECT});
            await connection.stop();
        };
    },
    setEditElement: (thread) => {
        return async (dispatch) => {
            dispatch({name: stateName, type: websocketRequestActionNames.EDIT, element: thread});
        };
    },
    deleteThread(thread: InquiryThread): AppThunkAction<KnownInquiryActions> {
        return async (dispatch) => {
            await dispatch({name: stateName, type: websocketRequestActionNames.LOADING});
            const response = await connection.invoke("Delete", thread.id);
            if (response?.statusCode === 200) {
                await dispatch({name: stateName, type: websocketRequestActionNames.DELETE, element: thread});
            }
        }
    },
    deleteMessage(messageId: string): AppThunkAction<KnownInquiryActions> {
        return async (dispatch, getState) => {
            const thread = getState().inquiries.editElement;
            if (thread) {
                const response = await connection.invoke("DeleteMessage", thread.id, messageId);
                if (response?.statusCode === 200) {
                    await dispatch({
                        name: stateName,
                        type: websocketRequestActionNames.UPDATE_SELECTED,
                        element: response.result
                    });
                }
            }
        }
    },
    sendMessage(text: string): AppThunkAction<KnownInquiryActions> {
        return async (dispatch, getState) => {
            const currentState = getState();
            const currentUser = currentState.auth.currentUser;
            const thread = currentState.inquiries.editElement;
            if (!thread) return;

            const content = isSMSThread(thread) ? text + `\n(Husk at starte alle dine beskeder til os med hs ${thread.keyword})` : text;

            const message = {
                threadId: thread.id,
                content,
                author: {
                    id: currentUser?.id,
                    name: `${currentUser?.firstName} ${currentUser?.lastName}`
                }
            };

            thread.messages.push({
                id: "new",
                author: {
                    id: "",
                    name: "",
                    color: "",
                    createdAt: new Date(),
                    updatedAt: new Date(),
                },
                content,
                createdAt: new Date(),
            });
            dispatch({name: stateName, type: websocketRequestActionNames.UPDATE_SELECTED, element: thread});
            const response = await connection.invoke("CreateMessage", message);
            if (response.statusCode !== 200) {
                dispatch(({
                    name: stateName, type: websocketRequestActionNames.ERROR, feedback: {
                        severity: "warning",
                        message: response.message,
                        errorCode: response.statusCode,
                    }
                }))
            }
        }
    },
    setFeedback(feedback: RequestFeedback | undefined): AppThunkAction<KnownInquiryActions> {
        return async (dispatch) => {
            await dispatch({name: stateName, type: websocketRequestActionNames.ERROR, feedback});
        };
    },
    updateAuthor(author: InquiryAuthor): AppThunkAction<KnownInquiryActions> {
        return async (dispatch, getState) => {
            const thread = getState().inquiries.editElement;
            if (!thread) return;

            await connection.invoke("UpdateAuthor", thread.id, author);

            const updatedThread = {...thread, author} as InquiryThread;
            await dispatch({name: stateName, type: websocketRequestActionNames.UPDATE_SELECTED, element: updatedThread})
        }
    },
    updateRead(id: string, read: boolean): AppThunkAction<KnownInquiryActions> {
        return async (dispatch, getState) => {
            const thread = getState().inquiries.elements.find(t => t.id === id);
            if (!thread) return;
            await connection.invoke('UpdateRead', id, read);

            await dispatch({
                name: stateName,
                type: websocketRequestActionNames.UPDATE_SELECTED,
                element: {...thread, read}
            })
        }
    },
};

export const inquiryReducer = websocketRequestReducer<Required<InquiryThread>>(stateName, initialInquiryState);

export default inquiryReducer;

