import {authReducer, AuthState, initialAuthState} from './AuthState';
import {initialUsersState, usersReducer, UsersState} from './UsersState';
import {RouterState} from 'connected-react-router';
import {centersReducer, CentersState, initialCentersState} from './CentersState';
import {eventsReducer, EventsState, initialEventsState} from './EventsState';
import {initialNotificationsState, notificationsReducer, NotificationsState} from "./NotificationsState";
import {initialPostsState, postsReducer, PostsState} from "./PostsState";
import {chatsReducer, ChatsState, initialChatsState} from "./ChatsState";
import inquiryReducer, {initialInquiryState, InquiryState} from "./InquiriesState";

export interface AppState {
    auth: AuthState;
    posts: PostsState;
    users: UsersState;
    centers: CentersState;
    events: EventsState;
    notifications: NotificationsState;
    chats: ChatsState;
    inquiries: InquiryState;
    router?: RouterState;
}

export const initialApplicationState: AppState = {
    auth: initialAuthState,
    posts: initialPostsState,
    users: initialUsersState,
    centers: initialCentersState,
    events: initialEventsState,
    notifications: initialNotificationsState,
    chats: initialChatsState,
    inquiries: initialInquiryState,
};

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the authReducer with the matching name. It's important that the names match exactly, and that the authReducer
// acts on the corresponding InitialAppState property variant.

export const reducers = {
    auth: authReducer,
    users: usersReducer,
    centers: centersReducer,
    posts: postsReducer,
    events: eventsReducer,
    notifications: notificationsReducer,
    chats: chatsReducer,
    inquiries: inquiryReducer
};

// This variant can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => AppState): void;
}
