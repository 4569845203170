import {AppClient} from './appClient';
import RequestMethod from '../models/RequestMethod';
import User, {CurrentUser, PendingUser} from '../models/User';
import Authorization from '../models/Authorization';
import {saveAuthorization} from './localStorage';
import AppResponse from "../models/httprequest/AppResponse";

const AUTHENTICATE = '/api/v1/auth/authenticate';
const REFRESH = '/api/v1/auth/refresh';
const FORGOT_PASSWORD = '/api/v1/auth/forgot';
const RESET_PASSWORD = '/api/v1/auth/reset-password';
const SSO = '/api/v1/auth/sso/';

type CurrentUserResponse = {
    currentUser: CurrentUser;
}
type UserResponse = {
    user: User;
}

type PendingUserResponse = {
    user: PendingUser;
}
type AuthorizationResponse = {
    authorization: Authorization;
}
type TokenResponse = {
    accessToken: string;
}

export type CurrentUserAndAuthorizationResponse = CurrentUserResponse & AuthorizationResponse

export interface AuthService {
    signInWithEmailAndPassword: (email: string, password: string) => AppResponse<CurrentUserAndAuthorizationResponse>;
    refreshAuthorisation: (accessToken: string | undefined, refreshToken: string | undefined) => AppResponse<AuthorizationResponse>;
    forgotPassword: (email: string) => AppResponse<TokenResponse>;
    resetPassword: (password: string, token: string) => AppResponse<undefined>;
    signInWithSSO: (ssoAccountId: string) => AppResponse<CurrentUserAndAuthorizationResponse>;
    connectWithSSO: (ssoAccountId: string, email: string, password: string) => AppResponse<CurrentUserAndAuthorizationResponse>;
}


export const authService = (client: AppClient): AuthService => ({

    async signInWithSSO(ssoAccountId): AppResponse<CurrentUserAndAuthorizationResponse> {
        const response = await client.fetchJSON<CurrentUserAndAuthorizationResponse>(RequestMethod.POST, SSO + ssoAccountId);
        if (response.success && response.value.authorization) {
            saveAuthorization(response.value.authorization, client.scope)
        }
        return response;
    },

    async connectWithSSO(ssoAccountId, email, password): AppResponse<CurrentUserAndAuthorizationResponse> {
        const response = await client.fetchJSON<CurrentUserAndAuthorizationResponse>(RequestMethod.POST, SSO + ssoAccountId + '/connect', {
            email: email,
            password: password,
        });
        if (response.success && response.value.authorization) {
            saveAuthorization(response.value.authorization, client.scope)
        }
        return response;
    },

    async signInWithEmailAndPassword(email: string, password: string): AppResponse<CurrentUserAndAuthorizationResponse> {
        const response = await client.fetchJSON<CurrentUserAndAuthorizationResponse>(RequestMethod.POST, AUTHENTICATE, {
            email: email,
            password: password,
        });
        if (response.success && response.value.authorization) {
            saveAuthorization(response.value.authorization, client.scope)
        }
        return response;
    },

    async refreshAuthorisation(accessToken: string | undefined, refreshToken: string | undefined): AppResponse<AuthorizationResponse> {
        const response = await client.fetchJSON<AuthorizationResponse>(RequestMethod.POST, REFRESH, {
            accessToken: accessToken,
            refreshToken: refreshToken,
        });
        if (response.success) {
            saveAuthorization(response.value.authorization, client.scope)
        }
        return response;
    },


    async forgotPassword(email: string): AppResponse<TokenResponse> {
        return await client.fetchJSON<TokenResponse>(RequestMethod.POST, FORGOT_PASSWORD, {
            email: email,
        });
    },

    async resetPassword(password: string, token: string): AppResponse<undefined> {
        const authorization = 'Bearer ' + token;
        console.log('resetPassword.authorization:', authorization);
        return await client.fetchJSON(RequestMethod.POST, RESET_PASSWORD, {
            password: password,
        }, {
            Authorization: authorization,
        });
    },
});


export default authService;
