import React, { useState } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, makeStyles, TextField, Button, TextFieldProps, Tooltip } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import appService from 'services/appService';
import Alert from '@material-ui/lab/Alert';
import useCurrentUser from 'hooks/useCurrentUser';


const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
    },
    maxWidth: {
        width: '100%',
    },
    capitalize: {
        textTransform: "capitalize",
    }
}))

const labels = ["forslag", "fejl", "spørgsmål", "andet"];
const sentFeedbacks = {
    "forslag": "Tak for dit forslag! Det er nu blevet sendt ind til os.",
    "fejl": "Tak for din fejlrapportering! Den er nu blevet sendt ind til os.",
    "spørgsmål": "Tak for dit spørgsmål! Det er nu blevet sendt ind til os.",
    "andet": "Tak for din henvendelse! Den er nu blevet sendt ind til os",
}

const IssueForm: React.FC = () => {
    const classes = useStyles();
    const currentUser = useCurrentUser();

    const [label, setLabel] = useState<string>(labels[0]);
    const handleSetLabel: SelectProps['onChange'] = e => setLabel(e.target.value as string);

    const [message, setMessage] = useState<string>("");
    const handleSetMessage: TextFieldProps['onChange'] = e => setMessage(e.target.value as string);

    const [sentFeedback, setSentFeedback] = useState("");
    const emptyFeedback = () => setSentFeedback("");

    const handleSend = async () => {
        await appService.support.sendEmail({
            title: "Intranet feedback",
            text: `${label}\n\n${message}\n\n${currentUser.firstName + currentUser.lastName}\n${currentUser.email}\n${currentUser.centers?.map(c => c.name).join(", ")}`,
        });
        setSentFeedback(sentFeedbacks[label]);
        setLabel(labels[0]);
        setMessage("");
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" width="100%">
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="select">Type</InputLabel>
                    <Select id="select" labelId="select" label="Type" value={label} className={classes.capitalize} onChange={handleSetLabel}>
                        {labels.map(l => <MenuItem key={l} value={l} className={classes.capitalize}>{l}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={clsx(classes.formControl, classes.maxWidth)}>
                    <TextField 
                        variant="outlined" 
                        placeholder="Skriv til os"
                        value={message}
                        onChange={handleSetMessage}
                    />
                </FormControl>
                <Tooltip title="Send din feedback">
                    <Box p={1}>
                        <Button 
                            onClick={handleSend} 
                            variant="contained"
                            color="primary"
                            disableElevation
                        >
                            <SendIcon />
                        </Button>
                    </Box>
                </Tooltip>
            </Box>
            {sentFeedback && <Alert severity="success" action={emptyFeedback}>{sentFeedback}</Alert>}
        </Box>
    )
}

export default IssueForm;
