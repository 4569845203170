import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box/Box';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {NavItem} from 'pages/dashboard/navigationMap';
import ColorDot from "../common/ColorDot";
import {matchPath} from "react-router";

const useStyles = makeStyles<Theme, boolean>((theme) => ({
    listItem: {
        '&:hover': {
            backgroundColor: (active) => active ? theme.palette.common.white : 'rgba(0, 0, 0, 0.04)',
        },
        '& .MuiTouchRipple-root': {
            color: 'rgb(255, 255, 255)',
        },
        marginBottom: '1px',
        backgroundColor: (active) => active ? 'white' : 'transparent'
    },
    icon: {
        display: 'flex',
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: (active) => active ? theme.palette.secondary.main : theme.palette.common.white,
    },
    text: {
        marginRight: 12, // Web-henvendelser fix
        fontSize: '16px',
        fontWeight: 600,
        color: (active) => active ? theme.palette.secondary.main : theme.palette.common.white,
    },
    selectedIndicator: {
        borderRight: `3px solid ${theme.palette.secondary.main}`,
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
        height: '32px',
    },
}));

// dispatch(push(path))
interface IconButtonProps {
    navItem: NavItem;
    showDot?: boolean;
    onClick?: VoidFunction;
    // brightness?: 'light' | 'dark';
}

const NavButton: React.FC<IconButtonProps> = ({navItem, onClick, showDot}) => {
    const name = navItem.name;
    const icon = navItem.icon;
    const path = navItem.path;
    const currentPath = useHistory().location.pathname;
    const active = !!matchPath(currentPath, {
        path: path,
        exact: true,
    });
    const classes = useStyles(active);
    const dispatch = useDispatch();

    const handleClick = path && !onClick ? () => dispatch(push(path)) : onClick;

    return (
        <ListItem
            className={classes.listItem}
            style={{backgroundColor: active ? 'white': undefined}}
            button
            onClick={handleClick}
        >
            <Box className={classes.icon}>
                {icon}
            </Box>
            <span className={classes.text}>
                    {name}
                </span>
            <ColorDot type={'error'} visible={showDot} tooltip={"Ulæste"}/>
        </ListItem>
    );
};

export default NavButton;
