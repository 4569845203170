import smsSideVideo from "./images/sms/SMS_side_video.png"
import smsSideIndsaetLink from "./images/sms/SMS_side_indsaet_link.png"
import registrerVideo from './images/Registrer_video.png';
export default `## Sådan opretter du en videosamtale

1. Samtalerummet for en videosamtale oprettes ved at klikke på det kamera-ikon, som du kan finde på SMS-henvendelsessiden.

2. Når du klikker på kamera-ikonet, så kopierer du automatisk det link, som den unge skal bruge for at tilgå jeres videosamtale.

![Dette er videosiden](${smsSideVideo})

2. Indsæt det kopierede videolink i feltet ”Skriv en besked…” sammen med din besked til den unge. Når dette er gjort, klikker du på knappen ”SEND”.

![Dette er videosiden](${smsSideIndsaetLink})

3. Du kan tilgå videosamtalen ved at klikke på linket. Det er op til dig og den unge at koordinere, hvornår I skal ses og tale sammen på videosamtalen.

4. Ved afslutningen af samtalen har både du og den unge mulighed for at registrere samtalen, ved at trykke på knappen ”REGISTRER SAMTALE”.

![Dette er videosiden](${registrerVideo})

`;
