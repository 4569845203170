import {HttpTransportType, HubConnection, HubConnectionBuilder, JsonHubProtocol, LogLevel} from "@microsoft/signalr";
import {HOST} from "../constants/config";
import {loadAuthState} from "./localStorage";

export const startSignalRConnection = (hubPath: string, connection: HubConnection) => connection.start()
    .then(() => console.info(hubPath + ' Connected'))
    .catch(err => {
        console.error('Connection Error: ', err)
    });

export const appSignalRClient = (hubPath: string, params: string, anonymous: boolean): HubConnection => {
    const connectionHub = `${HOST}/${hubPath}?${params}`;

    return new HubConnectionBuilder()
        .withUrl(connectionHub, {
            transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
            logMessageContent: false,
            logger: LogLevel.Error,
            accessTokenFactory: () => anonymous ? '' : loadAuthState().authorization?.accessToken ?? '',
        })
        .withHubProtocol(new JsonHubProtocol())
        .withAutomaticReconnect()
        .build();
};


