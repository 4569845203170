import React, {useState} from "react";
import EventApi from "@fullcalendar/core/api/EventApi";
import View from "@fullcalendar/core/View";
import Popover from "@material-ui/core/Popover";
import {Box} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {Edit} from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import NotesIcon from "@material-ui/icons/Notes";
import LocationOn from "@material-ui/icons/LocationOn";
import PeopleIcon from "@material-ui/icons/People";
import DeleteIcon from '@material-ui/icons/Delete';
import grey from "@material-ui/core/colors/grey";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import AppEvent, {eventFromEventAPI} from "../models/calendar/AppEvent";
import {toLocalDayDateYearString, toLocalISOTime} from "../lib/toLocalISO";
import filterOrPush from "../lib/filterOrPop";
import ParticipantDisplay from "./ParticipantDisplay";
import StyledTooltip from "./common/StyledTooltip";

const useStyles = makeStyles(() => ({
    root: {},
    title: {
        fontSize: 24,
        color: grey[900],
        paddingBottom: 8
    },
    titleIcon: {
        width: 16,
        height: 16,
        borderRadius: 5,
    },
    subtitle: {
        fontSize: 15,
        color: grey[900],
        paddingBottom: 8
    }
}));

interface EventRenderInfo {
    isMirror: boolean;
    isStart: boolean;
    isEnd: boolean;
    event: EventApi;
    el: HTMLElement;
    view: View;
}


interface EventCardProps {
    info: EventRenderInfo;
    currentUserId: string;
    onEditClick: (event: AppEvent) => void;
    onDelete: (event: AppEvent) => void;
    onUpdate: (event: AppEvent) => void;
    // onCopyClick: (event: AppEvent) => void;
}


export const EventCard: React.FC<EventCardProps> = ({info, currentUserId, onEditClick, onUpdate, onDelete}) => {
    const classes = useStyles();

    const event = eventFromEventAPI(info.event);
    const time = event.allDay ? '' : toLocalISOTime(event.start) + ' - ' + toLocalISOTime(event.end)
    const isNotMonthView = info.view.type !== "dayGridMonth";

    const [open, setOpen] = useState<boolean>(false);
    const onOpenClick = () => setOpen(true);
    const onCloseClick = () => setOpen(false);

    const eventTitleIcon = <div className={classes.titleIcon} style={{backgroundColor: event?.backgroundColor}}/>;

    const handleEditClick = () => {
        onEditClick(event);
        onCloseClick();
    }

    const handleDeleteClick = () => {
        onDelete(event);
        onCloseClick();
    }

    const handleCopyClick = () => {
        onUpdate({
            ...event,
            id: '',
        });
        onCloseClick();
    }

    const handleAddUserClick = () => {
        onUpdate({
            ...event,
            participantIds: filterOrPush(event.participantIds, currentUserId),
        });
        onCloseClick();
    }

    const typeNameLower = event.type?.name?.toLocaleLowerCase();

    // POSSIBLY MOVE TO BACKEND
    // const isCurrentUserParticipant = event.participants?.find(p => p.id === currentUserId);

    return (
        <React.Fragment>
            {/*<Tooltip title={event.description}>*/}
            <EventStatus event={event}/>
            <div className="fc-content" onClick={onOpenClick}>
                <span className="fc-title">
                    {event.title}
                </span>
                <span className="fc-time">
                    {time}
                </span>
                {isNotMonthView && event.participants?.map(p => (
                    <span key={p.id} className={"fc-participants"}>
                        {/*<div style={{width: 6, height: 6, marginRight: 2, background: 'green', borderRadius: '100%'}}/>*/}
                        {p.name}
                    </span>
                    // <span style={{borderLeft: '4px solid green', paddingLeft: 4}} key={p.id} className={"fc-participants"}>{p.name}</span>
                ))}
            </div>
            <div className="fc-resizer fc-end-resizer"/>
            {/*</Tooltip>*/}
            <Popover
                open={open}
                anchorEl={info.el}
                onClose={onCloseClick}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >

                <Box width={448} maxWidth={448} pt={2} pb={2} display={'flex'} flexDirection={'column'}>
                    <Box pr={2} display={'flex'} justifyContent={'flex-end'}>
                        <StyledTooltip title={'Rediger ' + typeNameLower}>
                            <IconButton onClick={handleEditClick}>
                                <Edit fontSize={"small"}/>
                            </IconButton>
                        </StyledTooltip>
                        <StyledTooltip title={'Slet ' + typeNameLower}>
                            <IconButton onClick={handleDeleteClick}>
                                <DeleteIcon fontSize={"small"}/>
                                {/*<EventBusyIcon fontSize={"small"}/>*/}
                            </IconButton>
                        </StyledTooltip>
                        <StyledTooltip title={'Kopier ' + typeNameLower}>
                            <IconButton onClick={handleCopyClick}>
                                <FileCopyIcon fontSize={"small"}/>
                            </IconButton>
                        </StyledTooltip>
                        <StyledTooltip title={'Tilføj eller fjern mig fra ' + typeNameLower}>
                            <IconButton onClick={handleAddUserClick}>
                                <PersonAddIcon fontSize={"small"}/>
                            </IconButton>
                        </StyledTooltip>
                        <StyledTooltip title={'Luk'}>
                            <IconButton onClick={onCloseClick}>
                                <Close fontSize={"small"}/>
                            </IconButton>
                        </StyledTooltip>
                    </Box>
                    <EventDisplayRow className={classes.title} icon={eventTitleIcon}>
                        {event.title}
                    </EventDisplayRow>
                    <EventDisplayRow className={classes.subtitle}>
                        <div>{toLocalDayDateYearString(event.start)}{event.end && " - " + toLocalDayDateYearString(event.end)}</div>
                    </EventDisplayRow>
                    <EventDisplayRow icon={<NotesIcon/>}>{event?.description}</EventDisplayRow>
                    <EventDisplayRow icon={<LocationOn/>}>{event?.location}</EventDisplayRow>

                    <EventDisplayRow
                        icon={<PeopleIcon/>}>{`Deltagere (${event.participants?.length ?? 0})`}</EventDisplayRow>
                    <EventDisplayRow>
                        {event.participants?.map(ParticipantDisplay)}
                    </EventDisplayRow>
                </Box>
            </Popover>
        </React.Fragment>
    )
}


interface EventDisplayRowProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
}

const EventDisplayRow: React.FC<EventDisplayRowProps> = ({icon, children, className}) => {
    if (!children) return <React.Fragment/>

    return (
        <Box display={'flex'} color={grey[700]} fontSize={16} alignItems={'center'} minHeight={32} pr={2}>
            <Box width={40} pl={4} maxHeight={52}>
                {icon}
            </Box>
            <div className={className}>
                {children}
            </div>
        </Box>

    )
}

export const EventStatus: React.FC<{ event: AppEvent }> = ({event}) => {

    if (!event.isCancelled && !event.isMoved && !event.isNoShow) return <div/>;

    return (
        <div style={{
            pointerEvents: 'none',
            touchAction: 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            zIndex: 10,
            marginTop: 1,
            marginLeft: 0.4,
        }}>
            <EventStatusTag event={event}/>
        </div>
    )
}

export const EventStatusTag: React.FC<{ event: AppEvent }> = ({event}) => {

    const eventStatus = [event.isCancelled && "AFLYST", event.isMoved && "FLYTTET", event.isNoShow && "AFBUD"];

    return (
        <div>
            {eventStatus.map(status => status && (
                <div style={{
                    // height: 20,
                    backgroundColor: 'red',
                    borderTopLeftRadius: 3,
                    borderBottomRightRadius: 3,
                    color: 'white',
                    fontWeight: 600,
                    padding: '0px 6px',
                    marginRight: '4px'
                }}>
                    {status}
                </div>
            ))}
        </div>
    )
}

export default EventCard;
