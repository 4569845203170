import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box/Box';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    searchIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    search: {
        fontWeight: 'normal',
        position: 'relative',
        width: '100%'
    },
}));

interface SearchInputProps {
    search: string;
    onSearchChange: (search: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({search, onSearchChange}) => {
    const classes = useStyles();
    return (
        <Box display="flex" alignItems="center">
            <SearchIcon className={classes.searchIcon}/>
            <InputBase
                placeholder="Søg …"
                inputProps={{'aria-label': 'search', style: {width: '100%'}}}
                value={search}
                fullWidth
                className={classes.search}
                onChange={(event) => onSearchChange(event.target.value)}
            />
        </Box>
    )
};

export default SearchInput