import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    ListItem,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
    error: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        // margin: theme.spacing(4, 0),
        flexDirection: 'column',
        '& svg': {
            height: theme.spacing(8),
            width: 'auto',
            color: theme.palette.error.light,
        }
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }
}));

interface LoadingOrErrorProps {
    loading: boolean;
    skeletonHeight?: number;
    skeletonCount?: number;
    error?: string;
    type?: 'table' | 'list' | 'circular' | 'card';
    children?: React.ReactNode;
}

export const LoadingOrError: React.FC<LoadingOrErrorProps> = ({loading, error, children, skeletonHeight = 64, skeletonCount = 3, type = 'table'}) => {
    const classes = useStyles();

    if (error) {
        return (
            <div className={classes.error}>
                <ErrorIcon/>
                {error}
            </div>
        )
    }

    if (loading) {
        if (type === 'table') {
            return (
                <Table>
                    <TableBody>
                        {Array.from(Array(skeletonCount)).map((_, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <Skeleton animation="wave" variant="rect" height={skeletonHeight}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        }
        if (type === 'list') {
            return (
                <div>
                    {Array.from(Array(skeletonCount)).map((_, i) => (
                        <ListItem key={i}>
                            <Skeleton animation="wave" variant="rect" height={skeletonHeight} width="100%"/>
                        </ListItem>
                    ))}
                </div>
            )
        }
        if (type === 'circular') {
            return (
                <div className={classes.flexCenter}>
                    <CircularProgress size={64}/>
                </div>
            )
        }
        if (type === 'card') {
            return (
                <>
                    {Array.from(Array(skeletonCount)).map((_, i) => (
                        <Card key={i}
                              style={{marginTop: 16}}>
                            <CardHeader
                                title={<Skeleton animation="wave" height={10} width="50%" style={{marginBottom: 6}}/>}
                                subheader={<Skeleton animation="wave" height={10} width="20%"/>}
                            />
                            <CardContent>
                                <Skeleton animation="wave" height={10} width="80%"/>
                                <Skeleton animation="wave" height={10} style={{marginTop: 8}}/>
                                <Skeleton animation="wave" height={10} width="60%" style={{marginTop: 8}}/>
                            </CardContent>
                            <Skeleton animation="wave" variant="rect" height={200}/>
                            <CardContent>
                                <Skeleton animation="wave" variant="rect" height={50} style={{borderRadius: 8}}/>
                            </CardContent>
                        </Card>
                    ))}
                </>
            )
        }
    }

    if (children) {
        return <>{children}</>;
    }

    return null;
};

export default LoadingOrError;
