import React, {RefObject} from 'react';
import clsx from 'clsx';

import FullCalendar from '@fullcalendar/react'
import daLocale from '@fullcalendar/core/locales/da';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    root: {
        '& .fc-toolbar h2': {
            fontSize: 24,
            fontWeight: 'lighter',
            textTransform: 'capitalize',
        },
        '& .fc-button-primary': {
            backgroundColor: '#1565c0',
            border: 'none',
        },
        '& .fc-button-primary:hover': {
            backgroundColor: '#003c8f',
            // border: 'none',
        },
        '& .fc-button-primary:not(:disabled).fc-button-active': {
            backgroundColor: '#003c8f',
            // border: 'none',
        },
        '& .fc-center': {
            marginLeft: 16,
            marginRight: 16,
        },
        '& .fc-event': {
            display: 'flex',
            opacity: 0.8,
        },
        '& .fc-content': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // paddingTop: 0,
            // paddingLeft: 4,
            // paddingBottom: 4,
            fontSize: 13,
            fontWeight: 400,
        },
        '& .fc-title': {
            fontSize: 14,
            fontWeight: 500,
        },
        '& .fc-time': {
            minHeight: 18,
            fontSize: 13,
            fontWeight: 400,
        },
        '& .fc-day-number': {},
        '& .fc-day-header, .fc-head-container': {
            // borderLeft: 'none',
            // borderRight: 'none',
            color: theme.palette.grey['700'],
            fontSize: 16,
            lineHeight: '2.5',
            textTransform: 'uppercase',
        },
        '& .fc-sun, .fc-sat ': {
            // width: '100px',
        },
        // TEMP FIX
        '& .fc-body .fc-row': {
            height: 'auto !important',
        }
    }
}));

export type StyledCalendarProps = FullCalendar['props'] & {
    style?: React.CSSProperties;
    className?: string;
    calendarRef?: RefObject<FullCalendar>;
}

export const StyledCalendar: React.FC<StyledCalendarProps> = ({style, className, calendarRef, ...props}) => {
    const classes = useStyles();
    return (
        <div className={clsx([classes.root, className])} style={style}>
            <FullCalendar
                ref={calendarRef}
                locale={daLocale}
                minTime={"08:00:00"}
                maxTime={"22:00:00"}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                }}
                header={false}
                {...props}
            />
        </div>
    );
};

export default StyledCalendar;