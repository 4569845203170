import React, {useEffect, useState} from "react";
import BasePage from "../BasePage";
import ThreadList from "../../components/message/ThreadList";
import ThreadCard from "../../components/message/ThreadCard";
import ThreadDisplay from "../../components/message/ThreadDisplay";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {ChatThread, emptyChat} from "../../models/chat/ChatThread";
import useCurrentUser from "../../hooks/useCurrentUser";
import SearchInput from "../../components/SearchInput";
import {Add} from "@material-ui/icons";
import {chatsActions} from "../../store/ChatsState";
import User from "../../models/User";
import searchFilter from "../../lib/searchFilter";
import {ChatMessage, emptyChatMessage} from "../../models/chat/ChatMessage";
import toIdList from "../../lib/toIdList";
import {FixedSizeList} from 'react-window'
import useMediaQueryMobile from "../../hooks/useMediaQueryMobile";
import getFullName from "../../lib/getFullName";
import PickRespondents from "./PickRespondents";
import AddOrRemovePeople from "./AddOrRemovePeople";
import Button from "@material-ui/core/Button";
import {Asset} from "../../models/file/Asset";
import ColorDot from "../../components/common/ColorDot";

const ChatPage = () => {
    const dispatch = useDispatch();
    const currentUser = useCurrentUser();
    const isMobile = useMediaQueryMobile();
    const scrollRef: React.RefObject<FixedSizeList> = React.useRef<FixedSizeList>();
    const {elements, editElement, loading} = useSelector((state: AppState) => state.chats);
    const [newChat, setNewChat] = useState<User[] | undefined>(undefined);
    const [search, setSearch] = useState<string>('');

    const filteredThreads = elements?.filter(chatThreadSearchFilter(search)) ?? [];
    const selectedThread = filteredThreads.find((thread) => thread.id === editElement?.id);

    useEffect(() => {
        if (scrollRef?.current && selectedThread) {
            scrollRef.current.scrollToItem(filteredThreads.findIndex(thread => thread === selectedThread), "center")
        }
    }, [selectedThread, filteredThreads]);

    const firstThread = filteredThreads[0] ?? undefined;
    useEffect(() => {
        dispatch(chatsActions.setSelected(isMobile ? undefined : firstThread ));
    }, [isMobile, dispatch, firstThread]);

    const handleSelectThread = (thread: ChatThread | undefined) => () => {
        if (thread?.id !== selectedThread?.id) {
            dispatch(chatsActions.setSelected(thread));
            if (!thread) return;
            if (thread.readByIds.includes(currentUser.id)) return;
            dispatch(chatsActions.updateRead(thread.id, currentUser.id))
        }
    }
    const handleNewChatClick = () => {
        setNewChat([]);
        dispatch(chatsActions.setSelected(undefined));
    };
    const handleCreateChat = async (message: ChatMessage) => {
        if (!newChat) return;
        const ids = [...new Set([...toIdList(newChat), currentUser.id])];
        const threadName = (ids.length === newChat.length ? (getFullName(currentUser) + ' ') : '') + newChat.map((user) => getFullName(user)).join(',');
        await dispatch(chatsActions.createThread({
            ...emptyChat,
            name: threadName,
            attendantIds: ids,
            messages: [message],
        }));

        setNewChat(undefined);
    };
    const handleDeleteMessage = (threadId: string, message: ChatMessage) => dispatch(chatsActions.deleteChatMessage(threadId, message));
    const handleDeleteThread = () => {
        if (selectedThread !== undefined) dispatch(chatsActions.deleteThread(selectedThread));
    }
    const handleSubmitThreadName = async (name: string) => {
        if (selectedThread === undefined) return;
        await dispatch(chatsActions.updateThreadName(selectedThread?.id, name))
    };
    const handleSendMessage = async (text: string, files?: (Asset[] | undefined)) => {
        const message: ChatMessage = {
            ...emptyChatMessage,
            content: text,
            files: files,
            author: {id: currentUser.id, firstName: currentUser.firstName, lastName: currentUser.lastName}
        };

        if (selectedThread === undefined) return await handleCreateChat(message);

        await dispatch(chatsActions.createMessage(selectedThread.id, message))
        selectedThread.messages.push(message);
    }

    return (
        <BasePage>
            <ThreadList
                selectedId={selectedThread?.id}
                elementsLength={filteredThreads.length}
                loading={loading}
                listItem={({index,style}) => {
                    const thread = filteredThreads[index];
                    const secondary = secondaryMessage(currentUser.id, thread);
                    return (
                        <ThreadCard
                            style={style}
                            id={thread.id}
                            primary={thread.name}
                            secondary={secondary}
                            isSelected={thread.id === selectedThread?.id}
                            updatedAt={thread.updatedAt}
                            onSelect={handleSelectThread(thread)}
                            indicators={<ColorDot type={"error"} visible={!thread.readByIds.includes(currentUser.id)} tooltip={"Ulæst"}/>}
                        />
                    )
                }}
            >
                <SearchInput search={search} onSearchChange={setSearch} />
                <Button variant='contained' color='primary' onClick={handleNewChatClick}>
                    Opret <Add fontSize="small"/>
                </Button>
            </ThreadList>
            <ThreadDisplay
                selectedId={selectedThread?.id}
                title={selectedThread?.name}
                messages={selectedThread?.messages ?? []}
                onBack={handleSelectThread(undefined)}
                onTitleChange={handleSubmitThreadName}
                onSendMessage={handleSendMessage}
                onThreadDelete={handleDeleteThread}
                emptyHeader={<PickRespondents chat={newChat} onChange={setNewChat} />}
                endHeader={<AddOrRemovePeople thread={selectedThread} />}
                identifyMe={(messageAuthorId => messageAuthorId === currentUser.id)}
                onDeleteMessage={handleDeleteMessage}
                withEmoji
                withAssets
            />
        </BasePage>
    )
};

const secondaryMessage = (userId: string, thread: ChatThread) => {
    if (thread.messages.length === 0) return 'ingen beskeder';
    const lastMessage = thread.messages[thread.messages.length - 1];
    const me = lastMessage.author.id === userId;
    return (me ? 'Du' : lastMessage.author.firstName) + ': ' + lastMessage.content;
};

const chatThreadSearchFilter = (search: string) => ({name, messages, updatedAt}: ChatThread) => searchFilter({
    name: name,
    messages: messages.map(m => m.content).join(),
    updatedAt: updatedAt.toString(),
}, search);

export default ChatPage;
