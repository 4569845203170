import React from 'react';
import Box from '@material-ui/core/Box/Box';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import PostView from "./PostView";
import Link from "@material-ui/core/Link";
import SchoolIcon from '@material-ui/icons/School';

const HomePage: React.FC = () => {
    const currentUser = useCurrentUser();

    return (
        <Box display="flex" justifyContent="space-between" flexGrow={1} p={2} marginTop={-2}>
            <Grid container spacing={3} justify="space-between" wrap='wrap-reverse'>
                <Grid item xs={12} sm={12} md={8}>
                    <Typography variant={'h4'} style={{fontWeight: 600, color: "black"}}>
                        Hej {currentUser.firstName}
                    </Typography>
                    <div style={{width: 24, height: 4, marginTop: 12, marginBottom: 24, backgroundColor: "black"}}/>
                    <PostView/>
                </Grid>

                <Grid item xs={12} sm={false} md={4}>
                    <Paper>
                        <Card>
                            <CardHeader
                                title={"Genveje"}
                            />
                            <Divider/>
                            <CardContent>
                                <List>
                                    <Link href={'https://headspace.portal-agylia.com/#/access/sso/bypass'}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{color: 'black', textDecoration: 'none'}}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>
                                                <SchoolIcon color='secondary'/>
                                            </ListItemIcon>
                                            <ListItemText primary='HS Academy'/>
                                        </ListItem>
                                    </Link>
                                    {/*<Link href="headspace-remote-desktop:"*/}
                                    {/*      style={{color: 'black', textDecoration: 'none'}}>*/}
                                    {/*    <ListItem button>*/}
                                    {/*        <ListItemIcon>*/}
                                    {/*            <DesktopWindowsIcon color='secondary'/>*/}
                                    {/*        </ListItemIcon>*/}
                                    {/*        <ListItemText primary='Fjernskrivebord'/>*/}
                                    {/*    </ListItem>*/}
                                    {/*</Link>*/}
                                </List>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HomePage;
