import React from 'react';
import {Field, Form, Formik} from 'formik';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import FormButton from '../../components/buttons/FormButton';
import Validators from '../../services/Validators';
import {ConfirmedUser, emptyUser} from '../../models/User';
import capitalize from '@material-ui/core/utils/capitalize';
import {FormikCallback} from '../../models/Callbacks';
import {
    BirthDateFormField,
    EducationLevelFormField,
    EducationSubjectFormField,
    SexFormField,
    WorkStatusFormField
} from 'components/form_fields';

const useStyles = makeStyles(() => ({
    checkThis: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& p': {
            marginLeft: 24,
            '& a': {
                color: 'blue'
            }
        },
    },
    submit: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

interface RegisterFormProps {
    onSavePress: FormikCallback<ConfirmedUser & { password: string; passwordRepeat: string }>;
    firstName?: string;
    lastName?: string;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({onSavePress, firstName = '', lastName = ''}) => {
    const classes = useStyles();

    return (
        <Formik<ConfirmedUser & { password: string; passwordRepeat: string }>
            onSubmit={onSavePress}
            initialValues={{
                ...emptyUser,
                firstName: capitalize(firstName ?? ''),
                lastName: capitalize(lastName ?? ''),
                password: '',
                passwordRepeat: '',
            }}
        >
            {({values, errors, touched, isSubmitting, setFieldValue}) => {
                const {phoneNumber, educationLevel, password, passwordRepeat} = values;
                return (
                    <Form>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    validate={Validators.name}
                                    error={errors.firstName && touched.firstName && values.firstName.length !== 0}
                                    helperText={values.firstName.length !== 0 && errors.firstName}
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="Fornavn"
                                    name="firstName"
                                    type="text"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    validate={Validators.name}
                                    error={errors.lastName && touched.lastName && values.lastName.length !== 0}
                                    helperText={values.lastName.length !== 0 && errors.lastName}
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Efternavn"
                                    name="lastName"
                                    type="text"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    validate={Validators.password}
                                    error={errors.password && touched.password && values.password.length !== 0}
                                    helperText={values.password.length !== 0 && errors.password}
                                    required
                                    fullWidth
                                    id="password"
                                    label="Adgangskode"
                                    name="password"
                                    type="password"
                                    autoComplete='new-password'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    validate={() => Validators.passwordRepeat(password, passwordRepeat)}
                                    error={errors.passwordRepeat && touched.passwordRepeat && values.passwordRepeat.length !== 0}
                                    helperText={values.passwordRepeat.length !== 0 && errors.passwordRepeat}
                                    required
                                    fullWidth
                                    id="passwordRepeat"
                                    label="Gentag adgangskode"
                                    name="passwordRepeat"
                                    type="password"
                                    autoComplete='new-password'
                                />
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <SexFormField id="sex" error={errors.sex}/>
                            </Grid>

                            <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <BirthDateFormField id="birthDate" required={true} setFieldValue={setFieldValue}/>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <WorkStatusFormField id="workStatus" error={errors.workStatus}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Field
                                    as={TextField}
                                    validate={() => Validators.phoneNumber(phoneNumber)}
                                    error={errors.phoneNumber && touched.phoneNumber && values.phoneNumber.length !== 0}
                                    helperText={values.phoneNumber.length !== 0 && errors.phoneNumber}
                                    fullWidth
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Telefonnummer"
                                    type="phone"
                                    autoComplete='phone'
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <EducationLevelFormField
                                    id="educationLevel"
                                    error={errors.educationLevel}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <EducationSubjectFormField
                                    id="educationSubject"
                                    error={errors.educationSubject}
                                    required={true}
                                    educationLevel={educationLevel}
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkThis}>
                                <Typography component="p" variant="body1">
                                    {'Ved indsendelse af oplysningerne godkender jeg Det Sociale Netværk/headspace Danmarks '}
                                    <a href="https://www.psykisksaarbar.dk/privatlivspolitik" target="_blank"
                                       rel="noopener noreferrer">
                                        privatlivspolitik
                                    </a>{' *'}
                                </Typography>
                                <Field
                                    as={Checkbox}
                                    required
                                    name="policy"
                                    id="policy"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.submit}>
                            <FormButton fullWidth={false} isSubmitting={isSubmitting}>
                                Indsend oplysninger
                            </FormButton>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};


export default RegisterForm;
