import {Redirect, Route} from 'react-router';
import React from 'react';
import Routes from '../constants/Routes';
import {checkPermission} from '../models/User';
import {useCurrentUser} from '../hooks/useCurrentUser';

const PermissionRoute = ({component: Component, permission, ...rest}) => {
    const currentUser = useCurrentUser();
    const permitted = checkPermission(currentUser, permission);
    return <Route {...rest}>{permitted ? <Component/> : <Redirect to={Routes.dashboard}/>}</Route>;
}

export default PermissionRoute;
