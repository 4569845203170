import RequestMethod from '../models/RequestMethod';
import {HOST} from '../constants/config';
import debugPrint from '../lib/debugPrint';
import {clearAuthorization, loadAuthorization} from './localStorage';
import isPastUTC from '../lib/isPastUTC';
import Authorization, {emptyAuthorization} from '../models/Authorization';
import appService from "./appService";
import {AppResponse, responseFromError} from "../models/httprequest/AppResponse";

export interface AppClient {
    readonly scope: string | undefined;
    fetchAuthorization: () => Promise<Authorization>;
    fetch: <T>(method: RequestMethod, path: string, body?: BodyInit, headers?: { [p: string]: string }) => AppResponse<T>;
    fetchJSON: <T>(method: RequestMethod, path: string, body?: { [p: string]: string | unknown }, headers?: { [p: string]: string }) => AppResponse<T>;
    fetchForm: <T>(method: RequestMethod, path: string, body?: FormData, headers?: { [p: string]: string }) => AppResponse<T>;
}

export function appClientWithScope(scope?: string): AppClient {
    return {
        scope: scope,
        async fetchAuthorization(): Promise<Authorization> {
            const authorization = loadAuthorization(scope);
            if (authorization && isPastUTC(authorization?.expiredAt)) {
                clearAuthorization(scope);
                const response = await appService.auth.refreshAuthorisation(authorization?.accessToken, authorization?.refreshToken);
                console.log("refreshResponse:", response);
                if (response.success && response.value.authorization) {
                    return response.value.authorization;
                }
            }
            return authorization ?? emptyAuthorization;
        },

        async fetchForm<T>(method: RequestMethod, path: string, body?: FormData, headers: { [p: string]: string } = {}): AppResponse<T> {
            return this.fetch(method, path, body, headers)
        },

        async fetchJSON<T>(method: RequestMethod, path: string, body?: { [p: string]: string | unknown }, headers: { [p: string]: string } = {}): AppResponse<T> {
            return this.fetch(method, path, JSON.stringify(body), {
                'Content-Type': 'application/json',
                ...headers,
            })
        },

        async fetch<T>(method: RequestMethod, path: string, body?: BodyInit, headers: { [p: string]: string } = {}): AppResponse<T> {
            const url = HOST + path;
            const methodName = RequestMethod[method];

            const authorization = await this.fetchAuthorization();
            const authorizationHeader = 'Bearer ' + authorization?.accessToken;
            const response: Response | void = await fetch(url, {
                method: methodName,
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                headers: {
                    'Authorization': authorizationHeader,
                    // "Accept": "*/*",
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Connection': 'keep-alive',
                    ...headers,
                },
                body,
            }).catch((error) => {
                console.error('AppClient.fetch', error);
            });
            if (response === undefined) {
                debugPrint(methodName, url, '\nheaders:', headers, '\nbody:', body, '\nstatus:', 408);
                return responseFromError(408);
            }

            const responseBody = await response.json().catch((error) => {
                debugPrint('ERROR parsing json body\n' +
                    'url:', url, '\nerror:', error
                );
            });

            debugPrint(methodName, url, '\n', body, '\n', response.status, response.statusText, '\n', responseBody);

            if (!response.ok) {
                return responseFromError(response.status, responseBody);
            }

            return {
                success: true,
                status: response.status,
                statusText: response.statusText,
                feedback: {severity: 'success', message: ''},
                value: responseBody as T,
            }
        },
    }
}


export const appClient = appClientWithScope();
export default appClient;
