import React from "react";
import AppEvent from "../../models/calendar/AppEvent";
import {EventStatus} from "../../components/EventCard";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {Box} from "@material-ui/core";
import {toLocalISOTime} from "../../lib/toLocalISO";
import {LocationOn} from "@material-ui/icons";
import NotesIcon from "@material-ui/icons/Notes";
import ParticipantDisplay from "../../components/ParticipantDisplay";
import EventDisplayRow from "./EventDisplayRow";
import Identifiable from "../../models/Identifiable";

type Event = AppEvent & Identifiable;
interface RenderedEventProps {
    event: Event;
    onToggleUserClick: (event: Event) => void;
    onEventClick: (event: Event) => void | undefined;
}

const RenderedEvent: React.FC<RenderedEventProps> = ({ event, onEventClick, onToggleUserClick }) => {
    const handleToggleUserClick = () => onToggleUserClick(event);
    const handleEventClick = () => onEventClick && onEventClick(event);

    return (
        <React.Fragment>
            <EventStatus event={event}/>
            <div className="fc-content" style={{cursor: onEventClick !== undefined ? 'pointer' : 'default'}}>
                <div style={{position: 'absolute', right: 0, cursor: onToggleUserClick !== undefined ? 'pointer' : 'default'}} onClick={handleToggleUserClick}>
                    <IconButton size={"small"}>
                        <PersonAddIcon style={{color: 'white'}}/>
                    </IconButton>
                </div>

                <Box display={'flex'} flexDirection={'column'} style={{minHeight: 40}}
                     onClick={handleEventClick}>
                    <span className="fc-title" style={{fontSize: 16}}>
                        {event.title}
                    </span>
                    {!event.allDay && <span className="fc-title" style={{fontSize: 16}}>
                        {toLocalISOTime(event.start) + ' - ' + toLocalISOTime(event.end)}
                    </span>}
                    <EventDisplayRow icon={<LocationOn/>}>{event?.location}</EventDisplayRow>
                    <EventDisplayRow icon={<NotesIcon/>}>{event?.description}</EventDisplayRow>
                    <span style={{fontSize: 14}}>
                        {event.participants?.map(ParticipantDisplay)}
                    </span>
                </Box>
            </div>
        </React.Fragment>
    );
};

export default RenderedEvent;
