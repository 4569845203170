import React, {useEffect, useRef, useState} from "react";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {IdentifiableMessage} from "models/Identifiable";
import AssetLightBoxWrapper from "../assets/AssetLightBoxWrapper";

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        position: 'relative',
        overflowY: 'scroll',
    },
}));

export interface MessagesDisplayProps<M extends IdentifiableMessage> {
    messages: M[];
    renderMessage: (message: M) => React.ReactNode;
}

const MessagesDisplay = <M extends IdentifiableMessage>
    ({
         messages,
         renderMessage,
     }: MessagesDisplayProps<M>) => {

        const classes = useStyles();
        const messagesEndRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
        const [lastMessageLength, setLastMessageLength] = useState<number>(0);

        const scrollToBottom = () => {
            if (lastMessageLength === messages.length && lastMessageLength !== 0) return;
            messagesEndRef?.current?.scrollIntoView({behavior: 'auto'});
            setLastMessageLength(messages.length);
        };

        useEffect(scrollToBottom, [messages, messages.length]);

        return (
            <Box className={classes.root}>
                <AssetLightBoxWrapper>
                    {messages.map(message => renderMessage(message))}
                    <div ref={messagesEndRef}/>
                </AssetLightBoxWrapper>
            </Box>
        )
    };

export default MessagesDisplay;