export interface Role {
    id?: string;
    name: string;
    description: string;
    claims: string[];
}


export const emptyRole: Role = {
    name: '',
    description: '',
    claims: [],
};

export const roleHasPermission = (role: Role, permission?: string): boolean => {
    return role.claims?.findIndex((p) => p === permission) !== -1;
};

export default Role;
