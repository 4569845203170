import React, {useState} from 'react';
import RequestFeedback from "../models/httprequest/RequestFeedback";

export interface RequestFeedbackState {
    loading: boolean;
    feedback: RequestFeedback | undefined;
}

export const useRequestFeedbackState: () => [
    RequestFeedbackState,
    React.Dispatch<React.SetStateAction<RequestFeedbackState>>,
    (errorMessage: string) => void,
    (loading: boolean) => void
] = () => {
    const [requestState, setRequestState]: [RequestFeedbackState, React.Dispatch<React.SetStateAction<RequestFeedbackState>>] = useState<RequestFeedbackState>({
        loading: false,
        feedback: undefined,
    });
    const setErrorMessage = (errorMessage: string): void => {
        setRequestState({
            loading: false,
            feedback: {severity: 'error', message: errorMessage},
        });
    };
    const setLoading = (loading: boolean): void => {
        setRequestState({
            loading: loading,
            feedback: undefined,
        });
    };
    return [requestState, setRequestState, setErrorMessage, setLoading];
};

export default useRequestFeedbackState;
