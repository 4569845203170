import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PersonAvatar from 'components/PersonAvatar';
import {ChatThread} from 'models/chat/ChatThread';
import {useDispatch} from 'react-redux';
import User from 'models/User';
import {chatsActions} from 'store/ChatsState';
import useMediaQueryMobile from 'hooks/useMediaQueryMobile';

interface ManageChatDialogProps {
    open: boolean;
    selectedThread: ChatThread | undefined;
    users: User[];
    onClose: () => void;
}

const ManageChatDialog: React.FC<ManageChatDialogProps> = ({open, selectedThread, users, onClose}) => {
    const theme = useTheme();
    const isMobile = useMediaQueryMobile();
    const dispatch = useDispatch();

    const [attendants, setAttendants] = React.useState<string[]>(selectedThread?.attendantIds ?? []);

    const handleSubmitAttendents = async () => {
        if (selectedThread === undefined) return;
        await dispatch(chatsActions.updateAttendents(selectedThread?.id, attendants));
        onClose();
    };

    const isMember = (user: User) => {
        if (selectedThread) {
            return attendants.includes(user?.id ?? "");
        }
        return false;
    };

    const removeAttendantId = (userId: string | undefined) => () => {
        if (selectedThread && userId) {
            const newList = attendants.filter(id => id !== userId);
            setAttendants(newList);
        }
    };

    const addAttendantId = (userId: string | undefined) => () => {
        if (selectedThread && userId) {
            setAttendants([...attendants, userId]);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={isMobile}
        >
            <DialogTitle id="alert-dialog-title" style={{padding: '0px'}}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="flex-start">
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Annuller
                            </Button>
                        </DialogActions>
                    </Box>
                    <Box textAlign="center" maxWidth="50%">
                        Tilføj/fjern personer
                    </Box>
                    <Box display="flex" alignItems="flex-end">
                        <DialogActions>
                            <Button onClick={handleSubmitAttendents} color="primary">
                                Gem
                            </Button>
                        </DialogActions>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                {users.map(user => {
                    return (
                        <React.Fragment key={user.id}>
                            <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <PersonAvatar name={user.firstName} color={user.color}/>
                                    {user.firstName + " " + user.lastName}
                                </Box>
                                <Box alignItems="flex-end">
                                    {isMember(user) ? (
                                        <Button onClick={removeAttendantId(user.id)}>
                                            <RemoveIcon style={{
                                                marginRight: '4px',
                                                color: theme.palette.error.main
                                            }}/>
                                        </Button>
                                    ) : (
                                        <Button onClick={addAttendantId(user.id)}>
                                            <AddIcon style={{
                                                marginRight: '4px',
                                                color: theme.palette.success.main
                                            }}/>
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </React.Fragment>
                    );
                })}
            </DialogContent>
        </Dialog>
    )
}

export default ManageChatDialog;
