import React from "react";
import Box from "@material-ui/core/Box/Box";
import {IconButton} from "@material-ui/core";
import {toLocalDateTimeString} from "../../lib/toLocalISO";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import StyledTooltip from "components/common/StyledTooltip";
import useCurrentUser from "hooks/useCurrentUser";
import PostCommentModel from "models/PostComment";

const useStyles = makeStyles(({spacing, palette}) => ({
    comment: {
        margin: spacing(0, 0, 1, 0),
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        padding: spacing(1, 2, 1, 2),
        borderRadius: 7,
        backgroundColor: "rgb(240, 242, 245)",
        flex: 1,
    },
    commentAuthor: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 2
    },
    commentDate: {
        fontSize: 12,
        marginLeft: spacing(2),
        color: palette.grey.A700
    },
}));

interface PostCommentProps {
    postId: string;
    comment: PostCommentModel;
    onDeleteClick: (postId: string, commentId: string) => void;
}

export const PostComment: React.FC<PostCommentProps> = ({postId, comment, onDeleteClick}) => {
    const classes = useStyles();
    const currentUser = useCurrentUser();
    return (
        <Box className={classes.comment}>
            <div key={comment.id} className={classes.content}>
                <Box display="flex" flexDirection="column" flex={1}>
                <span
                    className={classes.commentAuthor}>{comment.author.name}</span>
                    <span>{comment.content}</span>
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {comment.author.id === currentUser.id && (
                        <StyledTooltip title="Slet kommentar">
                            <IconButton onClick={() => onDeleteClick(postId, comment.id)}>
                                <DeleteIcon fontSize="small"/>
                            </IconButton>
                        </StyledTooltip>
                    )}
                </Box>
            </div>
            <span className={classes.commentDate}>{toLocalDateTimeString(comment.updatedAt)}</span>
        </Box>
    )
};

export default PostComment;
