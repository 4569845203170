import React from 'react';
import Validators from '../../services/Validators';
import RequestForm from '../../components/RequestForm';
import {useSelector} from 'react-redux';
import qs from 'qs';
import BackToLoginButton from '../../components/buttons/BackToLoginButton';
import {AppState} from '../../store';
import appService from "../../services/appService";
import RequestFeedback from "../../models/httprequest/RequestFeedback";

export const ResetPasswordForm: React.FC = () => {
    const location = useSelector((state: AppState) => state?.router?.location);
    const query = qs.parse(location?.search ?? '', {ignoreQueryPrefix: true}) as { [key: string]: string };
    const token = query.token;

    const onUpdatePress = async (values): Promise<RequestFeedback> => {
        const response = await appService.auth.resetPassword(values.password, token);
        if (response.success) {
            return {
                severity: 'success',
                message: 'Din adgangskode er nu opdateret',
            };
        }
        return response.feedback;
    };

    return (
        <React.Fragment>
            <RequestForm
                request={onUpdatePress}
                validate={(values) => Validators.passwordRepeat(values.password, values.passwordRepeat)}
                buttonText={'Opdater adgangskode'}
                fields={[
                    {
                        id: 'password',
                        label: 'Nyt adganskode',
                        validator: Validators.password,
                        type: 'password',
                    },
                    {
                        id: 'passwordRepeat',
                        label: 'Gentag adganskode',
                        validator: Validators.password,
                        type: 'password',
                    },
                ]}
            />
            <BackToLoginButton/>
        </React.Fragment>
    );
};

export default ResetPasswordForm;
