import React from "react";
import {Box} from "@material-ui/core";

interface EventDisplayRowProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
}

const EventDisplayRow: React.FC<EventDisplayRowProps> = ({icon, children, className}) => {
    if (!children) return <React.Fragment/>

    return (
        <Box display={'flex'} color={'white'} fontSize={14} alignItems={'center'} minHeight={32} pr={2}>
            <Box width={30} maxHeight={52}>
                {icon}
            </Box>
            <div className={className} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                {children}
            </div>
        </Box>

    )
}

export default EventDisplayRow;
