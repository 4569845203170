import {ChatAuthor, emptyChatAuthor} from "./ChatAuthor";
import {Asset} from "../file/Asset";

export interface ChatMessage {
    id: string;
    content: string;
    author: ChatAuthor;
    files?: Asset[];
    createdAt: Date;
    updatedAt: Date;
}

export const emptyChatMessage: ChatMessage = {
    id: '',
    content: '',
    author: emptyChatAuthor,
    files: [],
    createdAt: new Date(),
    updatedAt: new Date(),
};
