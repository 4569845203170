import User from "../../models/User";
import React, {Dispatch, SetStateAction, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import getFullName from "../../lib/getFullName";
import {Box, Chip, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

interface PickRespondentsProps {
    chat: User[] | undefined;
    onChange: Dispatch<SetStateAction<User[] | undefined>>;
}

const PickRespondents: React.FC<PickRespondentsProps> = ({chat, onChange}) => {
    const users = useSelector((state: AppState) => state.users.elements);
    const [input, setInput] = useState<string>('');

    const renderOptionLabel = (user: User) => `${getFullName(user)} (${user.centers?.map(c => c.name)?.join(', ')})`;

    return (
        <Box width='100%'>
            <Autocomplete<User>
                multiple
                fullWidth
                filterSelectedOptions
                value={chat}
                onChange={(event, newValue) => onChange(newValue)}
                inputValue={input}
                onInputChange={(event, newInputValue) => setInput(newInputValue)}
                options={users}
                getOptionLabel={renderOptionLabel}
                style={{minWidth: '100%'}}
                noOptionsText="Ingen muligheder"
                renderTags={(value, getTagProps) =>
                    value.map((option, index: number) => (
                        <Chip key={index} variant="outlined"
                              label={`${option.firstName} ${option.lastName}`} {...getTagProps({index})} />
                    ))
                }
                renderInput={(params) =>
                    <TextField
                        {...params}
                        fullWidth
                        placeholder="Skriv til..."
                    />
                }
            />
        </Box>
    )
}

export default PickRespondents;
