import React, {useState} from "react";
import Input, {InputProps} from "@material-ui/core/Input/Input";
import Box from "@material-ui/core/Box/Box";

export interface MessageInputProps {
    value: string;
    onSubmit: (text: string) => void;
    onChange: (text: string) => void;
    placeholder?: string;
    InputProps?: Partial<InputProps>;
}

const MessageInput: React.FC<MessageInputProps> = ({
                                                       value = "",
                                                       onSubmit,
                                                       onChange,
                                                       InputProps,
                                                       placeholder = "Ny linje: SHIFT + ENTER",

                                                   }) => {

    const [shift, setShift] = useState(false);
    const [ctrl, setCtrl] = useState(false);

    const handleKeyDown: InputProps['onKeyDown'] = (e) => {
        if (e.key === 'Enter' && !shift && !ctrl) {
            e.preventDefault();
            if (value.trim().length === 0) {
                onChange("");
                return;
            }
            onSubmit(value);
            onChange("");
        }
        if (e.key === 'Shift') {
            setShift(true);
        } else if (e.key === 'Control') {
            setCtrl(true);
        }
    };

    const handleKeyUp: InputProps['onKeyUp'] = (e) => {
        if (e.key === 'Shift') {
            setShift(false);
        } else if (e.key === 'Control') {
            setCtrl(false);
        }
    };

    const handleChange: InputProps['onChange'] = e => onChange(e.target.value);

    return (
        <Box flex={1}>
            <Input
                value={value}
                fullWidth
                multiline
                rowsMax="4"
                placeholder={placeholder}
                name="content"
                type="text"
                id="content"
                autoComplete="text"
                autoFocus
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                {...InputProps}
            />
        </Box>
    );

};

export default MessageInput;