import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useEffect} from "react";
import {useCurrentUser} from "../hooks/useCurrentUser";
import {toIdValueMap} from "../lib/toIdValueMap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store";
import {authActions} from "../store/AuthState";
import Center from "../models/Center";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import eventsActions from "../store/EventsState";
import postsActions from "store/PostsState";
import {addWeeks, subWeeks} from "date-fns";
import useMediaQueryMobile from "../hooks/useMediaQueryMobile";
import {inquiryActions} from "../store/InquiriesState";

const useStyles = makeStyles((theme) => ({
    selecter: {
        '& > div': {
            paddingLeft: 0,
            color: "white",
            border: "none",
            fontWeight: 600,
            fontSize: 16,
        },
        '& > svg': {
            color: "white",
        },
        '& > fieldset': {
            border: "none"
        }
    },
    center: {
        color: "white",
        fontWeight: "bold",
        fontSize: 16,
        padding: theme.spacing(3, 3, 2, 0),
        letterSpacing: "0.00938em",
    }
}))


const CenterSelector: React.FC = () => {
    const currentUser = useCurrentUser();
    const dispatch = useDispatch();
    const classes = useStyles();
    const isMobile = useMediaQueryMobile();

    const centerIdValueMap = toIdValueMap(currentUser.centers.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }));

    const center = useSelector((state: AppState) => state.auth.center) as Center;
    const renderValue = (centerId) => {
        return centerIdValueMap[centerId as string]?.name ?? '';
    }
    const handleChange = (event) => {
        dispatch(authActions.updateCenter(centerIdValueMap[event.target.value]));
    };

    useEffect(() => {
        if (!center.id) return;

        const now = new Date();
        const start = subWeeks(new Date(now.getFullYear(), now.getMonth(), 0, 0, 0, 0), 1)
        const end = addWeeks(start, 7)
        dispatch(eventsActions.getAllByCenterIdForRange(center.id, start, end));
        dispatch(inquiryActions.connect());
        dispatch(postsActions.getAllByCenterId(center.id));
    }, [isMobile, dispatch, center.id]);

    if (currentUser.centers.length === 1) {
        return (
            <Box className={classes.center}>
                {currentUser.centers[0].name}
            </Box>
        )
    }

    return (
        <Box>
            <FormControl variant="outlined">
                {/*<InputLabel id="center-select-label">Center</InputLabel>*/}
                <Select
                    id="center-select"
                    className={classes.selecter}
                    // label="Center"
                    // labelId="center-select-label"
                    value={center.id}
                    renderValue={renderValue}
                    onChange={handleChange}
                >
                    <MenuItem disabled><em>Center</em></MenuItem>
                    {currentUser.centers.map(center => (
                        <MenuItem key={center.id} value={center.id}>{center.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>

    )
}

export default CenterSelector;