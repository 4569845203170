import {AppClient, appClientWithScope} from './appClient';
import authService, {AuthService} from './authService';
import {UserListService, userListService} from './userService';
import restService, {RestServiceType} from './restService';
import Role from '../models/Role';
import {meService, MeService} from './meService';
import Center from '../models/Center';
import {EventType} from "../models/calendar";
import {supportService, SupportService} from "./supportService";
import videoService, {VideoService} from "./videoService";
import EventService, {eventService} from "./eventService";
import {postService, PostService} from './postService';
import FileService, {fileService} from "./fileService";

export interface AppService {
    readonly client: AppClient;
    readonly auth: AuthService;
    readonly users: UserListService;
    readonly centers: RestServiceType<Center>;
    readonly roles: RestServiceType<Role>;
    readonly posts: PostService;
    readonly events: EventService;
    readonly eventTypes: RestServiceType<EventType>;
    readonly me: MeService;
    readonly video: VideoService;
    readonly files: FileService;
    readonly support: SupportService;
}


export function appServiceWithScope(scope?: string): AppService {
    const client = appClientWithScope(scope);
    return {
        client,
        auth: authService(client),
        users: userListService(client),
        centers: restService<Center>(client, '/api/v1/centers'),
        roles: restService<Role>(client, '/api/v1/roles'),
        me: meService(client),
        posts: postService(client),
        events: eventService(client),
        eventTypes: restService<EventType>(client, '/api/v1/event-types'),
        video: videoService(client),
        files: fileService(client),
        support: supportService(client),
    }
}

export const appService = appServiceWithScope();
export default appService;