import React from "react";
import TextField, {TextFieldProps} from "@material-ui/core/TextField/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {KeyboardDatePicker, KeyboardDatePickerProps, KeyboardTimePicker} from "@material-ui/pickers";
import {KeyboardTimePickerProps} from "@material-ui/pickers/TimePicker/TimePicker";

export const useFilledTextFieldStyle = makeStyles(({spacing}) => ({
    textField: {
        margin: 0,
        minWidth: 124,
        borderRadius: spacing(0.5),
        '& .MuiFilledInput-root, .MuiInput-root': {
            borderRadius: spacing(0.5),
            backgroundColor: "#E8E8E8",
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        '& .MuiAutocomplete-inputRoot': {
            paddingTop: 0,
        },
        '& .MuiFilledInput-underline:before, .MuiFilledInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:after': {
            borderBottom: 'none'
        },
        '& .MuiSelect-root': {
            padding: spacing(1,2),
        },
        '& input': {
            padding: spacing(1, 2),
        },
        '& input[type=number]': {
            paddingLeft: 0,
            textAlign: 'right',
        },
    },

}));

export const FilledTextField: React.FC<TextFieldProps> = ({...rest}) => {
    const classes = useFilledTextFieldStyle();
    return (
        <TextField
            className={classes.textField}
            variant={'filled'}
            fullWidth
            {...rest}
        />
    )
}

export const FilledDateField: React.FC<KeyboardDatePickerProps> = (props) => {
    const classes = useFilledTextFieldStyle();
    return (
        <KeyboardDatePicker
            className={classes.textField}
            required
            margin="normal"
            KeyboardButtonProps={{
                'aria-label': 'skift tidspunkt',
            }}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            invalidDateMessage="Ugyldigt datoformat"
            {...props}
        />
    )
}

export const FilledTimeField: React.FC<KeyboardTimePickerProps> = ({onChange, value, ...rest}) => {
    const classes = useFilledTextFieldStyle();
    return (
        <KeyboardTimePicker
            className={classes.textField}
            required
            margin="normal"
            ampm={false}
            KeyboardButtonProps={{
                'aria-label': 'skift tidspunkt',
            }}
            invalidDateMessage="Ugyldigt tidsformat"
            value={value}
            onChange={onChange}
            {...rest}
        />
    )
}
