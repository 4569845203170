import React from 'react';
import {Field} from 'formik';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import daLocale from 'date-fns/locale/da';

interface BirthDateFormFieldProps {
    id: string;
    required?: boolean;
    style?: React.CSSProperties;
    error?: string | undefined;
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
}

export const BirthDateFormField: React.FC<BirthDateFormFieldProps> = ({id, required, style, error, setFieldValue}) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
            <Field
                as={KeyboardDatePicker}
                format="dd / MM / yyyy"
                mask="__ / __ / ____"
                variant="inline"
                error={error}
                required={required}
                fullWidth
                id={id}
                label="Fødselsdag"
                name={id}
                autoComplete={id}
                invalidDateMessage={<span>Ugyldig dato</span>}
                onChange={(date) => {
                    console.log(date);
                    setFieldValue(id, date, false)
                }}
                style={style}
            />
        </MuiPickersUtilsProvider>
    );
}

export default BirthDateFormField;
