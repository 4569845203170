import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {green} from "@material-ui/core/colors";
import PersonIcon from "@material-ui/icons/Person";
import {Theme} from "@material-ui/core/styles";


const useStyles = makeStyles<Theme, {color: string | undefined}>(({spacing, palette}) => ({
    avatar: {
        width: spacing(6),
        height: spacing(6),
        fontSize: 16,
        fontWeight: 'bold',
        margin: spacing(1, 1, 1, 1),
        backgroundBlendMode: 'color',
        backgroundColor: ({color}) => color ?? palette.secondary.main,
    }
}));

interface PersonAvatarProps {
    name?: string;
    color?: string;
}

const PersonAvatar: React.FC<PersonAvatarProps> = ({name, color}) => {
    const classes = useStyles({color});

    if (!name) return (
        <Avatar className={classes.avatar}>
            <PersonIcon/>
        </Avatar>
    )

    const capitalLetters = name.replace(/[^A-Z]/g, '');
    const initials = capitalLetters.length <= 2 ? name.slice(0, 2).toUpperCase() : capitalLetters.slice(0, 2);
    return (
        <Avatar alt={name} className={classes.avatar} style={{backgroundColor: color ?? green[600]}}>
            {initials}
        </Avatar>
    );
};

export default PersonAvatar;
