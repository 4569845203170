import React, {Dispatch, SetStateAction, useState} from "react";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import grey from "@material-ui/core/colors/grey";
import {toLocalDayDateYearString} from "../../lib/toLocalISO";
import PopoverDatePicker from "./PopoverDatePicker";


const initialStart = new Date();
initialStart.setDate(1)
initialStart.setHours(0, 0, 0);

const initialEnd = new Date();
initialEnd.setMonth(initialEnd.getMonth() + 1)
initialEnd.setDate(0)
initialEnd.setHours(23, 59, 0);

export const useDateIntervalState = () => {
    return useState<DateIntervalState>({
        start: initialStart,
        end: initialEnd,
    });
}

export interface DateIntervalState {
    start: Date;
    end: Date;
}

const DateIntervalPicker: React.FC<{ state: DateIntervalState; setState: Dispatch<SetStateAction<DateIntervalState>> }> = ({state, setState}) => {
    const handleSetStart = (date: MaterialUiPickersDate) => {
        setState({
            start: new Date(date?.toString() ?? initialStart),
            end: state.end,
        });
    };
    const handleSetEnd = (date: MaterialUiPickersDate) => {
        setState({
            start: state.start,
            end: new Date(date?.toString() ?? initialEnd),
        });
    }

    return (
        <div>
            <DateButton title="Fra" date={state.start} onDateChange={handleSetStart}/>
            <DateButton title="Til" date={state.end} onDateChange={handleSetEnd}/>
        </div>
    )
}


export interface DateButtonProps {
    title: string;
    date: Date;
    onDateChange: (date: MaterialUiPickersDate) => void;
}

export const DateButton: React.FC<DateButtonProps> = ({title, date, onDateChange}) => {
    return (
        <PopoverDatePicker value={date} onChange={onDateChange} label={title} >
            <Button>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <span style={{color: grey['600']}}>{title}</span>
                    <span style={{color: grey['700'], fontWeight: 600}}>{toLocalDayDateYearString(date)}</span>
                </Box>
            </Button>
        </PopoverDatePicker>
    )
}

export default DateIntervalPicker;