import React, {useState} from 'react';
import EmailForm from './EmailForm';
import ReplyForm from './ReplyForm';
import Paper from '@material-ui/core/Paper/Paper';
import Box from '@material-ui/core/Box/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, Theme} from '@material-ui/core';
import useLocationQuery from '../../hooks/useLocationQuery';
import MessageSent from './MessageSent';
import {WebMessageForm} from 'models/web';
import {webFormService} from "../../services/webFormService";

const useStyles = makeStyles<Theme>((theme) => ({
    paper: {
        minWidth: 360,
        maxWidth: theme.breakpoints.width('md'),
        padding: theme.spacing(8, 4),
        paddingTop: theme.spacing(4),
    },
}));

const ContactPage: React.FC = () => {
    const classes = useStyles();
    const {threadId, token} = useLocationQuery();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [sent, setSent] = useState(false);

    const handleContact = async (values: WebMessageForm) => {
        const response = await webFormService.createTread(values);
        if (response.success) {
            setSent(true);
        }
    };

    const handleReply = async (content: string) => {
        if (threadId && token) {
            const response = await webFormService.respondWithMessage(threadId, content, token);
            if (response.success) {
                setSent(true);
            }
        }
    };

    return (
        <Box pt={8} pb={8} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'center'}>
                <Paper className={classes.paper} elevation={isMobile ? 0 : 6}>
                    {sent
                        ? <MessageSent/>
                        : threadId
                            ? <ReplyForm onSubmit={handleReply}/>
                            : <EmailForm onSubmit={handleContact}/>}
                </Paper>
            </Box>
        </Box>
    );
};

export default ContactPage;
