import React, {useLayoutEffect, useState} from "react";
import EnhancedTable from "../../components/EnhancedTable";
import {TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell/TableCell";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Box from "@material-ui/core/Box";
import searchFilter from "../../lib/searchFilter";
import DateIntervalPicker, {useDateIntervalState} from "../../components/pickers/DateIntervalPicker";
import BasePage from "pages/BasePage";
import useCurrentCenter from "../../hooks/useCurrentCenter";
import appService from "../../services/appService";

const tableHeadLabels = [{
    name: 'Navn',
    field: 'name',
}, {
    name: 'Antal',
    field: 'eventCount',
}, {
    name: 'Total timer',
    field: 'hours',
}];

export interface UserTime {
    id: string;
    name: string;
    count: number;
    hours: number;
}

export const userTimeSearchFilter = (search: string) => ({name, count, hours}) => searchFilter({
    name: '' + name,
    count: '' + count,
    hours: '' + hours
}, search);

const EventUserTimeTable: React.FC = () => {
    const [dateInterval, setDateInterval] = useDateIntervalState();
    const [userTimes, setUserTimes] = useState<UserTime[]>([]);
    const centerId = useCurrentCenter().id;

    useLayoutEffect(() => {
        const fetchTimes = async () => {
            const response = await appService.events.calculateTimeOverview(centerId, dateInterval.start, dateInterval.end);
            if (response.success) {
                setUserTimes(response.value ?? []);
            }
        }
        fetchTimes();
    },[centerId, dateInterval.end, dateInterval.start])

    const errorMessage = userTimes.length === 0 ? "Ingen tilmeldte i det tidsinterval" : undefined;

    return (
        <BasePage>
            <EnhancedTable<UserTime>
                tableHeadLabels={tableHeadLabels}
                elements={userTimes}
                createPermission={false}
                loading={false}
                error={errorMessage}
                elementSearchFilter={userTimeSearchFilter}
                actions={
                    <Box display="flex" justifyContent="flex-end" pr={4}>
                        <DateIntervalPicker state={dateInterval} setState={setDateInterval}/>
                    </Box>
                }
            >
                {(sortedUsers) => sortedUsers.map(user => (
                    <TableRow key={user.id}>
                        <TableCell padding="none">
                            <ListItem>{user.name}</ListItem>
                        </TableCell>
                        <TableCell padding="none">
                            <ListItem>{user.count}</ListItem>
                        </TableCell>
                        <TableCell padding="none">
                            <ListItem>{user.hours}</ListItem>
                        </TableCell>
                        <TableCell padding="none"/>
                    </TableRow>
                ))}
            </EnhancedTable>
        </BasePage>
    )
}


export default EventUserTimeTable;