import {AppClient} from './appClient';
import RequestMethod from '../models/RequestMethod';
import Identifiable from '../models/Identifiable';
import AppResponse, {responseFromError} from "../models/httprequest/AppResponse";

export interface RequestFunction<Request, Response = Request> {
    (element: Request): AppResponse<Response>;
}

export interface ListRequestFunction<T> {
    (): AppResponse<T[]>;
}

export interface IdListRequestFunction<T> {
    (id: string): AppResponse<T[]>;
}

export interface IdRequestFunction<Response> {
    (id: string): AppResponse<Response>;
}


// export interface ParseFunctionType<T> {
//     (element: unknown): T;
// }
//
// export function parseFunction<T extends Identifiable>(): ParseFunctionType<T> {
//     return (element: unknown) => element as T;
// }

export interface RestServiceType<Request extends Partial<Identifiable>, Response = Request> {
    getAll: ListRequestFunction<Required<Response>>;
    getAllByCenterId: IdListRequestFunction<Required<Response>>;
    get: IdRequestFunction<Response>;
    create: RequestFunction<Request, Response>;
    update: RequestFunction<Request, Required<Response>>;
    delete: RequestFunction<Request, Response>;
}


export function restService<Request extends Partial<Identifiable>, Response = Request>(
    client: AppClient,
    path: string,
    // parse: ParseFunctionType<T> = parseFunction<Required<T & Identifiable>>()
): RestServiceType<Request, Response> {
    const elementPath = (id: string) => '' + path + '/' + id;
    return {
        async getAll() {
            return await client.fetchJSON(RequestMethod.GET, path);
        },
        async getAllByCenterId(id: string) {
            return await client.fetchJSON(RequestMethod.GET, path + "?centerId=" + id);
        },
        async get(id: string) {
            if (!id) return responseFromError(400);
            return client.fetchJSON(RequestMethod.GET, elementPath(id));
        },
        async create(element) {
            return client.fetchJSON(RequestMethod.POST, path, element);
        },
        async update(element) {
            if (!element.id) return responseFromError(400);
            return client.fetchJSON(RequestMethod.PUT, elementPath(element.id), element);
        },
        async delete(element) {
            if (!element.id) return responseFromError(400);
            return await client.fetchJSON(RequestMethod.DELETE, elementPath(element.id));
        },
    };
}

export default restService;
