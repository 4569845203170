import React from "react";
import StyledTooltip from "../common/StyledTooltip";
import {LoadedAsset, MAX_FILE_SIZE} from "../../models/file/Asset";

interface AssetPickerProps {
    assets: LoadedAsset[];
    setAssets: (assets: LoadedAsset[]) => void;
}

const AssetPickerWrapper: React.FC<AssetPickerProps> = ({
                                                            children,
                                                            assets,
                                                            setAssets
                                                        }) => {
    const onChange = async (files: File[]) => {

        const toLargeFiles: string[] = [];

        const filteredFiles = files.reduce((result: File[], element) => {
            const fileSize = Math.round((element.size));
            if (fileSize >= MAX_FILE_SIZE) {
                toLargeFiles.push(element.name);
                return result;
            }
            result.push(element);
            return result;
        }, []);

        // TODO
        const newAssets = await Promise.all(filteredFiles.map(async (file) => {
            const dataFile = await handleReadFile(file);
            return {
                file: file,
                fileName: file.name,
                dataUrl: dataFile?.toString() ?? ""
            };
        }));

        // TODO: Do not use alert for this
        // return a error instead
        if (toLargeFiles.length > 0) {
            alert("Max filstørrelse er 25 mb, disse filer er over: " + toLargeFiles.join(", "));
        }

        setAssets([...assets, ...newAssets])
    };

    const handleReadFile = (file: File): Promise<string | ArrayBuffer | null> =>
        new Promise<string | ArrayBuffer | null>(resolve => {
            const reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });

    return (
        <StyledTooltip title="Tilføj filer">
            <label htmlFor="upload-photo">
                <input
                    style={{display: "none"}}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    multiple
                    onChange={(event => onChange([...(event.target.files?.[Symbol.iterator]() ?? [])]))}
                />
                {children}
            </label>
        </StyledTooltip>
    )
};


export default AssetPickerWrapper;
