import React from 'react';
import BasePage from '../BasePage';
import {Divider, makeStyles, Paper, Typography} from '@material-ui/core';
import {useCurrentUser} from 'hooks/useCurrentUser';
import ProfileForm from './ProfileForm';
import ProfilePasswordForm from "./ProfilePasswordForm";
import Box from "@material-ui/core/Box";
import ProfileNameAndEmailForm from "./ProfileNameAndEmailForm";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(4),
    },
    card: {
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    padded: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));

const ProfilePage: React.FC = () => {
    const classes = useStyles();
    const user = useCurrentUser();

    return (
        <BasePage>
            <Paper className={classes.paper}>
                {/*{feedback && <LoadingOrError loading={true} error={feedback?.message} type="circular"/>}*/}

                <Typography variant="h5" component={'h3'} className={classes.title}>
                    Rediger dine oplysninger
                </Typography>


                <Divider style={{width: '100%', marginTop: 16}}/>
                <Box mt={2} mb={3} style={{fontWeight: 600, fontSize: 18}}>
                    Opdater navn og email
                </Box>
                <ProfileNameAndEmailForm user={user}/>


                <Divider style={{width: '100%', marginTop: 16}}/>
                <Box mt={2} mb={3} style={{fontWeight: 600, fontSize: 18}}>
                    Opdater oplysninger
                </Box>
                <ProfileForm currentUser={user}/>


                <Divider style={{width: '100%', marginTop: 16}}/>
                <Box mt={2} mb={3} style={{fontWeight: 600, fontSize: 18}}>
                    Skift din adgangskode
                </Box>
                <ProfilePasswordForm/>


            </Paper>
        </BasePage>
    );
};

export default ProfilePage;
