import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import {Container, Grid} from '@material-ui/core';

const lightGreen = '#60a73f';

const useStyles = makeStyles(theme => ({
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: lightGreen,
        '& svg': {
            height: theme.spacing(16),
            width: 'auto',
            padding: theme.spacing(16, 0),
        }
    },
    link: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: lightGreen,
        textDecoration: 'none',
        '&:active,:visited,:link': {
            color: lightGreen,
        }
    }
}))

export const MessageSent: React.FC = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="md">
            <Grid container>
                <Grid item xs={12}>
                    <h2 style={{color: lightGreen}}>{'Din besked er blevet sendt til dit lokale headspace-center'}</h2>
                </Grid>
                <Grid item xs={12} className={classes.icon}>
                    <CheckIcon/>
                </Grid>
                <Grid item xs={12} className={classes.link}>
                    <a href="https://www.headspace.dk">https://www.headspace.dk</a>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MessageSent;
