import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {useState} from "react";

const useScrollingUp = () => {
    const [scrollingUp, setScrollingUp] = useState(false);

    useScrollPosition(({prevPos,  currPos}) => {
        if (currPos.y === 0) {
            setScrollingUp(false);
        } else if (prevPos.y < currPos.y) {
            setScrollingUp(true);
        } else {
            setScrollingUp(false);
        }
    }, []);

    return scrollingUp;
}

export default useScrollingUp;
