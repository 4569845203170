import PostComment from "./PostComment";
import PostAuthor, {emptyPostAuthor} from "./PostAuthor";
import {Asset} from "./file/Asset";


export interface Post {
    id: string;
    centerId: string;
    centerIdAll?: boolean;
    content: string;
    files?: Asset[];
    createdAt: Date;
    updatedAt: Date;
    author: PostAuthor;
    comments?: PostComment[];
    centerIdList?: string[];
    functionIdList?: string[];
}

export const emptyPost: Post = {
    id: '',
    centerId: '',
    centerIdAll: false,
    content: '',
    files: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    author: emptyPostAuthor,
    // authorId: undefined,
    comments: [],
};

export default Post;

