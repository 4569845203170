import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    ListItem
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, {useState} from "react";
import PersonAvatar from "../PersonAvatar";
import TextEditField from "../TextEditField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {mobileMediaQuery} from "../../hooks/useMediaQueryMobile";
import StyledTooltip from "../common/StyledTooltip";
import DeleteIcon from '@material-ui/icons/Delete';

export const DeleteConfirmButton: React.FC<{ onConfirmClick: () => void }> = ({onConfirmClick}) => {
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const handleOpen = () => setOpenAlert(true);
    const handleClose = () => setOpenAlert(false);
    const handleConfirm = () => {
        handleClose()
        onConfirmClick && onConfirmClick()
    }

    return (
        <React.Fragment>
            <StyledTooltip title="Slet samtale">
                <IconButton onClick={handleOpen}>
                    <DeleteIcon/>
                </IconButton>
            </StyledTooltip>
            <Dialog
                open={openAlert}
                onClose={handleClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Du er ved at slette dette element. Er du sikker på, at dette er den ønskede handling?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Nej
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const useStyles = makeStyles(({palette}) => ({
    root: {
        backgroundColor: palette.action.hover,
        boxShadow: 'inset 0 -1px 0px 0px rgba(224, 224, 224)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    onMobile: {
        display: "none",
        ['@media ' + mobileMediaQuery]: {
            display: "block",
        }
    },
    subtitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        ['@media ' + mobileMediaQuery]: {
            display: "none",
        }
    }
}));

export interface ThreadHeaderProps {
    threadId: string | undefined;
    title: string | undefined;
    subtitle?: string;
    onBack: () => void;
    onTitleChange: (title: string) => void;
    onThreadDelete: () => void;
    color?: string;
    emptyAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
}

const ThreadHeader: React.FC<ThreadHeaderProps> = ({
                                                       threadId,
                                                       title,
                                                       subtitle,
                                                       onBack,
                                                       onTitleChange,
                                                       onThreadDelete,
                                                       color,
                                                       emptyAdornment,
                                                       endAdornment
                                                   }) => {
    const classes = useStyles();

    return (
        <ListItem className={classes.root}>
            <Box display='flex' alignItems='center' flexDirection='row' width='100%'>
                <div className={classes.onMobile}>
                    <IconButton size="small" onClick={onBack}>
                        <ArrowBackIcon/>
                    </IconButton>
                </div>
                <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <Box display="flex" flex={1}>
                        <PersonAvatar name={title} color={color}/>
                        <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
                            {threadId ? (
                                <React.Fragment>
                                    <TextEditField
                                        value={title}
                                        onSubmit={onTitleChange}
                                        tooltip='Ændrer navnet'
                                    />
                                    <div className={classes.subtitle}>
                                        <span>{subtitle}</span>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {emptyAdornment}
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                </Box>
                {threadId && (
                    <React.Fragment>
                        {endAdornment}
                        <DeleteConfirmButton onConfirmClick={onThreadDelete}/>
                    </React.Fragment>
                )}
            </Box>
        </ListItem>
    )
}

export default ThreadHeader;
