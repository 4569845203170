export interface ChatAuthor {
    id: string;
    firstName: string;
    lastName: string;
}

export const emptyChatAuthor: ChatAuthor = {
    id: '',
    firstName: '',
    lastName: ''
};