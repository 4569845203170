import restService, {RestServiceType} from "./restService";
import AppResponse from "../models/httprequest/AppResponse";
import {Asset} from "../models/file/Asset";
import {AppClient} from "./appClient";
import RequestMethod from "../models/RequestMethod";

export type FileService = RestServiceType<Asset> & {
    createFile: (file: File) => AppResponse<Required<Asset>>;
};

const filesPath = '/api/v1/files';

export function fileService(client: AppClient): FileService {
    return {
        ...restService<Asset>(client, filesPath),
        createFile: async (file) => {

            const form = new FormData();
            form.append('file', file);

            const response = await client.fetchForm<Required<Asset>>(RequestMethod.POST, filesPath, form);
            if (!response.success) return response;
            return response;
        }
    }
}

export default FileService;
