import {OptionalIdentifiable} from '../models/Identifiable';
import React, {useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import Dialog, {DialogProps} from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import {Backdrop, DialogContentText} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import RequestFeedbackDisplay from "./RequestFeedbackDisplay";
import RequestFeedback from "../models/httprequest/RequestFeedback";
import useMediaQueryMobile from 'hooks/useMediaQueryMobile';

export interface CrudDialogProps<T extends OptionalIdentifiable> {
    title?: string;
    element?: T;
    open?: boolean;
    loading?: boolean;
    feedback?: RequestFeedback;
    onSave?: () => void;
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
    onCancel: () => void;
    onDelete?: (element: T) => void;
    children: React.ReactNode;
    hideBackdrop?: boolean;
    contentPadding?: string | number;
    maxWidth?: DialogProps['maxWidth'];
}

export const CrudDialog = <T extends OptionalIdentifiable, >({children, title, element, open, loading = false, feedback, hideBackdrop = false, maxWidth = 'sm', contentPadding = 16, onSave, onCancel, onDelete, submitButtonRef}: CrudDialogProps<T>) => {
    const theme = useTheme();
    const isMobile = useMediaQueryMobile();

    const handleSaveClick = () => {
        if (onSave) return onSave();
        submitButtonRef?.current?.click && submitButtonRef.current.click()
    };

    const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
    const [deleteElement, setDeleteElement] = useState<T>();

    const handleClickOpen = () => {
        setOpenAlertDialog(true);
    };

    const handleClickClose = () => {
        setDeleteElement(undefined);
        setOpenAlertDialog(false);
    };

    const handleClickAccept = () => {
        if (onDelete && deleteElement) {
            onDelete(deleteElement);
            setDeleteElement(undefined);
        }
        setOpenAlertDialog(false);
    };

    return (
        <>
            <Dialog
                open={open || Boolean(element)}
                onClose={onCancel}
                maxWidth={maxWidth}
                fullWidth={true}
                hideBackdrop={hideBackdrop}
                fullScreen={isMobile}
                style={{overflow: "inherit"}}
            >
                <Backdrop style={{zIndex: 10, color: 'white'}} open={loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent style={{padding: contentPadding}}>
                    {children}
                    <RequestFeedbackDisplay feedback={feedback}/>
                </DialogContent>
                <DialogActions>
                    {element?.id && onDelete && (
                        <Button onClick={() => {
                            setDeleteElement(element);
                            handleClickOpen();
                        }}>
                            <DeleteIcon style={{marginRight: '4px', color: theme.palette.error.main}}/>
                            SLET
                        </Button>
                    )}
                    <Box flex={1}/>
                    <Button onClick={onCancel}>
                        <ClearIcon style={{marginRight: '4px', color: theme.palette.error.main}}/>
                        ANNULLER
                    </Button>
                    <Button onClick={handleSaveClick}>
                        <DoneIcon style={{marginRight: '4px', color: theme.palette.success.main}}/>
                        GEM
                    </Button>
                </DialogActions>
            </Dialog>
            <Box>
                <Dialog
                    open={openAlertDialog}
                    onClose={handleClickClose}
                >
                    <DialogContent>
                        <DialogContentText>
                            Du er ved at slette dette element. Er du sikker på at dette er den ønskede handling?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickClose} color="primary">
                            Nej
                        </Button>
                        <Button onClick={handleClickAccept} color="primary" autoFocus>
                            Ja
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default CrudDialog;
