import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {Box, IconButton, TextField, TextFieldProps, Tooltip, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import DiscardIcon from '@material-ui/icons/Close';
import SubmitIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
    sendButton: {
        marginLeft: theme.spacing(1),
    },
    done: {
        color: theme.palette.success.main
    },
    discard: {
        color: theme.palette.error.main
    }
}));

interface TextEditField {
    value: string;
    onSubmit: (name: string) => Promise<void>;
    tooltip: string;
}

export const TextEditField: React.FC<TextEditField> = ({value, onSubmit, tooltip}) => {
    const classes = useStyles();

    const [editValue, setEditValue] = useState<string | undefined>(undefined);

    const handleChange: TextFieldProps['onChange'] = event => setEditValue(event.target.value);

    const handleSubmit = async () => {
        await onSubmit(editValue ?? value);
        setEditValue(undefined);
    };

    const handleDiscard = () => {
        setEditValue(undefined);
    };

    useEffect(() => {
        setEditValue(undefined);
    }, [value]);

    if (editValue !== undefined) {
        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                <TextField value={editValue} onChange={handleChange}/>
                <IconButton onClick={handleSubmit} className={clsx([classes.sendButton, classes.done])} color="primary"
                            size="small">
                    <SubmitIcon/>
                </IconButton>
                <IconButton onClick={handleDiscard} className={clsx([classes.sendButton, classes.discard])} color="primary"
                            size="small">
                    <DiscardIcon/>
                </IconButton>
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Typography>{value}</Typography>
            <Tooltip title={tooltip} placement="bottom">
                <IconButton onClick={() => setEditValue(value)} className={classes.sendButton} color="primary" size="small">
                    <EditIcon/>
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default TextEditField;
