import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {AppEventFormValues} from "../../models/calendar";
import {useFilledTextFieldStyle} from "../../components/FilledTextField";
import Box from "@material-ui/core/Box";

interface StatusSelecterProps {
    values: AppEventFormValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const StatusSelecter: React.FC<StatusSelecterProps> = ({values, setFieldValue}) => {
    const classes = useFilledTextFieldStyle();

    const statusValueMap = {
        isCancelled: "Aflyst",
        isMoved: "Flyttet",
        isNoShow: "Afbud",
    };

    const renderValue = (selected: unknown): React.ReactNode => {
        if (typeof selected !== typeof ['']) {
            return '' + selected;
        }
        if ((selected as string[]).length === 0) {
            return "Status";
        }
        return (selected as string[]).map(id => statusValueMap[id]).join(', ');
    }

    const statusValue = [values.isCancelled && "isCancelled", values.isMoved && "isMoved", values.isNoShow && "isNoShow"].filter(_ => _);

    return (
        <Box className={classes.textField} width="100%">
            <Select
                labelId="status-multiselect-label"
                id="status-multiselect-label"
                multiple
                value={statusValue}
                renderValue={renderValue}
                fullWidth
                variant="filled"
                displayEmpty
            >
                <MenuItem value={undefined} disabled>Status</MenuItem>
                <MenuItem value="isCancelled" button={false}>
                    <ListItemText primary={statusValueMap["isCancelled"]}/>
                    <Checkbox
                        id="isCancelled"
                        name="isCancelled"
                        checked={values.isCancelled}
                        onChange={(event) => setFieldValue('isCancelled', event.target.checked, false)}
                    />
                </MenuItem>
                <MenuItem value="isMoved" button={false}>
                    <ListItemText primary={statusValueMap["isMoved"]}/>
                    <Checkbox
                        id="isMoved"
                        name="isMoved"
                        checked={values.isMoved}
                        onChange={(event) => setFieldValue('isMoved', event.target.checked, false)}
                    />
                </MenuItem>
                <MenuItem value="isNoShow" button={false}>
                    <ListItemText primary={statusValueMap["isNoShow"]} />
                    <Checkbox
                        id="isNoShow"
                        name="isNoShow"
                        checked={values.isNoShow}
                        onChange={(event) => setFieldValue('isNoShow', event.target.checked, false)}
                    />
                </MenuItem>
            </Select>
        </Box>
    )
}

export default StatusSelecter;
