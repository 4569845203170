import React from 'react';
import { Box, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

interface SendButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

const SendButton: React.FC<SendButtonProps> = ({ onClick, disabled }) => {
    return (
        <Box ml={2}>
            <Button
                onClick={onClick}
                disabled={disabled}
                variant="contained"
                color="secondary"
                disableElevation
            >
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row">
                        <Box mr={1} component={'b'}>SEND</Box>
                        <SendIcon/>
                    </Box>
                </Box>
            </Button>
        </Box>
    )
}

export default SendButton;
