import searchFilter from '../lib/searchFilter';

export interface Center {
    id?: string;
    name: string;
    keywords: string[];
}

export const emptyCenter: Center = {
    name: '',
    keywords: [],
};

export const centerSearchFilter = (search: string) => ({name, keywords}: Center) => searchFilter({
    name,
    keywords: '' + keywords,
}, search);


export default Center;
