import {initialRequestState, requestActions, RequestActionType, requestReducer, RequestState} from './RequestState';
import User from '../models/User';
import appService from '../services/appService';

const stateName = 'USERS_STATE';

export type UsersState = RequestState<Required<User>>;

export const initialUsersState = initialRequestState<Required<User>>();

export const userListActions: RequestActionType<User> = {
    ...requestActions<User>(stateName, appService.users),
};

export const usersReducer = requestReducer<Required<User>>(stateName, initialUsersState);

export default usersReducer;
