import restService, {RestServiceType} from "./restService";
import AppEvent from "../models/calendar/AppEvent";
import RequestMethod from "../models/RequestMethod";
import AppResponse from "../models/httprequest/AppResponse";
import {AppClient} from "./appClient";
import stableSortByKey from "lib/stableSortByKey";
import {UserTime} from "../pages/calendar/EventUserTimeTable";

export interface ShiftPlan {
    centerId: string;
    weekDays: boolean[];
    maxParticipants: number;
    startDate: Date;
    endDate: Date;
    shiftIntervals: ShiftInterval[];
    allDay: boolean;
    reminderMillis: number;
}

export interface ShiftInterval {
    title: string;
    startTime: string; // 10:00
    endTime: string; // 14:00
}

export type EventService = RestServiceType<AppEvent> & {
    createShiftPlan: (shiftPlan: ShiftPlan) => AppResponse<Required<AppEvent>[]>;
    deleteShiftPlan: (id?: string) => AppResponse<Required<AppEvent>[]>;
    toggleMe: (id?: string) => AppResponse<Required<AppEvent>[]>;
    getAllByCenterIdForRange: (centerId: string, start: Date, end: Date) => AppResponse<Required<AppEvent>[]>;
    calculateTimeOverview: (centerId: string, start: Date, end: Date) => AppResponse<Required<UserTime>[]>;
};

const path = "/api/v1/events";

export function eventService(client: AppClient): EventService {
    return {
        ...restService<AppEvent>(client, '/api/v1/events'),
        getAllByCenterId: async (centerId) => {
            const response = await client.fetchJSON<Required<AppEvent>[]>(RequestMethod.GET, path + "?centerId=" + centerId);
            if (!response.success) return response;
            response.value = response.value.map(event => {
                event.participants = stableSortByKey(event.participants, 'name');
                return event;
            });
            return response;
        },
        getAllByCenterIdForRange: async (centerId, start, end) => {
            const query = "?centerId=" + centerId + "&start=" + start.toJSON() + "&end=" + end.toJSON();
            const response = await client.fetchJSON<Required<AppEvent>[]>(RequestMethod.GET, path + query);
            if (!response.success) return response;
            response.value = response.value.map(event => {
                event.participants = stableSortByKey(event.participants.filter(e => e !== null), 'name');
                return event;
            })
            return response;
        },
        calculateTimeOverview: async (centerId, start, end) => {
            const query = "?centerId=" + centerId + "&start=" + start.toJSON() + "&end=" + end.toJSON();
            return await client.fetchJSON<Required<UserTime>[]>(RequestMethod.GET, path + "/time-overview" + query);
        },
        /*getAll: async () => {
            const response = await client.fetchJSON<Required<AppEvent>[]>(RequestMethod.GET, '/api/v1/events');
            if (!response.success) return response;
            response.value = response.value.map(event => {
                event.participants = stableSortByKey(event.participants, 'name');
                return event;
            });
            return response;
        },*/
        createShiftPlan: (shiftPlan) => {
            return client.fetchJSON(RequestMethod.POST, path + '/shift-plan', {
                ...shiftPlan,
            });
        },
        deleteShiftPlan: (id) => {
            return client.fetchJSON(RequestMethod.DELETE, path + '/shift-plan/' + id);
        },
        toggleMe: (id) => {
            return client.fetchJSON(RequestMethod.PUT, path + '/' + id + '/toggle-me');
        },
    }
}


export default EventService;