import React, {useLayoutEffect, useRef, useState} from "react";
import AppEvent from "../../models/calendar/AppEvent";
import BasePage from "../BasePage";
import {Box, DialogContentText} from "@material-ui/core";
import {toLocalDateTimeString, toLocalDayDateString} from "../../lib/toLocalISO";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ParticipantDisplay from "../../components/ParticipantDisplay";
import useCrudRequestState from "../../hooks/useCrudRequestState";
import eventsActions from "../../store/EventsState";
import eventActions from "../../store/EventsState";
import LoadingOrError from "components/LoadingOrError";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import NotesIcon from "@material-ui/icons/Notes";
import {LocationOn} from '@material-ui/icons';
import {EventStatusTag} from "../../components/EventCard";
import EventViewDialog from "../calendar/EventViewDialog";
import useCurrentCenter from "../../hooks/useCurrentCenter";
import {addWeeks, subDays, subWeeks} from "date-fns";

export const ShiftPlannerPageMobile: React.FC = () => {
    const [{start, end}, setRange] = useState({start: subDays(new Date(), 1), end: addWeeks(new Date(), 4) });
    const {state: {elements, feedback}, dispatch} = useCrudRequestState(state => state.events, eventsActions, false);
    const [viewEvent, setViewEvent] = useState<AppEvent>();
    const ref = useRef<HTMLDivElement>(null);
    const centerId = useCurrentCenter().id;

    const handleToggleMeClick = (event: AppEvent) => {
        dispatch(eventActions.toggleMe(event.id))
    }

    const handleScroll = () => {
        if (ref.current) {
            if (ref.current.scrollTop === 0) {
                const newStart = subWeeks(start, 1);
                dispatch(eventActions.getAllByCenterIdForRange(centerId, newStart, end));
                ref.current.scrollTo({
                    left: 0,
                    top: 60,
                    behavior: "smooth",
                });
                setRange({ start: newStart, end });
            } else if (ref.current.scrollTop === (ref.current as HTMLDivElement & { scrollTopMax: number }).scrollTopMax) {
                const newEnd = addWeeks(end, 2);
                dispatch(eventActions.getAllByCenterIdForRange(centerId, start, newEnd));
                setRange({ start, end: newEnd });
            }
        }
    }

    const refIsSet = ref.current !== null;
    useLayoutEffect(() => {
        if (refIsSet && ref.current) {
            ref.current.scrollTo({
                left: 0,
                top: 60,
                behavior: "auto",
            });
        }
    },[refIsSet])

    return (
        <BasePage >
            <div style={{ width: "100%", height: "100%", overflow: "scroll", overscrollBehavior: "contain", paddingTop: 60 }} ref={ref} onScroll={handleScroll}>
                <LoadingOrError loading={false} error={elements.length === 0 ? "Ingen vagter" : undefined}>
                    {elements.map((event, index) => {
                        const lastEvent = index !== 0 ? elements[index - 1] : undefined;
                        const lastEventIsOtherDay = (lastEvent?.start === undefined) || (new Date(lastEvent.start).getDate() !== new Date(event.start).getDate());
                        return (
                            <React.Fragment key={event.id}>
                                {lastEventIsOtherDay && <Box style={{fontWeight: 600, fontSize: 20}}>
                                    <Box pl={2} pb={0.5}>{toLocalDayDateString(event.start)}</Box>
                                    <Divider/>
                                </Box>}

                                <Box
                                    m={2} mt={1.5}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    style={{borderRadius: 7, backgroundColor: 'white'}}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        style={{
                                            position: 'relative',
                                            backgroundColor: event.type?.color,
                                            color: 'white',
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4
                                        }}
                                        onClick={() => setViewEvent(event)}
                                    >
                                        <Box p={1} pb={0} style={{
                                            fontSize: 17,
                                            fontWeight: 600,
                                            overflow: "hidden",
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            paddingRight: 48,
                                            display: 'flex',
                                        }}>
                                            <EventStatusTag event={event} />
                                            {event.title}
                                        </Box>

                                        <Box p={1} pt={0} style={{fontSize: 14}}>
                                            {event.allDay ? "Heldagsbegivenhed" : toLocalDateTimeString(event.start) + " - " + toLocalDateTimeString(event.end)}
                                        </Box>

                                        <div style={{
                                            position: 'absolute',
                                            right: 0,
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <IconButton size={"medium"} onClick={() => handleToggleMeClick(event)}>
                                                <PersonAddIcon style={{color: 'white'}}/>
                                            </IconButton>
                                        </div>

                                    </Box>

                                    <EventDisplayRow icon={<LocationOn/>}>{event?.location}</EventDisplayRow>
                                    <EventDisplayRow icon={<NotesIcon/>}>{event?.description}</EventDisplayRow>
                                    {(event?.participants?.length ?? 0) > 0 && (
                                        <Box p={1} display="flex" flexDirection="column">
                                            <span>{event.participants?.map(ParticipantDisplay)}</span>
                                        </Box>
                                    )}
                                </Box>
                            </React.Fragment>
                        )
                    })}
                </LoadingOrError>
            </div>

            <EventViewDialog event={viewEvent} mobile={true} onClose={() => setViewEvent(undefined)} />

            <Dialog
                open={!!feedback}
                onClose={() => dispatch(eventActions.cancelError())}
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {feedback?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dispatch(eventActions.cancelError())} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </BasePage>
    )
}

interface EventDisplayRowProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
}

const EventDisplayRow: React.FC<EventDisplayRowProps> = ({icon, children, className}) => {
    if (!children) return <React.Fragment/>

    return (
        <Box display={'flex'} color={'black'} fontSize={15} alignItems={'center'} minHeight={32} pr={2}>
            <Box width={30} maxHeight={52}>
                {icon}
            </Box>
            <div className={className} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                {children}
            </div>
        </Box>
    )
}

export default ShiftPlannerPageMobile;