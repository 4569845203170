import React, {createRef, useEffect, useState} from 'react';
import {render} from "react-dom";
import {useParams} from 'react-router-dom';
import {push} from 'connected-react-router';
import BasePage from 'pages/BasePage';
import useCrudRequestState from "../../hooks/useCrudRequestState";
import AppEvent, {emptyAppEvent, eventFromEventAPI} from "../../models/calendar/AppEvent";
import eventActions, {EventDeleteOption, EventsState} from "../../store/EventsState";
import {DialogContentText} from "@material-ui/core";
import useMediaQueryMobile from "../../hooks/useMediaQueryMobile";
import Paper from "@material-ui/core/Paper";
import StyledCalendar from "../../components/StyledCalendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import CrudDialog from "../../components/CrudDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import {EventForm} from "../calendar/EventForm";
import ShiftPlanForm from "./ShiftPlanForm";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ShiftPlannerPageMobile from "./ShiftPlannerPageMobile";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import ShiftPlannerToolbar, {CALENDAR_VIEW_KEY, View} from "./ShiftPlannerToolbar";
import RenderedEvent from "./RenderedEvent";
import useCurrentUser from "../../hooks/useCurrentUser";
import {checkPermission} from "../../models/User";
import EventViewDialog from "../calendar/EventViewDialog";
import {EventDropInfo} from "../calendar/CalendarPage";

const ShiftPlannerPage: React.FC = () => {
    const isMobile = useMediaQueryMobile();
    return isMobile ? <ShiftPlannerPageMobile/> : <ShiftPlannerPageDesktop/>;
};

const views = {
    timeGridOneDay: {
        type: 'timeGridWeek',
        duration: {days: 1},
    },
    dayGridFourDay: {
        type: 'dayGrid',
        duration: {days: 7},
        hiddenDays: [0, 5, 6],
    },
    dayGridMonth: {
        type: 'dayGridMonth',
    }
}

// const nowMonday = new Date(Date.now() - (Math.max(0, new Date().getDay()-1) * 24 * 60 * 60 * 1000));

const ShiftPlannerPageDesktop: React.FC = () => {
    const calendarRef = React.createRef<FullCalendar>();
    const currentUser = useCurrentUser();
    const [deleteOption, setDeleteOption] = useState<EventDeleteOption>('');
    const [view, setView] = useState<View>({
        title: '',
        currentStart: '',
        currentEnd: '',
        type: ''
    });
    const submitButtonRef: React.RefObject<HTMLButtonElement> = createRef<HTMLButtonElement>();
    const {state: {loading, feedback, elements, editElement, editShiftPlan}, dispatch, setEditElement, updateElement, deleteElement} = useCrudRequestState<AppEvent, EventsState>((state) => state.events, eventActions, false);
    const [viewEvent, setViewEvent] = useState<AppEvent>();
    const defaultView = localStorage.getItem(CALENDAR_VIEW_KEY) ?? "dayGridWeek";

    const handleDatesRender = ({view}) => setView(view);
    const handleViewChange = (view: View) => setView(view);
    const handleSetEditShiftPlan = (value: boolean) => () => dispatch(eventActions.setEditShiftPlan(value));
    const handleNewShiftPlan = () => dispatch(eventActions.setEditShiftPlan(true));
    const handleAddShiftEvent = () => setEditElement({...emptyAppEvent, typeId: "shift"});
    const handleAddTalkEvent = () => setEditElement({...emptyAppEvent, typeId: "talk"});
    const handleAddOtherEvent = () => setEditElement({...emptyAppEvent, typeId: "other"});
    const handleClose = () => dispatch(eventActions.cancelError())
    const handleToggleUserClick = (event: AppEvent) => {
        dispatch(eventActions.toggleMe(event.id))
    };
    const handleDeleteClick = () => {
        if (editElement?.batchId) {
            return setDeleteOption('single');
        }
        deleteElement(editElement);
    };
    const handleConfirmDelete = () => {
        dispatch(eventActions.deleteEvent(editElement, deleteOption));
        setDeleteOption('');
    };
    const handleCancelClick = () => {
        setEditElement(undefined);
        dispatch(push('/dashboard/shift-planner'));
    };
    const handleEventRender = ({onEventClick, onToggleUserClick}) => (info) => {
        const event = eventFromEventAPI(info.event);
        render(<RenderedEvent
            event={event}
            onEventClick={onEventClick}
            onToggleUserClick={onToggleUserClick}
        />, info.el);
        return info.el;
    };
    const handleEventDrop = (dropInfo: EventDropInfo) => {
        updateElement(eventFromEventAPI(dropInfo.event));
    };

    const correctType = (event: AppEvent) => (event.type?.id === "shift" || event.type?.id === "talk" || event.type?.id === "other");
    const events = elements?.filter(event => correctType(event));
    const eventId = useParams();
    const selectedEvent = events.find((event) => event.id === eventId);

    useEffect(() => {
        if (selectedEvent) {
            setEditElement(selectedEvent);
        }
    }, [selectedEvent, dispatch, setEditElement]);

    return (
        <BasePage>
            <Paper style={{height: 'fit-content'}}>
                <ShiftPlannerToolbar
                    calendarRef={calendarRef}
                    view={view}
                    onChangeView={handleViewChange}
                    onAddShiftEvent={handleAddShiftEvent}
                    onAddTalkEvent={handleAddTalkEvent}
                    onAddOtherEvent={handleAddOtherEvent}
                    onNewShiftPlan={handleNewShiftPlan}
                />
                <StyledCalendar
                    calendarRef={calendarRef}
                    height="auto"
                    defaultView={defaultView}
                    views={views}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    datesRender={handleDatesRender}
                    events={events}
                    editable={true}
                    eventDrop={handleEventDrop}
                    eventRender={handleEventRender({
                        onEventClick: checkPermission(currentUser, 'Permissions.Calendar.Update') ? setEditElement : setViewEvent,
                        onToggleUserClick: handleToggleUserClick,
                    })}
                />
            </Paper>
            <CrudDialog
                title="Opret vagtplan"
                loading={loading}
                feedback={feedback}
                open={editShiftPlan}
                submitButtonRef={submitButtonRef}
                onCancel={handleSetEditShiftPlan(false)}
            >
                <ShiftPlanForm
                    submitButtonRef={submitButtonRef}
                    onSubmit={(shiftPlan) => dispatch(eventActions.createShiftPlan(shiftPlan))}
                />
            </CrudDialog>

            <EventViewDialog event={viewEvent} onClose={() => setViewEvent(undefined)}/>

            <CrudDialog<AppEvent>
                element={editElement}
                loading={loading}
                maxWidth={"md"}
                feedback={feedback}
                contentPadding={0}
                submitButtonRef={submitButtonRef}
                onCancel={handleCancelClick}
                onDelete={handleDeleteClick}
            >
                <EventForm
                    event={editElement}
                    onSubmit={updateElement}
                    submitButtonRef={submitButtonRef}
                    eventType={editElement?.typeId ?? "talk"}
                />
            </CrudDialog>
            <Dialog
                open={!editElement && !!feedback && feedback.severity !== 'success'}
                onClose={handleClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {feedback?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title={"Slet tilbagevendende vagt"}
                open={Boolean(deleteOption)}
                onClose={() => setDeleteOption('')}
                onConfirm={handleConfirmDelete}
            >
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="delete-option"
                        name="delete-option"
                        value={deleteOption}
                        onChange={(e) => setDeleteOption(e.target.value as EventDeleteOption)}
                    >
                        <FormControlLabel value="single" control={<Radio/>} label="Denne vagt"/>
                        <FormControlLabel value="all" control={<Radio/>} label="All vagter"/>
                    </RadioGroup>
                </FormControl>
            </ConfirmDialog>
        </BasePage>
    );
};

export default ShiftPlannerPage;
